import { useEffect, useState } from 'react';
import { userService } from '../_services';

function LinkedInCallBack() {

    const [linkedInStatus, setLinkedInStatus] = useState(false) as any;
    const [linkedInCode, setLinkedInCode] = useState('') as any;


    useEffect(() => {
        let code = new URLSearchParams(window.location.search).get("code");
        getLinkedInAccesstoken(code);
        // setLinkedInCode(code);
    }, []);

    function getLinkedInAccesstoken(code: any) {
        let url = `https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&code=${code}&redirect_uri=${process.env.REACT_APP_LINKED_IN_CLIENT_URI}/auth/linkedin/callback&client_id=${process.env.REACT_APP_LINKED_IN_CLIENT_ID}&client_secret=${process.env.REACT_APP_LINKED_IN_CLIENT_SECRET}`;
        userService.getLinkedInAccessToken(url).then((response: any) => {

        }).catch((err: any) => {

        })
    }

    function handleLinkedInShare() {
        let params = {
            "share_type": "L",
            "competition_id": String(localStorage.getItem("competition_id")),
            "time": localStorage.getItem("notification_timer"),
        };
        userService.shareAd(params).then(resp => {
            console.log(resp)
            localStorage.setItem('isLinkedInTrigger', "true");
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
        })
    }

    return (
        <>
        </>
    )
}

export default LinkedInCallBack;