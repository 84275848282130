import { useState } from "react";
import { Form } from "react-bootstrap";
import { userService } from '../../_services';
import { toast } from 'react-toastify';
import { Loader } from '../Loader';
import ProfileImage from '../../images/profile.png';


const Step2 = (props: any) => {
	const [showloader, setShowLoader] = useState("none");
	const [profile, setProfie] = useState(props.defaultData.profileImageshow);

	function readURL(input: any) {
		if (input.files && input.files[0]) {
			const image = new Image();
			image.src = URL.createObjectURL(input.files[0])
			image.onload = function () {
				if (image.width > 2500 || image.height > 2000) {
					toast.error(`Image size is too large, Please select image with smaller size with dimension: 2500px X 2000px`);
					return false;
				} else {
					var get_ext = input.files[0].name.split('.');
					var exts = ['png', 'jpg', 'jpeg', 'gif'];
					get_ext = get_ext.reverse();
					var a = exts.indexOf(get_ext[0].toLowerCase());
					if (a > -1) {
						setProfie(input.files[0])
						props.defaultData.profileImage = input.files[0]

						var reader = new FileReader();
						reader.onload = function (e: any) {
							setProfie(e.target.result)
							props.defaultData.profileImageshow = e.target.result
						}
						reader.readAsDataURL(input.files[0]);
						return true;
					} else {
						toast.error(`Only png, jpg, jpeg, gif formats are allowed`);
						return false;
					}
				}
			}
		}
	}

	const nextstep = () => {
		setShowLoader("flex");
		if(props.defaultData.profileImage){
			var formData = new FormData();
			formData.append("profileImage", props.defaultData.profileImage);
			userService.createUserProfile(formData).then(function (response: any) {
				localStorage.setItem('profileImage', response.data.data.user.profileImage);
				setShowLoader("none");
				props.next();
			}).catch(function (error: any) {
				setShowLoader("none");
			});
		}else{
			props.next();
		}
	}
	return (

		<section className="loginsec">
			<Loader status={showloader} />
			<div className="right_side py-5">
				<div className="container">
					<div className="row">
						<aside className="col-xl-5 col-lg-7 col-sm-12 m-auto">
							<div />
							<div className="login_form mt-4">
								<div className="text-center">
									{/* <p className="text-right">
                                        <span className="skip" onClick={props.next}>SKIP</span>
                                    </p> */}
									<p className="text-secondary">STEP 2</p>
									{props.defaultData.editProfile ? (<h4>Update Your Profile</h4>) : (<h4>Create Your Profile</h4>)}
									<p>Select a picture from your device or take a picture to use as your
										profile picture. This will help your friends and family find you on
										the app where they can invite you to private groups and fun
										competition that improve your health.</p>
								</div>
								<div className="prilfe_image position-relative my-4">
									{/* <button className="btn bg-transparent brder-0 camerabtn">
                                        <img src={require('../../images/camera.svg').default} alt="" width="35px" />
                                    </button> */}
									<Form.Group className="mb-3" controlId="profilePic">
										<Form.Control type="file" className="input103 upload-profile-pic" onChange={(e: any) => readURL(e.target)} />
									</Form.Group>
									<div className="profile_box m-auto">
										<img src={profile?profile:''} alt="profile" width="90px" onError={(event: any) => { event.target.src = ProfileImage; }} />
									</div>
									{/* <button className="btn bg-transparent brder-0 imagebtn">
                                        <img src={require('../../images/image.svg').default} alt="" width="35px" />
                                    </button> */}
								</div>
								<div className="text-center mt-4">
									<button onClick={props.prev} className="backbtn btn shadow text-white mr-2">Back</button>
									<button onClick={nextstep} className="loginbtn btn shadow text-white">Next</button>
									<p className="forgotpassword">Profile 40% Complete </p>
								</div>
							</div>
						</aside>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Step2;