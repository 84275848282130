import { useState } from "react";
import { Form, Container, Button, Row, Col, Modal } from 'react-bootstrap'
import moment from 'moment';
import { Formik, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";

import { userService, formValidation } from '../_services';
import AddPrec from "./AddPrec";
import { Loader } from "./Loader";
import { PRESCRIPTION_CATEGORY } from '../config/constants';

const AddPrescription = (props: any) => {
    const [prescriptions, setkeyvalues] = useState([{
        prescription: {
            'at_time': "",
            'dosage': "",
            'dosage_unit': "",
            'end_time': "",
            'name': "",
            'start_time': "",
            'times_in_day': ""
        }
    }]) as any;

    const defaultData = {
        at_time: '',
        dosage: '',
        dosage_unit: 'MG',
        end_time: '',
        prescription_name: '',
        start_time: '',
        times_in_day: ''
    }

    let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

    const addPrescriptionSchema = Yup.object().shape({
        prescription: Yup.array().of(
            Yup.object().shape({
                at_time: Yup.string().required('At time Field is required'),
                dosage: Yup.number().required('Dosage is required').min(0, 'Dosage is required').max(99999, '').typeError('Dosage is required').test(
                    "is-decimal",
                    "",
                    (val: any) => {
                        if (val != undefined) {
                            return patternTwoDigisAfterComma.test(val);
                        }
                        return true;
                    }
                ),
                dosage_unit: Yup.string().required('Dosage Unit is required'),
                end_time: Yup.string().required('End time is required'),
                prescription_name: Yup.string().required('Name is required').trim(),
                start_time: Yup.string().required('Start time is required'),
                times_in_day: Yup.string().required('Time in Day is required')
            })
        )
    });

    const [addPrescription, setAddPrescription] = useState({
        prescription: [{
            at_time: '',
            dosage: '',
            dosage_unit: 'MG',
            end_time: '',
            prescription_name: '',
            start_time: '',
            times_in_day: ''
        }]
    }) as any;

    const prop = { prescriptions, setkeyvalues };
    const [show, setShow] = useState(false);
    const [showerr, setShowErr] = useState(false);
    const handleCloseE = () => setShowErr(false);
    const handleShow = () => setShow(true);
    const [showLoader, setShowLoader] = useState("none");
    const handleClose = () => {
        window.location.href = "/";
        setShow(false);
    }
    const submit = () => {
        // let values:any = {}
        let values = {
            "dosage": "",
            "prescription_name": "",
            "dosage_unit": "",
            "start_date": "",
            "times_in_day": "",
            "at_time": "",
            "end_date": ""
        };

        let validate = formValidation.addpres_Validation(prescriptions);
        if (validate) {
            setShowLoader("flex");
            // improved code removed warning in terminal
            prescriptions.forEach((data: any, index: number) => {
                if (index == 0) {
                    values.prescription_name = data.prescription.name;
                    values.dosage = data.prescription.dosage;
                    values.dosage_unit = data.prescription.dosage_unit;
                    values.start_date = data.prescription.start_time;
                    values.end_date = data.prescription.end_time;
                    values.times_in_day = data.prescription.times_in_day;
                    values.at_time = data.prescription.at_time;
                } else {
                    values.prescription_name = values.prescription_name + "~" + data.prescription.name;
                    values.dosage = values.dosage + "~" + data.prescription.dosage;
                    values.dosage_unit = values.dosage_unit + "~" + data.prescription.dosage_unit;
                    values.start_date = values.start_date + "~" + data.prescription.start_time;
                    values.end_date = values.end_date + "~" + data.prescription.end_time;
                    values.times_in_day = values.times_in_day + "~" + data.prescription.times_in_day;
                    values.at_time = values.at_time + "~" + data.prescription.at_time;
                }
            })

            // prewritten code showing warning
            // debugger
            //             prescriptions.map(function (p: any, index: any) {
            //                 if (index == 0) {
            //                     values.prescription_name = p.prescription.name;
            //                     values.dosage = p.prescription.dosage;
            //                     values.dosage_unit = p.prescription.dosage_unit;
            //                     values.start_date = p.prescription.start_time;
            //                     values.end_date = p.prescription.end_time;
            //                     values.times_in_day = p.prescription.times_in_day;
            //                     values.at_time = p.prescription.at_time;
            //                 } else {
            //                     values.prescription_name = values.prescription_name + "~" + p.prescription.name;
            //                     values.dosage = values.dosage + "~" + p.prescription.dosage;
            //                     values.dosage_unit = values.dosage_unit + "~" + p.prescription.dosage_unit;
            //                     values.start_date = values.start_date + "~" + p.prescription.start_time;
            //                     values.end_date = values.end_date + "~" + p.prescription.end_time;
            //                     values.times_in_day = values.times_in_day + "~" + p.prescription.times_in_day;
            //                     values.at_time = values.at_time + "~" + p.prescription.at_time;
            //                 }
            //             })
            //    console.log(JSON.stringify(values),"=====")
            // debugger
            console.log('userService.add_prescription...', JSON.stringify(values));
            userService.add_prescription(values).then(function (response: any) {
                handleShow();
                setShowLoader("none");
                localStorage.setItem("prescription", "yes");
            }).catch(function (error: any) {
                if (error.response.status == 401) {
                    localStorage.clear();
                    window.location.href = '/';
                }
                setShowLoader("none");
            });
        } else {
            setShowErr(true);
        }
    }

    const back = () => {
        localStorage.setItem("prescription", "yes");
        window.location.href = "/";
    }


    function submitForm(value: any) {
        let values = {
            "dosage": "",
            "prescription_name": "",
            "dosage_unit": "",
            "start_date": "",
            "times_in_day": "",
            "at_time": "",
            "end_date": ""
        };

        value.prescription.forEach((data: any, index: number) => {
            if (index == 0) {
                values.prescription_name = data.prescription_name;
                values.dosage = data.dosage;
                values.dosage_unit = data.dosage_unit;
                values.start_date = data.start_time;
                values.end_date = data.end_time;
                values.times_in_day = data.times_in_day;
                values.at_time = data.at_time;
            } else {
                values.prescription_name = values.prescription_name + "~" + data.prescription_name;
                values.dosage = values.dosage + "~" + data.dosage;
                values.dosage_unit = values.dosage_unit + "~" + data.dosage_unit;
                values.start_date = values.start_date + "~" + data.start_time;
                values.end_date = values.end_date + "~" + data.end_time;
                values.times_in_day = values.times_in_day + "~" + data.times_in_day;
                values.at_time = values.at_time + "~" + data.at_time;
            }
        })
        setShowLoader("flex");
        addPrescriptionApi(values);
    }

    // API for add prescription
    function addPrescriptionApi(values: any) {
        userService.add_prescription(values).then(function (response: any) {
            handleShow();
            setShowLoader("none");
            localStorage.setItem("prescription", "yes");
        }).catch(function (error: any) {
            if (error.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            setShowLoader("none");
        });
    }

    let startDateMinMaxObject = {
        min: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        max: moment().format('YYYY-MM-DD')
    };

    return (
        <>
            <section className="bodyvital_section py-5">
                <Loader status={showLoader} />
                <Container>
                    <div className="inner_box">
                        <Row className="bodyvitals_head">
                            <Col md={6}>
                                <h5>
                                    <span onClick={back}>
                                        <img src={require('../images/back.svg').default} alt="back" />
                                    </span>
                                </h5>
                                <h2>Add Prescription</h2>
                                <p>Make sure your prescriptions are upto date </p>
                            </Col>
                            <Col md={12}>
                                <hr />
                            </Col>
                        </Row>
                        <Row className="metrics_row">
                            <Col lg={8} md={8}>
                                <Formik
                                    initialValues={addPrescription}
                                    validationSchema={addPrescriptionSchema}
                                    onSubmit={(values) => {
                                        submitForm(values)
                                    }}>
                                    {({ errors, touched, values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                        <form onSubmit={handleSubmit} autoComplete="off">
                                            <FieldArray name="prescription">
                                                {
                                                    ({ insert, remove, push }) => (
                                                        <div>
                                                            {
                                                                values.prescription.map((data: any, index: any) => {
                                                                    // dynamic handling of maxLength of dosage
                                                                    //  if dosage is 20.45 then maxLength will be 5
                                                                    // if dosage is 1.65 then maxLength will be 4
                                                                    // if dosage is .65 then maxLength will be 3
                                                                    // if there is no decimal then maxLength will be 5
                                                                    let maxLength = data.dosage.includes('.') ? data.dosage.split('.')[0].length === 2 ? 5 : data.dosage.split('.')[0].length === 1 ? 4 : data.dosage.split('.')[0].length === 0 ? 3 : 5 : 5
                                                                    return (
                                                                        <Row className="metric_form">
                                                                            <Col md={12}>
                                                                                <div className="prescription-delete">
                                                                                    <h3>#{index + 1} Prescription</h3>
                                                                                    {(values.prescription.length > 1) ? (<h4 onClick={() => remove(index)}><i className="fa fa-trash" aria-hidden="true" /> Delete</h4>) : (<></>)}
                                                                                </div>
                                                                                <Form.Group className="mb-3" controlId="prescription_name">
                                                                                    <Form.Control maxLength={50} type="text" name={`prescription.${index}.prescription_name`} value={data.prescription_name} placeholder="Enter Name" onChange={handleChange} />
                                                                                </Form.Group>
                                                                                <ErrorMessage name={`prescription.${index}.prescription_name`} component="div" className="error mb-2" />
                                                                            </Col>
                                                                            <Col md={12}><h4>Dosage</h4></Col>
                                                                            <Col md={6}>
                                                                                <Form.Group className="mb-3" controlId="dosage">
                                                                                    <Form.Control maxLength={maxLength} type="text" name={`prescription.${index}.dosage`} value={data.dosage} placeholder="Enter Dosage" onChange={handleChange} />
                                                                                </Form.Group>
                                                                                <ErrorMessage name={`prescription.${index}.dosage`} component="div" className="error mb-2" />
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <Form.Group controlId="dosage_unit">
                                                                                    <Form.Control name={`prescription.${index}.dosage_unit`} value={data.dosage_unit} onChange={handleChange} as="select">
                                                                                        <option value="MG">MG</option>
                                                                                        <option value="ML">ML</option>
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                                <ErrorMessage name={`prescription.${index}.dosage_unit`} component="div" className="error mb-2" />
                                                                            </Col>
                                                                            <Col md={12}><h4>Time/Day</h4></Col>
                                                                            <Col md={6}>
                                                                                <Form.Group className="mb-3" controlId="times_in_day">
                                                                                    <Form.Control type="text" placeholder="0" name={`prescription.${index}.times_in_day`} value={data.times_in_day} onChange={handleChange} readOnly />
                                                                                </Form.Group>
                                                                                <ErrorMessage name={`prescription.${index}.times_in_day`} component="div" className="error mb-2" />
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <ul className="timing-box">
                                                                                    {
                                                                                        PRESCRIPTION_CATEGORY.map(function (cat: any, id: any) {
                                                                                            console.log('categories...', cat);
                                                                                            console.log('data.at_time', data.at_time)
                                                                                            console.log(data.at_time ? data.at_time.includes(cat.split("")[0]) === data.at_time ? "active" : "" : "");
                                                                                            return (<li
                                                                                                className={data.at_time ? data.at_time.includes(cat.split("")[0]) ? "active" : "" : ""}
                                                                                                key={`${cat}_${id}`}
                                                                                                onClick={(e: any) => {
                                                                                                    if (data.at_time) {
                                                                                                        let atTime = '';
                                                                                                        // FIND INDEX OF IF INDEX EXIST THEN PARTICULAR STRING IS EXTRACTED FROM ARRAY
                                                                                                        let indexOf = data.at_time.length > 1 ? data.at_time.split(",").indexOf(cat.split("")[0]) : 0;
                                                                                                        // FINDING PATTERN FROM AN ARRAY
                                                                                                        let replace = indexOf > 0 ? `,${cat.split("")[0]}` : cat.split("")[0];
                                                                                                        // REPLACING PATTERN FROM ARRAY
                                                                                                        atTime = data.at_time.includes(cat.split("")[0]) ? data.at_time.replace(replace, "") : data.at_time + "," + cat.split("")[0];
                                                                                                        console.log('atTime', atTime.split(',').length);
                                                                                                        setFieldValue(`prescription.${index}.at_time`, atTime);
                                                                                                        setFieldValue(`prescription.${index}.times_in_day`, atTime ? atTime.split(',').length : 0);
                                                                                                    } else {
                                                                                                        setFieldValue(`prescription.${index}.at_time`, cat.split("")[0]);
                                                                                                        setFieldValue(`prescription.${index}.times_in_day`, 1);
                                                                                                    }
                                                                                                }}
                                                                                            >{cat}</li>)
                                                                                        })
                                                                                    }
                                                                                </ul>
                                                                                <ErrorMessage name={`prescription.${index}.at_time`} component="div" className="error mb-2" />
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <h4>Start Time</h4>
                                                                                <Form.Group className="mb-3" controlId="start_time">
                                                                                    <Form.Control type="date" placeholder="Select Start Time" name={`prescription.${index}.start_time`} value={data.start_time} min={startDateMinMaxObject.min} max={startDateMinMaxObject.max} onChange={handleChange} />
                                                                                </Form.Group>
                                                                                <ErrorMessage name={`prescription.${index}.start_time`} component="div" className="error mb-2" />
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <h4>End Time</h4>
                                                                                <Form.Group className="mb-3" controlId="end_time">
                                                                                    <Form.Control type="date" placeholder="Select End Time" name={`prescription.${index}.end_time`} value={data.end_time} min={moment(data.start_time).format('YYYY-MM-DD')} max={moment().format('YYYY-MM-DD')} onChange={handleChange} disabled={data.start_time ? false : true} />
                                                                                </Form.Group>
                                                                                <ErrorMessage name={`prescription.${index}.end_time`} component="div" className="error mb-2" />
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                })
                                                            }
                                                            <div className="col-md-12 mb-4">
                                                                <button className="btn btn-link" type="button" onClick={() => push({ ...defaultData })}>
                                                                    <i className="fa fa-plus-circle more-prescription" aria-hidden="true" /> Add More Prescription
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </FieldArray>
                                            <Col md={12}>
                                                <Button variant="unset" type="submit" className="btnsumit">Submit</Button>
                                            </Col>
                                        </form>
                                    )}
                                </Formik>
                                {/* <Form autoComplete="off" onSubmit={(event:Event)=>{event.preventDefault()}}>
                                    <AddPrec {...prop} />
                                    <Col md={12}>
                                        <Button variant="unset" type="button" className="btnsumit" onClick={submit}>Submit</Button>
                                    </Col>
                                </Form> */}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <Modal show={show} onHide={handleClose} size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton className="border-0 p-1">
                    <Modal.Title />
                </Modal.Header>
                <Modal.Body className="text-center pt-0">
                    <img src={require('../images/check1.svg').default} alt="check1" width="85px" />
                    <h5 className="mt-2 mb-3">Prescription added successfully!!</h5>
                </Modal.Body>
            </Modal>
            <Modal show={showerr} onHide={handleCloseE} size="sm">
                <Modal.Header closeButton className="border-0 headerclose">
                </Modal.Header>
                <Modal.Body className="text-center py-4">
                    <img src={require('../../src/images/check.svg').default} alt="tick" />
                    <h5 className="thnkutext mt-3 mb-1">Try Again!</h5>
                    <p className="mb-0">Please fill all required field</p>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default AddPrescription;