import { useEffect } from "react";

const Demo_home = () => {

  useEffect(() => {
    setTimeout(() => {
      window.location.href = "/";
    }, 5000);
  }, []);

  return (
    <section className="symtomps_section py-4">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <img src={require('../../src/images/check.svg').default} alt="check" />
          </div>
          <div className="col-md-12" />
          <div className="col-md-12">
            <h5 className="thnkutext mt-3 mb-1">Thank You!</h5>
            <p className="mb-0">Your submission has been received.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Demo_home;