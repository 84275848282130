import React, { useEffect, useState } from 'react';
import { userService } from '../_services/user.services';
import { getItems } from '../utils/localStorage';
import { Loader } from './Loader';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import Pagination from './Pagination';
import { returnTotalPages } from '../utils/pagination';
// MODAL
import PopUpModal from '../modals';
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';

function AcceptReject() {

    const [requestList, setRequestList] = useState([]);
    const [pagination, setPagination] = useState({
        page: 0,
        totalPage: 0
    });
    const [isPaginationtrigger, setIsPaginationTrigger] = useState(false);
    const [show, setShow] = useState("flex");
    const [groupId, setGroupId] = useState("");
    const [popUpModal, setPopUpModal] = useState({
        status: false,
        modalType: ''
    });
    const [reason, setReason] = useState("");
    const [otherId, setOtherId] = useState('');
    const [totalRecord, setTotalRecord] = useState('');

    let history = useHistory();

    useEffect(() => {
        let group_id = getItems(`group_id`);
        if (group_id) {
            setGroupId(group_id);
            getRequestlist(`?group_id=${Number(group_id)}&page_no=${pagination.page}`);
        } else {
            setShow('none');
        }
    }, []);

    // get request list for group admin
    function getRequestlist(params: any) {
        userService.getRequestlist(params).then((response: any) => {
            if (response.data.data.length) {
                setRequestList(response.data.data);
            } else {
                setRequestList([]);
            }
            if(response.data.total_records){
                setTotalRecord(response.data.total_records);
                let totalPages = returnTotalPages(response.data.total_records, 10);
                // totalPages = totalPages > 1 ? totalPages : totalPages;
                updatePaginationState('totalPage', totalPages);
            }else{
                updatePaginationState('totalPage', 0);
                setTotalRecord('');
            }
            triggerPopUpModal(false, 'reject_user_modal');
            setOtherId('');
            setReason('');
            setShow("none");
            setIsPaginationTrigger(false);
        }).catch((error: any) => {
            if (error.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            setShow("none");
        })
    }

    // Common function for confirmation
    function confirmAction(type: string, id: string) {
        Swal.fire({
            text: `Are you sure you want to ${type} the request?`,
            title: "Alert",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No"
        }).then(e => {
            if (e.value) {
                if (type === "reject") {
                    triggerPopUpModal(true, 'reject_user_modal');
                    setOtherId(id);
                } else {
                    setShow("flex");
                    let data = {
                        group_id: groupId,
                        other_id: id,
                        type: type
                    };
                    acceptRejectUser(data);
                }
            }
        })
    }

    function acceptRejectUser(params: any) {
        userService.acceptRejectUser(params).then((response: any) => {
            // handling of pagination
            // if request list single and user is on last page then pagination move to previous page
            // in case of single record in first page then pagination will set from first page
            getRequestlist(`?group_id=${Number(groupId)}&page_no=${requestList.length === 1?pagination.page===0?pagination.page:pagination.page-1:pagination.page}`);
        }).catch((error: any) => {
            setShow("none");
        })
    }

    //  common function for pagination update page number and page size
    function updatePaginationState(name: any, value: any) {
        setPagination(prevPagination => {
            return {
                ...prevPagination,
                [name]: value
            }
        });
    }

    function handlePageClick(event: any) {
        if (event.selected <= pagination.totalPage) {
            updatePaginationState('page', event.selected);
            setIsPaginationTrigger(true);
            setShow("flex");
        }
    };

    useEffect(() => {
        if (isPaginationtrigger) {
            let group_id = getItems(`group_id`);
            if (group_id) {
                getRequestlist(`?group_id=${Number(group_id)}&page_no=${pagination.page}`);
            } else {
                setShow("none");
                setIsPaginationTrigger(false);
            }
        }
    }, [isPaginationtrigger]);

    // redirect to previous page
    function back() {
        history.push(`/invite_group?id=${getItems(`group_id`)}`)
    }

    // common function for trigger popup modal
    function triggerPopUpModal(status: Boolean, type: any) {
        setPopUpModal((prevPopUpModal: any) => {
            return {
                ...prevPopUpModal,
                status: status,
                modalType: type
            }
        });
    }

    // handle Modal Input
    function handleModalInput(event: any) {
        let { value } = event.target;
        setReason(value);
    }

    // handle Modal Submit
    function handleModalSubmit(event: any) {
        event.preventDefault();
        setShow("flex");
        let data = {
            group_id: groupId,
            other_id: otherId,
            type: "reject"
        };
        acceptRejectUser(data);

    }

    let modalProps = {
        reason: reason
    }, modalFuncProps = {
        handleModalInput: handleModalInput,
        handleModalSubmit: handleModalSubmit
    };

    return (
        <React.Fragment>
            <Loader status={show} />
            <PopUpModal
                status={popUpModal.status}
                modalType={popUpModal.modalType}
                onClose={triggerPopUpModal}
                {...modalProps}
                {...modalFuncProps}
            />
            <section className="bodyvital_section py-5">
                <div className="container">
                    <div className="inner_box pb-0">
                        <div className="row">
                            <div className='col-md-6'>
                                <h5>
                                    <span className="c-pointer" onClick={back}>
                                        <img src={require('../images/back.svg').default} alt="back" />
                                    </span>
                                </h5>

                            </div>
                        </div>
                        <div className="row">
                            {
                                requestList.length ?
                                    <div className="col-md-12">
                                        <div className="gorup_Details_head d-block">

                                            {totalRecord ? <h5 className="d-block ml-0 mb-4"> <span>{`Request${requestList.length>1?'s':''}`}</span> </h5> : ''}
                                            <Row>
                                            {
                                                requestList.map((value: any, index: any) => {
                                                    return (
                                                        <Col md={6} key={`${value.id}_${index}`}>
                                                        <div className="group_list_row2 justify-content-between user-invite align-items-center mb-4">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="user_image_row1">
                                                                    <img src={value.profileImage} alt="group_list_row2" />
                                                                </div>
                                                                <span className='text-capitalize'>{value.full_name}</span>
                                                            </div>
                                                            <div className="confirmation-box">
                                                                <button className="accept_reject_btn accept_border" onClick={() => confirmAction('accept', value.user_id)}><i className='fa fa-check accept_btn'/></button>
                                                                <button className="accept_reject_btn reject_border" onClick={() => confirmAction('reject', value.user_id)}><i className='fa fa-close reject_btn'/></button>
                                                            </div>
                                                        </div>
                                                        </Col>
                                                    )
                                                })
                                            }
                                            </Row>
                                        </div>
                                    </div>
                                    : <div className="col-md-12 text-center mt-4">No Matching Record Found</div>
                            }
                        </div>
                        {pagination.totalPage > 1 ?
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <Pagination {...pagination} handlePageClick={handlePageClick} />
                                </div>
                            </div>
                            : ''}
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
export default AcceptReject;