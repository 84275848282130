import { Modal } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';


function PrecautionSymptomModal(props: any) {
    let { status, preCautionData, modalType } = props;
    console.log('preCautionData',preCautionData.replace(/\r\n/ig,""));
    return (
        <Modal show={status} onHide={() => props.onClose(false, '')} size="lg" centered backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton className="border-0"> <h4>{modalType === "precaution_modal" ? "Precautions" : "Symptoms"}</h4></Modal.Header>
            <Modal.Body className="pt-0 precaution-modal-height">
               
                {/* <p>
                </p> */}
                    {ReactHtmlParser(preCautionData.replace(/\r\n/ig,""))}
            </Modal.Body>
        </Modal>
    )
}

export default PrecautionSymptomModal;