import { useState, useEffect } from 'react'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { userService } from '../_services';
import { Loader } from './Loader';
import ProfileImage from '../images/profile.png';


const Leaderboard = () => {
    const [show, setShow] = useState(false);
    const [showloader, setShowloader] = useState({ display: "none" });
    const [data, setData] = useState([]) as any;

    let user_id = localStorage.getItem("user_id") as any;
    let history = useHistory();

    useEffect(() => {
        setShowloader({ "display": "flex" })
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let comp_id = params.get("comp_id");
        userService.competitionDetails(comp_id).then(resp => {
            setData(resp.data.data);
            setShowloader({ "display": "none" });
            console.log(resp.data.data);
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            setShowloader({ "display": "none" });
        })
    }, []);

    function toggleModalView(status: any) {
        setShow(status);
    }

    return (
        <>
            <section className="bodyvital_section py-5">
                <Loader status={showloader.display} />
                <Container>
                    <div className="inner_box pb-4">
                        <Row className="bodyvitals_head">
                            <Col md={12}>
                                <h5><span onClick={() => { history.go(-1) }}><img src={require('../images/back.svg').default} alt="back" /></span></h5>
                                <h2>{data.title}</h2>
                            </Col>
                            <Col md={12}><hr /></Col>
                        </Row>
                        <Row>
                            {data.leaderBoard ?
                                data.leaderBoard.map((l: any, i: any) => {
                                    console.log(l.member_id === user_id, l.member_id, user_id)
                                    return (
                                        <Col md={6} key={`${l.member_id}_${i}`}>
                                            <div className="users_box leaderboard_box mb-3">
                                                <div className="invite_user_image bg-success">
                                                    <img src={l.profile_image} alt="profile" onError={(event: any) => { event.target.src = ProfileImage; }} />
                                                    {/* <span>{i + 1}</span> */}
                                                </div>
                                                <div>
                                                    <h6 className='mb-0'>{l.member_id === parseInt(user_id) ? `You` : `${l.firstname} ${l.lastname}`}</h6>
                                                    <p className='mb-0'>{l.points}</p>
                                                </div>
                                                <div className={l.member_id === parseInt(user_id) ? "login_user_count" : "invite_user_count"}><span>{i + 1}</span></div>
                                            </div>
                                        </Col>
                                    )
                                })
                                :
                                <Col>
                                    <p className="text-center">No data found</p>
                                </Col>
                            }
                        </Row>
                    </div>
                </Container>
            </section>
            <Modal show={show} onHide={() => toggleModalView(!show)} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton className="border-0 p-1 pt-2 pr-3 pb-0" />
                <Modal.Body className="text-center pt-0">
                    <img src={require('../images/check1.svg').default} alt="check1" width="75px" />
                    <h5 className="mt-3 mb-3">Successfully sent invitation.</h5>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Leaderboard;