import React, { useEffect, useState } from 'react'
import { Form, Container, Button, Row, Col, Modal } from 'react-bootstrap'
import DatePicker from 'react-date-picker';
import { userService } from '../_services';
import moment from 'moment';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Loader } from './Loader';
// CSS
import 'rc-slider/assets/index.css';

const Lifestyle = () => {
    let history = useHistory();
    const [value, onChange] = useState(new Date());
    const [showloader, setShowloader] = useState({ display: "none" });
    const [show, setShow] = useState(false);
    const [metricdata, setMetricdata] = useState([]);
    const [fieldVals, setFieldVals] = useState(new Map);
    const [params, setParams] = useState({});
    const [showErr, setShowErr] = useState(false);
    const [valDate, setValDate] = useState(new Date()) as any;

    useEffect(() => {
        setShowloader({ display: "flex" })
        localStorage.setItem("record", "yes")
        var todayDate = new Date
        var today = todayDate.getFullYear() + "-" + todayDate.getMonth() + "-" + todayDate.getDate()
        userService.metric_list(moment().format('YYYY-MM-DD')).then(response => {
            setMetricdata(response.data.data.lifestyle_work);
            const map = new Map() as any;
            let object = {} as any;
            response.data.data.lifestyle_work.forEach((value: any) => {
                let keys = value.metrics.toLowerCase().replace(" ", "_");
                object[keys] = value.id + "_" + value.value;
                map.set(value.id,value.value === "0"?"": value.value);
            });
            setFieldVals(map);
            setParams(object);
            setShowloader({ display: "none" });
            let fitbitId = localStorage.getItem('fitbitId');
            if (fitbitId) {
                getFitBitData(today);
            }
        })
            .catch(error => {
                if (error.response.status == 401) {
                    localStorage.clear()
                    window.location.href = '/'
                }
                setShowloader({ display: "none" })
            }
            )
    }, [])

    function getFitBitData(today: any) {
        userService.getFitBitData(today).then(resp => {
            var summary = resp.data.summary
            var ingested = summary.caloriesBMR // id=22
            var expanded = summary.caloriesOut // id=21
            let fitbitData = []
            fitbitData.push({ id: 21, val: ingested, unit: 'calories / day', metrics: 'Calorie Expended' })
            fitbitData.push({ id: 22, val: expanded, unit: 'calories / day', metrics: 'Calorie Ingested' })
            setDatafromFitbit(fitbitData)
        }).catch(err => {
            if (err.response && err.response.status == 401) {
                console.log('Please login fitbit');
            } else {
                console.log('error occured')
            }
        })
    }

    const submit = () => {
        var keys = Object.keys(params)
        if (keys.length == 0) {
            setShowErr(true)
            return false
        }
        setShowloader({ display: "flex" })
        let date = moment(value, 'DD-MM-YYYY').format('yyyy-MM-DD');
        let data: any = {
            ...params,
            date_measured: date,
            user_id: localStorage.getItem('user_id')
        }
        let viaIsDetail=localStorage.getItem('viaIsDetail');
        if(viaIsDetail === "true"){
            data.competition_id=localStorage.getItem('comp_id');
        }
        userService.health_matrics(data).then(response => { return response.data })
            .then((result) => {
                setShowloader({ display: "none" });
                // toast.success('Record added successfully.');
                localStorage.removeItem("record");
                let viaInviteGroup=localStorage.getItem("viaInviteGroup");
                if(viaInviteGroup === "true"){
                    let inviteGroupId=localStorage.getItem("inviteGroupId")
                    if(inviteGroupId){
                        localStorage.setItem("viaInviteGroup","false");
                        history.push(`/invite_group?id=${inviteGroupId}`)
                    }else{
                        history.push('/');
                    }
                }else{
                    history.push('/');
                }
            })
            .catch(error => {
                if (error.response.status == 401) {
                    localStorage.clear()
                    window.location.href = '/'
                }
                setShowloader({ display: "none" })
            });
    }

    function handleClose() {
        setShow(false);
        history.push("/")
    }

    function handleDate(e: any) {
        if(e){
            var date = new Date(e);
            var dt = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();
            getFitBitData(dt);
            setValDate(e);
        }
    }

    function setDatafromFitbit(data: any) {
        var f_val = new Map(fieldVals)
        var f_params = { params } as any
        data.map((d: any) => {
            let val = d.val
            var key = d.metrics.toLowerCase().replace(" ", "_")
            var key_value = d.id + "_" + val + " " + d.unit
            f_params[key] = key_value
            f_val.set(d.id, val)
        })
        setFieldVals(f_val)
        setParams(f_params)
    }

    function handleChange(id: any, e: any, unit: any, metrics: any) {
        let val = e.target.value
        var regex = new RegExp("^[0-9]{0,6}$");
        if (!regex.test(val)) {
            e.preventDefault()
            return false
        }
        var key = metrics.toLowerCase().replace(" ", "_")
        var key_value = id + "_" + val + " " + unit
        let value = { ...params, [key]: key_value }
        setParams(value)
        let f_val = new Map(fieldVals)
        f_val.set(id, val)
        setFieldVals(f_val)
    }

    return (
        <>
            <section className="bodyvital_section py-5">
                <Loader status={showloader.display} />
                <Container>
                    <div className="inner_box">
                        <Row className="bodyvitals_head">
                            <Col md={6}>
                                <h5><a href="/"><img src={require('../images/back.svg').default} alt="back" /></a></h5>
                                <h2>Life Style</h2>
                            </Col>
                            <Col md={6} className="text-right">
                                <h6>Date Measured</h6>
                                <div className="clndr_icon_1">
                                    <DatePicker
                                        onClickDay={handleDate}
                                        value={valDate}
                                        maxDate={new Date} 
                                        format="MM-dd-y"/>
                                    <span><img src={require('../images/clndr1.svg').default} alt="clndr1" width="17px" /></span>
                                </div>
                            </Col>
                            <Col md={12}>
                                <hr />
                            </Col>
                        </Row>

                        <Row className="metrics_row">
                            <Col md={12}>
                                <h3>Health Metrics</h3>
                                <p>Record measurements for as many metrics as you can</p>
                            </Col>

                            {metricdata ?
                                metricdata.map((data: any, i: any) => {
                                    return (
                                        <React.Fragment key={`${data.id}_${i}`}>
                                            <Col lg={6} md={7}>
                                                <Form autoComplete="off" onSubmit={(event:any)=>{event.preventDefault()}}>
                                                    <Row className="metric_form">
                                                        <Col md={12}>
                                                            <Form.Group className="mb-3" controlId="metrics">
                                                                <Form.Control value={fieldVals && fieldVals.get(data.id) ? fieldVals.get(data.id) : ""} type="text" placeholder={data.metrics} onChange={(e: any) => handleChange(data.id, e, data.ranges[0].unit, data.metrics)} />
                                                            </Form.Group>
                                                        </Col>

                                                    </Row>
                                                </Form>
                                            </Col>
                                            <Col lg={4} md={5} className="rightside_select">
                                                <Form autoComplete="off" onSubmit={(event:any)=>{event.preventDefault()}}>
                                                    <div className="mb-3">
                                                        <Form.Group controlId={"ControlSelect1" + i}>
                                                            <Form.Control as="select">
                                                                <option>{data.ranges[0].unit}</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <div className="info-div-one">
                                                            <img src={require('../images/info.svg').default} alt="info-div-one" />
                                                            <div className="systolic-box">
                                                                <div className="d-flex good-one">
                                                                    <p>Good:</p>
                                                                    <p>{data.ranges[0].green}</p>
                                                                </div>
                                                                <div className="d-flex caution-one">
                                                                    <p>Caution:</p>
                                                                    <p>&lt;{data.ranges[0].yellow_low}(min)<br />&gt;{data.ranges[0].yellow_high}(max)</p>
                                                                </div>
                                                                <div className="d-flex alert-one alert-last">
                                                                    <p>Alert Level:</p>
                                                                    <p>{data.ranges[0].red_low}(min)<br></br>{data.ranges[0].red_high}(max)</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Form>
                                            </Col>
                                        </React.Fragment>
                                    )
                                })
                                : ""
                            }
                            <Col md={12}>
                                <Button variant="unset" type="button" className="btnsumit" onClick={submit}>Submit</Button>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <Modal show={show} onHide={handleClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton className="border-0 p-1">
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center pt-0">
                    <img src={require('../images/check1.svg').default} alt="check1" width="85px" />
                    <h5 className="mt-2 mb-3">Record added successfully.</h5>
                </Modal.Body>
            </Modal>

            <Modal show={showErr} onHide={() => { setShowErr(false) }} size="sm">
                <Modal.Header closeButton className="border-0 headerclose">
                </Modal.Header>
                <Modal.Body className="text-center py-4">
                    <img src={require('../../src/images/check.svg').default} />
                    <h5 className="thnkutext mt-3 mb-1">Try Again!</h5>
                    <p className="mb-0">Please fill atleast one field</p>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Lifestyle;