import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { userService } from '../_services';
import { useHistory } from "react-router-dom";
import { Loader } from './Loader';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import { MetricAlertModal } from '../modals/metricAlertModal';
import { returnTotalPages } from '../utils/pagination';
import { getItems } from '../utils/localStorage';

const Alert = () => {
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState("none");
    const [allNotification, setAllNotification] = useState([]) as any;
    const [alertModal, setAlertModal] = useState(false);
    const [pagination, setPagination] = useState({
        page: 0,
        pageLimit: 20,
        totalPage: 0
    });
    const [isPaginationtrigger, setIsPaginationTrigger] = useState(false);
    const [activeMetric, setActiveMetric] = useState({}) as any;
    const history = useHistory();

    useEffect(() => {
        if(getItems("plan_details").plan_id === 1){
            Swal.fire({
                title: '',
                text: "This feature is not included in your current plan. To use this please upgrade your plan.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Go to Subscription Plan',
                cancelButtonText: 'Cancel'
            } as any).then((result: any) => {
                if (result.value) {
                    history.push('/membership/current');
                }else{
                    localStorage.setItem('left_menu_option','first');
                    history.push('/');
                }
            })
        }else{
            triggerLoader("flex");
            getAlertNotification();
            let activeMetric = localStorage.getItem("activeMetric");
            if (activeMetric) {
                let allData = JSON.parse(activeMetric);
                allData.ranges = JSON.parse(allData.ranges);
                allData.metric_name = { metrics: allData.metric_name };
                setActiveMetric(allData);
                triggerModal(true);
            } else {
                localStorage.setItem("activeMetric", '');
                setActiveMetric({});
            }
            localStorage.setItem("metricBadgeCount", '');
        }
    }, []);

    useEffect(() => {
        return () => {
            localStorage.setItem("activeMetric", '');
        }
    }, [])

    // get listing of all alert notification
    function getAlertNotification() {
        userService.getAlertNotification({ ...pagination }).then(resp => {
            triggerLoader("none");
            if (resp.data.data) {
                setAllNotification(resp.data.data);
            } else {
                setAllNotification([]);
            }
            var temp = resp.data.data;
            if (temp) {
                setData(resp.data.data);
            }
            let allPage = returnTotalPages(resp.data.Total_record, 20);
            updatePaginationState('totalPage', allPage);
            setIsPaginationTrigger(false);
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            triggerLoader("none");
            setIsPaginationTrigger(false);
        })
    }

    //clear all notification on delete button
    function clearNotification() {
        userService.deleteNotifs().then(resp => {
            toast.success(resp.data.message);
            getAlertNotification();
            triggerLoader("none");
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            triggerLoader("none");
            toast.error("An error occurerd~")
        })
    }

    //  common function for pagination update page number and page size
    function updatePaginationState(name: any, value: any) {
        setPagination(prevPagination => {
            return {
                ...prevPagination,
                [name]: value
            }
        });
    }

    function handlePageClick(event: any) {
        if (event.selected <= pagination.totalPage) {
            triggerLoader("flex");
            updatePaginationState('page', event.selected);
            setIsPaginationTrigger(true);
        }
    };

    useEffect(() => {
        if (isPaginationtrigger) {
            getAlertNotification();
        }
    }, [isPaginationtrigger]);

    function triggerLoader(status: any) {
        setLoader(status);
    }

    function triggerAlert(data: any) {
        triggerModal(true);
        setActiveMetric(data);
    }

    function triggerModal(status: any) {
        setAlertModal(status);
        if (status === false) {
            localStorage.setItem("activeMetric", '');
        }
    }

    // trigger sweetalert for delete alert
    function confirmDeleteAlert() {
        Swal.fire({
            text: `Are you sure you want to delete all metric alerts ?`,
            title: "Alert",
            showCancelButton: true,
            confirmButtonText: "Delete"
        }).then(e => {
            if (e.value) {
                triggerLoader("flex");
                clearNotification();
            }
        })
    }

    return (
        <React.Fragment>
            <section className="bodyvital_section friend_sections notification_sections py-5">
                <Loader status={loader} />
                <Container>
                    <div className="inner_box pb-4">
                        <Row className="align-items-center">
                            <Col lg={8} md={7}>
                                <h2 className="d-inline">Metric Alerts</h2>
                            </Col>
                            <Col lg={4} md={5} className="d-flex justify-content-end">
                                {data.length ? <span onClick={() => confirmDeleteAlert()}><i style={{ fontSize: "25px" }} className="fa fa-trash text-danger mx-2" /></span> : ""}
                                {/* <span className="position-relative d-inline-block ml-2"><img src={require('../images/bell_1.svg').default} alt="bell_1" width="22px" />{count ? <span className="count">{count}</span> : ""}</span> */}
                            </Col>
                            <Col md={12}><hr /></Col>
                        </Row>
                        <Row>
                            {
                                allNotification.length ?
                                    allNotification.map((value: any, index: any) => {
                                        return (
                                            <div className='col-lg-6 col-md-6 col-sm-6 mb-4' key={`${value.metric_id}_${index}`}>
                                                <div className="users_box mb-3" data-gid={value.metric_id} onClick={(e: any) => { triggerAlert(value) }}>
                                                    <div className="invite_user_image pr-2" style={{ backgroundColor: "unset", borderRadius: "unset" }}>
                                                        <img src={require('../images/metric_alert_warning.svg').default} alt="alert" style={{ objectFit: "unset" }}/>
                                                    </div>
                                                    <div className='ml-4 pl-4'>
                                                        <span>{value.message}</span>
                                                        <p><img src={require('../images/time.svg').default} alt="time" className="mr-2"/>{value.date}
                                                        {/* {moment(value.date.split(',')[0]).format('MM/DD/YYYY')} {value.date.split(',')[1]} */}
                                                        </p>
                                                    </div>
                                                    <div className="invite_btn1"><i className="fa fa-angle-right nextarrow" /></div>
                                                </div>
                                            </div>
                                        )
                                    }) : <Col className="text-center"><p className="pt-4">No metric alerts found</p></Col>
                            }
                        </Row>
                        {pagination.totalPage > 1 ? <Row>
                            <Col>
                                <ReactPaginate
                                    previousLabel="Previous"
                                    nextLabel="Next"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    forcePage={pagination.page}
                                    pageCount={pagination.totalPage}
                                    renderOnZeroPageCount={null}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                />
                            </Col>
                        </Row> : ''}
                    </div>
                </Container>
            </section>
            <MetricAlertModal alertModal={alertModal} activeMetric={activeMetric} triggerModal={triggerModal} />
        </React.Fragment>
    )
}

export default Alert;