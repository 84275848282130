// import firebase from "firebase";

// var fcmConfig = ({
//     apiKey: "AIzaSyBwEaLuBT1nD1nWqs7IqG3TNa3RqN3hT8Y",
//     authDomain: "xcellentlife-342f9.firebaseapp.com",
//     databaseURL: "https://xcellentlife-342f9.firebaseio.com",
//     projectId: "xcellentlife-342f9",
//     storageBucket: "xcellentlife-342f9.appspot.com",
//     messagingSenderId: "395031167092",
//     appId: "1:395031167092:web:aa9ef111e6df952c90d121",
//     measurementId: "G-D5QVDXN19M"
// });

// if(firebase.messaging.isSupported()){
//     firebase.initializeApp(fcmConfig)
// }

// export default firebase


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBwEaLuBT1nD1nWqs7IqG3TNa3RqN3hT8Y",
  authDomain: "xcellentlife-342f9.firebaseapp.com",
  databaseURL: "https://xcellentlife-342f9.firebaseio.com",
  projectId: "xcellentlife-342f9",
  storageBucket: "xcellentlife-342f9.appspot.com",
  messagingSenderId: "395031167092",
  appId: "1:395031167092:web:aa9ef111e6df952c90d121",
  measurementId: "G-D5QVDXN19M"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);

export default firebase;