import React from 'react';
import { Modal } from 'react-bootstrap';

function CompanyListModal(props: any) {
    let { status, company, companyId, searchUser,isSearchTrigger} = props;

    function handleScrollInput(event:any){
        console.log('scrollHeight',event.target.scrollHeight);
        console.log('offsetHeight',event.target.offsetHeight);
        console.log('scrollTop',event.currentTarget.scrollTop);        

        if(event.target.scrollHeight-event.target.offsetHeight === event.currentTarget.scrollTop){
            props.scrollToBottomPagination();
        }
    }

    return (
        <React.Fragment>
            <Modal show={status} onHide={() => {
                props.toggleCompany('');
                props.onClose(false, 'company_list_modal');
            }} size="md" centered backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>Select Company</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0 precaution-modal-main">
                    <form onSubmit={(event) => props.handleModalSearchSubmit(event)}>
                        <div className="search-form">
                            <input type="text" placeholder='Type here to search' value={searchUser} onChange={(event) => props.handleSearchInput(event)}/>
                            <div className='search-bt'>
                                {
                                    isSearchTrigger ?
                                        <button type="button" onClick={() => props.clearModalSearch()}>Reset</button>
                                        : <button type="submit" disabled={searchUser?false:true}>Search</button>
                                }
                            </div>
                        </div>
                    </form>
                    <div className='company-main' onScroll={(event: any) => handleScrollInput(event)}>
                        <div className='row ' id="companyList">
                            {
                                company.length ?
                                    company.map((data: any, index: any) => {
                                        return (
                                            <div className='col-md-12 c-pointer' key={`${data.id}_${index}`} onClick={() => props.toggleCompany(data.id)}>
                                                <div className={`${index === company.length - 1 ? `` : `company-box`} d-flex justify-content-between align-items-center`}>
                                                    <p className='company-text'>{data.name}</p>
                                                    {
                                                        companyId === data.id ? <div className='company-box-img'>
                                                            <img src={require('../images/check.png').default} alt="check" />
                                                        </div> : ''
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }) : <div className="col-md-12 text-center">
                                        No data found
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-12 text-center">
                            <button className='btnsumit text-white btn btn-unset' onClick={() => props.handleModalSubmit()} disabled={companyId?false:true}>Submit Request</button>
                        </div>
                    </div>                   
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}
export default CompanyListModal;