import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { userService } from '../_services';
import { Loader } from './Loader';
// import './App.css'
// import io from 'socket.io-client'
// import { config } from '../config/config';
// import logo from './logo.svg'

let ques: any

function Survey(props: any) {
  let user_id = props.match.params.id;

  const [questions, setQuestions] = useState() as any;
  const [showloader, setShowLoader] = useState({ display: "flex" });
  const [showbtn, setShowBtn] = useState({ display: "none" });
  const [showerr, setShowErr] = useState(false);
  // const [quesid, setQuesid] = useState() as any;
  const [answerId, setAnswerID] = useState([]) as any;
  const [message, setMessage] = useState('') as any;

  const handleCloseE = () => setShowErr(false);
  const handleShow = () => {
    toggleLoader("flex");
    setAnswerID([])
    // var targetDiv = document.querySelectorAll('.allquestions');
    var allInputs: any = document.getElementsByTagName("input");
    for (var xx = 0; xx < allInputs.length; xx++) {
      if (allInputs[xx].type == 'radio') {
        if (allInputs[xx].checked) {
          var valueToPush: any = {}; // or "var valueToPush = new Object();" which is the same
          valueToPush["ans"] = allInputs[xx].value;
          valueToPush["id"] = allInputs[xx].getAttribute('data-id');
          answerId.push(valueToPush)
        } // else{
        //   var valueToPush1:any = { }; // or "var valueToPush = new Object();" which is the same
        //   valueToPush1["ans"] = allInputs[xx].value;
        //   valueToPush1["id"]= allInputs[xx].getAttribute('data-id');
        //   answerId.push(valueToPush1)
        // }
      }

    }
    var textarea: any = document.getElementsByTagName("textarea");
    for (var xx = 0; xx < textarea.length; xx++) {
      if (textarea[xx].value != "") {
        var valueToPush: any = {};
        valueToPush["ans"] = textarea[xx].value;
        valueToPush["id"] = textarea[xx].getAttribute('data-id');
        answerId.push(valueToPush)
      }
    }
    if (answerId.length > 0) {
      let param = { 'survey_query': answerId, 'user_id': user_id, 'form_id': questions[0].form_id };
      userService.PostSurverAnswer(param).then(function (response) {
        window.location.href = "/thankyou";
      }).catch(error => {
        if (error.response.status == 401) {
          localStorage.clear();
          window.location.href = '/';
        }
        if (error.response.status == 422) {
          setShowErr(true);
          setMessage(error.response.data.message);
        }
      });
    } else {
      setMessage("Please select/type you answers.");
      toggleLoader("none");

    }
  }


  useEffect(() => {
    // setupSocket()
    getQuestions();
    //  Setrandomestr(randomString(10, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'))
  }, []);

  const getQuestions = () => {
    userService.getSurveyQuestions().then(function (response) {
      setShowBtn({ display: "inline-block" });
      toggleLoader("none");
      setQuestions(response.data.data);
    }).catch(error => {
      toggleLoader("none");
      if (error.response) {
        console.log(error.response.status)
      }
    });
  }
  const start = (id: any) => {
    ques = id
    // setQuesid(id)
    document.getElementById("Listrow" + id)?.classList.add("stop");
  }

  const toggleLoader = (status: any) => {
    setShowLoader(prevShowLoader => {
      return {
        ...prevShowLoader,
        display: status
      }
    });
  }

  const checkboxdata = (data: any, id: any) => {
    // console.log(data,"=====")
    let quesval = [];
    quesval = data.map(function (ques1: any, index: any) {
      return (
        <div className="form-check" key={`${ques1.id}_${index}`}>
          <label>
            <span className="mr-2 text-secondary">{index + 1}</span>
            <input
              type="radio"
              value={ques1.id}
              id={"male_" + ques1.id}
              data-id={ques1.question_id}
              name={"radios" + id} />
            {ques1.values}
          </label>
        </div>)
    })
    return quesval
  }

  let symtomps_listData = [];
  if (questions) {
    symtomps_listData = questions.map(function (ques: any, index: any) {
      let divid = "Listrow" + ques.id
      let checkbox: any = "";
      // let ansid = "Ans"+ques.id
      // let ansidrightaway = "Ans_rightaway"+ques.id
      // console.log(ques)
      //console.log(ques)
      if (ques.question_type != "input" && ques.values) {
        checkbox = checkboxdata(ques.values, ques.id)
        //  return ( <> </>)
      }

      return (<div className="symtomps_list mb-4" id={divid} key={index}>
        <div className="listrow">
          <span className="nom_circle">{index + 1}</span>
          <p className="allquestions" data-id={ques.id}>{ques.question}</p>
          <span className="record_btn btn" onClick={() => start(ques.id)}>Your Answer</span>
          {/* <span className="stop_btn btn" onClick={()=>stopRecord(ques.id)}>Close</span> */}
        </div>
        <div className="answer_box">
          <div className="mic_answer" >
            {/* <div className="gif_bg" id={Micid} >
                    <span className="pulse">
                      <i className="fa fa-microphone"></i>
                    </span>
                    </div> */}
            <div>
              <h6 className="select_answer">{ques.question_type != "input" ? 'Select Your Answer' : 'Type Your Answer'}</h6>
              {/* {ques.question_type != "input" ? (<h6 className="select_answer">Select Your Answer</h6>) : (<h6 className="select_answer">Type Your Answer</h6>)} */}
            </div>
          </div>
          {ques.question_type != "input" ? (<div className="check1_box myanswers">
            {checkbox} </div>) : (<div className="type_box myanswers">
              {/* <input type="text"  className="input100" placeholder="Type here"  data-id={ques.id}  name={"radios"+ques.id} /> */}
              <textarea className="input100" placeholder="Type here" data-id={ques.id} name={"radios" + ques.id} ></textarea>
            </div>)}
        </div>
      </div>
      )
    })
  }

  function back() {
    window.location.href = "/notification";
  }

  return (
    <section className="symtomps_section py-4">
      <Loader status={showloader.display} />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          <h5>
            <span onClick={back} className="cursor-pointer">
              <img src={require('../images/back.svg').default} alt="back" />
            </span>
          </h5>
            <h3 className="mb-4">Survey Form</h3>
            <p className="errors">{message}</p>
          </div>
          {symtomps_listData.length > 0 ? (<>
            <div className="col-md-12">
              {symtomps_listData}
            </div>
            <div className="col-md-12 text-center mt-4">
              <div className="btm_button">
                <button className="sub_btn btn" onClick={handleShow} style={showbtn}>Submit</button>
              </div>
            </div>
          </>) : (<h2>No data found</h2>)}
        </div>
        {/* <textarea value={transcript} readOnly={true} ></textarea> */}
      </div>

      <Modal show={showerr} onHide={handleCloseE} size="sm">
        <Modal.Header closeButton className="border-0 headerclose">
        </Modal.Header>
        <Modal.Body className="text-center py-4">
          <img src={require('../../src/images/check.svg').default} />
          <h5 className="thnkutext mt-3 mb-1">Try Again!</h5>
          <p className="mb-0">{message}</p>
        </Modal.Body>
      </Modal>
    </section>
  )
}

export default Survey;