import { Modal } from 'react-bootstrap';
import moment from 'moment';

export function MetricAlertModal(props: any) {
    let { alertModal, activeMetric } = props;
    return (
        <Modal show={alertModal} onHide={() => props.triggerModal(false)} backdrop="static" size="md" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton className="border-0 p-1 pb-0 pr-3 pt-3"><Modal.Title /></Modal.Header>
            <Modal.Body className="text-center alert-modal">
                <img src={require("../images/metric_alert.svg").default} alt="alert" />
                <h2 style={{ color: "#FE0000" }}>Alert!</h2>
                <p>{activeMetric ? moment(activeMetric.date).format('MMMM DD, YYYY, hh:mm A') : ''}</p>
                <p>Metric Name- <span>{activeMetric ? activeMetric.metric_name ? activeMetric.metric_name.metrics : '' : ''}</span></p>
                <p>Measured Value- <span>{activeMetric ? activeMetric.measured_value : ''}</span></p>
                <div className='range-value-area'>
                    <p className='range_text'>Range
                        {/* - <span>{activeMetric?activeMetric.metric_name?activeMetric.metric_name.metrics:'':''}</span> */}
                    </p>
                    <div className='ranges-color'>
                        <div className='green-box'>
                            <i className="fa fa-stop" aria-hidden="true" />
                            <p>Good:<br /> {activeMetric ? activeMetric.ranges?.green : ''}</p>
                        </div>
                        <div className='orange-box'>
                            <i className="fa fa-stop" aria-hidden="true" />
                            <p>Caution:<br /> {`${activeMetric ? activeMetric.ranges?.yellow_low : ''} (min)`} - {`${activeMetric ? activeMetric.ranges?.yellow_high : ''} (max)`}</p>
                        </div>
                        <div className='red-box'>
                            <i className="fa fa-stop" aria-hidden="true" />
                            <p>Alert Level:<br /> {`${activeMetric ? activeMetric.ranges?.red_low : ''} (min)`} - {` ${activeMetric ? activeMetric.ranges?.red_high : ''} (max)`}</p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}