import { useEffect, useState } from 'react';
import { Nav, Container, Tab, Row, Col,Modal } from 'react-bootstrap';
import { Link ,useHistory} from 'react-router-dom';
import { userService } from '../_services';
import { Loader } from './Loader';
import ProfileImage from '../images/profile.png';

const Competition = () => {
    let history = useHistory();

    const [open, setOpen] = useState(false);
    const [openmedi, setOpenmedi] = useState(false);
    const [takenShow, setTakenShow] = useState(false);
    const [Precautionshow, setPrecautionShow] = useState(false);
    const [Symtomsshow, setSymtomsShow] = useState(false);
    const [allCompetitions, setAllCompetitions] = useState([]);
    const [joinedCompetitions, setJoinedCompetitions] = useState([]);
    const [completedCompetitions, setCompletedCompetitions] = useState([]);
    const [showloader, setShowloader] = useState({ display: "none" });
    const [tab, setTab] = useState("invited");
    // const takenClose = () => setTakenShow(false);
    // const takenShow = () => setTakenShow(true);
    const precautionClose = () => setPrecautionShow(false);
    const symtomsClose = () => setSymtomsShow(false);
    const precautionShow = () => setPrecautionShow(true);
    const symtomsShow = () => setSymtomsShow(true);

    const PRECAUTION_MAIN = ["Manage your stress.", "Exercise regularly.", "Eat heart-healthy foods.", "Control your blood pressure.", "Lose extra weight.", "Don’t smoke."];
    const SYMPTOM_SHOW = ["Heart palpitations.", "Irregular pulse.", "Shortness of breath, especially during physical activity or emotional stress.", "Weakness, fatigue.", "Chest pain.", "Dizziness, confusion.", "Lightheadedness or fainting (syncope)."];
    const COMPETITION_LEFT_MENU = ["Invited", "Joined", "Completed"];

    useEffect(() => {
        setShowloader({ display: "flex" })
        var activeTab = localStorage.getItem('compTab')
        if (activeTab) {
            setTab(activeTab)
        }
        getAllCompetitions();
    }, []);

    function getAllCompetitions(){
        userService.competitionList().then((resp) => {
            setAllCompetitions(resp.data.data.all)
            setJoinedCompetitions(resp.data.data.joined)
            setCompletedCompetitions(resp.data.data.completed)
            setShowloader({ display: "none" })
        }).catch(err => {
            setShowloader({ display: "none" })
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
        })
    }

    function handleTabs(e: any) {
        setTab(e);
        localStorage.setItem("compTab", e);
        localStorage.setItem('viaIsDetail',"false");
        localStorage.setItem('viaNotification',"false");
        getAllCompetitions();
    }

    function toggleTakenShowModal(status:any){
        setTakenShow(status);
    }

    return (
        <>
            <section className="dashboard_section py-5">
                <Loader status={showloader.display} />
                <Container>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="all" activeKey={tab}>
                        <div className="maintabing_row competition_row">
                            <div className="left_side">
                                <Nav variant="pills" className="flex-column" activeKey={tab} onSelect={handleTabs}>
                                    {
                                        COMPETITION_LEFT_MENU.map((value: any, index: Number) => {
                                            return (
                                                <Nav.Item key={`${value}_${index}`}>
                                                    <Nav.Link eventKey={value.toLowerCase()}>
                                                        <div className="leftside_tabing">
                                                            <img src={require('../images/comp_checked.svg').default} alt="comp_checked" />
                                                            <h3 className="mb-0">{value}</h3>
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            )
                                        })
                                    }
                                </Nav>
                            </div>
                            <div className="right_side">
                                <Tab.Content>
                                    <Tab.Pane eventKey="invited">
                                        <h2 className="mb-0">List of Invited Competition</h2>
                                        <hr />
                                        <Row className="mt-4">
                                            {allCompetitions.length ?
                                                allCompetitions.map((data: any, index: Number) => {
                                                    return (
                                                        <Col md={6} key={`${data.competition_id}_${index}`}>
                                                            <Link to={`/live_competition?comp_id=${data.competition_id}`}>
                                                                <div className="users_box mb-3">
                                                                    <div className="invite_user_image">
                                                                        <img className="fit-img" src={data.comp_image} alt={data.title} onError={(event: any) => { event.target.src = ProfileImage; }}/>
                                                                    </div>
                                                                    <span className="mb-1 d-block">{data.title}</span>
                                                                    <p>Start date: <span>{data.start_date}</span></p>
                                                                    <p>Fee: <span>${data.entry_fee}</span></p>
                                                                </div>
                                                            </Link>
                                                        </Col>
                                                    )
                                                })
                                                :
                                                <Col md={12} className="py-3 text-center">
                                                    <p>No data found</p>
                                                </Col>
                                            }
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="joined">
                                        <h2 className="mb-0">List of Joined Competition</h2>
                                        <hr />
                                        <Row className="mt-4">
                                            {joinedCompetitions.length ?
                                                joinedCompetitions.map((data: any, index: Number) => {
                                                    return (
                                                        <Col md={6} key={`${data.competition_id}_${index}`}>
                                                            <Link to={`/competitiondetails?comp_id=${data.competition_id}`}>
                                                                <div className="users_box mb-3">
                                                                    <div className="invite_user_image">
                                                                        <img src={data.comp_image} alt={data.title} />
                                                                    </div>
                                                                    <span className="mb-1 d-block">{data.title}</span>
                                                                    <p>End date: <span>{data.end_date}</span></p>
                                                                    <p>Rank: <span>{data.rank}</span></p>
                                                                </div>
                                                            </Link>
                                                        </Col>
                                                    )
                                                })
                                                :
                                                <Col md={12} className="py-3 text-center">
                                                    <p>No data found</p>
                                                </Col>
                                            }
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="completed">
                                        <h2 className="mb-0">List of Completed Competition</h2>
                                        <hr />
                                        <Row className="mt-4">
                                            {completedCompetitions.length ?
                                                completedCompetitions.map((data: any, index: Number) => {
                                                    return (
                                                        <Col md={6} key={`${data.competition_id}_${index}`}>
                                                            <Link to={`/competitiondetails?comp_id=${data.competition_id}`}>
                                                                <div className="users_box mb-3">
                                                                    <div className="invite_user_image">
                                                                        <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={data.comp_image} alt={data.title} />
                                                                    </div>
                                                                    <span className="mb-1 d-block">{data.title}</span>
                                                                    <p>End date: <span>{data.end_date}</span></p>
                                                                    <p>Rank: <span>{data.rank}</span></p>
                                                                </div>
                                                            </Link>
                                                        </Col>
                                                    )
                                                })
                                                :
                                                <Col md={12} className="py-3 text-center">
                                                    <p>No data found</p>
                                                </Col>
                                            }
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </Container>
            </section>

            <Modal show={takenShow} onHide={()=>toggleTakenShowModal(!takenShow)} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton className="border-0 p-1">
                    <Modal.Title />
                </Modal.Header>
                <Modal.Body className="text-center pt-0">
                    <img src={require('../images/check1.svg').default} alt="check1" width="85px" />
                    <h5 className="mt-2 mb-3">Prescription Taken</h5>
                </Modal.Body>
            </Modal>
            <Modal show={Precautionshow} onHide={precautionClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton className="border-0 p-1">
                    <Modal.Title />
                </Modal.Header>
                <Modal.Body className="text-center pt-0 precaution-modal-main">
                    <h5 className="mt-2 mb-3">Precautions</h5>
                    <p>Do all you can to prevent heart disease,which is a cause of atrial fibrillation. Take steps toward a heart-healthy lifestyle.</p>
                    <ul className="precaution-modal">
                        {
                            PRECAUTION_MAIN.map((value: string, index: Number) => {
                                return (
                                    <li key={`${value}_${index}`}>
                                        <i className="fa fa-check mr-3" aria-hidden="true" /> {value}
                                    </li>
                                )
                            })
                        }
                    </ul>
                </Modal.Body>
            </Modal>
            <Modal show={Symtomsshow} onHide={symtomsClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton className="border-0 p-1">
                    <Modal.Title />
                </Modal.Header>
                <Modal.Body className="text-center pt-0 precaution-modal-main">
                    <h5 className="mt-2 mb-3">Symptoms</h5>
                    <p>Symptoms include:</p>
                    <ul className="precaution-modal">
                        {
                            SYMPTOM_SHOW.map((value: string, index: Number) => {
                                return (
                                    <li key={`${value}_${index}`}>
                                        <i className="fa fa-check mr-3" aria-hidden="true" /> <p>{value}</p>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Competition;