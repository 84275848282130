export function authHeader() {
 
    // return authorization header with basic auth credentials
    let user = localStorage.getItem('accesstoken')
    
    if (user && user) {
        return { 'Authorization': 'Bearer ' + user,appversion:63,device:"w",crossDomain: true};
    } else {
        let user1 = localStorage.getItem('accesstoken_create')
        if (user1 && user1) {
            return { 'Authorization': 'Bearer ' + user1,appversion:63,device:"w",crossDomain: true};
        }else{
            return {appversion:63,device:"w",crossDomain: true};
        }
    }
}

