import { useEffect, useState } from 'react';
import { Container, Tab, Nav, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { userService } from '../_services';
import { toast } from 'react-toastify';
import { Loader } from './Loader';
import ProfileImage from '../images/profile.png';

const Group = () => {

    const [showloader, setShow] = useState({ display: "none" });
    const [joinedGroups, setJoinedGroups] = useState([]) as any;
    const [invitedGroups, setInvitedGroups] = useState([]) as any;
    const [activeTab, setActiveTab] = useState("first");

    useEffect(() => {
        setShow({ display: "flex" })
        // let search = window.location.search;
        // let params = new URLSearchParams(search);

        userService.getGroupList().then(resp => {
            setJoinedGroups(resp.data.data.joined);
            setInvitedGroups(resp.data.data.invited);
            setShow({ display: "none" });
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            toast.error('Group list not loaded.');
            setShow({ display: "none" });
        });
        let tab = localStorage.getItem("groupTab") as any;
        if (tab) {
            setActiveTab(tab);
        }
    }, [])

    function handleTabs(e: any) {
        setActiveTab(e);
        localStorage.setItem("groupTab", e);
    }

    return (
        <>
            <section className="bodyvital_section py-5">
                <Loader status={showloader.display} />
                <Container>
                    <div className="inner_box">
                        <Row className="bodyvitals_head">
                            <Col md={12}>
                                <div className="prescription-box">
                                    <h2>Groups</h2>
                                    <Link className="btnsumit text-center add-description text-white" to="/creategroup">+ Add New Group</Link>
                                </div>
                            </Col>
                        </Row>
                        <Row className="group_row mt-1">
                            <Col lg={12} md={12}>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first" activeKey={activeTab} >
                                    <Nav variant="tabs" className="flex-row" activeKey={activeTab} onSelect={handleTabs}>
                                        <Nav.Item>
                                            <Nav.Link eventKey="first" className={activeTab === "first"?"nav_active_link":"nav_in_active_link"}>Joined</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second" className={activeTab === "second"?"nav_active_link":"nav_in_active_link"}>Invited</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content className="mt-3">
                                        <Tab.Pane eventKey="first">
                                            <section className="group-box-section">
                                                <Container>
                                                    <Row>
                                                        {
                                                            joinedGroups.length ?
                                                                joinedGroups.map((g: any, i: any) => {
                                                                    return (
                                                                        <Col lg={4} md={6} className="mb-3" key={i}>
                                                                            <Link className="group-box group-box-new" to={`/group_chat?id=${g.group_id}`}>
                                                                                <div className="img_row11">
                                                                                    <div className="group-shot">
                                                                                        {g.group_image == "https://nlpbackend.xcellentlife.com/group/"
                                                                                            ? <img src={require('../images/profile.png').default} alt="profile" />
                                                                                            : <img src={g.group_image} alt="group" onError={(event: any) => { event.target.src = ProfileImage; }}/>}
                                                                                    </div>
                                                                                    <div className="group-content">
                                                                                        <h5 style={{ overflowWrap: "anywhere" }} className="group-heading">{g.group_name}</h5>
                                                                                        <p className={`group-sub ${g.is_my_group?'joined':''}`}>{g.is_my_group?"Joined":"Admin"}</p>
                                                                                        {/* {g.is_my_group ? <p className="group-sub joined">Joined</p>: <p className="group-sub">Admin</p>} */}
                                                                                    </div>
                                                                                    <img style={{ marginLeft: 'auto' }} src={require('../images/arrow.png').default} alt="arrow" />
                                                                                </div>
                                                                            </Link>
                                                                        </Col>
                                                                    )
                                                                })
                                                                :
                                                                <Col md={12} lg={12} className="text-center">
                                                                    <p>No data found</p>
                                                                </Col>
                                                        }
                                                    </Row>
                                                </Container>
                                            </section>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <section className="group-box-section">
                                                <Container>
                                                    <Row>
                                                        {
                                                            invitedGroups.length ?
                                                                invitedGroups.map((g: any, i: any) => {
                                                                    return (
                                                                        <Col lg={4} md={6} className="mb-3" key={i}>
                                                                            <Link className="group-box group-box-new" to={"/join_group?id=" + g.group_id}>
                                                                                <div className="img_row11">
                                                                                    <div className="group-shot">
                                                                                        {g.group_image == "https://nlpbackend.xcellentlife.com/group/"
                                                                                            ? <img src={require('../images/profile.png').default} alt="profile" />
                                                                                            : <img src={g.group_image} alt="group" onError={(event: any) => { event.target.src = ProfileImage; }}/>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="group-content mt-2">
                                                                                        <h5 style={{ overflowWrap: "anywhere" }} className="group-heading">{g.group_name}</h5>
                                                                                    </div>
                                                                                    <img style={{ marginLeft: 'auto' }} src={require('../images/arrow.png').default} alt="arrow" />
                                                                                </div>
                                                                            </Link>
                                                                        </Col>
                                                                    )
                                                                })
                                                                :
                                                                <Col md={12} lg={12} className="text-center">
                                                                    <p>No data found</p>
                                                                </Col>
                                                        }
                                                    </Row>
                                                </Container>
                                            </section>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}
export default Group;