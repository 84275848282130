import { useState, useEffect } from "react";
import { Form,Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Loader } from './Loader';
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from 'react-router-dom';
import { userService } from '../_services';
import { setItems } from '../utils/localStorage/index';
import { returnFeatureByPlanId } from '../utils/commonFunction';

const Login = (props: any) => {
	const [showloader, setShow] = useState({ display: "none" });
	const [loginform, setLoginForm] = useState({
		email: '',
		password: ''
	}) as any;
	const [message, setMessage] = useState('') as any;
	const [showerr, setShowErr] = useState(false);
	const handleCloseE = () => setShowErr(false);

	const loginSchema = Yup.object().shape({
		email: Yup.string().required("E-mail field can’t be empty."),
		password: Yup.string().required("Password field can’t be empty."),
	});

	useEffect(() => {
		const accesstoken = localStorage.getItem('accesstoken');
		localStorage.removeItem('record');
		localStorage.removeItem('prescription');
		if (accesstoken) {
			// OLD CODE
			// var oldURL = document.referrer;
			// if (window.location.href != oldURL) {
			// 	window.location.href = oldURL;
			// }
			
			window.location.href="/"
		}
	}, []);

	const submit = (values: any) => {
		let params = {
			...values,
			// roleId: Role,
			deviceId: "",
			deviceType: "w",
			unique_device_id: ""
		}
		setShow({ display: "flex" })
		let token = localStorage.getItem("firebaseToken") as any
		if (token) {
			params.deviceId = token
			params.unique_device_id = localStorage.getItem('udid') as any
		}
		userService.login(params)
			.then(function (response) {
				localStorage.setItem('accesstoken', response.data.data.access_token);
				localStorage.setItem('user_id', response.data.data.user.id);
				localStorage.setItem('username', response.data.data.user.firstname + " " + response.data.data.user.lastname);
				localStorage.setItem('profileImage', response.data.data.user.profileImage);
				localStorage.setItem('referCode', response.data.data.user.referral_code);
				if (response.data.data.user.plan_details) {
					let activeFeature = returnFeatureByPlanId(response.data.data.user.plan_details.plan_id);
					if (activeFeature) {
						setItems('plan_features', activeFeature);
					}
					setItems('plan_details', response.data.data.user.plan_details);
				}
				setShow({ display: "none" });
				// if user have not filled firstname then it is redirected to create profile page
				if (response.data.data.user.firstname == null) {
					window.location.href = "/create_profile";
				} else {
					// if user has a plan then he is redirected to dashboard	
					if (response.data.data.user.plan_details) {
						window.location.href = "/";
					} else {
						// if user has not a plan then he is redirected to membership plan page
						window.location.href = "/membership/plan";
					}
				}
			}).catch(error => {
				setShow({ display: "none" })
				if (error.response.status == 422) {
					toast.error(error.response.data.message);
					return;
				} else {
					toast.error('An error occured.');
					return;
				}
			});
	}

	return (
		<section className="loginsec">
			<Loader status={showloader.display} />
			<div className="right_side py-5">
				<div className="container">
					<div className="row">
						<aside className="col-xl-5 col-lg-7 col-sm-12 m-auto">
							<div />
							<div className="login_form mt-4">
								<div className="text-center">
									<h4>Login</h4>
									<p>Login into your account.</p>
									<img src={require('../images/line.svg').default} alt="line" />
								</div>
								<Formik
									initialValues={loginform}
									validationSchema={loginSchema}
									enableReinitialize={true}
									onSubmit={(values) => {
										submit(values);
									}}>
									{({ errors, touched, values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
										<form className="mt-4" autoComplete="off" onSubmit={handleSubmit}>
											<Form.Group className="mb-3" controlId="email">
												<Form.Control type="email" placeholder="Email*" name="email" className="input103" value={values.email ? values.email : ''} onChange={handleChange} />
												{errors.email && touched.email ? (<label id="uname-error" className="error" htmlFor="email"> {errors.email}</label>) : null}
											</Form.Group>
											<Form.Group className="mb-3" controlId="password">
												<Form.Control type="password" placeholder="Password*" className="input103" name="password" value={values.password ? values.password : ''} onChange={handleChange} />
												{errors.password && touched.password ? (<label id="uname-error" className="error" htmlFor="password"> {errors.password}</label>) : null}
											</Form.Group>
											<div className="text-center mt-4">
												<button type="submit" className="loginbtn btn shadow text-white">LOGIN</button>
												<Link className="forgotpassword" to="/forgot_password">Forgot Password? </Link>
											</div>
										</form>
									)}
								</Formik>
								<div className="text-center mt-0 mb-4">
									<p>Don't have an account ?   <Link to="/signup" className="signup_text" style={{ fontWeight: "bold" }}>Register Now</Link></p>
								</div>
							</div>
						</aside>
					</div>
				</div>
			</div>
			<Modal show={showerr} onHide={handleCloseE} size="sm">
				<Modal.Header closeButton className="border-0 headerclose">
				</Modal.Header>
				<Modal.Body className="text-center py-4">
					<img src={require('../../src/images/check.svg').default} alt="check" />
					<h5 className="thnkutext mt-3 mb-1">Try Again!</h5>
					<p className="mb-0">{message}</p>
				</Modal.Body>
			</Modal>
		</section>
	)
}

export default Login;