import React, { useState } from "react";
import moment from 'moment';
import { ACCEPT_NUMBER } from '../config/constants';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NORMAL_CRITICAL_TOOLTIP } from '../config/constants';

interface DiseaseInterface {
    myDiseases: any,
    setMyDiseases: any,
    list: any,
    type: any,
    setErr: any,
    setErrMessage: any
};

const MedicalHistoryRecord = ({ myDiseases, setMyDiseases, list, type, setErr, setErrMessage }: DiseaseInterface) => {
    const [fields, setFields] = useState([{ "disease": "", "date": moment().format('YYYY-MM-DD'), "age": "", "stage": "", "type": type ,"triggered":false}])

    function handleAdd(e: any) {
        let currentField = fields[fields.length - 1];
        if (currentField.disease == '' || currentField.stage == '') {
            setErr(true);
            let message = `Please fill ${currentField.disease === "" ? "disease" : ''}${currentField.disease === "" && currentField.stage === "" ? "," : ""}${currentField.stage === "" ? "stage" : ''} field${currentField.disease === "" && currentField.stage === "" ? "s" : ''}`
            setErrMessage(message);
            e.preventDefault();
            return false
        }
        if (type != 'I' && currentField.age == "") {
            let message = `Please fill ${currentField.age === "" ? "age" : ''}`
            setErrMessage(message);
            setErr(true);
            e.preventDefault();
            return false
        }
        const values = [...fields];
        values.push({ "disease": "", "date": moment().format('YYYY-MM-DD'), "age": "", "stage": "", "type": type ,"triggered":false});
        setFields(values);
        var My_Diseases = [...myDiseases];
        My_Diseases.map((d: any) => {
            if (d[type]) {
                d[type] = values;
            }
        });
        setMyDiseases(My_Diseases);
    }

    function handleRemove(index: any) {
        const values = [...fields];
        values.splice(index, 1);
        setFields(values);
        var My_Diseases = [...myDiseases];
        My_Diseases.map((d: any) => {
            if (d[type]) {
                d[type] = values;
            }
        });
        setMyDiseases(My_Diseases);
    }

    function handleChange(index: any, value: any, diseaseType: any) {
        const values = [...fields];
        if (diseaseType == 'disease') {
            values[index].disease = value;
        }
        if (diseaseType == 'date') {
            values[index].date = value;
        }
        if (diseaseType == 'age') {
            if (ACCEPT_NUMBER.test(value)) {
                if (value.trim().length > 2 || value.trim() == '00' || value.trim() == "0") {
                } else {
                    values[index].age = value.trim();
                }
            }
        }
        if (diseaseType == 'stage') {
            values[index].stage = value
        }
        setFields(values);
        var My_Diseases = [...myDiseases]
        My_Diseases.map((d: any) => {
            if (d[type]) {
                d[type] = values
            }
        })
        setMyDiseases(My_Diseases);
    }

    // return validation
    function returnValidationStatus(type: any, index: any) {
        if (type === 'I') {
            return false;
        } else {
            let { disease, age, stage } = fields[index];
            if (disease || age || stage) {
                return false
            } else {
                return false
            }
        }
    }

    let startDateMinMaxObject = {
        min: moment().subtract(100, 'year').format('YYYY-MM-DD'),
        max: moment().format('YYYY-MM-DD')
    };

    return (
        <>
            {fields.map((field: any, index: any) => {
                console.log('field....', field);
                return (
                    <React.Fragment key={`${field.age}_${index}`}>
                        <div className="col-md-12">
                            <div className="prescription-delete">
                                <h3>#{index + 1} Disease</h3>
                                {(fields.length > 1) ? (<h4 onClick={() => handleRemove(index)}><i className="fa fa-trash" aria-hidden="true" /> Delete</h4>) : (<></>)}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group mb-3">
                                <h4>Select your medical disease</h4>
                                <select
                                    name="disease"
                                    id="disease"
                                    className="form-control"
                                    onChange={(e: any) => handleChange(index, e.target.value, "disease")}
                                    value={field.disease}
                                    required={returnValidationStatus(field.type, index)}>
                                    <option value='' disabled>Choose disease</option>
                                    {list.map((disease: any, index: any) => {
                                        return (
                                            <option value={disease.id} key={`${disease.id}_${index}`}>{disease.disease_name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className={`col-md-${type === 'I' ? 6 : 5}`}>
                            <h4>Date Diagnosed</h4>
                            <div className="mb-3 form-group">
                                <input type="date" placeholder="YYYY-MM-DD" className="form-control" id="dateDiagonised" name="start_date" min={startDateMinMaxObject.min} max={startDateMinMaxObject.max} value={moment(field.date).format('YYYY-MM-DD')} onChange={(e: any) => handleChange(index, e.target.value, "date")} required={returnValidationStatus(field.type, index)} />
                            </div>
                        </div>
                        {type === 'I' ? '' : <div className="col-md-3">
                            <h4>Age</h4>
                            <div className="form-group mb-3">
                                <input placeholder="Enter Age" type="text" id="age" className="form-control" value={field.age} onChange={(e: any) => handleChange(index, e.target.value, "age")} required={returnValidationStatus(field.type, index)} />
                            </div>
                        </div>}
                        <div className={`col-md-${type === 'I' ? 6 : 4}`}>
                            <div className="d-flex">
                                <h4>Stage</h4>
                                <div className="ml-2">
                                    <OverlayTrigger
                                        placement="top"
                                        className="medical_tooltip"
                                        overlay={
                                            <Tooltip className="medical_tooltip">
                                                {`Normal -${NORMAL_CRITICAL_TOOLTIP.Normal} \n  \n Critical - ${NORMAL_CRITICAL_TOOLTIP.Critical}`}
                                            </Tooltip>
                                        }>
                                        <i className="fa fa-info-circle c-pointer" style={{ color: '#1ea2e3' }} />
                                    </OverlayTrigger>
                                </div>

                            </div>
                            <div className="form-group mb-3">
                                <select className="form-control" onChange={(e: any) => handleChange(index, e.target.value, "stage")} value={field.stage} required={returnValidationStatus(field.type, index)}>
                                    <option value='' disabled>Select Stage</option>
                                    {
                                        ["Normal", "Critical"].map((value, index) => {
                                            return (
                                                <option value={value} key={`${value}_${index}`}>{value}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </React.Fragment>
                )
            })}
            <div className="col-md-12">
                <button className='btn' type="button" onClick={handleAdd} style={{ color: "#1AA0E6" }}><i className="fa fa-plus-circle more-prescription" aria-hidden="true" /> Add More</button>
            </div>
        </>
    )
}

export default MedicalHistoryRecord