/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router';
import { jsonObj, reports } from '../config/config';
import { userService } from '../_services';
import Loader from './common/Loader';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { INDIVIDUAL_VS_GROUP_ANALYSIS } from '../config/constants';
import { returnTotalPages } from '../utils/pagination';
import { getItems } from '../utils/localStorage';


const Heatmap = () => {
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    let item = searchParams.get("item") as any;
    let page = searchParams.get("page") as any;
    const [temp, setTemp] = useState(0);
    const history = useHistory();
    const [loading, setloading] = useState(true);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        page: 0,
        totalPage: 0
    });
    const [isPaginationtrigger, setIsPaginationTrigger] = useState(false);

    // <Array<jsonObj>>([{}])

    useEffect(() => {
        if (page) {
            updatePaginationState('page', parseInt(page) - 1);
            setIsPaginationTrigger(true);
        } else {
            triggerListingApi();
        }
    }, [temp]);

    function triggerListingApi() {
        let api;
        if (parseInt(item) === 4) {
            api = userService.getReportListing(item, pagination.page);
        } else {
            api = userService.metricFilter(item, pagination.page);
        }
        api.then(resp => {
            setloading(false);
            let { data } = resp.data;
            let totalPage = returnTotalPages(resp.data.totalCount, 10);
            updatePaginationState('totalPage', totalPage);
            setData(data ? data : []);
            setIsPaginationTrigger(false);
        }).catch(err => {
            setloading(false);
            setData([]);
            setIsPaginationTrigger(false);
            updatePaginationState('totalPage', 0);
            updatePaginationState('page', 0);
        })
    }

    useEffect(() => {
        if (isPaginationtrigger) {
            triggerListingApi();
        }
    }, [isPaginationtrigger]);

    function updatePaginationState(name: any, value: any) {
        setPagination(prevPagination => {
            return {
                ...prevPagination,
                [name]: value
            }
        });
    }


    function handlePageClick(event: any) {
        if (event.selected <= pagination.totalPage) {
            setloading(true);
            updatePaginationState('page', event.selected);
            setIsPaginationTrigger(true);
            window.history.pushState(`Page ${event.selected + 1}`, 'Title', `/report/list?item=${item}&page=${event.selected + 1}`);
        }
    };

    function confirmDeleteReport(report_id: number) {
        // if(getItems("plan_details").plan_id === 1){
        //     Swal.fire({
        //         title: '',
        //         text: "This feature is not included in your current plan. To use this please upgrade your plan.",
        //         icon: 'warning',
        //         showCancelButton: true,
        //         confirmButtonColor: '#3085d6',
        //         cancelButtonColor: '#d33',
        //         confirmButtonText: 'Upgrade Membership',
        //         cancelButtonText: 'cancel'
        //     } as any).then((result: any) => {
        //         if (result.value) {
        //             history.push('/membership/current');
        //         }
        //     })
        // }else{
        // }
        Swal.fire({
            text: "Are you sure you want to delete the report ?",
            title: "Alert",
            showCancelButton: true,
            confirmButtonText: "Delete"
        }).then(e => {
            if (e.value) {
                setloading(true)
                let api;
                if (parseInt(item) === 4) {
                    api = userService.deleteAnalysisReport(report_id)
                } else {
                    api = userService.deleteMetricFilter(report_id)
                }
                api.then(resp => {
                    toast.success("Report deleted successfully.")
                    setloading(false)
                    setTemp(t => t + 1)
                }).catch(err => {
                    setloading(false)
                    if (err.response?.status == 400) {
                        toast.error(err.response.data.message)
                    } else {
                        toast.error("Unknown error occured.")
                    }
                })
            }
        })
    }

    function back() {
        // window.location.href = "/";
        history.push('/');
    }

    function loadGraphPage(url: any) {
        if(getItems("plan_details").plan_id === 1){
            Swal.fire({
                title: '',
                text: "This feature is not included in your current plan. To use this please upgrade your plan.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Go to Subscription Plan',
                cancelButtonText: 'Cancel'
            } as any).then((result: any) => {
                if (result.value) {
                    history.push('/membership/current');
                }
            })
        }else{
            localStorage.setItem('active_url', window.location.href);
            history.push(url);
        }
    }
    // get category details by id to show data in card box
    function getCategory(id: number) {
        let data = INDIVIDUAL_VS_GROUP_ANALYSIS.filter((x: any) => {
            if (x.value === id) {
                return x
            } else {
                return false
            }
        })[0];
        return data;
    }

    return (
        <>
            {loading && <Loader />}
            <section className="bodyvital_section friend_sections notification_sections py-5">
                <Container>
                    <div className="inner_box pb-4">
                        <h5 className='mb-2 cursor-pointer'>
                            <span onClick={back} role="button">
                                <img src={require('../images/back.svg').default} alt="back-btn" />
                            </span>
                        </h5>
                        <Row className="align-items-center">
                            <Col lg={8} md={7}>
                                <h2 className="d-inline">{reports[item]}</h2>
                            </Col>
                            <Col lg={4} md={5} className="d-flex justify-content-end">
                                <span className="position-relative d-inline-block ml-2 c-pointer" onClick={() => loadGraphPage(`/report/add?item=${item}`)}>
                                    <i className='fa fa-plus add_map' /><span className='add_1'>Add</span>
                                </span>
                            </Col>
                            <Col md={12}>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            {
                                data.length ? data.map((report: jsonObj, index: number) => {
                                    let category = getCategory(report.metric_type);
                                    let url = `/report/graph?id=${report.id}&item=${item}${category ? `&categoryId=${category.value}` : ``}`
                                    return (
                                        <Col lg={6} md={6} sm={6} key={`${report.id}_${index}`} className={`${parseInt(item) > 4 ? `mb-3` : ``} cursor-pointer`}>
                                            <div className={`users_box ${parseInt(item) > 4 ? `map_report_box` : `map_report`} mb-3`} >
                                                <div style={{ width: "95%" }} onClick={() => loadGraphPage(url)}>
                                                    <span className={parseInt(item) > 4?'d-block mb-3':''}>{report.report_name}</span>
                                                    {parseInt(item) === 4 ?
                                                        <p className='mb-1'>Category  : {category ? category.label : ''}</p> :
                                                        parseInt(item) > 4 ? '' : <p className='mb-1'>Level: {report.level}</p>
                                                    }
                                                    {/* parseInt(item) === 5 || parseInt(item) === 6 || parseInt(item) === 7 || parseInt(item) === 8 || parseInt(item) === 9 */}
                                                    <p className={`time_date`}>
                                                        <i className='fa fa-calendar mr-2' /> {moment(report.created_at).format("MM/DD/YYYY hh:mm a")}
                                                    </p>
                                                </div>
                                                <div className="edit_report" onClick={() => loadGraphPage(`/report/add?id=${report.id}&item=${item}`)}>
                                                    <i className="fa fa-pencil-square-o mr-1" />
                                                    Edit Report
                                                </div>
                                                <div className="del_report" onClick={() => confirmDeleteReport(report.id)}>
                                                    <i className="fa fa-trash mr-1" />
                                                    Delete Report
                                                </div>

                                            </div>
                                        </Col>
                                    )
                                }) : <div className="mt-4 col-12 text-center">
                                    No listing available
                                </div>
                            }
                            {/* <div className="users_box map_report mb-3">
                                    <div>
                                        <span>
                                            mY Report
                                        </span>
                                        <p className='mb-1'>
                                            Level: High
                                        </p>
                                        <p className='time_date'>
                                            <i className='fa fa-calendar mr-2'></i> 07/15/2020 02:30 PM
                                        </p>
                                    </div>
                                    <div className="edit_report">
                                        <i className="fa fa-pencil-square-o mr-1"></i>
                                        Edit Report
                                    </div>
                                </div> */}
                        </Row>

                        {pagination.totalPage > 1 ?
                            <div className='row'>
                                <div className="col-md-4 mt-2">
                                    <ReactPaginate
                                        previousLabel="Previous"
                                        nextLabel="Next"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        forcePage={pagination.page}
                                        pageCount={pagination.totalPage}
                                        renderOnZeroPageCount={null}
                                        containerClassName="pagination"
                                        activeClassName="active"
                                    />
                                </div>
                            </div>
                            : ''}
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Heatmap;