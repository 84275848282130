import { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap"
import { userService, formValidation } from '../_services';
import { toast } from "react-toastify";
import { useHistory ,Link} from "react-router-dom";
import { Loader } from './Loader';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { ACCEPT_NUMBER } from '../config/constants';


const AddBank = (props: any) => {
    let history = useHistory();
    const [show, setShow] = useState("none") as any;
    const [err, setErr] = useState("") as any;
    const [bankAddress, setBankAddress] = useState("") as any;
    const [isLocationValid, setIsLocationValid] = useState(false);
    const [paymentObj, setPaymentObj] = useState({});
    const [locationDetailObj, setLocationDetailObj] = useState({}) as any;
    const [isAccountAdded, setIsAccountAdded] = useState(false);
    const toastId = useRef() as any;

    useEffect(() => {
        let accesstoken = localStorage.getItem('accesstoken');
        setShow("flex");
        // alert(accesstoken)
        userService.userdetail(accesstoken).then((response) => {
            const userInfo: any = response.data.data;
            console.log('\n userInfo...', userInfo);
            if (userInfo) {
                let arr = ["street", "city", "state", "postal_code", "country"];
                let fullAddress = '';
                arr.forEach((v1: any) => {
                    let value = v1 === "country" ? "US" : userInfo[v1] ? `${userInfo[v1]}` : '' as any;
                    let address = v1 === "country" ? "US" : userInfo[v1] ? `${userInfo[v1]}, ` : '' as any;
                    fullAddress = fullAddress + address;
                    handleLocationObj(v1, value);
                })
                setBankAddress(fullAddress);
                setIsLocationValid(true);
                if (userInfo.account_holder_name) {
                    handlePaymentObj("account_holder_name", userInfo.account_holder_name);
                }
                if (userInfo.routingNumber) {
                    handlePaymentObj("routingNumber", userInfo.routingNumber);
                }
                if (userInfo.ssn_no) {
                    handlePaymentObj("ssn_no", userInfo.ssn_no);
                }
                if (userInfo.accountNumber) {
                    handlePaymentObj("accountNumber", userInfo.accountNumber);
                    setIsAccountAdded(true);
                } else {
                    setIsAccountAdded(false);
                }
            }
            setShow("none");
        }).catch((error: any) => {
            if (error.response.status === 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            setShow("none");
        });
    }, []);

    const handleBankFieldInput = (e: any, fieldname: any) => {
        if (ACCEPT_NUMBER.test(e)) {
            handlePaymentObj(fieldname, e);
        }
    }

    const errors: any = {
        account_holder_name: "",
        accountNumber: "",
        routingNumber: "",
        location: "",
        ssn_no: "",
        // cardNumber: "",
        // expiryDate: "",
        // cvv: "",
        formIsValid: false,
    };

    // let refCode = localStorage.getItem("referral_code");

    const handleSubmit = (event: any) => {
        event.preventDefault();
        let params = {
            location: bankAddress,
            ...paymentObj
        } as any;
        let validate = formValidation.step4_validation(params, errors);
        if (validate.formIsValid) {
            if (isLocationValid) {
                setShow("flex");
                let data = {
                    account_holder_name: params.account_holder_name,
                    routing_number: params.routingNumber,
                    account_number: params.accountNumber,
                    ssn_no: params.ssn_no,
                    ...locationDetailObj
                } as any;
                if (window.location.pathname === "/group_payment") {

                } else {
                    userService.addBank(data).then(function (response: any) {
                        if (response.data.status) {
                            if (isAccountAdded) {
                                toast.success("Bank detail added successfully.");
                            } else {
                                toast.success("Bank detail updated successfully.");
                            }
                            setShow("none");
                            history.push('/setting');
                        } else {
                            toast.error(response.data.message);
                            setShow("none");
                        }
                    }).catch(function (error: any) {
                        setShow("none");
                    });
                }
            } else {
                if (toastId.current) {
                    // toast.dismiss(toastId.current);
                    toastId.current = null;
                } else {
                    toastId.current = toast.error('Please check location');
                }
            }
        } else {
            setErr(validate);
        }
    }

    const handleSelect = (address: any) => {
        geocodeByAddress(address).then((result) => {
            let city = '', state = '', country = '', street = '', postal_code = '' as any;
            result.forEach((value) => {
                console.log("result...", value);
                value.address_components.forEach((data) => {
                    if (data.types.includes("postal_code")) {
                        postal_code = data.long_name;
                    }
                    if (data.types.includes("street_number")) {
                        street = data.long_name;
                    }
                    if (data.types.includes("route")) {
                        street = street + ',' + data.long_name;
                    }
                    if (data.types.includes("administrative_area_level_1")) {
                        state = data.short_name;
                    }
                    if (data.types.includes("country")) {
                        country = data.short_name;
                    }
                    if (data.types.includes("locality")) {
                        city = data.short_name;
                    }
                })
            });
            let object = {
                street: street,
                city: city,
                state: state,
                country: country,
                postal_code: postal_code
            } as any;
            if (city && state && country && street && postal_code) {
                if (country === "US") {
                    Object.keys(object).forEach((objKey: any) => {
                        handleLocationObj(objKey, object[objKey])
                    })
                    setIsLocationValid(true);
                } else {
                    toast.error('Only United States location is allowed');
                    setIsLocationValid(false);
                }
            } else {
                let errorMessage = ''
                Object.keys(object).forEach((objKey: any, index: any) => {
                    let status = object[objKey] ? false : true;
                    if (status) {
                        errorMessage += " " + objKey.replace("_", " ");
                    }
                })
                if (errorMessage) {
                    errorMessage.trim();
                    errorMessage = 'Detail of ' + errorMessage + ` are Invalid. \n Please check selected Location`;
                }
                console.log('errorMessage', errorMessage);
                toast.error(errorMessage);
                setIsLocationValid(false);
            }
        }).catch(err => {
            console.log('err...', err);
        })

    };

    const handleLocationInput = (event: any) => {
        let { value } = event.target as any;
        setBankAddress(value);
    }

    const handlePaymentObj = (name: any, value: any) => {
        setPaymentObj((prevPaymentObj: any) => {
            return {
                ...prevPaymentObj,
                [name]: value
            }
        })
    }

    const handleLocationObj = (name: any, value: any) => {
        setLocationDetailObj((prevLocationDetailObj: any) => {
            return {
                ...prevLocationDetailObj,
                [name]: value
            }
        })
    }

    let {
        account_holder_name,
        routingNumber,
        ssn_no,
        accountNumber
    } = paymentObj as any;
    return (
        <>
            <section className="loginsec">
                <Loader status={show} />
                <div className="right_side py-5">
                    <div className="container">
                        <div className="row">
                            <aside className="col-xl-5 col-lg-7 col-sm-12 m-auto">
                                <div />
                                {window.location.pathname === "/group_payment" ? "" :<h5><span onClick={()=>{history.go(-1)}}><img src={require('../images/back.svg').default} alt="back" /></span></h5>}
                                <div className="login_form mt-4">
                                    {
                                        window.location.pathname === "/group_payment" ? "" :
                                        <>                                        
                                        <div className="text-center">
                                            <p>Xcellent Life needs your banking information in order to make a direct payment to you when you win wellness competition or people pay you for private groups you can create.</p>
                                        </div>
                                        </>
                                    }
                                    <Form className="mt-4" autoComplete="off" onSubmit={(event: any) => handleSubmit(event)}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Form.Group className="mb-3" controlId="account_holder_name">
                                                    <Form.Label>Account Holder Name</Form.Label>
                                                    <Form.Control type="text" name="account_holder_name" placeholder="Account holder name" className="input103 mb-3" defaultValue={account_holder_name} onChange={(e: any) => handlePaymentObj('account_holder_name', e.target.value)} minLength="5" maxLength="30" />
                                                    {err ? (<span className="errors">{err.account_holder_name}</span>) : (<></>)}
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="accountNumber">
                                                    <Form.Label>Bank Account</Form.Label>
                                                    <Form.Control type="text" placeholder="Bank Account Number" className="input103 mb-3" value={accountNumber} onChange={(e: any) => handleBankFieldInput(e.target.value, 'accountNumber')} minLength="6" maxLength="20" />
                                                    {err ? (<span className="errors">{err.accountNumber}</span>) : (<></>)}
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="routingNumber">
                                                    <Form.Label>Routing Number</Form.Label>
                                                    <Form.Control type="text" placeholder="Routing Number" className="input103 mb-3" value={routingNumber} onChange={(e: any) => handleBankFieldInput(e.target.value, 'routingNumber')} minLength="9" maxLength="9" />
                                                    {err ? (<span className="errors">{err.routingNumber}</span>) : (<></>)}
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="ssn_no">
                                                    <Form.Label>SSN Number</Form.Label>
                                                    <Form.Control type="text" name="ssn_no" placeholder="Enter last four digits of SSN Number" className="input103 mb-3" value={ssn_no} onChange={(e: any) => handleBankFieldInput(e.target.value, 'ssn_no')} minLength="4" maxLength="4" />
                                                    {err ? (<span className="errors">{err.ssn_no}</span>) : (<></>)}
                                                </Form.Group>
                                                <div className="form-group">
                                                    <label htmlFor="location">Bank Address </label>
                                                    {/* <input type="text" className="form-control" id="location" /> */}
                                                    <PlacesAutocomplete
                                                        value={bankAddress}
                                                        onChange={(e: any) => {
                                                            let event = {
                                                                target: {
                                                                    value: e,
                                                                    name: `location`,
                                                                },
                                                            };
                                                            handleLocationInput(event);
                                                        }}
                                                        onSelect={(e: any) => {
                                                            let event = {
                                                                target: {
                                                                    value: e,
                                                                    name: `location`,
                                                                }
                                                            };
                                                            handleSelect(e);
                                                            handleLocationInput(event);
                                                        }}>
                                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                            <div>
                                                                <input
                                                                    {...getInputProps({
                                                                        placeholder: 'Search Places ...',
                                                                        className: 'location-search-input form-control input103 mb-3',
                                                                    })} />
                                                                <div className="autocomplete-dropdown-container">
                                                                    {loading && <div>Loading...</div>}
                                                                    {suggestions.map(suggestion => {
                                                                        // item-suggetion  ==> Don not Remove Class
                                                                        const className = `suggestion-item${suggestion.active ? '--active' : ''} `;
                                                                        const style = {
                                                                            backgroundColor: suggestion.active ? '#fafafa' : '#ffffff',
                                                                            cursor: 'pointer'
                                                                        };
                                                                        return (
                                                                            <div
                                                                                {...getSuggestionItemProps(suggestion, {
                                                                                    className,
                                                                                    style,
                                                                                })} key={suggestion.placeId}>
                                                                                <span>{suggestion.description}</span>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </PlacesAutocomplete>
                                                    {err ? (<span className="errors">{err.location}</span>) : (<></>)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-4">
                                            <button type="submit" className="loginbtn btn shadow text-white mx-2">Done</button>
                                        </div>
                                    </Form>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AddBank;