import React, { useEffect, useState } from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { userService } from '../_services';
import { useHistory } from 'react-router';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { LinkedinShareButton, TwitterShareButton, FacebookShareButton } from "react-share";
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import { returnTotalPages } from '../utils/pagination';
import {setItems} from '../utils/localStorage';
import { Loader } from './Loader';
// import { IMAGE_REGEX } from '../config/constants';
// TESTING
import { shareDialog } from '../_helpers/init-facebook-sdk'

const Notification = () => {
    const [show, setShow] = useState(false);
    const [timerStatus, setTimerStatus] = useState(false);
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState("none");
    const [count, setCount] = useState(0);
    const [groupName, setGroupName] = useState("");
    const [adtoshow, setadtoshow] = useState([]) as any;
    const [loadBtn, showBtn] = useState(true);
    const [toShow, setToShow] = useState(20);
    const [showData, setShowData] = useState([]);
    const [timer, setTimer] = useState(1000);
    const [shareURL, setShareURL] = useState({}) as any;
    const [shareData, setShareData] = useState({}) as any;
    const [pagination, setPagination] = useState({
        page: 0,
        pageLimit: 20,
        totalPage: 0
    });
    const [isPaginationtrigger, setIsPaginationTrigger] = useState(false);
    const [downLoadUrl, setDownLoadUrl] = useState('');
    const IMAGE_REGEX = (/\.(gif|jpe?g|tiff?|png|webp|bmp|jfif)$/i);
    // const user_id = localStorage.getItem('user_id');


    let history = useHistory();
    let isLinkedInTrigger = localStorage.getItem('isLinkedInTrigger');

    useEffect(() => {
        localStorage.setItem("notif_badge", '');
        triggerLoader("flex");
        getNotificationListing();
        // markReadNotification(user_id);
    }, []);

    useEffect(() => {
        if (isLinkedInTrigger) {
            if (Boolean(isLinkedInTrigger)) {
                let timer = localStorage.getItem('notification_timer') as any;
                setTimer(timer);
                toggleTimer(true);
            }
        }
    }, [isLinkedInTrigger])

    function getNotificationListing() {
        userService.getNotificationList({ ...pagination }).then(resp => {
            triggerLoader("none");
            setCount(resp.data.Unread_count);
            var temp = resp.data.data;
            if (temp) {
                for (let index = 0; index < temp.length; index++) {
                    const element = temp[index];
                    if (index < toShow) {
                        element.show = true;
                    } else {
                        element.show = false;
                    }
                }
                setData(resp.data.data);
                setShowData(temp);
            }
            let totalPage = returnTotalPages(resp.data.Total_record, resp.data.pageLimit);
            updatePaginationState('totalPage', totalPage);
            setIsPaginationTrigger(false);
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            triggerLoader("none");
            setIsPaginationTrigger(false);
        })
    }

    function clearNotification() {
        Swal.fire({
            text: "Are you sure to delete all notifications ?",
            title: "Alert",
            showCancelButton: true,
            confirmButtonText: "Delete"
        }).then(e => {
            if (e.value) {
                triggerLoader("flex");
                userService.deleteNotifs().then(resp => {
                    toast.success(resp.data.message);
                    localStorage.removeItem("notif_badge");
                    setData([]);
                    triggerLoader("none");
                    setCount(0);
                    updatePaginationState('totalPage', 0);
                }).catch(err => {
                    if (err.response.status == 401) {
                        localStorage.clear()
                        window.location.href = '/'
                    }
                    triggerLoader("none");
                    toast.error("An error occurerd~")
                })
            }
        })
    }

    // trigger API of mark read
    // function markReadNotification(id: any) {
    //     userService.markRead(id).then(response => {
    //         triggerLoader("none");
    //     }).catch(error => {
    //         triggerLoader("none");
    //         console.log('error...', error)
    //     })
    // }

    function redirectNotifaction(n: any) {
        let label = n.label;
        let metricType = n.metric_type;

        if (label == 'new_message') {
            console.log(n);
            localStorage.setItem('otherFriendChat', n.sender_name);
            window.location.href = "/friendchat?room=" + btoa(n.group_id) + "&uid=" + btoa(n.sent_user_id);
        }
        if (label == 'group_message') {
            window.location.href = "/group_chat?id=" + n.group_id;
        }
        if (label == 'group_invitation') {
            window.location.href = "/join_group?id=" + n.group_id;
        }
        if (label == 'group_join' || label == "group_member_added") {
            window.location.href = "/invite_group?id=" + n.group_id;
        }
        if (label == 'keep_great_work_metric' || label == 'prescription_reminder') {
            localStorage.removeItem("record");
            localStorage.removeItem("prescription");
            window.location.href = '/';
        }
        if (label == 'update_information_notification' || label == 'last_year_no_updation') {
            localStorage.removeItem("prescription");
            localStorage.setItem("record", 'yes');
            window.location.href = '/';
        }
        if (label == 'reject_request') {
            window.location.href = '/sendfriendrequest?uid=' + btoa(n.sent_user_id);
        }
        if (label == 'friend_request') {
            window.location.href = '/friendrequest?uid=' + btoa(n.sent_user_id);
        }
        if (label == 'accept_request') {
            window.location.href = '/viewuser?uid=' + btoa(n.sent_user_id);
        }
        if (label == 'keep_great_work_share' || label == 'competition_winner') {
            if (n.competition_id) {
                window.location.href = '/leaderboard?comp_id=' + n.competition_id;
            } else {
                window.location.href = '/leaderboard?comp_id=' + n.group_id;
            }
        }
        if (label == 'wellness_notification' || label == 'start_competition' || label == 'competition_last_updation') {
            if (n.competition_id) {
                window.location.href = '/competitiondetails?comp_id=' + n.competition_id;
            } else {
                window.location.href = '/competitiondetails?comp_id=' + n.group_id;
            }
        }
        if (label == 'competition_invite' || label == 'referral_competition_invitation') {
            if (n.competition_id) {
                window.location.href = '/live_competition?comp_id=' + n.competition_id;
            } else {
                window.location.href = '/live_competition?comp_id=' + n.group_id;
            }
        }
        if (metricType) {
            if (metricType == "1") {
                window.location.href = '/bodygraph';
            }
            if (metricType == "2") {
                window.location.href = '/bloodgraph';
            }
            if (metricType == "3") {
                window.location.href = '/lifegraph';
            }
            else {
                window.location.href = '/moodgraph';
            }
        }
        if (label == 'Survey' || label == 'Web') {
            // let url = n.message.split("-")[1];
            // window.location.href = url;
            // n.message.replace("Hey!! Please fill the SurveyForm-","");
            let stringReplace = label == 'Survey' ? "Hey!! Please fill the Survey Form>" : "Hey!! Please fill the Web Form>";
            console.log(n.message.replace(stringReplace, ""));
            window.location.href = n.message.replace(stringReplace, "");
        }
        if (label == 'accept_request') {
            history.push('/viewuser?uid=' + btoa(n.sent_user_id));
        }
        if (label == 'sharing_notification') {
            // setShareURL(`${host}/competitiondetails?comp_id=${n.group_id}`);
            if (n.ad_images.length) {
                setadtoshow(n.ad_images);
                handleSocialShareUrl(n.ad_images[0]);
                // setShareURL(n.ad_images[0].attachment_url);
            }
            setTimer(n.sharing_time);
            localStorage.setItem('notification_timer', n.sharing_time.toFixed());
            localStorage.setItem('competition_id', n.group_id);
            localStorage.setItem('notification_timer_status', 'start');

            setGroupName(n.group_name);
            setShareData(n);
            setShow(true);
            toggleTimer(true);
            setDownLoadUrl(n.download_url);
        }

        if (label == "add_bank_account") {
            history.push('/addBank');
        }
        if (label == "alert") {
            history.push("/alert");
        }

        if (n.message.includes("going to end tomorrow") || n.message.includes("going to end today")) {
            triggerLoader("flex");
            userService.competitionDetails(n.group_id).then(resp => {
                // check if competition is completed
                triggerLoader("none");
                if (moment().format('YYYY-MM-DD') <= resp.data.data.end_date) {
                    localStorage.setItem('viaNotification', "true");
                    history.push(`/competitiondetails?comp_id=${n.group_id}`)
                } else {
                    toast.error('Competition is ended')
                }
            }).catch(err => {
                triggerLoader("none");
                if (err.response.status == 401) {
                    localStorage.clear();
                    window.location.href = '/';
                }
                if (err.response.status === 422) {
                    history.push("/competition");
                    toast.error(err.response.data.message);
                }
            })
        }
        if (label === "Group Invitation Request") {
            setItems('group_id',n.group_id);
            history.push(`/viewrequest`);
        }
    }

    function handleTimerState(data: number) {
        if (data === 0) {
            setShow(false);
            toggleTimer(false);
            setTimer(data);
        }
    }

    function readTime(t: any) {
        handleTimerState(parseFloat(t.remainingTime.toFixed()))
        localStorage.setItem('notification_timer', t.remainingTime.toFixed());
        return (
            <div><h1>{t.remainingTime}</h1></div>
        )
    }

    function socialShare(p: any, time: any) {
        let params = {
            "share_type": p,
            "competition_id": String(shareData.group_id),
            "time": time,
        };
        toggleTimer(true);
        userService.shareAd(params).then(resp => console.log(resp)).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
        })
    }

    function updatePaginationState(name: any, value: any) {
        setPagination(prevPagination => {
            return {
                ...prevPagination,
                [name]: value
            }
        });
    }

    function handlePageClick(event: any) {
        if (event.selected <= pagination.totalPage) {
            triggerLoader("flex");
            updatePaginationState('page', event.selected);
            setIsPaginationTrigger(true);
            // setloading(true);
            // window.history.pushState(`Page ${event.selected + 1}`, 'Title', `/report/list?item=${item}&page=${event.selected + 1}`);
        }
    };

    useEffect(() => {
        if (isPaginationtrigger) {
            getNotificationListing();
        }
    }, [isPaginationtrigger]);

    // trigger loader status to show or hide loader
    function triggerLoader(status: any) {
        setLoader(status);
    }

    function handleSocialShareUrl(url: any) {
        setShareURL((prevShareURL: any) => {
            return {
                ...prevShareURL,
                ...url
            }
        });
    }

    function toggleTimer(status: any) {
        if (!status) {
            localStorage.setItem('notification_timer_status', 'stop');
        }
        setTimerStatus(status);
    }

    function handleCopyText(text: string) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).then(() => {
                toast.success('Ad URL copied.', { autoClose: 2500, hideProgressBar: true });
            }).catch((error) => {
                toast.error(error);
            })
        } else {
            toast.error('Something went wrong');
        }
    }

    function triggerFbShare() {
        toggleTimer(false);
        let params = {
            method: 'share',
            href: shareURL.attachment_url
        }
        shareDialog(params).then(response => {
            if (response && !response.error_message) {
                socialShare("F", timer);
            } else {
                toast.error('Sharing Failed');
                toggleTimer(true);
            }
        })
    }

    // trigger linkedin window
    function triggerLinkedinLogin() {
        var width = 550, height = 420, winHeight = window.screen.height, winWidth = window.screen.width, left, top;

        let windowObjectReference = null as any; // global variable

        let url = `${process.env.REACT_APP_OAUTH_URL}authorization?response_type=code&client_id=${process.env.REACT_APP_LINKED_IN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_LINKED_IN_CLIENT_URI}/auth/linkedin/callback&state=foobar&scope=w_member_social`;

        left = Math.round((winWidth / 2) - (width / 2));
        top = 0;

        if (winHeight > height) {
            top = Math.round((winHeight / 2) - (height / 2));
        }
        if (windowObjectReference === null || windowObjectReference.closed) {
            let windowOption = `scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=${width},height=${height},left=${left},top=${top}`;
            localStorage.setItem('isLinkedInTrigger', "false");
            toggleTimer(false);
            windowObjectReference = window.open(url, 'intent');
            console.log('windowObjectReference...', windowObjectReference);
        } else {
            windowObjectReference.focus();
        }

    }

    return (
        <>
            <section className="bodyvital_section friend_sections notification_sections py-5">
                <Loader status={loader} />
                <Container>
                    <div className="inner_box pb-4">
                        <Row className="align-items-center">
                            <Col lg={8} md={7}><h2 className="d-inline">Notification</h2></Col>
                            <Col lg={4} md={5} className="d-flex justify-content-end">
                                {data.length ? <span onClick={clearNotification}><i style={{ fontSize: "25px" }} className="fa fa-trash text-danger mx-2" /></span> : ""}
                                {/* BELL ICON */}
                                {/* <span className="position-relative d-inline-block ml-2">
                                    <img src={require('../images/bell_1.svg').default} alt="bell_1" width="22px" />
                                    {count ? <span className="count">{count}</span> : ""}
                                </span> */}
                            </Col>
                            <Col md={12}><hr /></Col>
                        </Row>

                        <Row>
                            {data.length ? showData.map((d: any, i: any) => {
                                return (
                                    <React.Fragment key={`${d.group_id}_${i}`}>
                                        {d.show
                                            ?
                                            <Col lg={6} md={6} sm={6} className="mb-3">
                                                <div className="users_box mb-3" data-gid={d.group_id} onClick={(e: any) => { redirectNotifaction(d) }}>
                                                    <div>
                                                        <span>
                                                            {d.label == 'Web' || d.label == 'Survey' ? d.message.split(">")[0] : d.label == 'friend_request' ? d.sender_name + " sent you friend request." : d.message}
                                                        </span>
                                                        {
                                                            d.label != 'friend_request' ?
                                                                <p>
                                                                    <img src={require('../images/time.svg').default} alt="time" className="mr-2" />
                                                                    {moment(d.notification_time).format('MM/DD/YYYY, hh:mm A')}
                                                                </p>
                                                                : ""
                                                        }
                                                    </div>
                                                    <div className="invite_btn1">
                                                        <i className="fa fa-angle-right nextarrow" />
                                                    </div>
                                                </div>
                                            </Col>
                                            : ""
                                        }
                                    </React.Fragment>
                                )
                            }) :
                                <Col className="text-center">
                                    <p className="pt-4">No data found</p>
                                </Col>
                            }
                        </Row>
                        {pagination.totalPage > 1 ? <Row>
                            <Col>
                                <ReactPaginate
                                    previousLabel="Previous"
                                    nextLabel="Next"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    forcePage={pagination.page}
                                    pageCount={pagination.totalPage}
                                    renderOnZeroPageCount={null}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                />
                            </Col>
                        </Row> : ''}
                    </div>
                </Container>
            </section>

            <Modal show={show} onHide={() => { setShow(false); toggleTimer(false); }} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton className="border-0 p-1 pb-0 pr-3 pt-3"><Modal.Title /></Modal.Header>
                <Modal.Body className="notification_modal pt-0">
                    <h5>Share Message</h5>
                    <p>You can share {groupName} ad.</p>
                    <div className="my-4">
                        <div style={{ width: 180, height: 180 }} className="m-auto">
                            <CountdownCircleTimer duration={timer} isPlaying={timerStatus} colors={[["#004777", 0.33]]} >
                                {readTime}
                            </CountdownCircleTimer>
                        </div>
                    </div>
                    {adtoshow.length ? <div className="container">
                        {
                            downLoadUrl ? <div className="refral_row position-relative">
                                <h3 className="mb-2">Ad URL:</h3>
                                <p style={{ color: "#1AA0E6" }} id="referral_code">{downLoadUrl} <i className="fa fa-copy" onClick={() => { handleCopyText(downLoadUrl) }} /></p>
                            </div> : ''
                        }

                        <div className="row justify-content-center">
                            {
                                adtoshow.map((value: any, index: Number) => {
                                    let extension = value.attachment_url.split('/').pop().split('.').pop();
                                    let attachmentObject = value as any;
                                    if (IMAGE_REGEX.test(`.${extension}`)) {

                                    } else {
                                        attachmentObject.notification_attachement_id = `${value.id}&${value.id}`;
                                    }
                                    return (
                                        <div className={`col-md-3 pl-2 pr-2 pb-2 pt-2 ${parseInt(shareURL.id) === value.id ? "select-image" : ""}`} key={`${value.id}_${index}`} id={value.id} onClick={() => handleSocialShareUrl(value)} style={{ position: "relative" }}>
                                            {
                                                IMAGE_REGEX.test(`.${extension}`) ?
                                                    <img src={value.attachment_url} alt={value.attachment_url} className="comp_ad_media" /> :
                                                    <>
                                                        <i className="fa fa-play-circle play-bt-vid" aria-hidden="true" />
                                                        <video src={value.attachment_url} autoPlay={false} controls={false} className="thumb_video" />
                                                    </>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div> : ''}
                    {/* IMPORTANT CODE */}
                    {/* <div className='images-all'>
                        {
                            adtoshow.length ?
                                adtoshow.map((value: any, index: Number) => {
                                    let extension = value.attachment_url.split('/').pop().split('.').pop();
                                    return (
                                        <div className={`modal_img_row mr-2 ${shareURL === value.attachment_url ? "select-image" : ""}`} key={`${value.id}_${index}`} id={value.id} onClick={() => handleSocialShareUrl(value.attachment_url)}>
                                            {IMAGE_REGEX.test(`.${extension}`) ? <img src={value.attachment_url} alt={value.attachment_url} /> : <video src={value.attachment_url} autoPlay={false} controls={false} />}
                                        </div>
                                    )
                                }) : ''
                        }
                    </div> */}
                    <div className="invite_users_links mt-4">

                        <button type="button" className="btn btn-link mx-1 p-0" onClick={() => triggerFbShare()}>
                            <img src={require('../images/invite_fb.svg').default} alt="invite_fb" />
                        </button>
                        {/* CODE OF REACT_SOCIAL_SHARE */}
                        {/* <button type="button" className="btn btn-link mx-1 p-0">
                            <FacebookShareButton
                                onShareWindowClose={(response: any) => {
                                    socialShare("F", timer)
                                }}
                                beforeOnClick={() => {
                                    toggleTimer(false);
                                }}
                                url={shareURL.attachment_url}
                                quote={groupName}>
                                <img src={require('../images/invite_fb.svg').default} alt="invite_fb" />
                            </FacebookShareButton>
                        </button> */}
                        <button type="button" className="btn btn-link mx-1 p-0">
                            <TwitterShareButton
                                beforeOnClick={() => {
                                    toggleTimer(false);
                                }}
                                onShareWindowClose={(e: any) => {
                                    socialShare("T", timer)
                                }}
                                title={"Here is the link to " + groupName + " on XcellentLife."}
                                via={`${groupName}`}
                                url={shareURL.attachment_url}>
                                <img src={require('../images/invite_twitter.svg').default} alt="invite_twitter" />
                            </TwitterShareButton>
                        </button>
                        {/* IMPORTANT CODE DO NOT REMOVE */}
                        {/* <button type="button" className="btn btn-link mx-1 p-0">
                            <LinkedinShareButton
                                beforeOnClick={() => {
                                    toggleTimer(false);
                                }}
                                onShareWindowClose={() => { socialShare("L", timer) }}
                                title={"Here is the link to " + groupName + " on XcellentLife."}
                                url={shareURL.attachment_url}>
                                <img src={require('../images/invite_link.svg').default} alt="invite_link" />
                            </LinkedinShareButton>
                        </button> */}
                        <button type="button" className="btn btn-link mx-1 p-0" onClick={triggerLinkedinLogin}>
                            <img src={require('../images/invite_link.svg').default} alt="invite_link" />
                        </button>
                        {/* <button onClick={triggerFbShare} type="button" className="btn btn-link">
                        <img src={require('../images/invite_fb.svg').default} alt="invite_fb" />
                    </button> */}
                    </div>
                    {/* <button className="btn sharebtn text-white mt-4">SHARE</button> */}
                    <button className="btn sharebtn text-white mb-4" type="button" onClick={() => { setShow(false) }}>DECLINE</button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Notification;