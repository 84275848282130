/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import { Line } from 'react-chartjs-2'
import { userService } from '../_services';
import { Loader } from './Loader';
const
  Moodgraph = () => {
    const [showloader, setShowloader] = useState({ display: "none" });
    const [healthSummary, setHealthSummary] = useState() as any
    const [graphData, setgraphData] = useState('week') as any
    let pressurelabels: any = [];
    let heartlabels: any = [];
    let heartdata: any = [];
    let heartColors: any = [];
    let pressuredColors: any = [];
    let oxytColors: any = [];
    let bodydataColors: any = [];
    let resdataColors: any = [];
    let bmdataColors: any = [];
    let HdataColors: any = [];
    let WdataColors: any = [];
    let Wdata1Colors: any = [];
    let Wdata2Colors: any = [];
    let pressured: any = [];
    let oxylabels: any = [];
    let oxytdata: any = [];
    let bodylabels: any = [];
    let bodydata: any = [];
    let reslabels: any = [];
    let resdata: any = [];
    let bmlabels: any = [];
    let bmdata: any = [];
    let Hlabels: any = [];
    let Hdata: any = [];
    let Wlabels: any = [];
    let Wdata: any = [];
    let Wlabels1: any = [];
    let Wdata1: any = [];
    let Wlabels2: any = [];
    let Wdata2: any = [];
    let Wlabels3: any = [];
    let Wdata3: any = [];
    let Wdata3Colors: any = [];
    let Wlabels4: any = [];
    let Wdata4: any = [];
    let Wdata4Colors: any = [];
    let Wlabels5: any = [];
    let Wdata5: any = [];
    let Wdata5Colors: any = [];

    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
      plugins: {
        legend: {
          display: false
        }
      }
    };

    useEffect(() => {
      setShowloader({ display: "flex" })
      userService.mood_graph().then(response => { return response.data })
        .then((result) => {
          setHealthSummary(result);
          setShowloader({ display: "none" })
        })
        .catch(error => {
          if (error.response.status == 401) {
            localStorage.clear()
            window.location.href = '/'
          }
          console.log(error.response)
          setShowloader({ display: "none" })
        });
    }, [])

    if (healthSummary) {
      const anger_green = healthSummary['data']['Anger']['metric_ranges'][0]['green'].split("-")
      const anger_yellow_low = healthSummary['data']['Anger']['metric_ranges'][0]['yellow_low']
      const anger_yellow_high = healthSummary['data']['Anger']['metric_ranges'][0]['yellow_high']

      if (graphData == "week") {
        healthSummary['overall_weekly_data'].map(function (gdata: any, index: any) {
          const val = gdata['measured_data_over']
          pressurelabels.push(gdata['day_over'])
          pressured.push(val)
          if (val >= 3 && val <= 5) {
            pressuredColors.push("#00d700")
          } else if (val > 0 && val <= 2) {
            pressuredColors.push("#c7c500")
          } else {
            pressuredColors.push("#ff0000")
          }
        })
        healthSummary['data']['Anger']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_data']
          heartlabels.push(gdata['day'])
          heartdata.push(val)
          if (val >= 0 && val <= 1) {
            heartColors.push("#00d700")
          } else if (val >= 2 && val <= 3) {
            heartColors.push("#c7c500")
          } else {
            heartColors.push("#ff0000")
          }
        })
        healthSummary['data']['Peacefulness']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_data']
          oxylabels.push(gdata['day'])
          oxytdata.push(val)
          if (val >= 4 && val <= 5) {
            oxytColors.push("#00d700")
          } else if (val > 2 && val <= 3) {
            oxytColors.push("#c7c500")
          } else {
            oxytColors.push("#ff0000")
          }
        })
        if (healthSummary['data']['Sadness']) {
          healthSummary['data']['Sadness']['values'].map(function (gdata: any, index: any) {
            const val = gdata['measured_data']
            bodylabels.push(gdata['day'])
            bodydata.push(val)
            if ((val >= 414 && val <= 550) || (val >= 1010 && val <= 2001)) {
              bodydataColors.push("#c7c500")
            } else {
              bodydataColors.push("#ff0000")
            }
          })
        }
        healthSummary['data']['Happiness']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_data']
          reslabels.push(gdata['day'])
          resdata.push(val)
          if (val >= 4 && val <= 5) {
            resdataColors.push("#00d700")
          } else if (val <= 3 && val >= 2) {
            resdataColors.push("#c7c500")
          } else {
            resdataColors.push("#ff0000")
          }
        })
        if (healthSummary['data']['Anxiety']) {
          healthSummary['data']['Anxiety']['values'].map(function (gdata: any, index: any) {
            const val = gdata['measured_data']
            bmlabels.push(gdata['day'])
            bmdata.push(val)
            if ((val >= 414 && val <= 550) || (val >= 1010 && val <= 2001)) {
              bmdataColors.push("#c7c500")
            } else {
              bmdataColors.push("#ff0000")
            }
          })
        }
        healthSummary['data']['Calm']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_data']
          Hlabels.push(gdata['day'])
          Hdata.push(val)
          if ((val >= 414 && val <= 550) || (val >= 1010 && val <= 2001)) {
            HdataColors.push("#c7c500")
          } else {
            HdataColors.push("#ff0000")
          }
        })
        healthSummary['data']['Depressed']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_data']
          Wlabels.push(gdata['day'])
          Wdata.push(val)
          if (val >= 0 && val <= 1) {
            WdataColors.push("#00d700")
          } else if (val >= 2 && val <= 3) {
            WdataColors.push("#c7c500")
          } else {
            WdataColors.push("#ff0000")
          }
        })
        healthSummary['data']['Optimistic']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_data']
          Wlabels1.push(gdata['day'])
          Wdata1.push(val)
          if (val >= 4 && val <= 5) {
            Wdata1Colors.push("#00d700")
          } else if (val > 2 && val <= 3) {
            Wdata1Colors.push("#c7c500")
          } else {
            Wdata1Colors.push("#ff0000")
          }
        })
        healthSummary['data']['Fatigue']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_data']
          Wlabels2.push(gdata['day'])
          Wdata2.push(val)
          if (val >= 0 && val <= 1) {
            Wdata2Colors.push("#00d700")
          } else if (val >= 2 && val <= 3) {
            Wdata2Colors.push("#c7c500")
          } else {
            Wdata2Colors.push("#ff0000")
          }
        })
        healthSummary['data']['Energetic']['values'].map(function (gdata: any, index: any) {
          Wlabels3.push(gdata['day'])
          const val = gdata['measured_data']
          Wdata3.push(val)
          if (val >= 4 && val <= 5) {
            Wdata3Colors.push("#00d700")
          } else if (val > 2 && val <= 3) {
            Wdata3Colors.push("#c7c500")
          } else {
            Wdata3Colors.push("#ff0000")
          }
        })
        healthSummary['data']['Want to Do Good']['values'].map(function (gdata: any, index: any) {
          Wlabels4.push(gdata['day'])
          const val = gdata['measured_data']
          Wdata4.push(val)
          if (val >= 4 && val <= 5) {
            Wdata4Colors.push("#00d700")
          } else if (val > 2 && val <= 3) {
            Wdata4Colors.push("#c7c500")
          } else {
            Wdata4Colors.push("#ff0000")
          }
        })

        healthSummary['data']['Want to Do Harm']['values'].map(function (gdata: any, index: any) {
          Wlabels5.push(gdata['day'])
          const val = gdata['measured_data']
          Wdata5.push(val)
          if (val >= 0 && val <= 1) {
            Wdata5Colors.push("#00d700")
          } else if (val >= 2 && val <= 3) {
            Wdata5Colors.push("#c7c500")
          } else {
            Wdata5Colors.push("#ff0000")
          }
        })
      } else {
        healthSummary['overall_monthly_data'].map(function (gdata: any, index: any) {
          const val = gdata['measured_data_over']
          pressurelabels.push("week" + gdata['day_over'])
          pressured.push(val)
          if (val >= 3 && val <= 5) {
            pressuredColors.push("#00d700")
          } else if (val > 0 && val <= 2) {
            pressuredColors.push("#c7c500")
          } else {
            pressuredColors.push("#ff0000")
          }
        })

        healthSummary['month']['Anger']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_week_data']
          heartlabels.push("week" + gdata['week'])
          heartdata.push(val)
          if (val >= 0 && val <= 1) {
            heartColors.push("#00d700")
          } else if (val >= 2 && val <= 3) {
            heartColors.push("#c7c500")
          } else {
            heartColors.push("#ff0000")
          }
        })

        healthSummary['month']['Peacefulness']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_week_data']
          oxylabels.push("week" + gdata['week'])
          oxytdata.push(val)
          if (val >= 4 && val <= 5) {
            oxytColors.push("#00d700")
          } else if (val >= 2 && val <= 3) {
            oxytColors.push("#c7c500")
          } else {
            oxytColors.push("#ff0000")
          }
        })

        healthSummary['month']['Sadness']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_week_data']
          bodylabels.push("week" + gdata['week'])
          bodydata.push(val)
          if ((val >= 414 && val <= 550) || (val >= 1010 && val <= 2001)) {
            bodydataColors.push("#c7c500")
          } else if (val < 1010 && val > 550) {
            bodydataColors.push("#00d700")
          } else {
            bodydataColors.push("#ff0000")
          }
        })

        healthSummary['month']['Happiness']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_week_data']
          reslabels.push("week" + gdata['week'])
          resdata.push(val)
          if (val >= 4 && val <= 5) {
            resdataColors.push("#00d700")
          } else if (val <= 3 && val >= 2) {
            resdataColors.push("#c7c500")
          } else {
            resdataColors.push("#ff0000")
          }
        })

        healthSummary['month']['Anxiety']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_week_data']
          bmlabels.push("week" + gdata['week'])
          bmdata.push(val)
          if ((val >= 414 && val <= 550) || (val >= 1010 && val <= 2001)) {
            bmdataColors.push("#c7c500")
          } else {
            bmdataColors.push("#ff0000")
          }
        })

        healthSummary['month']['Calm']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_week_data']
          Hlabels.push("week" + gdata['week'])
          Hdata.push(val)
          if ((val >= 414 && val <= 550) || (val >= 1010 && val <= 2001)) {
            HdataColors.push("#c7c500")
          } else {
            HdataColors.push("#ff0000")
          }
        })

        healthSummary['month']['Depressed']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_week_data']
          Wlabels.push("week" + gdata['week'])
          Wdata.push(val)
          if (val >= 0 && val <= 1) {
            WdataColors.push("#00d700")
          } else if (val >= 2 && val <= 3) {
            WdataColors.push("#c7c500")
          } else {
            WdataColors.push("#ff0000")
          }
        })

        healthSummary['month']['Optimistic']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_week_data']
          Wlabels1.push("week" + gdata['week'])
          Wdata1.push(val)
          if (val >= 4 && val <= 5) {
            Wdata1Colors.push("#00d700")
          } else if (val > 2 && val <= 3) {
            Wdata1Colors.push("#c7c500")
          } else {
            Wdata1Colors.push("#ff0000")
          }
        })

        healthSummary['month']['Fatigue']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_week_data']
          Wlabels2.push("week" + gdata['week'])
          Wdata2.push(val)
          if (val >= 0 && val <= 1) {
            Wdata5Colors.push("#00d700")
          } else if (val >= 2 && val <= 3) {
            Wdata5Colors.push("#c7c500")
          } else {
            Wdata5Colors.push("#ff0000")
          }
        })

        healthSummary['month']['Energetic']['values'].map(function (gdata: any, index: any) {
          Wlabels3.push("week" + gdata['week'])
          const val = gdata['measured_week_data']
          Wdata3.push(val)
          if (val >= 4 && val <= 5) {
            Wdata3Colors.push("#00d700")
          } else if (val > 2 && val <= 3) {
            Wdata3Colors.push("#c7c500")
          } else {
            Wdata3Colors.push("#ff0000")
          }
        })

        healthSummary['month']['Want to Do Good']['values'].map(function (gdata: any, index: any) {
          Wlabels4.push("week" + gdata['week'])
          const val = gdata['measured_week_data']
          Wdata4.push(val)
          if (val >= 4 && val <= 5) {
            Wdata4Colors.push("#00d700")
          } else if (val > 2 && val <= 3) {
            Wdata4Colors.push("#c7c500")
          } else {
            Wdata4Colors.push("#ff0000")
          }
        })

        healthSummary['month']['Want to Do Harm']['values'].map(function (gdata: any, index: any) {
          Wlabels5.push("week" + gdata['week'])
          const val = gdata['measured_week_data']
          Wdata5.push(val)
          if (val >= 0 && val <= 1) {
            Wdata5Colors.push("#00d700")
          } else if (val >= 2 && val <= 3) {
            Wdata5Colors.push("#c7c500")
          } else {
            Wdata5Colors.push("#ff0000")
          }
        })
      }



    }
    const data = {
      labels: pressurelabels,
      datasets: [
        {
          label: 'Over All Mood',
          backgroundColor: '#1AA0E6',
          borderColor: '#1AA0E6',
          pointRadius: 7,
          pointHoverRadius: 10,
          borderWidth: 1,
          data: pressured,
          pointBackgroundColor: pressuredColors
        }
      ]
    }


    const dataheart = {
      labels: heartlabels,
      datasets: [
        {
          label: 'Anger',
          data: heartdata,
          backgroundColor: '#1AA0E6',
          borderColor: '#1AA0E6',
          pointRadius: 7,
          pointHoverRadius: 10,
          pointBackgroundColor: heartColors,
          borderWidth: 1,
        }
      ],
    };
    const dataoxy = {
      labels: oxylabels,
      datasets: [
        {
          label: 'Peacefulness',
          data: oxytdata,
          backgroundColor: '#1AA0E6',
          borderColor: '#1AA0E6',
          pointRadius: 7,
          pointHoverRadius: 10,
          pointBackgroundColor: oxytColors,
          borderWidth: 1,
        }
      ],
    };
    const databody = {
      labels: bodylabels,
      datasets: [
        {
          label: 'Sadness',
          data: bodydata,
          backgroundColor: '#1AA0E6',
          borderColor: '#1AA0E6',
          pointRadius: 7,
          pointHoverRadius: 10,
          pointBackgroundColor: bodydataColors,
          borderWidth: 1,
        }
      ],
    };
    const datares = {
      labels: reslabels,
      datasets: [
        {
          label: 'Happiness',
          data: resdata,
          backgroundColor: '#1AA0E6',
          borderColor: '#1AA0E6',
          pointRadius: 7,
          pointHoverRadius: 10,
          pointBackgroundColor: resdataColors,
          borderWidth: 1,
        }
      ],
    };
    const databm = {
      labels: bmlabels,
      datasets: [
        {
          label: 'Anxiety',
          data: bmdata,
          backgroundColor: '#1AA0E6',
          borderColor: '#1AA0E6',
          pointRadius: 7,
          pointHoverRadius: 10,
          pointBackgroundColor: bmdataColors,
          borderWidth: 1,
        }
      ],
    };
    const dataH = {
      labels: Hlabels,
      datasets: [
        {
          label: 'Calm',
          data: Hdata,
          backgroundColor: '#1AA0E6',
          borderColor: '#1AA0E6',
          pointRadius: 7,
          pointHoverRadius: 10,
          pointBackgroundColor: HdataColors,
          borderWidth: 1,
        }
      ],
    };
    const dataW = {
      labels: Wlabels,
      datasets: [
        {
          label: 'Depressed',
          data: Wdata,
          backgroundColor: '#1AA0E6',
          borderColor: '#1AA0E6',
          pointRadius: 7,
          pointHoverRadius: 10,
          pointBackgroundColor: WdataColors,
          borderWidth: 1,
        }
      ],
    };
    const dataW1 = {
      labels: Wlabels1,
      datasets: [
        {
          label: 'Optimistic',
          data: Wdata1,
          backgroundColor: '#1AA0E6',
          borderColor: '#1AA0E6',
          pointRadius: 7,
          pointHoverRadius: 10,
          pointBackgroundColor: Wdata1Colors,
          borderWidth: 1,
        }
      ],
    };
    const dataW2 = {
      labels: Wlabels2,
      datasets: [
        {
          label: 'Tired',
          data: Wdata2,
          backgroundColor: '#1AA0E6',
          borderColor: '#1AA0E6',
          pointRadius: 7,
          pointHoverRadius: 10,
          pointBackgroundColor: Wdata2Colors,
          borderWidth: 1,
        }
      ],
    };

    const dataW3 = {
      labels: Wlabels3,
      datasets: [
        {
          label: 'Energetic',
          data: Wdata3,
          backgroundColor: '#1AA0E6',
          borderColor: '#1AA0E6',
          pointRadius: 7,
          pointHoverRadius: 10,
          pointBackgroundColor: Wdata3Colors,
          borderWidth: 1,
        }
      ],
    };
    const dataW4 = {
      labels: Wlabels4,
      datasets: [
        {
          label: 'Want to Do Good',
          data: Wdata4,
          backgroundColor: '#1AA0E6',
          borderColor: '#1AA0E6',
          pointRadius: 7,
          pointHoverRadius: 10,
          pointBackgroundColor: Wdata4Colors,
          borderWidth: 1,
        }
      ],
    };
    const dataW5 = {
      labels: Wlabels5,
      datasets: [
        {
          label: 'Want to Do Harm',
          data: Wdata5,
          backgroundColor: '#1AA0E6',
          borderColor: '#1AA0E6',
          pointRadius: 7,
          pointHoverRadius: 10,
          pointBackgroundColor: Wdata5Colors,
          borderWidth: 1,
        }
      ],
    };

    return (
      <>
        <section className="bodyvital_section py-5">
          <Loader status={showloader.display} />
          <Container>
            <div className="inner_box">
              <Row className="bodyvitals_head">
                <Col md={6}>
                  <h5>
                    <a href="/">
                      <img src={require('../images/back.svg').default} alt="back" />
                    </a>
                  </h5>
                  <h2>Mood Graphs</h2>
                </Col>
                <Col md={6} className="text-right">
                  <h6>Date Measured</h6>
                  <select onChange={(e: any) => setgraphData(e.target.value)}>
                    <option value="week" selected={graphData == 'week'}>Weekly</option>
                    <option value="month" selected={graphData == 'month'}>Monthly</option>
                  </select>
                </Col>
                <Col md={12}>
                  <hr />
                </Col>
                <Col md={12}>
                  <div className='header'>
                    <h3 className='title text-center'>Over All Mood</h3>
                    <div className='links'>
                      <a
                        className='btn btn-gh'
                        href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                      >
                      </a>
                    </div>
                  </div>
                  <Line options={options} data={data} />
                  {healthSummary ? (

                    <div className="colortext">

                      <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span> 3-5</p>
                      <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  0(min) 2(max)</p>
                      <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> 1(min) -5(max)

                      </p>

                    </div>) : (<></>)}

                </Col>


                <Col md={12}>
                  <div className='header'>
                    <h3 className='title text-center'>Anger</h3>
                    <div className='links'>
                      <a
                        className='btn btn-gh'
                        href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                      >
                      </a>
                    </div>
                  </div>
                  <Line options={options} data={dataheart} />
                  {healthSummary ? (

                    <div className="colortext">
                      {graphData == "week" ? (
                        <>
                          <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Anger']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Anger']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Anger']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Anger']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Anger']['metric_ranges'][0]['red_high']}(Max)</p>
                        </>) : (<>
                          <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Anger']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Anger']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Anger']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Anger']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Anger']['metric_ranges'][0]['red_high']}(Max)</p>
                        </>)}
                    </div>) : (<></>)}

                </Col>
                <Col md={12}>
                  <div className='header'>
                    <h3 className='title text-center'>Peacefulness</h3>
                    <div className='links'>
                      <a
                        className='btn btn-gh'
                        href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                      >
                      </a>
                    </div>
                  </div>
                  <Line options={options} data={dataoxy} />
                  {healthSummary ? (

                    <div className="colortext">
                      {graphData == "week" ? (
                        <>
                          <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Peacefulness']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Peacefulness']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Peacefulness']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Peacefulness']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Peacefulness']['metric_ranges'][0]['red_high']}(Max)</p>
                        </>) : (<>
                          <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Peacefulness']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Peacefulness']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Peacefulness']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Peacefulness']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Peacefulness']['metric_ranges'][0]['red_high']}(Max)</p>
                        </>)}
                    </div>) : (<></>)}

                </Col>
                <Col md={12}>
                  <div className='header'>
                    <h3 className='title text-center'>Sadness</h3>
                    <div className='links'>
                      <a
                        className='btn btn-gh'
                        href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                      >
                      </a>
                    </div>
                  </div>
                  <Line options={options} data={databody} />
                  {healthSummary && healthSummary['data']['Sadness'] ? (
                    <div className="colortext">
                      {graphData == "week" ? (
                        <>
                          <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Sadness']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Sadness']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Sadness']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Sadness']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Sadness']['metric_ranges'][0]['red_high']}(Max)</p>
                        </>) : (<>
                          <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Sadness']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Sadness']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Sadness']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Sadness']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Sadness']['metric_ranges'][0]['red_high']}(Max)</p>
                        </>)}
                    </div>) : (<></>)}

                </Col>

                <Col md={12}>
                  <div className='header'>
                    <h3 className='title text-center'>Happiness</h3>
                    <div className='links'>
                      <a
                        className='btn btn-gh'
                        href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                      >
                      </a>
                    </div>
                  </div>
                  <Line options={options} data={datares} />
                  {healthSummary ? (
                    <div className="colortext">
                      {graphData == "week" ? (
                        <>
                          <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Happiness']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Happiness']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Happiness']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Happiness']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Happiness']['metric_ranges'][0]['red_high']}(Max)</p>
                        </>) : (<>
                          <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Happiness']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Happiness']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Happiness']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Happiness']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Happiness']['metric_ranges'][0]['red_high']}(Max)</p>
                        </>)}
                    </div>) : (<></>)}

                </Col>
                <Col md={12}>
                  <div className='header'>
                    <h3 className='title text-center'>Anxiety</h3>
                    <div className='links'>
                      <a
                        className='btn btn-gh'
                        href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                      >
                      </a>
                    </div>
                  </div>
                  <Line options={options} data={databm} />
                  {healthSummary && healthSummary['data']['Anxiety'] ? (
                    <div className="colortext">
                      {graphData == "week" ? (
                        <>
                          <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Anxiety']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Anxiety']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Anxiety']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Anxiety']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Anxiety']['metric_ranges'][0]['red_high']}(Max)</p>
                        </>) : (<>
                          <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Anxiety']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Anxiety']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Anxiety']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Anxiety']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Anxiety']['metric_ranges'][0]['red_high']}(Max)</p>
                        </>)}
                    </div>) : (<></>)}

                </Col>
                <Col md={12}>
                  <div className='header'>
                    <h3 className='title text-center'>Calm</h3>
                    <div className='links'>
                      <a
                        className='btn btn-gh'
                        href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                      >
                      </a>
                    </div>
                  </div>
                  <Line options={options} data={dataH} />
                  {healthSummary ? (
                    <div className="colortext">
                      {graphData == "week" ? (
                        <>
                          <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Calm']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Calm']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Calm']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Calm']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Calm']['metric_ranges'][0]['red_high']}(Max)</p>
                        </>) : (
                        <>
                          <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Calm']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Calm']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Calm']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Calm']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Calm']['metric_ranges'][0]['red_high']}(Max)</p>
                        </>
                      )}
                    </div>) : (<></>)}

                </Col>
                <Col md={12}>
                  <div className='header'>
                    <h3 className='title text-center'>Depressed</h3>
                    <div className='links'>
                      <a
                        className='btn btn-gh'
                        href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                      >
                      </a>
                    </div>
                  </div>
                  <Line options={options} data={dataW} />
                  {healthSummary ? (
                    <div className="colortext">
                      {graphData == "week" ? (
                        <>
                          <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Depressed']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Depressed']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Depressed']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Depressed']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Depressed']['metric_ranges'][0]['red_high']}(Max)</p>
                        </>) : (
                        <>
                          <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Depressed']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Depressed']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Depressed']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Depressed']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Depressed']['metric_ranges'][0]['red_high']}(Max)</p>
                        </>
                      )}
                    </div>) : (<></>)}

                </Col>
                <Col md={12}>
                  <div className='header'>
                    <h3 className='title text-center'>Optimistic</h3>
                    <div className='links'>
                      <a
                        className='btn btn-gh'
                        href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                      >
                      </a>
                    </div>
                  </div>
                  <Line options={options} data={dataW1} />
                  {healthSummary ? (
                    <div className="colortext">
                      {graphData == "week" ? (
                        <>
                          <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Optimistic']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Optimistic']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Optimistic']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Optimistic']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Optimistic']['metric_ranges'][0]['red_high']}(Max)</p>
                        </>) : (
                        <> <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Optimistic']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Optimistic']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Optimistic']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Optimistic']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Optimistic']['metric_ranges'][0]['red_high']}(Max)</p></>)}
                    </div>) : (<></>)}
                </Col>
                <Col md={12}>
                  <div className='header'>
                    <h3 className='title text-center'>Tired</h3>
                    <div className='links'>
                      <a
                        className='btn btn-gh'
                        href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                      >
                      </a>
                    </div>
                  </div>
                  <Line options={options} data={dataW2} />
                  {healthSummary ? (
                    <div className="colortext">
                      {graphData == "week" ? (
                        <>
                          <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Fatigue']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Fatigue']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Fatigue']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Fatigue']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Fatigue']['metric_ranges'][0]['red_high']}(Max)</p>
                        </>) : (
                        <> <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Fatigue']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Fatigue']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Fatigue']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Fatigue']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Fatigue']['metric_ranges'][0]['red_high']}(Max)</p></>)}
                    </div>) : (<></>)}

                </Col>
                <Col md={12}>
                  <div className='header'>
                    <h3 className='title text-center'>Energetic</h3>
                    <div className='links'>
                      <a
                        className='btn btn-gh'
                        href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                      >
                      </a>
                    </div>
                  </div>
                  <Line options={options} data={dataW3} />
                  {healthSummary ? (
                    <div className="colortext">
                      {graphData == "week" ? (
                        <>
                          <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Energetic']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Energetic']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Energetic']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Energetic']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Energetic']['metric_ranges'][0]['red_high']}(Max)</p>
                        </>) : (
                        <> <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Energetic']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Energetic']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Energetic']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Energetic']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Energetic']['metric_ranges'][0]['red_high']}(Max)</p></>)}
                    </div>) : (<></>)}
                </Col>
                <Col md={12}>
                  <div className='header'>
                    <h3 className='title text-center'> Do Good</h3>
                    <div className='links'>
                      <a
                        className='btn btn-gh'
                        href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                      >
                      </a>
                    </div>
                  </div>
                  <Line options={options} data={dataW4} />
                  {healthSummary ? (
                    <div className="colortext">
                      {graphData == "week" ? (
                        <>
                          <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Want to Do Good']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Want to Do Good']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Want to Do Good']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Want to Do Good']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Want to Do Good']['metric_ranges'][0]['red_high']}(Max)</p>
                        </>) : (
                        <> <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Want to Do Good']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Want to Do Good']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Want to Do Good']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Want to Do Good']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Want to Do Good']['metric_ranges'][0]['red_high']}(Max)</p></>)}
                    </div>) : (<></>)}

                </Col>
                <Col md={12}>
                  <div className='header'>
                    <h3 className='title text-center'>Do Harm</h3>
                    <div className='links'>
                      <a
                        className='btn btn-gh'
                        href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                      >
                      </a>
                    </div>
                  </div>
                  <Line options={options} data={dataW5} />
                  {healthSummary ? (
                    <div className="colortext">
                      {graphData == "week" ? (
                        <>
                          <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Want to Do Harm']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Want to Do Harm']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Want to Do Harm']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Want to Do Harm']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Want to Do Harm']['metric_ranges'][0]['red_high']}(Max)</p>
                        </>) : (
                        <> <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Want to Do Harm']['metric_ranges'][0]['green']}</p>
                          <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Want to Do Harm']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Want to Do Harm']['metric_ranges'][0]['yellow_high']}(Max)</p>
                          <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Want to Do Harm']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Want to Do Harm']['metric_ranges'][0]['red_high']}(Max)</p></>)}
                    </div>) : (<></>)}
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </>
    )
  }

export default Moodgraph;