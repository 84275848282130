import React, { useRef, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { userService } from '../_services';
import { config, toastConfig } from '../config/config';
import { Modal } from "react-bootstrap";
import { Loader } from './Loader';
import { toast } from 'react-toastify';
import {socketUrl} from '../_services/socketio';

let bufferSize = 2048,
  context: any,
  processor: any,
  input: any,
  globalStream: MediaStream,
  ques: any;

const downsampleBuffer = (buffer: any, sampleRate: any, outSampleRate: any) => {
  if (outSampleRate === sampleRate) {
    return buffer;
  }
  if (outSampleRate > sampleRate) {
    throw new Error('downsampling rate show be smaller than original sample rate');
  }
  var sampleRateRatio = sampleRate / outSampleRate;
  var newLength = Math.round(buffer.length / sampleRateRatio);
  var result = new Int16Array(newLength);
  var offsetResult = 0;
  var offsetBuffer = 0;
  while (offsetResult < result.length) {
    var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio)
    var accum = 0, count = 0;
    for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
      accum += buffer[i];
      count++;
    }

    result[offsetResult] = Math.min(1, accum / count) * 0x7FFF;
    offsetResult++;
    offsetBuffer = nextOffsetBuffer;
  }
  return result.buffer;
}

function App(props: any) {
  let user_id = props.match.params.id;

  const chatSocket=useRef(null) as any;


  const socket = useRef(null) as any;
  const [transcript, setTranscript] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [startrecord, setstartRecord] = useState(false);
  const [questions, setQuestions] = useState([]) as any;
  const [showLoader, setShowLoader] = useState("flex");
  const [showbtn, setShowBtn] = useState({ display: "none" });
  const [showerr, setShowErr] = useState(false);
  const [quesid, setQuesid] = useState() as any;
  const [answerId, setAnswerId] = useState([]) as any;
  const [message, setMessage] = useState('') as any;
  const [randomestr, setrandomestr] = useState("") as any;
  const [userObjData, setUserObj] = useState("") as any;
  const [speechAnswerObj, setSpeechAnswerObj] = useState({}) as any;

  const handleClose = () => {
    getQuestions()
    // setShow(false);
    stop();
  }


  const handleCloseE = () => setShowErr(false);

  const handleShow = () => {
    stopRecord(quesid);
    setShowLoader("flex");
    setAnswerId([]);
    var targetDiv = document.querySelectorAll('.myanswers');

    targetDiv.forEach((value: any, index: number) => {
      let valueToPush: any = {};
      valueToPush["ans"] = value.textContent;
      valueToPush["id"] = value.getAttribute('data-id');
      answerId.push(valueToPush);
    });

    // for (var i = 0; i < targetDiv.length; i++) {
    //   if (targetDiv[i].textContent != "") {
    //     var valueToPush: any = {}; // or "var valueToPush = new Object();" which is the same
    //     // valueToPush["id"] = targetDiv[i].data-id;
    //     valueToPush["ans"] = targetDiv[i].textContent;
    //     valueToPush["id"] = targetDiv[i].getAttribute('data-id');
    //     answerId.push(valueToPush)
    //   }
    // }
    let answerArray = [] as any;
    if (Object.keys(speechAnswerObj).length) {
      Object.keys(speechAnswerObj).forEach((value: any) => {
        let newObj = {} as any;
        newObj.ans = speechAnswerObj[value];
        newObj.id = value.replace('Ans', '');
        answerArray.push(newObj);
      });
    }
    let param = {
      'search_query': answerArray,
      'user_id': user_id
    }
    userService.PostAnswer(param).then(function (response) {
      setSpeechAnswerObj({});
      window.location.href = "/thankyou";
      // setShow(true);
    }).catch(error => {

      //  alert(error.response.status)
      // console.log(error.response.status)
      if (error.response) {
        if (error.response.status == 422) {
          setShowLoader("none");
          setShowErr(true);
          setMessage(error.response.message);
          setMessage(error.response.data.message);
        } else {
          // setLoginErr("Please Check your server")
          setSpeechAnswerObj({});
        }
      }
    });
  }

  useEffect(() => {
    setupSocket();
    getQuestions();
  }, []);

  const getQuestions = () => {
    userService.getQuestions().then(function (response) {
      setShowBtn({ display: "inline-block" });
      setShowLoader("none");
      //  console.log(response.data.data)
      setQuestions(response.data.data);
    }).catch(error => {
      // console.log(error.response.status)
      setShowLoader("none");
      if (error.response) {
        console.log(error.response.status);
        // setLoginErr(error.response.data.message)
      } else {
        // setLoginErr("Please Check your server")
      }
    });
  }

  const setupSocket = () => {
    // socket.current = io(`${config.socketurl}`, {
    //   reconnection: true,
    //   reconnectionDelay: 1000,
    //   reconnectionDelayMax: 5000,
    //   reconnectionAttempts: Infinity,
    // }) as any;
    socket.current=socketUrl;
    socket.current.on('connect', () => {
      var userObj: any = {};
      var tmp = Math.floor(Date.now());
      setrandomestr(tmp);
      userObj.room_id = "room_id_" + user_id + tmp;
      let userObj_str = JSON.stringify(userObj);
      setUserObj(userObj_str)
      socket.current.emit('joinRoom', userObj_str);
      console.log('socket connect', userObj_str);
    });

    socket.current.on('disconnect', () => {
      console.log('disconnect');
    });

    socket.current.on('data_rightaway', (data: any) => {
      console.log("data_rightaway---", data);
      // let iddd = "Ans_rightaway" + ques;
      let answerField = `Ans${ques}`;
      setSpeechAnswerObj((prevSpeechAnswerObj: any) => {
        return {
          ...prevSpeechAnswerObj,
          [answerField]: data
        }
      });
      // var div: any = document.getElementById(iddd);
      // if (div) {
      //   div.innerHTML = data;
      // }
      //  console.log(save_data,"=====")
      //  setTranscript(data)
    });

    socket.current.on('data', (data: any) => {
      // data = transcript+" "+data;
      let iddd = "Ans" + ques;
      var div: any = document.getElementById(iddd);
      if (div) {
        var tmp: any = div.innerHTML;
        var save_data: any = "";
        if (tmp) {
          save_data = tmp + " " + data;
        } else {
          save_data = data;
        }
        div.innerHTML = save_data;
        setTimeout(function () {
          let iddda = "Ans_rightaway" + ques;
          var diva: any = document.getElementById(iddda);
          if (diva) {
            diva.innerHTML = "";
          }
        }, 100);
      }
      setTranscript(data);
      setSpeechAnswerObj((prevSpeechAnswerObj: any) => {
        return {
          ...prevSpeechAnswerObj,
          [iddd]: save_data
        }
      });
    });
  }

  const setup = (id: any) => {
    const mediaDevices = navigator.mediaDevices;
    if (mediaDevices) {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((response: any) => {
        // check connected input device and device ID
        navigator.mediaDevices.enumerateDevices().then((response) => {
          response.forEach((data) => {
            console.log(data.kind, data.deviceId);
          })
        }).catch((error) => {
          console.log('navigator.mediaDevices.enumerateDevices error', error);
        })
        document.getElementById("Mic" + id)?.classList.remove("hide_mic");
        document.getElementById("Mic" + id)?.classList.add("show_mic");
        let allOrangeJuiceByClass = document.getElementsByClassName("record_btn");
        for (var i = 0, len = allOrangeJuiceByClass.length | 0; i < len; i = i + 1 | 0) {
          allOrangeJuiceByClass[i].classList.add("disablestart");
        }
        let allOrangeJuiceByClass1 = document.getElementsByClassName("tryagin_btn");
        for (var i = 0, len = allOrangeJuiceByClass1.length | 0; i < len; i = i + 1 | 0) {
          allOrangeJuiceByClass1[i].classList.add("disablestart");
        }
        ques = id;
        document.getElementById("Listrow" + id)?.classList.add("stop");
        setQuesid(id)
        context = new (window.AudioContext || (window as any).webkitAudioContext)({
          // if Non-interactive, use 'playback' or 'balanced' // https://developer.mozilla.org/en-US/docs/Web/API/AudioContextLatencyCategory
          latencyHint: 'interactive',
        })
        processor = context.createScriptProcessor(bufferSize, 1, 1);
        processor.connect(context.destination);
        context.resume();
        globalStream = response
        input = context.createMediaStreamSource(response)
        input.connect(processor)
        processor.onaudioprocess = (e: any) => {
          microphoneProcess(e)
        }
        setIsStreaming(true);
        setstartRecord(true);
      }).catch((error: any) => {
        console.log('navigator.mediaDevices.getUserMedia...', error)
        toast.error(error, toastConfig);
        setIsStreaming(false);
        setstartRecord(false);
      })
    } else {
      toast.error('mediaDevices is not supported either browser or site url')
      console.log('mediaDevices is not supported either browser or site url');
    }
    // setQuesid(id)
    // context = new (window.AudioContext || (window as any).webkitAudioContext)({
    //   // if Non-interactive, use 'playback' or 'balanced' // https://developer.mozilla.org/en-US/docs/Web/API/AudioContextLatencyCategory
    //   latencyHint: 'interactive',
    // })
    // processor = context.createScriptProcessor(bufferSize, 1, 1);
    // processor.connect(context.destination);
    // context.resume();
    // const stream =  navigator.mediaDevices.getUserMedia({ audio: true })
    // console.log(">>>>>>>>>>>>>>.stream",stream)
    // globalStream = stream
    // input = context.createMediaStreamSource(stream)
    // input.connect(processor)
    // processor.onaudioprocess = (e: any) => {
    //   microphoneProcess(e)
    // }
  }

  const microphoneProcess = (e: any) => {

    var left = e.inputBuffer.getChannelData(0)
    var left16 = downsampleBuffer(left, 44100, 16000)
    // console.log("left16",left16);
    let tmp: any = {};
    tmp.left_16 = left16;

    tmp.room_id = "room_id_" + user_id + randomestr;
    // console.log( "======= tmp.room_id  ",tmp.room_id)
    // left16.room_id = "join_room_"+user_id;
    // socketnoti.Onstream(left16)
    socket.current.emit('stream', tmp)
  }

  const start = (id: any) => {
    setup(id);
  }

  const tryAgain = (id: any) => {
    // let iddd = "Ans" + id;
    // let ansid2 = "Ans_rightaway" + id;
    // var div2: any = document.getElementById(ansid2);
    // document.getElementById("Mic" + id)?.classList.remove("hide_mic");
    // document.getElementById("Mic" + id)?.classList.add("show_mic");
    // var div: any = document.getElementById(iddd);
    // div.innerHTML = "";
    // div2.innerHTML = "";
    let allOrangeJuiceByClass = document.getElementsByClassName("record_btn");
    for (var i = 0, len = allOrangeJuiceByClass.length | 0; i < len; i = i + 1 | 0) {
      allOrangeJuiceByClass[i].classList.add("disablestart");
    }
    let allOrangeJuiceByClass1 = document.getElementsByClassName("tryagin_btn");
    for (var i = 0, len = allOrangeJuiceByClass1.length | 0; i < len; i = i + 1 | 0) {
      allOrangeJuiceByClass1[i].classList.add("disablestart");
    }
    ques = id;
    setQuesid(id);
    document.getElementById("Listrow" + id)?.classList.add("stop");
    setstartRecord(true);
    setup(id);
    setIsStreaming(true);
    setSpeechAnswerObj((prevSpeechAnswerObj:any)=>{
      return{
        ...prevSpeechAnswerObj,
        [`Ans${id}`]:''
      }
    })
    // setSpeechAnswerObj({});
  }

  const stopRecord = (id: any) => {

    document.getElementById("Mic" + id)?.classList.add("hide_mic");
    //  console.log('userObjData=====>',userObjData)
    //  console.log(isStreaming,"=========isStreaming")
    let iddd = "Ans" + id;
    var div: any = document.getElementById(iddd);
    let ansid2 = "Ans_rightaway" + id;
    var div2: any = document.getElementById(ansid2);
    let allOrangeJuiceByClass = document.getElementsByClassName("record_btn");
    for (var i = 0, len = allOrangeJuiceByClass.length | 0; i < len; i = i + 1 | 0) {
      allOrangeJuiceByClass[i].classList.remove("disablestart");
    }
    let allOrangeJuiceByClass1 = document.getElementsByClassName("tryagin_btn");
    for (var i = 0, len = allOrangeJuiceByClass1.length | 0; i < len; i = i + 1 | 0) {
      allOrangeJuiceByClass1[i].classList.remove("disablestart");
    }
    if (div) {
      if (div.innerHTML.trim() != "" || div2.innerHTML.trim() != "") {
        document.getElementById("Listrow" + id)?.classList.add("active");
      } else {
        document.getElementById("Listrow" + id)?.classList.remove("active");
      }
      document.getElementById("Listrow" + id)?.classList.remove("stop");
    }
    if (isStreaming) {
      if (globalStream) {
        // stop record track from browser
        let track = globalStream.getTracks()[0];
        track.stop();
      }
      //  let allOrangeJuiceByClass = document.getElementsByClassName("symtomps_list");
      //   for (var i=0, len=allOrangeJuiceByClass.length|0; i<len; i=i+1|0) {
      //    allOrangeJuiceByClass[i].classList.remove("active");
      // }
      // let allOrangeJuiceByClass1 = document.getElementsByClassName("myanswers");
      // for (var i=0, len=allOrangeJuiceByClass1.length|0; i<len; i=i+1|0) {
      //   allOrangeJuiceByClass1[i].innerHTML = "";
      //  }
      setstartRecord(false);
      // stop recorder from browser

      input.disconnect(processor);
      processor.disconnect(context.destination);
      context.close().then(() => {
        input = null
        processor = null
        context = null
        socket.current.emit('stop', userObjData)
      });
      setIsStreaming(false);
    }
  }

  const stop = () => {
    //  console.log(isStreaming,"=========isStreaming")
    if (isStreaming) {
      // stop record track from browser
      // let track = globalStream.getTracks()[0]
      // track.stop()
      setQuesid();
      let allOrangeJuiceByClass = document.getElementsByClassName("symtomps_list");
      for (var i = 0, len = allOrangeJuiceByClass.length | 0; i < len; i = i + 1 | 0) {
        allOrangeJuiceByClass[i].classList.remove("active");
      }
      let allOrangeJuiceByClass1 = document.getElementsByClassName("myanswers");
      for (var i = 0, len = allOrangeJuiceByClass1.length | 0; i < len; i = i + 1 | 0) {
        allOrangeJuiceByClass1[i].innerHTML = "";
      }
      setstartRecord(false);
      // stop recorder from browser
      // input.disconnect(processor)
      // processor.disconnect(context.destination)
      context.close().then(() => {
        input = null
        processor = null
        context = null
        socket.current.emit('stop')
      });
      setIsStreaming(false);
    }
  }

  // let symtomps_listData = [];
  // if (questions) {
  //   symtomps_listData = questions.map(function (ques: any, index: any) {
  //     let divid = "Listrow" + ques.id;
  //     let ansid = "Ans" + ques.id;
  //     let ansidrightaway = "Ans_rightaway" + ques.id;
  //     let Micid = "Mic" + ques.id;
  //     return (
  //       <div className="symtomps_list mb-4" id={divid} key={index}>
  //         <div className="listrow">
  //           <span className="nom_circle">{index + 1}</span>
  //           <p>{ques.question}</p>
  //           <span className="record_btn btn" onClick={() => start(ques.id)}><i className="fa fa-microphone mr-1" aria-hidden="true" />Record Your Answer</span>
  //           <span className="tryagin_btn btn" onClick={() => tryAgain(ques.id)}><i className="fa fa-microphone mr-1" aria-hidden="true" />Try Again</span>
  //           <span className="stop_btn btn" onClick={() => stopRecord(ques.id)}><i className="fa fa-microphone mr-1" aria-hidden="true" />Stop Recording</span>
  //         </div>
  //         <div className="answer_box">
  //           <div className="mic_answer" >
  //             <div className="gif_bg" id={Micid} >
  //               <span className="pulse">
  //                 <i className="fa fa-microphone" />
  //               </span>
  //             </div>
  //             <div>
  //               <h6>Your Answer</h6>
  //               <div className="" style={{ flexWrap: "wrap" }}>
  //                 <span id={ansid} className="myanswers" data-id={ques.id}></span>
  //                 <span className="pl-1" id={ansidrightaway} ></span>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     )
  //   })
  // }


  const handleTextAreaInput = (event: any) => {
    let { id, value } = event.target as any;
    setSpeechAnswerObj((prevSpeechAnswerObj: any) => {
      return {
        ...prevSpeechAnswerObj,
        [id]: value
      }
    });
  }

  function back() {
    let url = localStorage.getItem('active_url');
    window.location.href = url ? url : '/';
  }

  return (
    <section className="symtomps_section py-4">
      <Loader status={showLoader} />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h5 className='mb-2 cursor-pointer'>
              <span onClick={back} role="button" className='symptom-back'>
                <img src={require('../images/back.svg').default} alt="back-btn" />
              </span>
            </h5>
            <h3 className="mb-4">Symptoms Form</h3>
          </div>
          <div className="col-md-12">
            {/* {symtomps_listData} */}
            {
              questions.length ? questions.map(function (ques: any, index: any) {
                let divid = "Listrow" + ques.id;
                let ansid = "Ans" + ques.id;
                let ansidrightaway = "Ans_rightaway" + ques.id;
                let Micid = "Mic" + ques.id;
                let textAreaValue = speechAnswerObj[ansid] ? speechAnswerObj[ansid] : ''
                return (
                  <div className="symtomps_list mb-4" id={divid} key={index}>
                    <div className="listrow">
                      <span className="nom_circle">{index + 1}</span>
                      <p>{ques.question}</p>
                      <span className="record_btn btn" onClick={() => start(ques.id)}><i className="fa fa-microphone mr-1" aria-hidden="true" />Record Your Answer</span>
                      <span className="tryagin_btn btn" onClick={() => tryAgain(ques.id)}><i className="fa fa-microphone mr-1" aria-hidden="true" />Try Again</span>
                      <span className="stop_btn btn" onClick={() => stopRecord(ques.id)}><i className="fa fa-microphone mr-1" aria-hidden="true" />Stop Recording</span>
                    </div>
                    <div className="answer_box">
                      <div className="mic_answer" >
                        <div className="gif_bg" id={Micid} >
                          <span className="pulse">
                            <i className="fa fa-microphone" />
                          </span>
                        </div>
                        <div>
                          {/* <h6>Your Answer</h6> */}
                          <div className="" style={{ flexWrap: "wrap" }}>
                            {/* <span id={ansid} className="myanswers" data-id={ques.id}></span> */}
                            <span className="pl-1" id={ansidrightaway} ></span>
                          </div>
                        </div>
                      </div>
                      <React.Fragment>
                        <label className='padding-left' htmlFor={ansid}>Your Answer</label>
                        <textarea className='padding-left' id={ansid} name={ansid} value={textAreaValue} onChange={(event: any) => handleTextAreaInput(event)} />
                      </React.Fragment>
                    </div>
                  </div>
                )
              }) : ''
            }
            {/* <div className="symtomps_list mb-4" id="Listrow">
                    <div className="listrow">
                      <span className="nom_circle">1</span>
                      <p>Tell me about any pains you are feeling.</p>
                      <span className="record_btn btn" onClick={start}><i className="fa fa-microphone mr-1" aria-hidden="true"></i>Record Your Answer</span>
                      <span className="tryagin_btn btn"><i className="fa fa-microphone mr-1" aria-hidden="true"></i>Try Again</span>
                     
                    </div>
                    <div className="answer_box">
                      <h6>Your Answer</h6>
                      <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't
                      look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem
                      Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words,
                      combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition,
                      injected humour, or non-characteristic words etc.</p>
                  </div>
                </div>
                <div className="symtomps_list mb-4">
                      <div className="listrow">
                          <span className="nom_circle">2</span>
                          <p>Are you bleeding any where?</p>
                          <span className="record_btn btn"><i className="fa fa-microphone mr-1" aria-hidden="true"></i> Record Your Answer</span>
                      </div>
                </div>
                <div className="symtomps_list mb-4">
                      <div className="listrow">
                          <span className="nom_circle">3</span>
                          <p>Do you have any swelling any where that you notice?</p>
                          <span className="record_btn btn"><i className="fa fa-microphone mr-1" aria-hidden="true"></i> Record Your Answer</span>
                      </div>
                </div>
                <div className="symtomps_list mb-4">
                  <div className="listrow">
                      <span className="nom_circle">4</span>
                      <p>Describe any symptoms you have that you feel that may be related to having a fever if any.</p>
                      <span className="record_btn btn" ><i className="fa fa-microphone mr-1" aria-hidden="true"></i> Record Your Answer</span>
                  </div>
                </div>
                <div className="symtomps_list mb-4">
                  <div className="listrow">
                      <span className="nom_circle">5</span>
                      <p>Describe any way in which your taste, sight, sense of smell, ability to feel or ability to think has change recently if at all.</p>
                      <span className="record_btn btn" ><i className="fa fa-microphone mr-1" aria-hidden="true"></i> Record Your Answer</span>
                  </div>
                </div> */}
          </div>
          <div className="col-md-12 text-center mt-4">
            <div className="btm_button">
              {/* <button className="prevqstn_btn btn mb-2">Previous Questions</button>
              <button className="nextqstn_btn btn mb-2">Next Questions</button> */}
              <button className="sub_btn btn" onClick={handleShow} style={showbtn}>Submit</button>
            </div>
          </div>
        </div>
        {/* <textarea value={transcript} readOnly={true} ></textarea> */}
      </div>

      {/* <Modal show={show} onHide={handleClose}
         size="sm">
          <Modal.Header closeButton className="border-0 headerclose"> 
          </Modal.Header>
          <Modal.Body className="text-center py-4">
              <img src={require('../../src/images/check.svg').default} />
              <h5 className="thnkutext mt-3 mb-1">Thank You!</h5>
              <p className="mb-0">Your submission has been received.</p>
          </Modal.Body>
        </Modal>*/}
      <Modal show={showerr} onHide={handleCloseE} size="sm">
        <Modal.Header closeButton className="border-0 headerclose">
        </Modal.Header>
        <Modal.Body className="text-center py-4">
          <img src={require('../../src/images/check.svg').default} alt="check" />
          <h5 className="thnkutext mt-3 mb-1">Try Again!</h5>
          <p className="mb-0">{message}</p>
        </Modal.Body>
      </Modal>
    </section>
  )
}

export default App;