import { useState, useEffect } from "react";
import { Steps, Step } from "react-step-builder";
import Step1 from "./create_profile/Step1";
import Step2 from "./create_profile/Step2";
import Step3 from "./create_profile/Step3";
import Step4 from "./create_profile/Step4";
import Step5 from "./create_profile/Step5";
import { userService } from '../_services';
import { Loader } from './Loader';
import profieimage from "../images/user_avtar.svg";
// import { useForm, useStep } from "react-hooks-helper";
// import { config } from '../config/config';
// import moment from 'moment';
// const steps = [
//   { id: "step1" },
//   { id: "step2" },
//   { id: "step3" },
//   { id: "step4" },
// ];

const defaultData = {
  firstname: "",
  email: "",
  lastname: "",
  profileImage: "",
  profileImageshow: profieimage,
  accountNumber: "",
  routingNumber: "",
  account_holder_name: "",
  branchLocation: "",
  ssn_no: '',
  birthDate: null,
  gender: "",
  location: "",
  height: "",
  weight: "",
  bloodGroup: "",
  latitude:"",
  longitude:"",
  city:'',
  state:'',
  country:'',
  street:'',
  postal_code:'',
  // isEmailVerified:false,
  formIsValid: false,
  editProfile: true
};
const errors = {
  firstname: "",
  email: "",
  lastname: "",
  profileImage: "",
  profileImageshow: "",
  accountNumber: "",
  routingNumber: "",
};

const EditProfile = (props: any) => {
  const [showloader, setShow] = useState({ display: "flex" });

  useEffect(() => {
    let accesstoken = localStorage.getItem('accesstoken')
    // alert(accesstoken)
    userService.userdetail(accesstoken).then((response) => {
      const userInfo: any = response.data.data;
      if (userInfo) {
        // console.log(`${config.Imageurl}`+userInfo.profileImagePath)
        defaultData.firstname = userInfo.firstname;
        defaultData.email = userInfo.email;
        defaultData.lastname = userInfo.lastname;
        defaultData.height = userInfo.height;
        defaultData.weight = userInfo.weight;
        defaultData.bloodGroup = userInfo.bloodGroup;
        defaultData.profileImageshow = userInfo.profileImage;
        defaultData.gender = userInfo.gender;
        defaultData.latitude=userInfo.latitude;
        defaultData.longitude=userInfo.longitude;
        defaultData.account_holder_name=userInfo.account_holder_name ;
        defaultData.city= userInfo.city;
        defaultData.state=userInfo.state ;
        defaultData.country=userInfo.country ;
        defaultData.street=userInfo.street ;
        defaultData.postal_code=userInfo.postal_code ;
        let arr=["street","city","state","postal_code","country"];
        let fullAddress='';
        arr.forEach((v1:any)=>{
          let value=v1 ==="country"?"US":userInfo[v1]?`${userInfo[v1]} , `:'' as any;
          fullAddress=fullAddress+ value;
        })
        defaultData.branchLocation=fullAddress;

        if (userInfo.location && userInfo.location != 'null') {
          defaultData.location = userInfo.location;
        }
        if (userInfo.birthDate && userInfo.birthDate != 'Invalid date') {
          defaultData.birthDate = userInfo.birthDate;
        }
        //  defaultData.birthDate  = moment(userInfo.birthDate, 'yyyy-MM-DD').format('MMM dd, yyyy');
        defaultData.accountNumber = userInfo.accountNumber;
        defaultData.routingNumber = userInfo.routingNumber;
        defaultData.profileImage = userInfo.profileImage;
        defaultData.ssn_no=userInfo.ssn_no;    
        setShow({ display: "none" })
      }
    }).catch((error: any) => {
      if (error.response.status == 401) {
        localStorage.clear()
        window.location.href = '/'
      }
    });
  }, []);

  const Navigation = (props: any) => {
    return (
      <div>
        {props.current == 1 ? (
          <button onClick={props.next}>Next</button>

        ) : (<> <button onClick={props.prev}>Previous</button>
          <button onClick={props.next}>Next</button></>)}
      </div>
    );
  }

  const Before = (props: any) => {
    return <span></span>
  }

  const After = (props: any) => {
    return <></>
  }
  const prop = { defaultData, errors };

  const config: any = {
    before: Before, // a React component with special props provided automatically
    after: After, // a React component with special props provided automatically
    navigation: {
      component: Navigation, // a React component with special props provided automatically
      location: "after" // or after
    }
  }
  
  return (
    <>
      <Loader status={showloader.display} />
      <Steps >
        <Step component={Step1} {...prop} />
        <Step component={Step2} {...prop} />
        <Step component={Step3} {...prop} />
        <Step component={Step4} {...prop} />
        <Step component={Step5} {...prop} />
      </Steps>
    </>
  )
};

export default EditProfile;