import { Navbar, Container, Row, Col } from 'react-bootstrap';
import { userService } from '../../_services';

const Header = () => {

  // const [cartcount, setcartcount] = useState(userInfo.count);
  const logout = () => {
    let params = {
      "deviceType": "W",
      "device_token": localStorage.getItem('firebaseToken') as any,
      "unique_device_id": localStorage.getItem('udid') as any
    }
    userService.logout(params)
      .then(function (response) {
        localStorage.removeItem('accesstoken');
      }).catch(error => {
      });
  }
// 'http://localhost:3000/symptoms/9'.includes("symptoms")?"#":
  return (
    <header className="py-4 forheader1">
      <Container>
        <Row>
          <Col md={12}>
            <Navbar.Brand href={"/"}>
              <img src={require("../../images/logo.png").default} alt="logo" />
            </Navbar.Brand>
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default Header