import VideoPreviewModal from './videoPreviewModal';
import PrecautionSymptomModal from './precautionSymptomModal';
import RejectUserModal from './rejectUserModal';
import CompanyListModal from './companyListModal';


function PopUpModal(props: any) {

    function returnModalType(modalType: any) {
        switch (modalType) {
            case 'file_upload':
                return <VideoPreviewModal {...props} />;
            case 'message_media_view':
                return <VideoPreviewModal {...props} />;
            case 'precaution_modal':
                return <PrecautionSymptomModal {...props} />;
            case 'symptom_modal':
                return <PrecautionSymptomModal {...props} />;
            case 'reject_user_modal':
                return <RejectUserModal {...props} />;
            case 'company_list_modal':
                return <CompanyListModal {...props} />
            default:
                return <></>;
        }
    }

    return returnModalType(props.modalType);
}

export default PopUpModal;