import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { userService } from '../_services';
import { Loader } from './Loader';
import ProfileImage from '../images/profile.png';

const ViewUser = () => {
    const [data, setData] = useState({}) as any;
    const [show, setShow] = useState("none") as any;
    let history = useHistory();

    useEffect(() => {
        triggerLoader("flex");
        let search = window.location.search;
        let params = new URLSearchParams(search);
        var uid = params.get("uid") as any;
        uid = atob(uid);
        var found = false;
        userService.getUserListing().then(resp => {
            var temp = resp.data.data
            for (let index = 0; index < temp.length; index++) {
                const element = temp[index];
                if (element.id == uid) {
                    found = true;
                    setData(element);
                }
            }
            triggerLoader("none");
            if (!found) {
                history.push('/friends');
            }
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            triggerLoader("none");
            toast.error('Unknown error occured.');
            history.go(-1);
        })
    }, []);

    function triggerLoader(status:any){
        setShow(status);
    }

    return (
        <>
            <section className="accpt_reject_section py-5">
                <Loader status={show} />
                <Container>
                <a href="/notification"><img src={require('../images/back.svg').default} alt="back" /></a>
                    <div className="inner_box pb-4">
                        <Row className="align-items-center">
                            <Col lg={12} md={12} className="text-center m-auto">
                                <div className="frndrqst_img m-auto">
                                    {data.profileImage ? <img src={data.profileImage} alt="frndrqst" onError={(event: any) => { event.target.src = ProfileImage; }} /> : "No Profile Image"}
                                </div>
                                <div className="mt-3">
                                    <h3>{`${data.firstname} ${data.lastname}`}</h3>
                                    {data.location != null ? <h5><img src={require('../images/location.svg').default} alt="location" className="mr-2" width="14px" />{data.location}</h5> : ""}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default ViewUser;