/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import { Line } from 'react-chartjs-2'
import { userService } from '../_services';
import { Loader } from './Loader'

const Bodyvitalsgraph = () => {
  const [healthSummary, setHealthSummary] = useState() as any
  const [graphData, setgraphData] = useState('week') as any
  const [showloader, setShowloader] = useState({ display: "none" });
  
  let pressurelabels: any = [];
  let heartlabels: any = [];
  let heartdata: any = [];
  let pressured: any = [];
  let pressured1: any = [];
  let pressuredColors: any = [];
  let pressured1Colors: any = [];
  let oxylabels: any = [];
  let oxytdata: any = [];
  let oxytdataColors: any = [];
  let bodylabels: any = [];
  let bodydata: any = [];
  let bodydataColors: any = [];
  let reslabels: any = [];
  let resdata: any = [];
  let resdataColors: any = [];
  let bmlabels: any = [];
  let bmdata: any = [];
  let bmdataColors: any = [];
  let Hlabels: any = [];
  let Hdata: any = [];
  let HdataColors: any = [];
  let Wlabels: any = [];
  let Wdata: any = [];
  useEffect(() => {
    setShowloader({ display: "flex" })
    userService.body_vitals_graph().then(response => { return response.data })
      .then((result) => {
        setHealthSummary(result);
        setShowloader({ display: "none" })
      })
      .catch(error => {
        setShowloader({ display: "none" })
        if (error.response?.status == 401) {
          localStorage.clear()
          window.location.href = '/'
        }

        //  setShow({display:"none"})
      });
  }, [])
  if (healthSummary) {
    const pressureLevel_green = healthSummary['data']['Blood Pressure']['metric_ranges'][0]['green'].split("-")
    const pressureLevel_yellow_low = healthSummary['data']['Blood Pressure']['metric_ranges'][0]['yellow_low'].split("-")
    const pressureLevel_yellow_high = healthSummary['data']['Blood Pressure']['metric_ranges'][0]['yellow_high'].split("-")

    const pressure1Level_green = healthSummary['data']['Blood Pressure']['metric_ranges'][1]['green'].split("-")
    const pressure1Level_yellow_low = healthSummary['data']['Blood Pressure']['metric_ranges'][1]['yellow_low'].split("-")
    const pressure1Level_yellow_high = healthSummary['data']['Blood Pressure']['metric_ranges'][1]['yellow_high'].split("-")

    const oxygenLevel_green = healthSummary['data']['Oxygen Level']['metric_ranges'][0]['green'].split("-")
    const oxygenLevel_yellow_low = healthSummary['data']['Oxygen Level']['metric_ranges'][0]['yellow_low'].split("-")
    const oxygenLevel_yellow_high = healthSummary['data']['Oxygen Level']['metric_ranges'][0]['yellow_high'].split("-")

    const bodyTempLevel_green = healthSummary['data']['Body Temperature']['metric_ranges'][0]['green'].split("-")
    const bodyTempLevel_yellow_low = healthSummary['data']['Body Temperature']['metric_ranges'][0]['yellow_low'].split("-")
    const bodyTempLevel_yellow_high = healthSummary['data']['Body Temperature']['metric_ranges'][0]['yellow_high'].split("-")

    const RRLevel_green = healthSummary['data']['Respiration Rate']['metric_ranges'][0]['green'].split("-")
    const RRLevel_yellow_low = healthSummary['data']['Respiration Rate']['metric_ranges'][0]['yellow_low'].split("-")
    const RRLevel_yellow_high = healthSummary['data']['Respiration Rate']['metric_ranges'][0]['yellow_high'].split("-")

    const bmiLevel_green = healthSummary['data']['BMI']['metric_ranges'][0]['green'].split("-")
    const bmiLevel_yellow_low = healthSummary['data']['BMI']['metric_ranges'][0]['yellow_low'].split("-")
    const bmiLevel_yellow_high = healthSummary['data']['BMI']['metric_ranges'][0]['yellow_high'].split("-")

    const HeightLevel_green = healthSummary['data']['Height']['metric_ranges'][0]['green'].split("-")
    const HeightLevel_yellow_low = healthSummary['data']['Height']['metric_ranges'][0]['yellow_low'].split("-")
    const HeightLevel_yellow_high = healthSummary['data']['Height']['metric_ranges'][0]['yellow_high'].split("-")
    const HeightLevel_red_low = healthSummary['data']['Height']['metric_ranges'][0]['red_low'].split("-")


    if (graphData == "week") {
      if (healthSummary['data']['Blood Pressure']) {
        healthSummary['data']['Blood Pressure']['values'].map(function (gdata: any, index: any) {
          pressurelabels.push(gdata['day'])
          let datp = gdata['measured_data'].split('/')
          if (parseFloat(datp[0]) >= 0) {
            pressured.push(datp[0]);
          }
          if (parseFloat(datp[1]) >= 0) {
            pressured1.push(datp[1])
          }
          if (datp[0] >= pressureLevel_green[0] && datp[0] <= pressureLevel_green[1]) {
            pressuredColors.push("#00d700")
          } else if ((datp[0] >= pressureLevel_yellow_low[0] && datp[0] <= pressureLevel_yellow_low[1]) || (datp[0] >= pressureLevel_yellow_high[0] && datp[0] <= pressureLevel_yellow_high[1])) {
            pressuredColors.push("#c7c500")
          } else {
            pressuredColors.push("#ff0000")
          }
          if (datp[1] >= pressureLevel_green[0] && datp[1] <= pressureLevel_green[1]) {
            pressured1Colors.push("#00d700")
          } else if ((datp[1] >= pressureLevel_yellow_low[0] && datp[1] <= pressureLevel_yellow_low[1]) || (datp[1] >= pressureLevel_yellow_high[0] && datp[1] <= pressureLevel_yellow_high[1])) {
            pressured1Colors.push("#c7c500")
          } else {
            pressured1Colors.push("#ff0000")
          }
        })
      }

      if (healthSummary['data']['Heart Rate']) {
        healthSummary['data']['Heart Rate']['values'].map(function (gdata: any, index: any) {
          if (parseFloat(gdata.measured_data) >= 0) {
            heartlabels.push(gdata['day'])
            heartdata.push(gdata['measured_data'])
          }
        })
      }

      if (healthSummary['data']['Oxygen Level']) {
        healthSummary['data']['Oxygen Level']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_data']
          if (parseFloat(val) >= 0) {
            oxylabels.push(gdata['day'])
            oxytdata.push(val)
          }
          if (val >= oxygenLevel_green[0] && val <= oxygenLevel_green[1]) {
            oxytdataColors.push("#00d700")
          } else if ((val >= oxygenLevel_yellow_low[0] && val <= oxygenLevel_yellow_low[1]) || (val >= oxygenLevel_yellow_high[0] && val <= oxygenLevel_yellow_high[1])) {
            oxytdataColors.push("#c7c500")
          } else {
            oxytdataColors.push("#ff0000")
          }
        })
      }

      if (healthSummary['data']['Body Temperature']) {
        healthSummary['data']['Body Temperature']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_data']
          if (parseFloat(val) >= 0) {
            bodylabels.push(gdata['day'])
            bodydata.push(val)
          }
          if (val >= bodyTempLevel_green[0] && val <= bodyTempLevel_green[1]) {
            bodydataColors.push("#00d700")
          } else if ((val >= bodyTempLevel_yellow_low[0] && val <= bodyTempLevel_yellow_low[1]) || (val > bodyTempLevel_yellow_high[0] && val <= bodyTempLevel_yellow_high[1])) {
            bodydataColors.push("#c7c500")
          } else {
            bodydataColors.push("#ff0000")
          }
        })
      }

      if (healthSummary['data']['Respiration Rate']) {
        healthSummary['data']['Respiration Rate']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_data']
          if (parseFloat(val) >= 0) {
            reslabels.push(gdata['day'])
            resdata.push(val)
          }
          if (val >= RRLevel_green[0] && val <= RRLevel_green[1]) {
            resdataColors.push("#00d700")
          } else if ((val >= RRLevel_yellow_low[0] && val <= RRLevel_yellow_low[1]) || (val >= RRLevel_yellow_high[0] && val <= RRLevel_yellow_high[1])) {
            resdataColors.push("#c7c500")
          } else {
            resdataColors.push("#ff0000")
          }
        })
      }

      if (healthSummary['data']['BMI']) {
        healthSummary['data']['BMI']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_data']
          if (parseFloat(val) >= 0) {
            bmlabels.push(gdata['day'])
            bmdata.push(val)
          }
          if (val >= bmiLevel_green[0] && val <= bmiLevel_green[1]) {
            bmdataColors.push("#00d700")
          } else if ((val >= bmiLevel_yellow_low[0] && val <= bmiLevel_yellow_low[1]) || (val >= bmiLevel_yellow_high[0] && val <= bmiLevel_yellow_high[1])) {
            bmdataColors.push("#c7c500")
          } else {
            bmdataColors.push("#ff0000")
          }
        })
      }

      if (healthSummary['data']['Height']) {
        healthSummary['data']['Height']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_data']
          if (parseFloat(val) >= 0) {
            Hlabels.push(gdata['day'])
            Hdata.push(val)
          }
          if (val >= HeightLevel_green[0] && val <= HeightLevel_green[1]) {
            HdataColors.push("#00d700")
          } else if ((val >= HeightLevel_yellow_low[0] && val <= HeightLevel_yellow_low[1]) || (val >= HeightLevel_yellow_high[0] && val <= HeightLevel_yellow_high[1])) {
            HdataColors.push("#c7c500")
          } else if (+val > +HeightLevel_red_low[0]) {
            HdataColors.push("#ff0000")
          } else {
            HdataColors.push("#1aa0e6")
          }
        })
      }

      if (healthSummary['data']['Weight']) {
        healthSummary['data']['Weight']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_data']
          if (parseFloat(val) >= 0) {
            Wlabels.push(gdata['day'])
            Wdata.push(gdata['measured_data'])
          }
        })
      }
    } else {
      if (healthSummary['month']['Blood Pressure']) {
        healthSummary['month']['Blood Pressure']['values'].map(function (gdata: any, index: any) {
          pressurelabels.push("week" + gdata['week'])
          let datp = gdata['measured_week_data'].split('/')
          if (parseFloat(datp[0]) >= 0) {
            pressured.push(datp[0])
          }
          if (parseFloat(datp[1]) >= 0) {
            pressured1.push(datp[1])
          }
          if (datp[0] >= pressureLevel_green[0] && datp[0] <= pressureLevel_green[1]) {
            pressuredColors.push("#00d700")
          } else if ((datp[0] >= pressureLevel_yellow_low[0] && datp[0] <= pressureLevel_yellow_low[1]) || (datp[0] >= pressureLevel_yellow_high[0] && datp[0] <= pressureLevel_yellow_high[1])) {
            pressuredColors.push("#c7c500")
          } else {
            pressuredColors.push("#ff0000")
          }
          if (datp[1] >= pressure1Level_green[0] && datp[1] <= pressure1Level_green[1]) {
            pressured1Colors.push("#00d700")
          } else if ((datp[1] >= pressure1Level_yellow_low[0] && datp[1] <= pressure1Level_yellow_low[1]) || (datp[1] >= pressure1Level_yellow_high[0] && datp[1] <= pressure1Level_yellow_high[1])) {
            pressured1Colors.push("#c7c500")
          } else {
            pressured1Colors.push("#ff0000")
          }
        })
      }

      if (healthSummary['month']['Heart Rate']) {
        healthSummary['month']['Heart Rate']['values'].map(function (gdata: any, index: any) {
          if (parseFloat(gdata.measured_data) >= 0) {
            heartlabels.push("week" + gdata['week'])
            heartdata.push(gdata['measured_week_data'])
          }
        })
      }

      if (healthSummary['month']['Oxygen Level']) {
        healthSummary['month']['Oxygen Level']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_week_data']
          if (parseFloat(val) >= 0) {
            oxylabels.push("week" + gdata['week'])
            oxytdata.push(val)
          }
          if (val >= oxygenLevel_green[0] && val <= oxygenLevel_green[1]) {
            oxytdataColors.push("#00d700")
          } else if ((val >= oxygenLevel_yellow_low[0] && val <= oxygenLevel_yellow_low[1]) || (val >= oxygenLevel_yellow_high[0] && val <= oxygenLevel_yellow_high[1])) {
            oxytdataColors.push("#c7c500")
          } else {
            oxytdataColors.push("#ff0000")
          }
        })
      }

      if (healthSummary['month']['Body Temperature']) {
        healthSummary['month']['Body Temperature']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_week_data']
          if (parseFloat(val) >= 0) {
            bodylabels.push("week" + gdata['week'])
            bodydata.push(val)
          }
          if (val >= bodyTempLevel_green[0] && val <= bodyTempLevel_green[1]) {
            bodydataColors.push("#00d700")
          } else if ((val >= bodyTempLevel_yellow_low[0] && val <= bodyTempLevel_yellow_low[1]) || (val > bodyTempLevel_yellow_high[0] && val <= bodyTempLevel_yellow_high[1])) {
            bodydataColors.push("#c7c500")
          } else {
            bodydataColors.push("#ff0000")
          }
        })
      }

      if (healthSummary['month']['Respiration Rate']) {
        healthSummary['month']['Respiration Rate']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_week_data']
          if (parseFloat(val) >= 0) {
            reslabels.push("week" + gdata['week'])
            resdata.push(val)
          }
          if (val >= RRLevel_green[0] && val <= RRLevel_green[1]) {
            resdataColors.push("#00d700")
          } else if ((val >= RRLevel_yellow_low[0] && val <= RRLevel_yellow_low[1]) || (val >= RRLevel_yellow_high[0] && val <= RRLevel_yellow_high[1])) {
            resdataColors.push("#c7c500")
          } else {
            resdataColors.push("#ff0000")
          }
        })
      }

      if (healthSummary['month']['BMI']) {
        healthSummary['month']['BMI']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_week_data']
          if (parseFloat(val) >= 0) {
            bmlabels.push("week" + gdata['week'])
            bmdata.push(val)
          }
          if (val >= bmiLevel_green[0] && val <= bmiLevel_green[1]) {
            bmdataColors.push("#00d700")
          } else if ((val >= bmiLevel_yellow_low[0] && val <= bmiLevel_yellow_low[1]) || (val >= bmiLevel_yellow_high[0] && val <= bmiLevel_yellow_high[1])) {
            bmdataColors.push("#c7c500")
          } else {
            bmdataColors.push("#ff0000")
          }
        })
      }

      if (healthSummary['month']['Height']) {
        healthSummary['month']['Height']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_week_data']
          if (parseFloat(val) >= 0) {
            Hlabels.push("week" + gdata['week'])
            Hdata.push(val)
          }
          if (val >= HeightLevel_green[0] && val <= HeightLevel_green[1]) {
            HdataColors.push("#00d700")
          } else if ((val >= HeightLevel_yellow_low[0] && val <= HeightLevel_yellow_low[1]) || (val >= HeightLevel_yellow_high[0] && val <= HeightLevel_yellow_high[1])) {
            HdataColors.push("#c7c500")
          } else if (+val > +HeightLevel_red_low[0]) {
            HdataColors.push("#ff0000")
          } else {
            HdataColors.push("#1aa0e6")
          }
        })
      }

      if (healthSummary['month']['Weight']) {
        healthSummary['month']['Weight']['values'].map(function (gdata: any, index: any) {
          const val = gdata['measured_week_data']
          Wlabels.push("week" + gdata['week'])
          if (parseFloat(val) >= 0) {
            Wdata.push(val)
          }
        })
      }
    }
  }

  console.log('pressured', pressured);
  console.log('pressured1', pressured1);
  const data = {
    labels: pressurelabels,
    datasets: [
      {
        label: 'Systolic',
        backgroundColor: '#ffe2a4',
        borderColor: '#ffe2a4',
        borderWidth: 1,
        pointRadius: 7,
        pointHoverRadius: 10,
        data: pressured,
        pointBackgroundColor: pressuredColors
      },
      {
        label: 'Diastolic',
        backgroundColor: '#1AA0E6',
        borderColor: '#1AA0E6',
        borderWidth: 1,
        pointRadius: 7,
        pointHoverRadius: 10,
        data: pressured1,
        pointBackgroundColor: pressured1Colors
      }
    ]
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };
  console.log('heartdata...', heartdata);
  const dataheart = {
    labels: heartlabels,
    datasets: [
      {
        label: 'Heart rate',
        data: heartdata,
        backgroundColor: '#1AA0E6',
        borderColor: '#1AA0E6',
        borderWidth: 1,
        pointRadius: 7,
        pointHoverRadius: 10,
      }
    ],
  };
  const dataoxy = {
    labels: oxylabels,
    datasets: [
      {
        label: 'Oxygen level',
        data: oxytdata,
        backgroundColor: '#1AA0E6',
        borderColor: '#1AA0E6',
        borderWidth: 1,
        pointRadius: 7,
        pointHoverRadius: 10,
        pointBackgroundColor: oxytdataColors
      }
    ],
  };
  const databody = {
    labels: bodylabels,
    datasets: [
      {
        label: 'Body Temprature',
        data: bodydata,
        backgroundColor: '#1AA0E6',
        borderColor: '#1AA0E6',
        borderWidth: 1,
        pointRadius: 7,
        pointHoverRadius: 10,
        pointBackgroundColor: bodydataColors
      }
    ],
  };
  const datares = {
    labels: reslabels,
    datasets: [
      {
        label: 'Respiration Rate',
        data: resdata,
        backgroundColor: '#1AA0E6',
        borderColor: '#1AA0E6',
        borderWidth: 1,
        pointRadius: 7,
        pointHoverRadius: 10,
        pointBackgroundColor: resdataColors
      }
    ],
  };
  const databm = {
    labels: bmlabels,
    datasets: [
      {
        label: 'BMI',
        data: bmdata,
        backgroundColor: '#1AA0E6',
        borderColor: '#1AA0E6',
        borderWidth: 1,
        pointRadius: 7,
        pointHoverRadius: 10,
        pointBackgroundColor: bmdataColors
      }
    ],
  };
  const dataH = {
    labels: Hlabels,
    datasets: [
      {
        label: 'Height',
        data: Hdata,
        backgroundColor: '#1AA0E6',
        borderColor: '#1AA0E6',
        borderWidth: 1,
        pointRadius: 7,
        pointHoverRadius: 10,
        pointBackgroundColor: HdataColors
      }
    ],
  };
  const dataW = {
    labels: Wlabels,
    datasets: [
      {
        label: 'Weight',
        data: Wdata,
        backgroundColor: '#1AA0E6',
        borderColor: '#1AA0E6',
        borderWidth: 1,
        pointRadius: 7,
        pointHoverRadius: 10,
      }
    ],
  };
  return (
    <>
      {console.log(HdataColors)}
      <section className="bodyvital_section py-5">
        <Loader status={showloader.display} />
        <Container>
          <div className="inner_box">
            <Row className="bodyvitals_head">
              <Col md={6}>
                <h5>
                  <a href="/">
                    <img src={require('../images/back.svg').default} alt="back" />
                  </a>
                </h5>
                <h2>Body Vitals Graphs</h2>
              </Col>
              <Col md={6} className="text-right">
                <h6>Date Measured</h6>
                <select value={graphData} onChange={(e: any) => setgraphData(e.target.value)}>
                  <option value="week">Weekly</option>
                  <option value="month">Monthly</option>
                </select>
                {/* <DatePicker
                  onChange={onChange}
                  value={value}
                /> */}
              </Col>
              <Col md={12}>
                <hr />
              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Heart Rate</h3>
                  <div className='links'>
                    <a className='btn btn-gh' href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js' />
                  </div>
                </div>
                {heartdata.length ? <Line options={options} data={dataheart} /> : <NoRecordFound />}
                {heartdata.length ? healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ?
                      <>
                        {
                          healthSummary['data']['Heart Rate']
                            ?
                            <>
                              <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Heart Rate']['metric_ranges'][0]['green']}</p>
                              <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Heart Rate']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Heart Rate']['metric_ranges'][0]['yellow_high']}(Max)</p>
                              <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Heart Rate']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Heart Rate']['metric_ranges'][0]['red_high']}(Max)</p>
                            </>
                            :
                            <>
                              <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span> x</p>
                              <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  x(Min) x(Max)</p>
                              <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> x(Min) x(Max)</p>
                            </>
                        }
                      </>
                      :
                      <>
                        {
                          healthSummary['data']['Heart Rate'] && healthSummary['month']['Heart Rate']
                            ?
                            <>
                              <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Heart Rate']['metric_ranges'][0]['green']}</p>
                              <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Heart Rate']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Heart Rate']['metric_ranges'][0]['yellow_high']}(Max)</p>
                              <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Heart Rate']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Heart Rate']['metric_ranges'][0]['red_high']}(Max)</p>
                            </>
                            :
                            <>
                              <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  x</p>
                              <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  x(Min) x(Max)</p>
                              <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> x(Min) x(Max)</p>
                            </>
                        }
                      </>
                    }
                  </div>) : "" : (<></>)}

              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Blood Pressure</h3>
                  <div className='links'>
                    <a className='btn btn-gh' href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js' />
                  </div>
                </div>
                <Line data={data} />
                {healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Blood Pressure']['metric_ranges'][0]['green']} (Systolic), {healthSummary['data']['Blood Pressure']['metric_ranges'][1]['green']} (Diastolic)</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Blood Pressure']['metric_ranges'][0]['yellow_low']}(Min) (Systolic) {healthSummary['data']['Blood Pressure']['metric_ranges'][1]['yellow_low']}(Min) (Diastolic), {healthSummary['data']['Blood Pressure']['metric_ranges'][0]['yellow_high']}(Max) (Systolic) {healthSummary['data']['Blood Pressure']['metric_ranges'][1]['yellow_high']}(Max) (Diastolic)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Blood Pressure']['metric_ranges'][0]['red_low']}(Min) (Systolic) {healthSummary['data']['Blood Pressure']['metric_ranges'][1]['red_low']}(Min) (Diastolic), {healthSummary['data']['Blood Pressure']['metric_ranges'][0]['red_high']}(Max) (Systolic) {healthSummary['data']['Blood Pressure']['metric_ranges'][1]['red_high']}(Max) (Diastolic)</p>
                      </>) : (<>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Blood Pressure']['metric_ranges'][0]['green']} (Systolic), {healthSummary['month']['Blood Pressure']['metric_ranges'][1]['green']} (Diastolic)</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Blood Pressure']['metric_ranges'][0]['yellow_low']}(Min) (Systolic) {healthSummary['month']['Blood Pressure']['metric_ranges'][1]['yellow_low']}(Min) (Diastolic), {healthSummary['month']['Blood Pressure']['metric_ranges'][0]['yellow_high']}(Max) (Systolic) {healthSummary['month']['Blood Pressure']['metric_ranges'][1]['yellow_high']}(Max) (Diastolic)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Blood Pressure']['metric_ranges'][0]['red_low']}(Min) (Systolic) {healthSummary['month']['Blood Pressure']['metric_ranges'][1]['red_low']}(Min) (Diastolic), {healthSummary['month']['Blood Pressure']['metric_ranges'][0]['red_high']}(Max) (Systolic) {healthSummary['month']['Blood Pressure']['metric_ranges'][1]['red_high']}(Max) (Diastolic)</p>
                      </>)}
                  </div>) : (<></>)}
              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Oxygen Level</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                {oxytdata.length ? <Line options={options} data={dataoxy} /> : <NoRecordFound />}
                {oxytdata.length ? healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Oxygen Level']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Oxygen Level']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Oxygen Level']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Oxygen Level']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Oxygen Level']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (<>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Oxygen Level']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Oxygen Level']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Oxygen Level']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Oxygen Level']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Oxygen Level']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>)}
                  </div>) : "" : ""}
              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Body Temperature</h3>
                  <div className='links'>
                    <a className='btn btn-gh' href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js' />
                  </div>
                </div>
                {bodydata.length ? <Line options={options} data={databody} /> : <NoRecordFound />}
                {bodydata.length ? healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Body Temperature']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Body Temperature']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Body Temperature']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Body Temperature']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Body Temperature']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (<>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Body Temperature']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Body Temperature']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Body Temperature']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Body Temperature']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Body Temperature']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>)}
                  </div>) : '' : ''}
              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Respiration Rate</h3>
                  <div className='links'>
                    <a className='btn btn-gh' href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js' />
                  </div>
                </div>
                {resdata.length ? <Line options={options} data={datares} /> : <NoRecordFound />}
                {resdata.length ? healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Respiration Rate']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Respiration Rate']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Respiration Rate']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Respiration Rate']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Respiration Rate']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (<>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Respiration Rate']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Respiration Rate']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Respiration Rate']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Respiration Rate']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Respiration Rate']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>)}
                  </div>) : '' : ''}

              </Col>

              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>BMI</h3>
                  <div className='links'>
                    <a className='btn btn-gh' href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js' />
                  </div>
                </div>
                {bmdata.length ? <Line options={options} data={databm} /> : <NoRecordFound />}
                {bmdata.length ? healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['BMI']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['BMI']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['BMI']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['BMI']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['BMI']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (<>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['BMI']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['BMI']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['BMI']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['BMI']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['BMI']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>)}
                  </div>) : '' : ''}

              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Height</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                {Hdata.length ? <Line options={options} data={dataH} /> : <NoRecordFound />}
                {Hdata.length ? healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Height']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Height']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Height']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Height']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Height']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (<>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Height']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Height']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Height']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Height']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Height']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>)}
                  </div>) : '' : ''}

              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Weight</h3>
                  <div className='links'>
                    <a className='btn btn-gh' href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js' />
                  </div>
                </div>
                {
                  Wdata.length ? <Line options={options} data={dataW} /> : <NoRecordFound />
                }
                {Wdata.length ? healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Weight']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Weight']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Weight']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Weight']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Weight']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Weight']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Weight']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Weight']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Weight']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Weight']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>
                    )}
                  </div>) : '' : (<></>)}
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  )
}

function NoRecordFound() {
  return (
    <div className="row mt-2 mb-2">
      <div className="col-md-12 text-center">No Record Found</div>
    </div>
  )
}

export default Bodyvitalsgraph;