import { Container, Row, Col, ListGroup } from 'react-bootstrap'
import { Link } from "react-router-dom";

const Footer = () => {

  // const [cartcount, setcartcount] = useState(userInfo.count);

  return (
    <footer className="py-4" id="footer">
      <Container>
        <Row className="align-items-center">
          <Col md={8}>
            <ListGroup horizontal className="firstfooter_list">
              <ListGroup.Item className="bg-transparent border-0 disabled text-light">Copyright {new Date().getFullYear()}, All Rights Reserved. </ListGroup.Item>
              <ListGroup.Item className="bg-transparent border-0">
                <a target="_blank" href="https://www.xcellentlife.com/privacy-policy" className="text-white" rel="noreferrer noopener">
                  Terms & Conditions
                </a>
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent border-0">
                <a target="_blank" href="https://www.xcellentlife.com/privacy-policy" className="text-white" rel="noreferrer noopener">
                  Privacy Policy
                </a>
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent border-0">
                <Link to="" className="text-white">
                  FAQs
                </Link>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={4}>
            <div className="footer_social_links">
              <ListGroup horizontal className="justify-content-end align-items-center">
                <ListGroup.Item className="bg-transparent border-0 disabled text-light firstlistgroup">Follow us on:</ListGroup.Item>
                <ListGroup.Item className="bg-transparent border-0">
                  <a target="_blank" href={process.env.REACT_APP_FACEBOOK_URL} className="text-white" rel="noreferrer noopener">
                    <img src={require('../../images/facebook.svg').default} alt="facebook" />
                  </a>
                </ListGroup.Item>
                <ListGroup.Item className="bg-transparent border-0">
                  <a href={process.env.REACT_APP_TWITTER_URL} target="_blank" className="text-white" rel="noreferrer noopener">
                    <img src={require('../../images/twitter.svg').default} alt="twitter" />
                  </a>
                </ListGroup.Item>
                <ListGroup.Item className="bg-transparent border-0">
                  <a href={process.env.REACT_APP_LINKED_IN_URL} target="_blank" className="text-white" rel="noreferrer noopener">
                    <img src={require('../../images/linkedin.svg').default} alt="linkedin" />
                  </a>
                </ListGroup.Item>
                <ListGroup.Item className="bg-transparent border-0">
                  <a href={process.env.REACT_APP_YOUTUBE_URL} target="_blank" className="text-white" rel="noreferrer noopener">
                    <img src={require('../../images/youtube.svg').default} alt="youtube" />
                  </a>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer