import { useEffect, useState } from 'react'
import { userService } from '../_services';
import { Modal } from "react-bootstrap";
import { Loader } from './Loader';
// import { Modal, Button } from 'react-bootstrap';
// import './App.css'
// import io from 'socket.io-client'
// import { config } from '../config/config';
// import logo from './logo.svg'

let ques: any;

function WebForm(props: any) {
  let user_id = props.match.params.id;

  const [questions, setQuestions] = useState() as any;
  const [showloader, setShow] = useState({ display: "flex" });
  const [showbtn, setShowBtn] = useState({ display: "none" });
  const [showerr, setShowErr] = useState(false);
  const [quesid, setQuesid] = useState() as any;
  const [answerId, setAnswerID] = useState([]) as any;
  const [message, setMessage] = useState('') as any;

  const handleClose = () => {
    setShowErr(false);
  }

  const handleShow = () => {
    setShow({ display: "flex" });
    setAnswerID([])
    var targetDiv = document.querySelectorAll('.allquestions');

    //  console.log('radios'+idq)
    //  console.log(document.querySelectorAll('input[name=radios'+idq+']').length)
    var allInputs: any = document.getElementsByTagName("input");

    for (var xx = 0; xx < allInputs.length; xx++) {
      if (allInputs[xx].type == 'radio') {
        if (allInputs[xx].checked) {
          var valueToPush: any = {}; // or "var valueToPush = new Object();" which is the same
          valueToPush["ans"] = allInputs[xx].value;
          valueToPush["id"] = allInputs[xx].getAttribute('data-id');
          answerId.push(valueToPush)
        }
      }
    }

    var textarea: any = document.getElementsByTagName("textarea");

    for (var xx = 0; xx < textarea.length; xx++) {
      if (textarea[xx].value != "") {
        var valueToPush: any = {};
        valueToPush["ans"] = textarea[xx].value;
        valueToPush["id"] = textarea[xx].getAttribute('data-id');
        answerId.push(valueToPush)
      }
    }

    let param = {
      'web_query': answerId,
      'user_id': user_id
    };
    if (answerId.length > 0) {
      userService.PostWebAnswer(param).then(function (response) {
        window.location.href = "/thankyou"
      }).catch(error => {
        if (error.response.status == 401) {
          localStorage.clear()
          window.location.href = '/'
        }
        if (error.response.status == 422) {
          setShowErr(true)
          setMessage(error.response.message)
          setMessage(error.response.data.message)
        }
      });
    } else {
      setMessage("Please select/type you answers.")
      setShow({ display: "none" });
    }
  }


  useEffect(() => {
    // setupSocket()
    getQuestions();
    //  Setrandomestr(randomString(10, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'))
  }, []);


  const getQuestions = () => {
    userService.getWebQuestions().then(function (response) {
      setShowBtn({ display: "inline-block" });
      setShow({ display: "none" });
      // console.log(response.data.data)
      setQuestions(response.data.data)
    }).catch(error => {
      if (error.response.status == 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      // console.log(error.response.status)
      setShow({ display: "none" });
      if (error.response) {
        console.log(error.response.status)
        // setLoginErr(error.response.data.message)
      } else {
        // setLoginErr("Please Check your server")
      }
    });
  }

  const start = (id: any) => {
    ques = id;
    setQuesid(id);
    document.getElementById("Listrow" + id)?.classList.add("stop");
  }

  const checkboxdata = (data: any, id: any) => {
    let quesval = [];
    quesval = data.map(function (ques1: any, index: any) {
      return (
        <div className="form-check" key={`${ques1.id}_${index}`}>
          <label>
            <span className="mr-2 text-secondary">{index + 1}</span>
            <input
              type="radio"
              value={ques1.id}
              id={"male_" + ques1.id}
              data-id={ques1.question_id}
              name={"radios" + id} />
            {ques1.values}
          </label>
        </div>)
    })
    return quesval
  }

  let web_listData = [];
  if (questions) {
    web_listData = questions.map(function (ques: any, index: any) {
      let divid = "Listrow" + ques.id
      //   let ansid = "Ans"+ques.id
      //   let ansidrightaway = "Ans_rightaway"+ques.id
      let checkbox: any = "";
      // console.log(ques)
      if (ques.question_type != "input") {
        checkbox = checkboxdata(ques.values, ques.id)
        //  return ( <> </>)
      }
      return (
        <div className="symtomps_list mb-4" id={divid} key={`${ques.id}_${index}`}>
          <div className="listrow">
            <span className="nom_circle">{index + 1}</span>
            <p className="allquestions" data-id={ques.id}>{ques.question}</p>
            <span className="record_btn btn" onClick={() => start(ques.id)}>Your Answer</span>
            {/* <span className="stop_btn btn" onClick={()=>stopRecord(ques.id)}>Close</span> */}
          </div>
          <div className="answer_box">
            <div className="mic_answer" >
              {/* <div className="gif_bg" id={Micid} >
                    <span className="pulse">
                      <i className="fa fa-microphone"></i>
                    </span>
                    </div> */}
              <div>
                {ques.question_type != "input" ? (
                  <h6 className="select_answer">Select Your Answer</h6>) : (<h6 className="select_answer">Type Your Answer</h6>)}
              </div>
            </div>
            {ques.question_type != "input" ? (
              <div className="check1_box myanswers">
                {checkbox}
              </div>) : (<div className="type_box myanswers">
                {/* <input type="text"  className="input100" placeholder="Type here"  data-id={ques.id}  name={"radios"+ques.id} /> */}
                <textarea className="input100" placeholder="Type here" data-id={ques.id} name={"radios" + ques.id} ></textarea>
              </div>)}
          </div>
        </div>
      )
    })
  }

  return (
    <section className="symtomps_section py-4">
      <Loader status={showloader.display} />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="mb-4">Web Form</h3>
            <p className="errors">{message}</p>
          </div>
          <div className="col-md-12">
            {web_listData}
          </div>
          <div className="col-md-12 text-center mt-4">
            <div className="btm_button">
              <button className="sub_btn btn" onClick={handleShow} style={showbtn}>Submit</button>
            </div>
          </div>
        </div>
        {/* <textarea value={transcript} readOnly={true} ></textarea> */}
      </div>

      <Modal show={showerr} onHide={handleClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton className="border-0 p-1">
          <Modal.Title />
        </Modal.Header>
        <Modal.Body className="text-center pt-0">
          <img src={require('../images/check1.svg').default} alt="check1" width="85px" />
          <h5 className="mt-2 mb-3">{message}.</h5>
        </Modal.Body>
      </Modal>
    </section>
  )
}

export default WebForm;