import React, { useState,useEffect } from 'react'
import { Form, Container, Button, Row, Col, Modal } from 'react-bootstrap'
import DatePicker from 'react-date-picker';
import { userService } from '../_services';
import moment from 'moment';
import { useHistory } from 'react-router';
import { Loader } from './Loader';

const Bloodwork = () => {
    let history = useHistory();
    const [showLoader, setShowLoader] = useState("none");
    const [value, setValue] = useState(new Date());
    const [fieldVals, setFieldVals] = useState(new Map)
    const [metricData, setMetricData] = useState([]);
    const [show, setShow] = useState(false);
    const [params, setParams] = useState({});
    const [showErr, setShowErr] = useState(false)
    useEffect(() => {
        setShowLoader("flex");
        localStorage.setItem("record", "yes");
        userService.metric_list(moment().format('YYYY-MM-DD')).then(response => {
            setMetricData(response.data.data.blood_work);
            const map = new Map() as any;
            let object={} as any;
            response.data.data.blood_work.forEach((value: any) => {
                let keys= value.metrics.toLowerCase().replace(" ", "_");
                object[keys]=value.id + "_" + value.value ;
                map.set(value.id, value.value === "0"?"": value.value);
            });
            setParams(object);
            setFieldVals(map);
            setShowLoader("none");
        }).catch(error => {
                if(error.response.status == 401){
                    localStorage.clear();
                    window.location.href = '/';
                }
                setShowLoader("none");
            });
    }, [])

    function onlyNumberKey(evt: any) {
        alert("sadsajdnjsa")
        if (isNaN(parseFloat(evt))) {
            console.log(evt)
        }
        // Only ASCII character in that range allowed
        var ASCIICode = (evt.which) ? evt.which : evt.keyCode
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
            return false;
        return true;
    }

    const handleClose = () => {
        setShow(false);
        history.push("/")
    }

    function handleChange(e: any, id: any, unit: any, metrics: any) {
        let val = e.target.value
        var regex = new RegExp("^[0-9]{0,6}$");

        if (regex.test(val) == false) {
            e.preventDefault()
            return false
        }

        let f_val = new Map(fieldVals)
        f_val.set(id, val)
        setFieldVals(f_val)
        var key = metrics.toLowerCase().replace(" ", "_")
        var key_value = id + "_" + val + " " + unit
        let value = { ...params, [key]: key_value }
        setParams(value)
    }

    const submit = () => {
        var keys = Object.keys(params)
        if (keys.length == 0) {
            setShowErr(true)
            return false
        }
        setShowLoader("flex");
        let date = moment(value, 'DD-MM-YYYY').format('yyyy-MM-DD');
        let data: any = {
            ...params,
            date_measured: date,
            user_id: localStorage.getItem('user_id')
        }
        let viaIsDetail=localStorage.getItem('viaIsDetail');
        if(viaIsDetail === "true"){
            data.competition_id=localStorage.getItem('comp_id');
        }   
        userService.health_matrics(data).then(response => { return response.data }).then((result) => {
                setShowLoader("none");
                // toast.success('Record added successfully.');
                localStorage.removeItem("record");
                let viaInviteGroup=localStorage.getItem("viaInviteGroup");
                if(viaInviteGroup === "true"){
                    let inviteGroupId=localStorage.getItem("inviteGroupId")
                    if(inviteGroupId){
                        localStorage.setItem("viaInviteGroup","false");
                        history.push(`/invite_group?id=${inviteGroupId}`)
                    }else{
                        history.push('/');
                    }
                }else{
                    history.push('/');
                }
            }).catch(error => {
                if(error.response.status == 401){
                    localStorage.clear()
                    window.location.href = '/'
                }
                setShowLoader("none");
            });
    }

    return (
        <>
            <section className="bodyvital_section py-5">
            <Loader status={showLoader} />
                <Container>
                    <div className="inner_box">
                        <Row className="bodyvitals_head">
                            <Col md={6}>
                                <h5>
                                    <a href="/">
                                        <img src={require('../images/back.svg').default} alt="back" />
                                    </a>
                                </h5>
                                <h2>Blood Work</h2>
                            </Col>
                            <Col md={6} className="text-right">
                                <h6>Date Measured</h6>
                                <div className="clndr_icon_1">
                                    <DatePicker
                                        onChange={setValue}
                                        value={value}
                                        maxDate={new Date}
                                        format="MM-dd-y"
                                    />
                                    <span>
                                        <img src={require('../images/clndr1.svg').default} alt="clndr1" width="17px" />
                                    </span>
                                </div>
                            </Col>
                            <Col md={12}>
                                <hr />
                            </Col>
                        </Row>

                        <Row className="metrics_row">
                            <Col md={12}>
                                <h3>Health Metrics</h3>
                                <p>Record measurements for as many metrics as you can</p>
                            </Col>

                            {metricData ?
                                metricData.map((data: any, i: any) => {
                                    console.log('data.ranges',data.ranges);
                                    return (
                                        <React.Fragment key={`${data.id}_${i}`}>
                                            <Col lg={6} md={7}>
                                                <Form autoComplete="off" onSubmit={(event:any)=>{event.preventDefault()}}>
                                                    <Row className="metric_form">
                                                        <Col md={12}>
                                                            <Form.Group className="mb-3" controlId="metrics">
                                                                <Form.Control value={fieldVals && fieldVals.get(data.id) ? fieldVals.get(data.id) : ""} type="text" placeholder={data.metrics} onChange={(e: any) => handleChange(e, data.id, data.ranges[0].unit, data.metrics)} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                            <Col lg={4} md={5} className="rightside_select">
                                                <Form autoComplete="off" onSubmit={(event:any)=>{event.preventDefault()}}>
                                                    <div className="mb-3">
                                                        <Form.Group controlId={"ControlSelect1" + i}>
                                                            <Form.Control as="select">
                                                                <option value={data.ranges[0].unit}>{data.ranges[0].unit}</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <div className="info-div-one">
                                                            <img src={require('../images/info.svg').default} alt="info" />
                                                            <div className="systolic-box">
                                                                <div className="d-flex good-one">
                                                                    <p>Good:</p>
                                                                    <p>{data.ranges[0].green}</p>
                                                                </div>
                                                                <div className="d-flex caution-one">
                                                                    <p>Caution:</p>
                                                                    <p>&lt;{data.ranges[0].yellow_low}(min)<br />&gt;{data.ranges[0].yellow_high}(max)</p>
                                                                </div>
                                                                <div className="d-flex alert-one alert-last">
                                                                    <p>Alert Level:</p>
                                                                    <p>{data.ranges[0].red_low}(min)<br></br>{data.ranges[0].red_high}(max)</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </Col>
                                        </React.Fragment>
                                    )
                                }): ""
                            }
                            <Col md={12}>
                                <Button variant="unset" type="button" className="btnsumit" onClick={submit}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <Modal show={show} onHide={handleClose} size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton className="border-0 p-1">
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center pt-0">
                    <img src={require('../images/check1.svg').default} alt="check" width="85px" />
                    <h5 className="mt-2 mb-3">Record added successfully.</h5>
                </Modal.Body>
            </Modal>

            <Modal show={showErr} onHide={() => { setShowErr(false) }} size="sm">
                <Modal.Header closeButton className="border-0 headerclose">
                </Modal.Header>
                <Modal.Body className="text-center py-4">
                    <img src={require('../../src/images/check.svg').default} alt="check"/>
                    <h5 className="thnkutext mt-3 mb-1">Try Again!</h5>
                    <p className="mb-0">Please fill atleast one field</p>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Bloodwork;


