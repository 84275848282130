import { useState,useEffect } from "react";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from 'react-router-dom';
import { userService } from '../_services';
import { Loader } from './Loader';

const ForgotPassword = () => {
    const [showLoader, setShowLoader] = useState({ display: "none" });
    const [ansErr, setAnsErr] = useState("") as any;
    const [forgotPasswordObj, setForgotPasswordObj] = useState({
        email: '',
        securityAnswer: '',
        securityQuestion: ''
    });

    const forgotPasswordSchema = Yup.object().shape({
        email: Yup.string().email().required("Email is required").max(50, 'Maximum 50 characters allowed').trim(),
        securityAnswer: Yup.string().required("Security answer is required"),
        securityQuestion: Yup.string().required("Security question is required")
    });

    useEffect(() => {
		const accesstoken = localStorage.getItem('accesstoken');
		localStorage.removeItem('record');
		localStorage.removeItem('prescription');
		if (accesstoken) {
			window.location.href="/";
		}
	}, []);

    const updateOptions = debounce((query: any) => {
        userService.getSecQuestions(query).then(function (response: any) {
            toggleLoader("none");
            handleFormInput('securityQuestion', response.data.data);
            setAnsErr("");
        }).catch(function (error: any) {
            if(error){
                setAnsErr(error.response.data.message);
            }
            handleFormInput('securityQuestion', '');
        });
    })
    // debouncing is implemented to avoid unneccessary API call
    function debounce(cb: any, delay = 500) {
        let timeout: any;
        return (...args: any) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay);
        }
    }

    // calling submit form API
    const submitForm = (values: any) => {
        let params = {
            ...values
        };
        userService.confirm_security_answer(params).then(function (response: any) {
            localStorage.setItem('email', values.email);
            window.location.href = "/new_password"
        }).catch(function (error: any) {
            toggleLoader("none");
            // setAnsERR(error.response.data.data.message)
            setAnsErr(error.response.data.message)
        });
    }

    // Common function to handle form input
    const handleFormInput = (name: any, value: any) => {
        setForgotPasswordObj((prevForgotPasswordObj: any) => {
            return {
                ...prevForgotPasswordObj,
                [name]: value
            }
        });
    }

    // Common function to trigger loader
    const toggleLoader = (status: any) => {
        setShowLoader((prevShowLoader: any) => {
            return {
                ...prevShowLoader,
                display: status
            }
        });
    }

    return (
        <section className="loginsec">
            <Loader status={showLoader.display} />
            <div className="right_side py-5">
                <div className="container">
                    <div className="row">
                        <aside className="col-xl-5 col-lg-7 col-sm-12 m-auto">
                            <div />
                            <div className="login_form mt-4">
                                <div className="text-center">
                                    <h4>Forgot Password?</h4>
                                    <p>Don’t worry! Just fill in your email and security question below.</p>
                                    <img src={require('../images/line.svg').default} alt="line" />
                                </div>
                                <Formik
                                    initialValues={forgotPasswordObj}
                                    validationSchema={forgotPasswordSchema}
                                    enableReinitialize={true}
                                    onSubmit={(values) => {
                                        toggleLoader("flex");
                                        submitForm(values);
                                    }}>
                                    {({ errors, touched, values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                        <Form className="mt-4" autoComplete="off" onSubmit={handleSubmit}>
                                            <Form.Group className="mb-3" controlId="email">
                                                <Form.Control type="email" placeholder="Email*" className="input103" value={values.email} onChange={(e: any) => {
                                                    // handleInput(e);
                                                    updateOptions(e.target.value);
                                                    setFieldValue('email', e.target.value, true);
                                                    handleFormInput('email', e.target.value);
                                                    setAnsErr('');
                                                }} />
                                                {errors.email && touched.email ? (<label id="uname-error" className="error" htmlFor="email"> {errors.email}</label>) : null}
                                            </Form.Group>
                                            {
                                                values.securityQuestion ? <>
                                                    <h5 className="mt-4">Security Question</h5>
                                                    <p className="text-left mb-3">{values.securityQuestion}</p>
                                                    <Form.Group className="mb-3" controlId="securityAnswer">
                                                        <Form.Control type="text" placeholder="Answer*" className="input103" value={values.securityAnswer} onChange={(e: any) => {
                                                            setFieldValue('securityAnswer', e.target.value, true);
                                                            setAnsErr('');
                                                        }} />
                                                        {errors.securityAnswer && touched.securityAnswer ? (<label id="uname-error" className="error" htmlFor="securityAnswer"> {errors.securityAnswer}</label>) : null}
                                                    </Form.Group>
                                                </> : ''
                                            }
                                            {ansErr ? <div className="text-center mt-4"> <label className="error">{ansErr}</label></div> : ''}
                                            <div className="text-center mt-4">
                                                <button className="loginbtn btn shadow text-white" type="submit">Submit</button>
                                            </div>
                                            <div className="text-center my-3">
                                                <p>Back to   <Link to="/login" className="signup_text">Login</Link></p>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ForgotPassword;