import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <header className="py-4 login-header">
      <Container>
        <Row>
          <Col md={12}>
            <Navbar collapseOnSelect expand="lg" className="py-0 justify-content-between">
               <Navbar.Brand href="/">
                <img src={require("../../images/logo.png").default} decoding="async"/>
              </Navbar.Brand>
              <Nav className="login-btn">
                  {/* <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="#deets">Competitions</Nav.Link>
                  <Nav.Link href="#deets">App Features</Nav.Link>
                  <Nav.Link href="#deets">Connect Device</Nav.Link>
                  <Nav.Link href="#deets">Contact Us</Nav.Link> */}
                  <Link className="login_btn" to="/login"  >Login</Link>
                  <Link className="Register_btn" to="/signup" >Register</Link>
                </Nav>
            </Navbar>
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default Header