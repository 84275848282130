import React, { useEffect, useState } from "react";
import moment from "moment";
import { useLocation } from "react-router";
import { reports, reportTypeSubtitle } from '../config/config';
import { Scatter, Line, Doughnut, Pie, Bar } from 'react-chartjs-2';
import { Loader } from './Loader';
import { Container, Row, Col } from 'react-bootstrap';
import { publicGraphServices } from "../_services/publicGraph.services";
import zoomPlugin from "chartjs-plugin-zoom";
import { Chart, registerables } from "chart.js"
import { ZOOM_PLUGIN_CHART_OPTIONS, SURVEY_GRAPH_HEADING, POPULATION_HEALTH_REPORT_PROGRESS_BAR_ARRAY ,GRAPH_COLOR_ARRAY} from "../config/constants";
import { returnTotalPages } from '../utils/pagination';
import Pagination from './Pagination';


Chart.register(...registerables, zoomPlugin);

const ReportGraphPublic = () => {
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    let item = searchParams.get("item") as any;
    let user = searchParams.get("user") as any;
    user = atob(user)
    let report_id = searchParams.get("report") as any;
    report_id = atob(report_id)
    item = item ? parseInt(item) : '';

    let categoryId = searchParams.get("categoryId") as any;
    categoryId = categoryId ? atob(categoryId) : '';

    const [pieChart, setPieChart] = useState([]);

    const [multiLineGraph, setMultiLineGraph] = useState([]);

    const [graphData, setGraphData] = useState({
        labels: [] as any,
        datasets: [] as any
    });

    const [triggerLoader, setTriggerLoader] = useState(true);

    const [yAxisOpt, setYAxisOpt] = useState({}) as any;

    const [healthHistoryData, setHealthHistoryData] = useState({
        record: [],
        user_details: {}
    }) as any;

    const [surveyGraph, setSurveyGraph] = useState({}) as any;

    const [groupHealthChart, setGroupHealthChart] = useState({}) as any;

    const [populationHealth, setPopulationHealth] = useState({
        medicalRecords: [],
        healthScoreChart: {}
    }) as any;

    const [pagination, setPagination] = useState({
        page: 0,
        totalPage: 0
    });

    const [isPaginationTrigger, setIsPaginationTrigger] = useState(false);

    const [wellnessGraph, setWellnessGraph] = useState({}) as any;


    const [gAnalysisUserDetail, setGAnalysisUserDetail] = useState({}) as any;

    let reportCondition = item ? reports[item] : 1;

    useEffect(() => {
        let footerElement = document.getElementById("footer")
        if (footerElement) {
            footerElement.remove()
        }
        if (item === 1) {
            getMetricData();
        }
        if (item === 2) {
            getTrendReportGraph();
        }
        if (item === 3) {
            metricComparisonReport();
        }
        if (item === 4) {
            groupAnalysisReport();
        }
        if (item === 5) {
            publicHealthHistoryTable();
        }
        if (item === 6) {
            publicSurveyReportGraph();
        }
        if (item === 7) {
            publicGroupListingReportGraph();
        }
        if (item === 8) {
            publicPopulationReportData();
        }
        if (item === 9) {
            publicWellnessReportGraph();
        }
    }, []);

    // get metric data of graph 
    function getMetricData() {
        publicGraphServices.HeatMapGraph(user, report_id).then((response) => {
            return response.data;
        }).then((result) => {
            composeGraphDataRadiusRange(result);
            // composeGraphData(result);
            setTriggerLoader(false)
        }).catch((error) => {
            setTriggerLoader(false)
        })
    }

    // get trend report graph
    function getTrendReportGraph() {
        publicGraphServices.TrendGraph(user, report_id).then((response) => {
            return response.data;
        }).then((result) => {
            let obj = { data: result.data.linechartData };
            composeLineGraphData(obj);
            let pChart = composePieChartData(result.data.piechartData);
            setPieChart(pChart);
            setTriggerLoader(false)

        }).catch((error) => {
            setTriggerLoader(false)

        })
    }

    // get listing of metric comparison report
    function metricComparisonReport() {
        publicGraphServices.MetricComparisonGraph(user, report_id).then((response) => {
            return response.data;
        }).then((result) => {
            composeGraphData(result);
            // composeGraphDataRadiusRange(result);
        }).catch((error) => {

        });
    }

    // Group Analysis Report
    function groupAnalysisReport() {
        let status = categoryId ? parseInt(categoryId) === 5 ? true : false : false;
        let api = status ? publicGraphServices.GroupHealthGraph(user, report_id) : publicGraphServices.GroupAnalysisGraph(user, report_id);
        api.then((response) => {
            return response.data;
        }).then((result) => {
            // composeIndividualAnalysisGraph(result.data);
            if (status) {
                composeIndividualHealthScore(result.data);
            } else {
                composeIndividualAnalysisGraph(result.data);
            }
        }).catch((error) => {

        });
    }

    // public health history table
    function publicHealthHistoryTable() {
        let params = `?report_id=${report_id}&user_id=${user}&page_no=${pagination.page}`;
        publicGraphServices.publicHealthHistoryTable(params).then((response: any) => {
            return response.data;
        }).then((result) => {
            setHealthHistoryData((prevHealthHistoryData: any) => {
                return {
                    ...prevHealthHistoryData,
                    record: result.data.record ? result.data.record : [],
                    user_details: result.data.user_details
                }
            });
            let totalPage = returnTotalPages(result.total_records, 10);
            updatePaginationState('totalPage', totalPage);
            setIsPaginationTrigger(false);
            setTriggerLoader(false);
        }).catch((error: any) => {
            setIsPaginationTrigger(false);
            setTriggerLoader(false);
        })
    }

    // public survey report graph
    function publicSurveyReportGraph() {
        let params = `?report_id=${report_id}&user_id=${user}`;
        publicGraphServices.publicSurveyReportGraph(params).then((response: any) => {
            if (response.data.data) {
                let apiResponse = response.data.data;
                // Composing plain array of percentage option heading color and label
                apiResponse.percentage = [];
                apiResponse.options = [];
                apiResponse.heading = [];
                apiResponse.colors = [];
                apiResponse.label = [];

                if (apiResponse.type === "Question Based" || apiResponse.type === "Healthscore Based") {
                    apiResponse.graph.forEach((value: any, index: any) => {
                        if (value.percentages.length) {
                            let object = {} as any;
                            let percentArray = [] as any;
                            let colorArray = [] as any;
                            let labelArray = [] as any;
                            value.percentages.forEach((data: any, key: any) => {
                                if (parseFloat(data.percentage)) {
                                    percentArray.push(parseFloat(data.percentage));
                                    colorArray.push(value.options[key].color);
                                    labelArray.push(value.options[key].name)
                                }
                            });
                            if (percentArray.length) {
                                object = percentArray;
                                apiResponse.colors.push(colorArray);
                                apiResponse.options.push(value.options);
                                apiResponse.percentage.push(object);
                                apiResponse.label.push(labelArray);
                                apiResponse.heading.push(SURVEY_GRAPH_HEADING[index]);
                            }
                        }
                    });
                } else {
                    apiResponse.graph.forEach((value: any, index: any) => {
                        if (value.percentages.length) {
                            let object = {} as any;
                            let percentArray = [] as any;
                            let colorArray = [] as any;
                            let labelArray = [] as any;
                            value.percentages.forEach((data: any, key: any) => {
                                if (parseFloat(data.percentage)) {
                                    percentArray.push(parseFloat(data.percentage));
                                    colorArray.push(value.options[key].color);
                                    labelArray.push(value.options[key].name)
                                }
                            });
                            if (percentArray.length) {
                                object = percentArray;
                                apiResponse.colors.push(colorArray);
                                apiResponse.options.push(value.options);
                                apiResponse.percentage.push(object);
                                apiResponse.label.push(labelArray);
                                apiResponse.heading.push(SURVEY_GRAPH_HEADING[index]);
                            }
                        }
                    });
                }
                setSurveyGraph(apiResponse);
            }
            setTriggerLoader(false);
        }).catch((error: any) => {
            setTriggerLoader(false);
        })
    }

    // public group listing report graph
    function publicGroupListingReportGraph() {
        let params = `?report_id=${report_id}&user_id=${user}&page_no=${pagination.page}`;
        publicGraphServices.publicGroupListingReportGraph(params).then((response: any) => {
            if (response.data.data) {
                setGroupHealthChart(response.data.data);
                let totalPage = returnTotalPages(response.data.total_records, 8);
                updatePaginationState('totalPage', totalPage);
            }
            setIsPaginationTrigger(false);
            setTriggerLoader(false);
        }).catch((error: any) => {
            setTriggerLoader(false);
        })
    }

    // public population report graph
    function publicPopulationReportData() {
        let params = `?report_id=${report_id}&user_id=${user}`;
        publicGraphServices.publicPopulationReportData(params).then((response: any) => {
            if (response.data.data) {
                setPopulationHealth((prevPopulationHealth: any) => {
                    return {
                        ...prevPopulationHealth,
                        medicalRecords: response.data.data.medicalRecords,
                        healthScoreChart: response.data.data.healthScoreChart
                    }
                });
            }
            setTriggerLoader(false);
        }).catch((error: any) => {
            setTriggerLoader(false);
        })
    }

    // API of public wellness report graph
    function publicWellnessReportGraph() {
        let params = `?report_id=${report_id}&user_id=${user}`;
        publicGraphServices.publicWellnessReportGraph(params).then((response: any) => {
            if (response.data.data) {
                let barChart = composeBarChartGraphData(response.data.data);
                response.data.data.barChart = barChart;
                setWellnessGraph(response.data.data);
            }
            setTriggerLoader(false);
        }).catch((error: any) => {
            setTriggerLoader(false);
        })
    }


    // common function for bar chart
    function composeBarChartGraphData(allData: any) {
        let value = createGraphLabel(allData.graph, 'competition_name') as any;
        let position = createGraphLabel(allData.graph, 'position') as any;
        let totalUser = createGraphLabel(allData.graph, 'total_users') as any;
        
        let datasets = [
            {
                label: 'position',
                data: position,
                backgroundColor: 'rgb(42, 183, 46,0.9)'
            },
            {
                label: 'users',
                data: totalUser,
                backgroundColor: 'rgba(237, 77, 39,0.9)'
            },
        ];
        let object = {
            labels: value,
            datasets: datasets
        };
        return object;
    }

    // Common function to handle Pagination object values
    function updatePaginationState(name: any, value: any) {
        setPagination((prevPagination: any) => {
            return {
                ...prevPagination,
                [name]: value
            }
        });
    }

    // compose pie chart data
    function composePieChartData(data: any) {
        let pieChartArray = [] as any;
        if (data.length) {
            for (let x = 0; x < data.length; x++) {
                // parent object in which final data is composes
                let parentObject = {} as any;
                // data set is an array inwhich pie chart values are pushed they further plotted in pie chart
                let datasets = [] as any;
                // label contains different values label , it represent different section
                let labels = [] as any;
                let object = {
                    label: '' as any,
                    data: [] as any,
                    backgroundColor: [] as any,
                    borderColor: [] as any,
                    borderWidth: 1
                };
                for (let y = 0; y < data[x].data.length; y++) {
                    if (data[x].data[y].percentage) {
                        labels.push(data[x].data[y].label);
                        object.label = data[x].data[y].label;
                        object.data.push(data[x].data[y].percentage);
                        // let randomIndex=Math.floor(Math.random()*array.length);
                    }
                }
                if (object.data.length) {
                    let array = ["#4472c4", "#ed7d31"];
                    array.forEach((value) => {
                        object.backgroundColor.push(value);
                        object.borderColor.push(value);
                    });
                }
                datasets.push(object);
                parentObject.labels = labels;
                parentObject.datasets = datasets;
                parentObject.borderWidth = 1;
                parentObject.graphTitle = data[x].Title;
                pieChartArray.push(parentObject);
                // if(object.data.length){
                // }
            }
        }
        return pieChartArray;
    }

    function random_rgba() {
        var o = Math.round, r = Math.random, s = 255;
        return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + r().toFixed(1) + ')';
    }


    // compose data of scatter graph
    function composeGraphDataRadiusRange(result: any) {
        let lblArray = [] as any, coordinateSet = [] as any;
        if (result.data) {
            for (let x = 0; x < result.data.length; x++) {
                let object = {
                    label: '',
                    data: [] as any,
                    backgroundColor: random_rgba(),
                    user_ranges: {} as object,
                    graphTitle: '',
                    toolTip: ''
                    // pointBackgroundColor:[] as any
                };
                let value = createGraphLabel(result.data[x].data, 'date_measured');
                let count = calculateTotalUserCount(result.data[x].data);
                let coordinateData = createCoordinates(result.data[x].data, 'userCount', 'distance');
                // let coordinateData = createCoordinates(result.data[x].data, 'date_measured','userCount');
                let colorCode = calculateColorCode(count, result.data[x].user_ranges);
                object.label = result.data[x].radius_range;
                object.data = coordinateData;
                object.backgroundColor = colorCode;
                object.user_ranges = result.data[x].user_ranges;
                lblArray.push(...value);
                object.graphTitle = result.mertic_name + " and " + result.disease_name;
                if (coordinateData.length) {
                    coordinateSet.push(object);
                }
            }
        }
        if (item === 1) {
            lblArray = lblArray.filter(function (item: any, pos: any, arr: any) {
                return arr.indexOf(item) === pos;
            })
            lblArray = lblArray.sort((a: any, b: any) => {
                let t1 = new Date(a) as any, t2 = new Date(b) as any;
                return t1 - t2;
            })
        }
        // console.log(lblArray)
        setGraphData(prevGraphData => {
            return {
                ...prevGraphData,
                labels: lblArray,
                datasets: coordinateSet
            }
        });
        setTriggerLoader(false)
    }

    // compose graph data
    function composeGraphData(result: any) {
        let lblArray = [] as any, coordinateSet = [] as any;
        let yAxisObj = {} as any;

        if (result.data) {
            let colorArray = ["#D10000", "#37BCA4"];
            for (let x = 0; x < result.data.length; x++) {
                let object = {
                    label: '',
                    data: [] as any,
                    backgroundColor: '',
                    borderColor: '',
                    yAxisID: `y${x}`,
                };
                let value = createGraphLabel(result.data[x].data, 'date_measured');
                // let coordinateData = createCoordinates(result.data[x].data,'date_measured' ,'Measured_data');
                let coordinateData = createCoordinates(result.data[x].data, 'Measured_data', '');
                object.data = coordinateData;
                object.label = result.data[x].lebel.metrics;
                object.backgroundColor = colorArray[x];
                yAxisObj[`y${x}`] = result.data[x].lebel.metrics;
                yAxisObj[`y${x}_max`] = Math.max(...coordinateData);
                object.borderColor = colorArray[x];
                coordinateSet.push(object);
                lblArray.push(...value);
            }
        }
        setYAxisOpt((prevYAxisOpt: any) => {
            return {
                ...prevYAxisOpt,
                ...yAxisObj

            }
        });
        lblArray = lblArray.map((val: any) => moment(val).format('Do MMM'));
        setGraphData(prevGraphData => {
            return {
                ...prevGraphData,
                labels: lblArray,
                datasets: coordinateSet
            }
        });
        setTriggerLoader(false);
    }

    //  compose graph of Individual Health Score
    function composeIndividualHealthScore(allData: any) {
        let status = categoryId ? parseInt(categoryId) === 5 ? true : false : false;
        let groups = status ? allData.groupData : allData.groups;
        let individual = status ? allData.individualData : allData.individual;
        let datasets = [] as any, coordinateSet = [] as any;
        let parentObject = {} as any;
        if (groups.length) {

            let object = {
                label: '',
                data: [] as any,
                backgroundColor: '',
                borderColor: '',
            },
                individualObj = {
                    label: '',
                    data: [] as any,
                    backgroundColor: '',
                    borderColor: '',
                };
            let coordinateArray = [] as any, individualArray = [] as any;
            groups.forEach((value: any, index: number) => {
                let status = value.data < 0 ? true : false;
                if (!status) {
                    let obj = { x: value.duration, y: value.data, id: `group_${index}` } as any;
                    coordinateArray.push(obj);
                }
            });
            if (coordinateArray.length) {
                object.data = coordinateArray;
                object.label = "Groups";
                object.backgroundColor = GRAPH_COLOR_ARRAY[1];
                object.borderColor = GRAPH_COLOR_ARRAY[1];
                datasets.push(object);
            }
            individual.forEach((value: any, index: number) => {
                let status = value.data < 0 ? true : false;
                if (!status) {
                    let obj = { x: value.duration, y: value.data, id: `group_${index}` } as any;
                    individualArray.push(obj);
                }
            });
            if (individualArray.length) {
                individualObj.data = individualArray;
                individualObj.label = "Individual";
                individualObj.backgroundColor = GRAPH_COLOR_ARRAY[0];
                individualObj.borderColor = GRAPH_COLOR_ARRAY[0];
                datasets.push(individualObj);
            }
            if (datasets.length) {
                parentObject.datasets = datasets;
                coordinateSet.push(parentObject);
            }
        }
        setGAnalysisUserDetail((prevGAnalysisUserDetail: any) => {
            return {
                ...prevGAnalysisUserDetail,
                ...allData.userDetails
            }
        })
        setMultiLineGraph(coordinateSet);
        setTriggerLoader(false);
    }

    // compose individual analysis graph
    function composeIndividualAnalysisGraph(allData: any) {
        let mainArray = [] as any;
        // let lblArray = [] as any, coordinateSet = [] as any;
        if (allData.groups.length) {
            allData.groups.forEach((value: any) => {
                let subArray = [] as any, mainObj = {} as any;
                let activeEntry = allData.individual.filter((data: any) => {
                    if (`${data.title}` === value.title) {
                        return data;
                    } else {
                        return false
                    }
                });
                if (activeEntry.length) {
                    activeEntry.forEach((values: any, index: any) => {
                        if (values.title === "Blood Pressure") {
                            values.data.forEach((bpValue: any, id: number) => {
                                let obj = {} as any;
                                let bPColorArray = ["#D10000", "#37BCA4"];
                                let capitaliseLabel = bpValue.label.charAt(0).toUpperCase() + bpValue.label.slice(1);
                                // let cIndex=bPColorArray.length-id;
                                obj.label = `Individual (${capitaliseLabel})`;
                                obj.data = createCoordinates(bpValue.bpData, 'duration', 'data');
                                obj.backgroundColor = bPColorArray[id];
                                obj.borderColor = bPColorArray[id];
                                subArray.push(obj);
                            });
                        } else {
                            let obj = {} as any;
                            obj.label = "Individual";
                            obj.data = createCoordinates(values.data, 'duration', 'data');
                            obj.backgroundColor = GRAPH_COLOR_ARRAY[index];
                            obj.borderColor = GRAPH_COLOR_ARRAY[index];
                            subArray.push(obj);
                        }
                    });
                    if (value.title === "Blood Pressure") {
                        value.data.forEach((bpValue: any, id: number) => {
                            let obj = {} as any;
                            let capitaliseLabel = bpValue.label.charAt(0).toUpperCase() + bpValue.label.slice(1);
                            // let cIndex=value.data.length-id;
                            obj.label = `Group (${capitaliseLabel})`;
                            let bPColorArray = ["#D62ECF", "#4472c4"];
                            obj.data = createCoordinates(bpValue.bpData, 'duration', 'data');
                            obj.backgroundColor = bPColorArray[id];
                            obj.borderColor = bPColorArray[id];
                            subArray.push(obj);
                        });
                        // let cIndex=value.data.length-id;
                        // obj.label = bpValue.label;
                        // obj.data = createCoordinates(bpValue.bpData, 'duration', 'data');
                        // obj.backgroundColor = colorArray[cIndex];
                        // obj.borderColor = colorArray[cIndex];
                        // subArray.push(obj);
                    } else {
                        let nextObj = {} as any;
                        nextObj.label = "Group";
                        nextObj.data = createCoordinates(value.data, 'duration', 'data');
                        nextObj.backgroundColor = GRAPH_COLOR_ARRAY[1];
                        nextObj.borderColor = GRAPH_COLOR_ARRAY[1];
                        subArray.push(nextObj);
                    }
                    mainObj.title = value.title;
                    mainObj.datasets = subArray;
                    mainArray.push(mainObj);
                }
            });
        }
        setGAnalysisUserDetail((prevGAnalysisUserDetail: any) => {
            return {
                ...prevGAnalysisUserDetail,
                ...allData.userDetails
            }
        });
        setMultiLineGraph(mainArray);
        setTriggerLoader(false);
    }

    // compose line graph data
    function composeLineGraphData(result: any) {
        let datasets = [] as any, coordinateSet = [] as any;
        let parentObject = {} as any;
        if (result.data) {
            for (let x = 0; x < result.data.length; x++) {
                // let color = random_rgba();
                let object = {
                    label: '',
                    data: [] as any,
                    backgroundColor: '',
                    borderColor: '',
                };
                let coordinateData = createCoordinates(result.data[x].data, 'date_measured', 'userCount');
                object.data = coordinateData;
                object.label = result.data[x].label;
                // let randomIndex=Math.floor(Math.random()*colorArray.length);
                object.backgroundColor = GRAPH_COLOR_ARRAY[x];
                object.borderColor = GRAPH_COLOR_ARRAY[x];
                datasets.push(object);
                parentObject.datasets = datasets;
            }
            coordinateSet.push(parentObject);
        }
        setMultiLineGraph(coordinateSet);
        setTriggerLoader(false)

    }

    // create graph label array for representation of x axis
    function createGraphLabel(array: any, type: any) {
        let unique = [] as Array<string | undefined>
        for (let i = 0; i < array.length; i++) {
            if (type === "date_measured") {
                let date = moment(array[i].date_measured).format('YYYY-MM-DD');
                if (!unique.includes(date)) {
                    unique.push(date);
                }
            }
            if (type === "lebel") {
                unique.push(array[i].lebel.metrics);
            }
            if (type === "label") {
                unique.push(array[i].label);
            }
            if (type === "competition_name") {
                unique.push(array[i].competition_name);
            }
            if (type === "position") {
                unique.push(array[i].position);
            }
            if (type === "total_users") {
                unique.push(array[i].total_users);
            }
        }
        return unique;
    }

    //create coordinates array to plot points in graph
    function createCoordinates(array: any, x: any, y: any) {
        var unique = [];
        for (let i = 0; i < array.length; i++) {
            if (y) {
                let obj = { x: '', y: '', id: '' } as any;
                if (item === 1 || item === 3) {
                    obj.label = {
                        'date_measured': array[i]["date_measured"],
                        'userCount': array[i]["userCount"],
                        'distance': item === 3 ? array[i]["Measured_data"] : array[i]["distance"]
                    }
                } else {
                    obj.label = ""
                }
                obj.x = array[i][x];
                if (y) {
                    obj.y = array[i][y];
                }
                obj.id = `${i + 1}`;
                unique.push(obj);
            } else {
                unique.push(array[i][x])
            }
        }
        return unique
    }

    // calculate total user count`  
    function calculateTotalUserCount(array: any) {
        let count = array.reduce(getSum, 0);
        return count;
    }
    // reduce function add all entries into single sum
    function getSum(total: any, num: any) {
        return total + num.userCount;
    }

    // calculate color code 
    function calculateColorCode(value: any, object: any) {
        let keys = Object.keys(object), colorCode = '';
        for (let x = 0; x < keys.length; x += 2) {
            let keySplit = keys[x].split("_");
            let color = keySplit[0];
            if (value >= object[keys[x]] || value <= object[keys[x + 1]]) {
                colorCode = returnColorCode(color);
            }
        }
        return colorCode;
    }

    // return color code on the basis of type
    function returnColorCode(color: any) {
        switch (color) {
            case "green":
                return "#00d700";
            case "yellow":
                return "#c7c500";
            case "red":
                return "#ff0000";
            default:
                return "#00d700";
        }
    }

    function returnReportSubTitle(type: number) {
        switch (type) {
            case 1:
                return reportTypeSubtitle.MetricsHeatMap;
            case 2:
                return reportTypeSubtitle.MetricsConditions;
            case 3:
                return reportTypeSubtitle.MetricsComparison;
            case 4:
                return reportTypeSubtitle.MetricsIndividualAndGroupAnalysis;
            case 5:
                return reportTypeSubtitle.MetricsHealthHistoryReport;
            case 6:
                return reportTypeSubtitle.MetricsSurveyReport;
            case 7:
                return reportTypeSubtitle.MetricsGroupHealthReport;
            case 8:
                return reportTypeSubtitle.MetricsPopulationHealthReport;
            case 9:
                return reportTypeSubtitle.MetricsWellnessCompetitionReport;
            default:
                return false
        }
    }

    function handlePageClick(event: any) {
        if (event.selected <= pagination.totalPage) {
            updatePaginationState('page', event.selected);
            setTriggerLoader(true);
            setIsPaginationTrigger(true);
        }
    };

    useEffect(() => {
        if (isPaginationTrigger) {
            setTriggerLoader(true);
            if (item === 7) {
                publicGroupListingReportGraph();
            } else {
                publicHealthHistoryTable();
            }
            window.scrollTo(0, 0);
        }
    }, [isPaginationTrigger]);

    // No data section
    function NoDataSection() {
        return (
            <div className='row mt-5'><div className="col-md-12 text-center">No matching record found</div></div>
        )
    }


    const multiYaxisOption = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1,
        resizeDelay: 0,
        // onResize: (width: number, height: number) => {
        //     console.log('width', width, 'height', height);
        // },
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        stacked: false,
        plugins: {
            legend: {
                position: 'bottom' as const,
                align: 'start',
            },
            title: {
                display: false,
            },
            ...ZOOM_PLUGIN_CHART_OPTIONS
        },
        scales: {
            x: {
                type: "category",
                grid: {
                    display: false
                },
                position: 'bottom',
                title: {
                    display: true,
                    text: 'Dates'
                },
                ticks: {
                    source: "data",
                },
                offset: true
            },
            y0: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
                title: {
                    display: true,
                    text: yAxisOpt ? yAxisOpt.y0 : ''
                }
            },
            y1: {
                type: 'linear' as const,
                display: true,
                position: 'right' as const,
                title: {
                    display: true,
                    text: yAxisOpt ? yAxisOpt.y1 : ''
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
    };

    let trendReportStatus = pieChart.length === 0 && multiLineGraph.length === 0 ? true : false;

    const pieChartoptions = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1,
        resizeDelay: 0,
        plugins: {
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (context: any) {
                        return ` ${context.label}: ${context.parsed} %`;
                    }
                }
            },
            legend: {
                position: 'bottom' as const,
                align: 'start',
                display: false
            },
            title: {
                display: false,
                text: ''
            },
            ...ZOOM_PLUGIN_CHART_OPTIONS
        },
    };

    let pieChartData = {
        labels: item === 9 ? ['Top 20', 'Top 60', 'Top 100'] : ['Normal', 'Good', 'Bad'],
        datasets: [
            {
                label: '# of Votes',
                data: [
                    item === 9 ? wellnessGraph.piechart ? wellnessGraph.piechart.top_20_percent : 0 : item === 8 ? populationHealth.healthScoreChart ? populationHealth.healthScoreChart.normal_health_score : 0 : groupHealthChart.normal_percentage ? parseFloat(groupHealthChart.normal_percentage) : 0,
                    item === 9 ? wellnessGraph.piechart ? wellnessGraph.piechart.top_60_percent : 0 : item === 8 ? populationHealth.healthScoreChart ? populationHealth.healthScoreChart.good_health_score : 0 : groupHealthChart.good_percentage ? parseFloat(groupHealthChart.good_percentage) : 0,
                    item === 9 ? wellnessGraph.piechart ? wellnessGraph.piechart.top_100_percent : 0 : item === 8 ? populationHealth.healthScoreChart ? populationHealth.healthScoreChart.bad_health_score : 0 : groupHealthChart.bad_percentage ? parseFloat(groupHealthChart.bad_percentage) : 0
                ],
                backgroundColor:
                    item === 9 ?
                        [
                            'rgba(60, 188, 165, 1)',
                            'rgba(208, 0, 13, 1)',
                            'rgba(212, 54, 203, 1)'
                        ]
                        : [
                            'rgba(208, 0, 13, 1)',
                            'rgba(60, 188, 165, 1)',
                            'rgba(212, 54, 203, 1)'
                        ],
                borderColor:
                    item === 9 ?
                        [
                            'rgba(60, 188, 165, 1)',
                            'rgba(208, 0, 13, 1)',
                            'rgba(212, 54, 203, 1)'
                        ]
                        : [
                            'rgba(208, 0, 13, 1)',
                            'rgba(60, 188, 165, 1)',
                            'rgba(212, 54, 203, 1)'
                        ],
                borderWidth: 1,
            },
        ],
    } as any;

    const barChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false,
            },
            ...ZOOM_PLUGIN_CHART_OPTIONS
        },
    };

    console.log('wellnessGraph.barChart', wellnessGraph);

    return (
        <>
            <section className={`${item === 8 ? `bodyvital_section ` : ``}friend_sections notification_sections py-5 public_graph_section`} >
                <Loader status={triggerLoader ? "block" : "none"} />
                <Container>
                    <div className="inner_box pb-4">
                        <Row>
                            <Col md={12} className="text-center mb-2">
                                <img src={require("../images/Icon-App-83.5x83.5@2x.png").default} alt="report" style={{ height: "40px" }} />
                            </Col>
                            <Col md={12} className="text-center">
                                <h3 className='heatmap_subtitle'>{reportCondition}</h3>
                            </Col>
                            {pieChart.length || multiLineGraph.length || graphData.datasets.length ?
                                <Col md={12}>
                                    <p>{returnReportSubTitle(item)}</p>
                                </Col> : ''}
                            <Col lg={12}>
                                {item === 1 ?
                                    graphData.datasets.length ?
                                        <div className="graph_outer row">
                                            {
                                                graphData.datasets.map((value: any, index: any) => {
                                                    const data = {
                                                        labels: value.data.map((val: any) => moment(val.label.date_measured).format('Do MMM')),
                                                        datasets: [
                                                            {
                                                                data: value.data,
                                                                backgroundColor: value.backgroundColor,
                                                            },
                                                        ],
                                                    };
                                                    const options = {
                                                        responsive: true,
                                                        maintainAspectRatio: true,
                                                        aspectRatio: 1,
                                                        resizeDelay: 0,
                                                        radius: 7,
                                                        hoverRadius: 10,
                                                        layout: {
                                                            padding: 10
                                                        },
                                                        scales: {
                                                            x: {
                                                                type: "category",
                                                                grid: {
                                                                    display: false
                                                                },
                                                                position: 'bottom',
                                                                title: {
                                                                    display: true,
                                                                    text: 'Dates'
                                                                },
                                                                ticks: {
                                                                    source: "data",
                                                                },
                                                                offset: true,
                                                            },
                                                            y: {
                                                                grid: {
                                                                    display: false
                                                                },

                                                                title: {
                                                                    display: true,
                                                                    text: item === 3 ? `Measured Data` : `Radius (Miles)`
                                                                },
                                                                ticks: {
                                                                    precision: 0,
                                                                }

                                                            }
                                                        },
                                                        plugins: {
                                                            title: {
                                                                display: true,
                                                                text: value.graphTitle,
                                                                font: {
                                                                    size: 16,
                                                                    weight: 'bold',
                                                                    color: "#000000",
                                                                    backgroundColor: `rgba(0, 0, 0, 0.1)`
                                                                }
                                                            },
                                                            legend: {
                                                                display: false,
                                                            },
                                                            ...ZOOM_PLUGIN_CHART_OPTIONS,
                                                            tooltip: {
                                                                enabled: true,
                                                                position: 'nearest',
                                                                callbacks: {
                                                                    label: function (ctx: any) {
                                                                        return false
                                                                    },
                                                                    footer: function (tooltipItems: any) {
                                                                        let toolTip = '';
                                                                        tooltipItems.forEach((ctx: any) => {
                                                                            let label = ctx.dataset.data[ctx.dataIndex];
                                                                            let labelX = "Date";
                                                                            let labelY = item === 3 ? `Measured Data` : `Radius`;
                                                                            let labelZ = "User count";
                                                                            labelX += ": " + moment(label.label.date_measured).format('Do MMMM');
                                                                            labelY += ": " + parseFloat(label.label.distance).toFixed(2) + `${item === 3 ? `` : ` Miles`}`;
                                                                            labelZ += ": " + label.label.userCount;
                                                                            toolTip = labelX + '\n' + labelZ + '\n' + labelY;
                                                                        })
                                                                        return toolTip;
                                                                    }
                                                                }
                                                            },

                                                        }
                                                    };
                                                    return (
                                                        <div className="col-md-12 px-4 mb-2" key={`${value.label}_${index}`}>
                                                            {
                                                                value.data.length ? <Scatter options={options} data={data} /> :
                                                                    <div className="row mt-1 mb-5">
                                                                        <div className="col-md-12 text-center ">
                                                                            <h6 className='mb-5'>{value.graphTitle}</h6>
                                                                            No matching record found
                                                                        </div>
                                                                    </div>
                                                            }
                                                            {
                                                                item === 1 ? <div className="colortext mt-4">
                                                                    <>
                                                                        <p>
                                                                            <i className="fa fa-square gooddata" aria-hidden="true" />
                                                                            <span className="gooddata ml-1">Good: </span>
                                                                            {`${value.user_ranges.green_min}(Min) - ${value.user_ranges.green_max}(Max)`}
                                                                        </p>
                                                                        <p>
                                                                            <i className="fa fa-square cautiondata" aria-hidden="true" />
                                                                            <span className="cautiondata ml-1">Caution: </span>
                                                                            {`${value.user_ranges.yellow_min}(Min) - ${value.user_ranges.yellow_max}(Max)`}
                                                                        </p>
                                                                        <p>
                                                                            <i className="fa fa-square alertdata" aria-hidden="true" />
                                                                            <span className="alertdata ml-1">Alert Level: </span>{` ≥ ${value.user_ranges.red_min} (Max)`}</p>
                                                                    </>
                                                                </div> : ''
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        : <NoDataSection /> : ''
                                }
                                {item === 2 ?
                                    <React.Fragment>
                                        {
                                            pieChart.length ?
                                                <div className="graph_outer row">
                                                    {
                                                        pieChart.map((value: any, index: any) => {
                                                            let options = {
                                                                responsive: true,
                                                                maintainAspectRatio: true,
                                                                aspectRatio: 1,
                                                                resizeDelay: 0,
                                                                weight: 1,
                                                                cutout: 180,
                                                                plugins: {
                                                                    legend: {
                                                                        position: 'top',
                                                                    },
                                                                    title: {
                                                                        display: true,
                                                                        text: value.graphTitle
                                                                    },
                                                                    ...ZOOM_PLUGIN_CHART_OPTIONS
                                                                }
                                                            };
                                                            if (value.labels.length) {
                                                                return (
                                                                    <div className='mt-4 col-md-6 col-sm-12 col-lg-6 chart_container' key={`${value.borderWidth}_${index}`}>
                                                                        <Doughnut options={options} data={value} />
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div className="col-md-12 text-center mt-4 mb-4">
                                                                        <h6 className='mb-0'>{value.graphTitle}</h6>
                                                                        <p className='mt-2'>No matching record found</p>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                                : !trendReportStatus ? <NoDataSection /> : ''
                                        }
                                        {
                                            multiLineGraph.length ?
                                                <div className="graph_outer row">
                                                    {
                                                        multiLineGraph.map((value, index) => {
                                                            const multiLineOptions = {
                                                                responsive: true,
                                                                maintainAspectRatio: true,
                                                                aspectRatio: 1,
                                                                resizeDelay: 0,
                                                                scales: {
                                                                    y: {
                                                                        beginAtZero: true,
                                                                        title: {
                                                                            display: true,
                                                                            text: `Percentage`
                                                                        }
                                                                    }
                                                                },
                                                                plugins: {
                                                                    legend: {
                                                                        position: 'bottom' as const,
                                                                        align: 'start',
                                                                    },
                                                                    title: {
                                                                        display: false,
                                                                    },
                                                                },
                                                                ...ZOOM_PLUGIN_CHART_OPTIONS
                                                            };
                                                            return (
                                                                <div className='mt-2 mb-2 col-md-12' key={`${index}_${index}`}>
                                                                    <Line options={multiLineOptions} data={value} />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                : !trendReportStatus ? <NoDataSection /> : ''
                                        }
                                        {
                                            trendReportStatus ? <NoDataSection /> : ''
                                        }
                                    </React.Fragment>
                                    : ''
                                }
                                {
                                    item === 4 ? multiLineGraph.length ?
                                        <div className="graph_outer row">
                                            {
                                                multiLineGraph.map((value: any, index: number) => {
                                                    const multiLineOptions = {
                                                        responsive: true,
                                                        maintainAspectRatio: true,
                                                        aspectRatio: 1,
                                                        resizeDelay: 0,
                                                        scales: {
                                                            x: {
                                                                beginAtZero: true,
                                                                title: {
                                                                    display: true,
                                                                    text: `Duration`
                                                                }
                                                            },
                                                            y: {
                                                                beginAtZero: true,
                                                                title: {
                                                                    display: true,
                                                                    text: `Health Score`
                                                                }
                                                            }
                                                        },
                                                        plugins: {
                                                            legend: {
                                                                position: 'bottom' as const,
                                                                align: 'start',
                                                            },
                                                            title: {
                                                                display: true,
                                                                text: value.title
                                                            },
                                                        },
                                                        ...ZOOM_PLUGIN_CHART_OPTIONS
                                                    };
                                                    return (
                                                        <div className='mt-2 mb-2 col-md-12' key={`${index}_${index}`}>
                                                            <Line options={multiLineOptions} data={value} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        : !trendReportStatus ? <NoDataSection /> : '' : ''
                                }
                                {
                                    item === 3 ?
                                        graphData.datasets.length ?
                                            <div className='mt-2 mb-2 col-md-12 chart_container'>
                                                <Line options={multiYaxisOption} data={graphData} />
                                            </div> : <NoDataSection />
                                        : ''
                                }

                                {
                                    item === 5 ?
                                        <>
                                            <div className="row">
                                                <div className="col-12">
                                                    <table className='table user-details mb-4'>
                                                        <thead>
                                                            <tr>
                                                                <th>First Name</th>
                                                                <th>Last Name</th>
                                                                <th>Age</th>
                                                                <th>Gender</th>
                                                                <th>Blood Group</th>
                                                                <th>Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-capitalize">{healthHistoryData.user_details.firstname}</td>
                                                                <td className="text-capitalize">{healthHistoryData.user_details.lastname}</td>
                                                                <td>{healthHistoryData.user_details.age}</td>
                                                                <td>{healthHistoryData.user_details.gender}</td>
                                                                <td>{healthHistoryData.user_details.bloodGroup}</td>
                                                                <td>{healthHistoryData.user_details.firstname ? moment().format('MMM-DD-YYYY') : ''}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col-12">
                                                    {
                                                        healthHistoryData.record.length ?
                                                            <div className='recorded-data'>
                                                                <table className='table '>
                                                                    <thead>
                                                                        <tr>
                                                                            <th className='date-format'>Date</th>
                                                                            <th>Recorded Data</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            healthHistoryData.record.map((value: any, index: any) => {
                                                                                return (
                                                                                    <tr key={`${value.type}_${index}`}>
                                                                                        <td>{moment(value.start_date).format('MMM-DD-YYYY')}</td>
                                                                                        <td>
                                                                                            <label style={{ color: "#1ea2e3" }}>{value.type === "precription" ? `Medication:` : `Disease:`} </label> {value.name}<br />
                                                                                            {
                                                                                                value.type === "precription" ? <><label style={{ color: "#1ea2e3" }}>Start & End date:</label>{`  ${moment(value.start_date).format('DD MMM YYYY')}${value.end_date ? ` || ${moment(value.end_date).format('DD MMM YYYY')}` : ''}`}</> :
                                                                                                    <><label style={{ color: "#1ea2e3" }}>Stage: </label>{` ${value.level}`}</>
                                                                                            }
                                                                                            <br />
                                                                                            {
                                                                                                value.type === "precription" ? <><label style={{ color: "#1ea2e3" }}>Dosage:</label> {value.quantity} MG</> : ''
                                                                                            }
                                                                                            {
                                                                                                value.type === "medical" ?
                                                                                                    <>
                                                                                                        <label style={{ color: "#1ea2e3" }}>Precautions: </label><p>{value.quantity}</p>
                                                                                                        <label style={{ color: "#1ea2e3" }}>Symptoms: </label><p>{value.symptoms}</p>
                                                                                                    </> : ''
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            :
                                                            <div className="text-center mt-4">
                                                                No Matching Record Found
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                            {pagination.totalPage > 0 ?
                                                <div className="row mt-4">
                                                    <div className="col-md-12">
                                                        <Pagination {...pagination} handlePageClick={handlePageClick} />
                                                    </div>
                                                </div>
                                                : ''}
                                        </>
                                        : ''
                                }
                                {
                                    item === 6 ?
                                        surveyGraph.graph ?
                                            <PieChartGraph  {...surveyGraph} />
                                            :
                                            <div className="row mt-4">
                                                <div className="col-md-12 text-center">
                                                    No Data Found
                                                </div>
                                            </div>
                                        : ''
                                }
                                {
                                    item === 7 ?
                                        groupHealthChart.normal_percentage || groupHealthChart.graph ?
                                            <>
                                                <div className='row centered-pie-chart'>
                                                    <div className="col-md-12 px-4 mt-2 mb-4">
                                                        <span>Your Health Score: </span>
                                                        <span className='digit-score'>{groupHealthChart.your_health_score ? groupHealthChart.your_health_score : 0}</span>
                                                        <span className='good-score' style={{ color: groupHealthChart.condition === "Bad" ? "rgba(212, 54, 203, 1)" : groupHealthChart.condition === "normal" ? "rgba(208, 0, 13, 1)" : "rgba(60, 188, 165, 1)" }}>
                                                            {groupHealthChart.condition === "Bad" ? "Bad" : groupHealthChart.condition === "normal" ? "Normal" : "Good"}</span>
                                                    </div>
                                                    <div className="col-md-12 px-4 mt-2 mb-4">
                                                        <h3>Survey according to health score 'Normal' , 'Bad' and 'Good'</h3>
                                                    </div>
                                                    {
                                                        groupHealthChart.graph.length ?
                                                            groupHealthChart.graph.map((data: any, id: any) => {
                                                                let multiPieChartData = {
                                                                    labels: ['Normal', 'Good', 'Bad'],
                                                                    datasets: [
                                                                        {
                                                                            label: '# of Votes',
                                                                            data: [
                                                                                data.normal_percentage ? parseFloat(data.normal_percentage) : 0,
                                                                                data.good_percentage ? parseFloat(data.good_percentage) : 0,
                                                                                data.bad_percentage ? parseFloat(data.bad_percentage) : 0
                                                                            ],
                                                                            backgroundColor: [
                                                                                'rgba(208, 0, 13, 1)',
                                                                                'rgba(60, 188, 165, 1)',
                                                                                'rgba(212, 54, 203, 1)'
                                                                            ],
                                                                            borderColor: [
                                                                                'rgba(208, 0, 13, 1)',
                                                                                'rgba(60, 188, 165, 1)',
                                                                                'rgba(212, 54, 203, 1)'
                                                                            ],
                                                                            borderWidth: 1,
                                                                        },
                                                                    ],
                                                                } as any;
                                                                return (
                                                                    <div className="col-md-5 px-4 mb-2" key={`${data.normal_percentage}_${id}`}>
                                                                        <div className="row">
                                                                            <div className="col-md-12 text-center">
                                                                                <label style={{ fontWeight: 600 }}>Group name:</label>
                                                                                <span>{`  ${data.group_name}`}</span>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <Pie data={multiPieChartData} options={pieChartoptions} />
                                                                            </div>
                                                                        </div>
                                                                        <div className='graph_outer row  justify-content-center' key={`${data.normal}_${id}`}>
                                                                            <div className="col-md-10 px-4 mt-2 mb-4">
                                                                                <div className="colortext mt-4">
                                                                                    <>
                                                                                        <p>
                                                                                            <i className="fa fa-square" style={{ color: "rgba(208, 0, 13, 1)" }} aria-hidden="true" />
                                                                                            <span className="ml-1">Option 'Normal':{data.normal} </span>
                                                                                        </p>
                                                                                        <p>
                                                                                            <i className="fa fa-square" style={{ color: "rgba(60, 188, 165, 1)" }} aria-hidden="true" />
                                                                                            <span className="ml-1">Option 'Good':{data.good} </span>
                                                                                        </p>
                                                                                        <p>
                                                                                            <i className="fa fa-square  " style={{ color: "rgba(212, 54, 203, 1)" }} aria-hidden="true" />
                                                                                            <span className="ml-1">Option 'Bad':{data.bad} </span>
                                                                                        </p>
                                                                                    </>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            : <div className="col-md-6 px-4 mb-2">
                                                                <Pie data={pieChartData} options={pieChartoptions} />
                                                            </div>
                                                    }
                                                </div>
                                                {
                                                    groupHealthChart.normal_percentage ?
                                                        <div className='graph_outer row  justify-content-center'>
                                                            <div className="col-md-10 px-4 mt-2 mb-4">
                                                                <div className="colortext mt-4">
                                                                    <>
                                                                        <p>
                                                                            <i className="fa fa-square" style={{ color: "rgba(208, 0, 13, 1)" }} aria-hidden="true" />
                                                                            <span className="ml-1">Option 'Normal':{groupHealthChart.normal} </span>
                                                                        </p>
                                                                        <p>
                                                                            <i className="fa fa-square" style={{ color: "rgba(60, 188, 165, 1)" }} aria-hidden="true" />
                                                                            <span className="ml-1">Option 'Good':{groupHealthChart.good} </span>
                                                                        </p>
                                                                        <p>
                                                                            <i className="fa fa-square  " style={{ color: "rgba(212, 54, 203, 1)" }} aria-hidden="true" />
                                                                            <span className="ml-1">Option 'Bad':{groupHealthChart.bad} </span>
                                                                        </p>
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''
                                                }
                                                {pagination.totalPage > 1 ?
                                                    <div className="row mt-4">
                                                        <div className="col-md-5">
                                                            <Pagination {...pagination} handlePageClick={handlePageClick} />
                                                        </div>
                                                    </div>
                                                    : ''}
                                            </> :
                                            <div className='graph_outer row justify-content-center'>
                                                <div className="col-md-10 px-4 mt-2 mb-4">
                                                    <div className='row mt-5'><div className="col-md-12 text-center">No matching record found</div></div>
                                                </div></div>
                                        : ''
                                }
                                {
                                    item === 8 ?
                                        <>
                                            {
                                                populationHealth.healthScoreChart ?
                                                    <div className='row centered-pie-chart'>
                                                        <div className="col-md-12 px-4 mt-2 mb-4">
                                                            <h3>Percentage of people having 'Normal' , 'Bad' and 'Good'</h3>
                                                        </div>
                                                        <div className="col-md-6 px-4 mb-2">
                                                            <Pie data={pieChartData} options={pieChartoptions} />
                                                        </div>
                                                    </div> : ''}
                                            {

                                                populationHealth.healthScoreChart ?
                                                    <div className='graph_outer row  justify-content-center'>
                                                        <div className="col-md-10 px-4 mt-2 mb-4">
                                                            <div className="colortext mt-4">
                                                                <>
                                                                    <p>
                                                                        <i className="fa fa-square" style={{ color: "rgba(208, 0, 13, 1)" }} aria-hidden="true" />
                                                                        <span className="ml-1">Option 'Normal': {populationHealth.healthScoreChart.normal_health_score_count} People </span>
                                                                    </p>
                                                                    <p>
                                                                        <i className="fa fa-square" style={{ color: "rgba(60, 188, 165, 1)" }} aria-hidden="true" />
                                                                        <span className="ml-1">Option 'Good': {populationHealth.healthScoreChart.good_health_score_count} People</span>
                                                                    </p>
                                                                    <p>
                                                                        <i className="fa fa-square  " style={{ color: "rgba(212, 54, 203, 1)" }} aria-hidden="true" />
                                                                        <span className="ml-1">Option 'Bad': {populationHealth.healthScoreChart.bad_health_score_count} People</span>
                                                                    </p>
                                                                </>
                                                            </div>
                                                        </div>
                                                    </div> : ''
                                            }
                                            {
                                                populationHealth.medicalRecords.length ?
                                                    <div className='row '>
                                                        <aside className='col-md-12'>
                                                            <h3 className='text-center my-5'>Percentage of people having disease</h3>
                                                        </aside>
                                                        {
                                                            populationHealth.medicalRecords.map((value: any, index: any) => {
                                                                let options = {
                                                                    responsive: true,
                                                                    maintainAspectRatio: true,
                                                                    aspectRatio: 1,
                                                                    resizeDelay: 0,
                                                                    weight: 1,
                                                                    cutout: 180,
                                                                    plugins: {
                                                                        legend: {
                                                                            position: 'top',
                                                                        },
                                                                        title: {
                                                                            display: true,
                                                                            text: value.graphTitle
                                                                        }
                                                                    },
                                                                    ...ZOOM_PLUGIN_CHART_OPTIONS
                                                                };
                                                                let color = index > populationHealth.medicalRecords.length - 1 ? POPULATION_HEALTH_REPORT_PROGRESS_BAR_ARRAY[Math.floor(Math.random() * (POPULATION_HEALTH_REPORT_PROGRESS_BAR_ARRAY.length - 1 - 0 + 1) + 0)] : POPULATION_HEALTH_REPORT_PROGRESS_BAR_ARRAY[index];
                                                                const data = {
                                                                    labels: [],
                                                                    datasets: [
                                                                        {
                                                                            label: '# of Votes',
                                                                            data: [100 - parseFloat(value.total_count), parseFloat(value.total_count)],
                                                                            backgroundColor: [
                                                                                'rgba(225, 225, 225, 1)',
                                                                                color
                                                                            ],
                                                                            borderColor: [
                                                                                'rgba(225, 225, 225, 1)',
                                                                                color
                                                                            ],
                                                                            borderWidth: 40,
                                                                        },
                                                                    ],
                                                                };
                                                                return (
                                                                    <div className="col-md-4 percentage-box" key={`${value.total_count}_${index}`}>
                                                                        <h2 className='percentage-txt' style={{ color: color }}>{value.total_count}%</h2>
                                                                        <Doughnut options={options} data={data} />
                                                                        <p className='text-center mt-3'>{value.total_user_count} people have {value.disease_name}</p>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    : <div className='graph_outer row justify-content-center'>
                                                        <div className="col-md-10 px-4 mt-2 mb-4">
                                                            <div className='row mt-5'><div className="col-md-12 text-center">No matching record found</div></div>
                                                        </div></div>
                                            }
                                        </>
                                        : ''
                                }
                                {
                                    item === 9 ?
                                        <>
                                            {
                                                wellnessGraph.graph ? <>
                                                    {
                                                        wellnessGraph.graph.length ?
                                                            <div className='row centered-pie-chart'>
                                                                <div className="col-md-12 px-4 mt-2 mb-4">
                                                                    <span>Your Health Score: </span>
                                                                    <span className='digit-score'>{wellnessGraph.health_score ? wellnessGraph.health_score : 0}</span>
                                                                    <span className='good-score' style={{ color: wellnessGraph.condition === "Bad" ? "rgba(212, 54, 203, 1)" : wellnessGraph.condition === "normal" ? "rgba(208, 0, 13, 1)" : "rgba(60, 188, 165, 1)" }}>
                                                                        {wellnessGraph.condition === "Bad" ? "Bad" : wellnessGraph.condition === "normal" ? "Normal" : "Good"}</span>
                                                                </div>
                                                                <div className='col-md-12 px-4 mt-2 mb-4'>
                                                                    <Bar options={barChartOptions} data={wellnessGraph.barChart} />
                                                                </div>
                                                                <div className="col-md-12 px-4 mt-2 mb-4 text-left">
                                                                    <span className='mr-4'>
                                                                    <i className="fa fa-square" style={{ color: "rgba(70 ,189, 80, 1)" }} aria-hidden="true" /> Your Position
                                                                    </span>
                                                                    <span>
                                                                    <i className="fa fa-square" style={{ color: "rgba(238,92,67, 1)" }} aria-hidden="true" /> Total Users
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-6 px-4 mb-2">
                                                                    <Pie data={pieChartData} options={pieChartoptions} />
                                                                </div>

                                                            </div>
                                                            : <div className='row mt-4'>
                                                                <div className="col-md-12 text-center">
                                                                    No matching record found
                                                                </div>

                                                            </div>
                                                    }
                                                    {wellnessGraph.graph.length ?
                                                        <div className='graph_outer row  justify-content-center'>
                                                            <div className="col-md-10 px-4 mt-2 mb-4">
                                                                <div className="colortext mt-4">
                                                                    <>
                                                                        <p>
                                                                            <i className="fa fa-square" style={{ color: "rgba(60, 188, 165, 1)" }} aria-hidden="true" />
                                                                            <span className="ml-1">Top 20: {wellnessGraph.piechart ? wellnessGraph.piechart.top_20 : 0} People</span>
                                                                        </p>
                                                                        <p>
                                                                            <i className="fa fa-square" style={{ color: "rgba(208, 0, 13, 1)" }} aria-hidden="true" />
                                                                            <span className="ml-1">Top 60: {wellnessGraph.piechart ? wellnessGraph.piechart.top_60 : 0} People </span>
                                                                        </p>
                                                                        <p>
                                                                            <i className="fa fa-square" style={{ color: "rgba(212, 54, 203, 1)" }} aria-hidden="true" />
                                                                            <span className="ml-1">Top 100: {wellnessGraph.piechart ? wellnessGraph.piechart.top_100 : 0} People</span>
                                                                        </p>
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </div> : ''}
                                                </>
                                                    :
                                                    ''}
                                        </>
                                        : ''
                                }
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

function PieChartGraph(props: any) {
    let { percentage, survey_name, created_date, current_date, total_people_responed, options, heading, colors, label, no_of_people_responded, type } = props;
    console.log('props...', props);
    const pieChartoptions = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1,
        resizeDelay: 0,
        plugins: {
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (context: any) {
                        return ` ${context.label}: ${context.parsed} %`;
                    }
                }
            },
            legend: {
                position: 'bottom' as const,
                align: 'start',
                display: false
            },
            title: {
                display: false,
                text: ''
            },
            ...ZOOM_PLUGIN_CHART_OPTIONS
        },
    };
    return (
        <>
            <div className='row centered-pie-chart'>
                <div className="col-12">
                    <table className='table user-details mb-4'>
                        <thead>
                            <tr>
                                <th>Survey Name</th>
                                <th>Survey Created Date</th>
                                <th>Current Date</th>
                                <th>No of People Responded</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-capitalize digit-score">{survey_name}</td>
                                <td className="text-capitalize digit-score">{moment(created_date).format('MMM-DD-YYYY')}</td>
                                <td className='digit-score'>{moment(current_date).format('MMM-DD-YYYY')}</td>
                                <td className='digit-score'>{no_of_people_responded ? no_of_people_responded : total_people_responed}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            {
                percentage.length ? percentage.map((value: any, index: any) => {
                    let pieChartData = {
                        labels: label[index],
                        datasets: [
                            {
                                label: '# of Votes',
                                data: value,
                                backgroundColor: colors[index],
                                borderColor: colors[index],
                                borderWidth: 1,
                            },
                        ],
                    } as any;
                    return (
                        <React.Fragment key={`graph_${index}`}>
                            {
                                value.length ?
                                    <>
                                        <div className="row">
                                            <div className="col-md-12 px-4 mt-2 mb-4 centered-pie-chart">
                                                {
                                                    type === "Question Based" ?
                                                        props.graph ?
                                                            <h3>Question {index + 1}: {props.graph[index].question_name}</h3>
                                                            : ''
                                                        : <h3>{heading[index]}</h3>
                                                }

                                            </div>
                                            <div className="col-md-12 px-4 mb-2">
                                                <div className="row centered-pie-chart">
                                                    <div className="col-md-6">
                                                        <Pie data={pieChartData} options={pieChartoptions} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : ''
                            }
                            <div className='row'>
                                <div className="col-md-10 px-4 mt-2 mb-4">
                                    <div className="colortext mt-4">
                                        {
                                            options.length ?
                                                options[index].map((data: any, key: any) => {
                                                    console.log("options[index]...", data.name.replace("option", "Option "));
                                                    return (
                                                        <p key={`${data.name}_${key}`}>
                                                            <i className="fa fa-square" style={{ color: data.color }} aria-hidden="true" />
                                                            <span className="ml-1">{data.name.replace("option", "Option ")}: {data.value} People</span>
                                                        </p>
                                                    )
                                                })
                                                : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }) :
                    <div className='graph_outer row justify-content-center'>
                        <div className="col-md-10 px-4 mt-2 mb-4">
                            <div className='row mt-5'><div className="col-md-12 text-center">No matching record found</div></div>
                        </div></div>
                // <div className="col-md-10 px-4 mt-2 mb-4 text-center">
                //     No Data Found
                // </div>
            }
        </>
    )
}

export default ReportGraphPublic