import React, { useEffect, useRef, useState } from 'react';
import { Form, Container, Row, Col, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { userService } from '../_services';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { socket } from '../_services/socketio';
import { JitsiMeeting } from '@jitsi/web-sdk';
import { Loader } from './Loader';
import Swal from 'sweetalert2';
import { IMAGE_REGEX } from '../config/constants';
import { jitsiConfig, interfaceJitsi } from '../config/config';
import { returnTotalPages } from '../utils/pagination';

// IMAGE
import ProfileImage from '../images/profile.png';
import VideoErrorIcon from '../images/image.png';

const GroupChat = () => {
    const chatSocket = useRef(null) as any;
    const [show, setShow] = useState("none");
    const [gId, setgId] = useState("") as any;
    const [data, setData] = useState([]) as any;
    const [msgData, setMsgData] = useState([]) as Array<any>;
    const [sendMsg, setMsg] = useState("");
    const [removeMsgs, setRemoveMsgs] = useState([]) as Array<any>;
    const [temp, setTemp] = useState(0);
    const [showCall, setCallModal] = useState(false);
    const [jitsiRoom, setJitsiRoom] = useState("");
    const [leaderBoard, setLeaderboard] = useState([]) as Array<any>;
    const [modalStatus, setModalStatus] = useState(false);
    const [play, setPlay] = useState("") as any;
    const [selectedFile, setSelectedFile] = useState({}) as any;
    const [selectedImage, setSelectedImage] = useState('') as any;
    const [selectedVideo,setSelectedVideo]=useState('');
    const[videoPreviewModal,setVideoPreviewModal]=useState(false);
    const [paginationObj,setPaginationObj]=useState({
        page_no:0,
        total_page:0
    });
    const [isPaginationTrigger,setIsPaginationTrigger]=useState(false);


    const apiRef = useRef(null);
    const formRef=useRef(null);

    let history = useHistory();
    let user_id = localStorage.getItem("user_id") as any;
    let username = localStorage.getItem("username");

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let g_id = params.get("id");
        let txn_id = params.get("transaction_id");
        let paySuccess = params.get("success");
        if (!g_id) {
            toast.error("Error while loading group detail.");
            history.push("/group");
        }

        // socket.current = io(`${config.chatSocket}`, {
        //     reconnection: true,
        //     reconnectionDelay: 1000,
        //     reconnectionDelayMax: 5000,
        //     reconnectionAttempts: Infinity
        // });

        chatSocket.current = socket;

        toggleLoader("flex");

        setgId(g_id);
        let tempBoard = [] as Array<any>;
        userService.groupDetail({ "group_id": g_id, "metric_id": "" }).then(resp => {
            toggleLoader("none");
            setData(resp.data.data);
            if (resp.data.data.leaderBoard) {
                let status=false;
                resp.data.data.leaderBoard.forEach((element: any) => {
                    if(element.member_id === Number(user_id)){
                        status=true;
                    }
                    if (element.member_id != user_id) {
                        tempBoard.push(element.member_id);
                    }
                });
                if(status){
                    setLeaderboard(tempBoard);
                }else{
                    toast.error('This group is no longer available');
                    history.push('/group');
                }
            }else{
                toast.error('This group is no longer available');
                history.push('/group');
            }
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            toggleLoader("none");
            toast.error('Error while loading group detail.');
        });

        chatSocket.current.on('connect', () => {
            console.log('connected to socket');
        })
        chatSocket.current.emit("joinroom", Number(g_id));
        chatSocket.current.emit("joinroom", `VCR-${user_id}`);

        let data = {
            group_id: g_id,
            page_no: `${paginationObj.page_no}`
        };

        getGroupChat(data);

        

        if (paySuccess == "1") {
            var item = localStorage.getItem('paymentDetails') as any
            var paymentObj = JSON.parse(item)
            if (paymentObj.payer_id == user_id && paymentObj.event_id == g_id) {
                var payload = {
                    discount_code: paymentObj.discount_code,
                    payment_id: paymentObj.payment_id,
                    status: "G",
                    transaction_id: txn_id,
                    group_id: paymentObj.event_id
                }
                userService.joinGroup(payload).then(resp => {
                    console.log(resp);
                    if (resp.data.status == true) {
                        toast.success('Payment completed successfully.');
                        localStorage.removeItem("paymentDetails");
                        localStorage.setItem("groupTab", "second");
                        history.push("/group");
                    } else {
                        toast.error(resp.data.message);
                        history.push('/group');
                    }
                }).catch(err => {
                    if (err.response.status == 401) {
                        localStorage.clear();
                        window.location.href = '/';
                    }
                })
            } else {
                toast.error('Invalid payment details');
                history.push('/group');
            }
        }
        let element=document.getElementById("messageCompose");
        if(element){
            window.scrollTo(0,element?.clientHeight);
        }
    }, [])

    function scrollToBottom() {
        var objDiv = document.getElementById('chatBox') as any;
        if (objDiv) {
            objDiv.scrollTop = objDiv?.scrollHeight;
        }
    }


    function getGroupChat(params:any){
        userService.getGroupChat(params).then(resp => {
            if (resp.data.data) {
                if (paginationObj.page_no > 0) {
                    if(msgData.length){
                        msgData.unshift(...(resp.data.data.reverse()));
                        setMsgData(msgData);
                        let element = document.getElementById('chatBox') as HTMLBodyElement;
                        if (element) {
                            element.scrollTo({
                                top: 60,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }
                    }else{
                        setMsgData(resp.data.data.reverse());
                        scrollToBottom();   
                    }
                    let totalPages = returnTotalPages(resp.data.total_record, 10);
                    handlePagination('total_page', totalPages);
                    // scrollToBottom();
                } else {
                    // resp.data.data.reverse();
                    setMsgData(resp.data.data.reverse());
                    scrollToBottom();
                    let totalPages = returnTotalPages(resp.data.total_record, 10);
                    handlePagination('total_page', totalPages);
                }
            } else {
                setMsgData([]);
            }
            setIsPaginationTrigger(false);
            setShow("none");          
        }).catch(err => {
            setShow("none");
            setIsPaginationTrigger(false);       
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            console.log('error loading chat.');
        })
    }

    function msgType(msg: any) {
        if (msg.message_type == 'F') {
            if (msg.message.toLowerCase().endsWith("png") || msg.message.toLowerCase().endsWith("jpg") || msg.message.toLowerCase().endsWith("jpeg")) {
                return (
                    <button type="button" className="btn btn-link" onClick={() => handlePlay(msg.message)}>
                        <img className='message-image' src={msg.message} alt="message" width={200} onError={(event: any) => { event.target.src = ProfileImage; }} />
                    </button>
                )
            }
            if (msg.message.toLowerCase().endsWith("doc") || msg.message.toLowerCase().endsWith("docx")) {
                return (
                    <a href={msg.message} target="_blank" rel="noreferrer noopener" title={msg.message.replace("https://dev-nlpbackend.xcellentlife.com/chat/", "")}>
                        <img width={150} src={require('../images/word.png').default} alt="word" />
                    </a>
                )
            }
            if (msg.message.toLowerCase().endsWith("xls") || msg.message.toLowerCase().endsWith("xlsx")) {
                return (
                    <a href={msg.message} target="_blank" rel="noreferrer noopener" title={msg.message.replace("https://dev-nlpbackend.xcellentlife.com/chat/", "")}>
                        <img width={150} src={require('../images/excel.png').default} alt="excel" />
                    </a>
                )
            }
            if (msg.message.toLowerCase().endsWith("ppt") || msg.message.toLowerCase().endsWith("pptx")) {
                return (
                    <a href={msg.message} target="_blank" rel="noreferrer noopener" title={msg.message.replace("https://dev-nlpbackend.xcellentlife.com/chat/", "")}>
                        <img width={150} src={require('../images/ppt.png').default} alt="ppt" />
                    </a>
                )
            }
            if (msg.message.toLowerCase().endsWith("pdf")) {
                return (
                    <a href={msg.message} target="_blank" rel="noreferrer noopener" title={msg.message.replace("https://dev-nlpbackend.xcellentlife.com/chat/", "")}>
                        <img width={150} src={require('../images/pdf.jpg').default} alt="pdf" />
                    </a>
                )
            }
            if (msg.message.toLowerCase().endsWith("mov") || msg.message.toLowerCase().endsWith("mp4")) {
                console.log('msg.thumbnail', msg);
                return (
                    <button type="button" className="btn btn-link" onClick={() => handlePlay(msg.message)} style={{ position: "relative" }}>
                        <img width={80}
                            src={msg.thumbnail ? msg.thumbnail === "undefined" ? VideoErrorIcon : msg.thumbnail : VideoErrorIcon}
                            alt="thumbnail"
                            onError={(event: any) => { event.target.src = VideoErrorIcon }}
                            className={msg.thumbnail ? msg.thumbnail === "undefined" ? "view-error-image" : "view-image" : "view-error-image"}
                            title={msg.thumbnail ? msg.thumbnail === "undefined" ? "Thumbnail error" : msg.message.replace("https://dev-nlpbackend.xcellentlife.com/chat/", "") : "Thumbnail error"}
                        />
                        <img width={80} className="play-box-bt" src={require('../images/play.png').default} alt="video" />
                    </button>
                )
            }
        }
        else {
            return (
                <p className="user_msg">{msg.message}</p>
            )
        }
    }

    if (chatSocket.current) {
        chatSocket.current.on("groupmessage", (recvData: any) => {
            let recvMsgId = recvData.group_msg_id
            var temp = msgData
            var findMsg = true
            var msg = recvData.message
            if (temp.length) {
                findMsg = temp.find((el: any) => el.group_msg_id == recvMsgId)
            } else {
                findMsg = false
            }
            if (!findMsg) {
                if (msg.endsWith("mp4")) {
                    recvData.thumbpath = msg.replace("mp4", "png");
                }
                if (msg.endsWith("mov")) {
                    recvData.thumbpath = msg.replace("mov", "png");
                }
                recvData.timeAgo = 'just now';
                temp.push(recvData);
                setMsgData(temp);
                toggleLoader("none");
                scrollToBottom();
            }
        })
        chatSocket.current.on("deleteGroupMessages", (recvData: any) => {
            var msgs = recvData.messsage_ids.split(",");
            var tempmsgs = msgData;
            msgs.forEach((el: any) => {
                for (let index = 0; index < tempmsgs.length; index++) {
                    const element = tempmsgs[index];
                    if (element.group_msg_id == el) {
                        tempmsgs.splice(index, 1)
                    }
                }
            });
            setMsgData(tempmsgs)
            setRemoveMsgs([] as Array<any>)
            setTemp(0)
            scrollToBottom()
        })
    }


    useEffect(()=>{
        if(isPaginationTrigger){
            getGroupChat({
                group_id: gId,
                page_no: `${paginationObj.page_no}`
            });
        }
    },[isPaginationTrigger]);


        // Common function for handling pagination
        function handlePagination(type:any,value:any){
            setPaginationObj((previousPaginationObj:any)=>{
                return{
                    ...previousPaginationObj,
                    [type]:value
                }
            })
        }


    function sendMessage() {
        if (selectedFile.length) {
            handleFileUpload();
        } else {
            if (!sendMsg || sendMsg.trim() == "") {
                setMsg("")
                toast.error('Cannot send empty message.')
                return false
            }
            var options = {
                "message": sendMsg.trim(),
                "firstname": username?.split(" ")[0],
                "profileImage": localStorage.getItem("profileImage"),
                "message_type": "T",
                "user_id": Number(user_id),
                "group_id": Number(gId),
            }
            // chatSocket.current.emit("groupmessage", options)
            emitGroupMessage(options);
            chatSocket.current.on("groupmessage", (recvData: any) => {
                let recvMsgId = Number(recvData.group_msg_id);
                var temp = msgData;
                var findMsg = true;
                if (temp.length) {
                    findMsg = temp.find((el: any) => el.group_msg_id == recvMsgId);
                } else {
                    findMsg = false;
                }
                if (!findMsg) {
                    recvData.timeAgo = 'just now';
                    temp.push(recvData);
                    setMsgData(temp);
                    scrollToBottom();
                }
            })
            let formDom=document.getElementById("messageCompose") as any;
            formDom.reset();
            setMsg("");
        }
    }

    function handleKeyPress(e: any) {
        if (e.which == 13) {
            sendMessage();
        }
    }

    function handleFile(e: any) {
        let validExts = ["png", "jpg", "jpeg", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf", "mov", "mp4"];
        if (e.target.value) {
            let fName = e.target.files[0].name;
            var ext = fName.split(".").at(-1).toLowerCase();
            var findExt = validExts.find((el: any) => el == ext);
            if (!findExt) {
                toast.error("Only png, jpg, jpeg, doc, docx, xls, xlsx, ppt, pptx, pdf, mov, mp4 formats are allowed");
                e.preventDefault()
                return false
            } else {
                returnFileType(e.target.files);
                setSelectedFile(e.target.files);
            }
        }
        // e.target.value="";
    }

    function returnFileType(e: any) {
        let fName = e[0].name;
        let ext = fName.split(".").at(-1).toLowerCase();
        if (returnFileTypeExtension(ext) === "image"||returnFileTypeExtension(ext) === "video") {
            var reader = new FileReader();
            reader.onload = function (event: any) {
                if(returnFileTypeExtension(ext) === "video"){
                    setSelectedVideo(event.target.result);
                    toggleVideoPreview(true);
                }else{
                    setSelectedImage(event.target.result);
                }
            }
            reader.readAsDataURL(e[0]);
        }
        if (returnFileTypeExtension(ext) === "doc") {
            setSelectedImage(require('../images/word.png').default);
        }
        if (returnFileTypeExtension(ext) === "xls") {
            setSelectedImage(require('../images/excel.png').default);
        }
        if (returnFileTypeExtension(ext) === "ppt") {
            setSelectedImage(require('../images/ppt.png').default);
        }
        if (returnFileTypeExtension(ext) === "pdf") {
            setSelectedImage(require('../images/pdf.jpg').default);
        }
        // if (returnFileTypeExtension(ext) === "video") {
        //     setSelectedImage(require('../images/videocall.svg').default);
        // }
    }

    function returnFileTypeExtension(ext: any) {
        if (['png', 'jpg', 'jpeg', 'gif'].includes(ext)) {
            return "image";
        }
        if (["doc", "docx"].includes(ext)) {
            return "doc";
        }
        if (["xls", "xlsx"].includes(ext)) {
            return "xls";
        }
        if (["ppt", "pptx"].includes(ext)) {
            return "ppt";
        }
        if (["pdf"].includes(ext)) {
            return "pdf";
        }
        if (["mov", "mp4"].includes(ext)) {
            return "video"
        }
    }


    useEffect(() => {
        if (selectedImage) {
            triggerImage();
        }
    }, [selectedImage]);


    function handleFileUpload() {
        toggleLoader("flex");
        let formData = new FormData();
        formData.append("file", selectedFile[0])
        formData.append("user_id", user_id)
        userService.uploadFile(formData).then(resp => {
            // e.target.value = ""
            if (resp.data.data.status) {
                var uploadedFile = resp.data.data.filepath
                var options = {
                    "message": uploadedFile,
                    "firstname": username?.split(" ")[0],
                    "profileImage": localStorage.getItem("profileImage"),
                    "message_type": "F",
                    "user_id": Number(user_id),
                    "group_id": Number(gId),
                } as any;
                if (resp.data.data.thumbpath || resp.data.data.thumbshortpath) {
                    options.thumbnail = resp.data.data.thumbpath ? resp.data.data.thumbpath : resp.data.data.thumbshortpath
                }
                emitGroupMessage(options);
                // chatSocket.current.emit("groupmessage", options)
                chatSocket.current.on("groupmessage", (recvData: any) => {
                    let recvMsgId = recvData.group_msg_id
                    var temp = msgData
                    var findMsg = true
                    var msg = recvData.message
                    if (temp.length) {
                        findMsg = temp.find((el: any) => el.group_msg_id == recvMsgId)
                    } else {
                        findMsg = false
                    }
                    if (!findMsg) {
                        if (msg.endsWith("mp4")) {
                            recvData.thumbpath = msg.replace("png")
                        }
                        if (msg.endsWith("mov")) {
                            recvData.thumbpath = msg.replace("png")
                        }
                        recvData.timeAgo = 'just now'
                        temp.push(recvData)
                        setMsgData(temp)
                        scrollToBottom()
                        // e.target.value = ""
                    }
                })
                removeFile();
                // e.target.value = ""
            } else {
                toast.error('An error occured.')
            }
            toggleLoader("none");
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            toast.error("An error occured while uploading file.")
            toggleLoader("none");
        })
    }

    function handleRemoveMsgs() {
        if (!removeMsgs.length) {
            toast.error('Please select messages to delete.')
            return false
        }
        toggleLoader("flex");

        var options = {
            group_id: Number(gId),
            user_id: localStorage.getItem("user_id"),
            messsage_ids: removeMsgs.toString(),
            sender_id: localStorage.getItem("user_id"),
            firstname: localStorage.getItem("username"),
            profileImage: localStorage.getItem("profileImage"),
            message_type: ""
        }

        var sckt = chatSocket.current.emit("deleteGroupMessages", options)
        if (sckt.connected) {
            var tempmsgs = msgData
            removeMsgs.forEach((el: any) => {
                for (let index = 0; index < tempmsgs.length; index++) {
                    const element = tempmsgs[index];
                    if (element.group_msg_id == el) {
                        tempmsgs.splice(index, 1)
                    }
                }
            });
            setMsgData(tempmsgs);
            setRemoveMsgs([] as Array<any>);
            setTemp(0);
            scrollToBottom();
        }

        chatSocket.current.on("deleteGroupMessages", (recvData: any) => {
            var msgs = recvData.messsage_ids.split(",");
            var tempmsgs = msgData;
            msgs.forEach((el: any) => {
                for (let index = 0; index < tempmsgs.length; index++) {
                    const element = tempmsgs[index];
                    if (element.group_msg_id == el) {
                        tempmsgs.splice(index, 1)
                    }
                }
            });
            setMsgData(tempmsgs)
            setRemoveMsgs([] as Array<any>)
            setTemp(0)
            scrollToBottom()
        });
        toggleLoader("none");
    }

    function handleCheckbox(e: any, msgId: any) {
        var tempmsgs = removeMsgs
        let checked = e.target.checked
        let msg_id = Number(msgId)
        if (checked) {
            if (!tempmsgs.includes(msg_id)) {
                tempmsgs.push(msg_id)
            }
        } else {
            for (let index = 0; index < tempmsgs.length; index++) {
                const element = tempmsgs[index];
                if (element == msg_id) {
                    tempmsgs.splice(index, 1)
                }
            }
        }
        setRemoveMsgs(tempmsgs)
        setTemp(temp + 1)
    }

    function keyGen() {
        var i, key = "", characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

        var charactersLength = characters.length;

        for (i = 0; i < 10; i++) {
            key += characters.substr(Math.floor((Math.random() * charactersLength) + 1), 1);
        }
        var dt = new Date().getTime()
        return key + String(dt);
    }

    function startCall() {
        let param = {
            "calltype": "G",
            "caller_id": user_id,
            "group_id": gId,
            "caller_name": username,
            "room_id": keyGen(),
            "call_event": "Start Call",
            "receiver_name": data.group_name,
            "receiver_id": leaderBoard.toString()
        };
        if (chatSocket.current) {
            chatSocket.current.emit("startcalls", param);
            chatSocket.current.on("startcalls", (r: any) => {
                if (r.call_status == 1) {
                    setCallModal(true);
                    setJitsiRoom(r.room_id);
                } else {
                    toast.error(r.error_msg, { hideProgressBar: true });
                }
            })
        }
    }

    function toggleLoader(status: any) {
        setShow(status)
    }

    function emitGroupMessage(options: any) {
        chatSocket.current.emit("groupmessage", options)
    }

    function handleApiReady(apiObj: any, ref: any) {
        ref.current = apiObj;
        ref.current.addEventListener("readyToClose", function (props: any) {
            chatSocket.current.emit("endcall", { room_id: jitsiRoom, calltype: "G", caller_id: user_id, call_event: "End Call" });
            chatSocket.current.on("endcall", (resp: any) => {
                if (resp.call_status == 1) {
                    setCallModal(false)
                    setJitsiRoom("")
                }
            })
            // setCallModal(false);
            // setJitsiRoom("");
        })
        ref.current.addEventListener("participantLeft", function (props: any) {
            chatSocket.current.emit("endcall", { room_id: jitsiRoom, calltype: "G", caller_id: user_id, call_event: "End Call" });
            chatSocket.current.on("endcall", (resp: any) => {
                if (resp.call_status == 1) {
                    setCallModal(false)
                    setJitsiRoom("")
                }
            })
        })
    }

    function handlePlay(url: any) {
        setPlay(url);
        toggleModal(true);
    }


    function confirmDeleteMessage() {
        Swal.fire({
            text: `Are you sure you want to delete the selected message${removeMsgs.length > 1 ? "(s)" : ""}.`,
            title: "Alert",
            showCancelButton: true,
            confirmButtonText: "Delete"
        }).then(e => {
            if (e.value) {
                handleRemoveMsgs()
            }
        })
    }

    function toggleModal(status: any) {
        setModalStatus(status);
    }

    function removeFile() {
        setSelectedFile({});
        setSelectedImage('');
        toggleVideoPreview(false);
        setSelectedVideo(''); 
    }


    function triggerImage() {
        Swal.fire({
            title:selectedImage.includes("video")?"":'Preview Image',
            imageUrl: selectedImage,
            imageAlt: 'Custom image',
            customClass:{
                image:'imagePreviewmessage'
            },
            showCancelButton: true,
            confirmButtonText: "Send"
        }).then(e => {
            if (e.value) {
                sendMessage();
            } else {
                removeFile();
            }
        })
    }

    function handleMessageSubmit(event:any){
        event.preventDefault();
        sendMessage();
    }

    const handleJitsiIFrameRef = (iframeRef: any) => {
        iframeRef.style.border = '10px solid cadetblue';
        iframeRef.style.background = 'cadetblue';
        iframeRef.style.height = '450px';
    };

    function toggleVideoPreview(status:any){
        setVideoPreviewModal(status)
    }

// handle scroll when scroll bar of message section
    function handleScrollInput(event:any){
        console.log('scrollHeight',event.target.scrollHeight);
        console.log('offsetHeight',event.target.offsetHeight);
        console.log('scrollTop',event.currentTarget.scrollTop);
        
        if(event.currentTarget.scrollTop === 0){
            if(paginationObj.page_no+1<paginationObj.total_page){      
                setShow("flex");          
                setIsPaginationTrigger(true);
                handlePagination('page_no',paginationObj.page_no+1);
            }
        }

    }


    return (
        <>
            <section className="bodyvital_section py-5">
                <Loader status={show} />
                <Container>
                    <div className="inner_box pb-0">
                        {showCall ? <Row>
                            <Col md={12} className="mb-3">
                                <JitsiMeeting configOverwrite={jitsiConfig} interfaceConfigOverwrite={interfaceJitsi} roomName={jitsiRoom} userInfo={{ displayName: username as any, email: "" }} getIFrameRef={handleJitsiIFrameRef} domain="meet.jit.si" onApiReady={externalApi => handleApiReady(externalApi, apiRef)} />
                            </Col>
                        </Row> : ""}
                        <Row className="bodyvitals_head">
                            <Col md={6}>
                                <h5><span onClick={() => { history.go(-1) }}><img src={require('../images/back.svg').default} alt="back" /></span></h5>
                                <h2>{data.group_name}</h2>
                            </Col>
                            <Col md={6} className="d-flex justify-content-end align-items-center">
                                {
                                    removeMsgs.length ?
                                        <span className="newrqst1 mr-4">
                                            {`Selected ${removeMsgs.length} ${removeMsgs.length > 1 ? 'messages' : 'message'}`}
                                        </span>
                                        : ''
                                }
                                {removeMsgs.length ? <h5 onClick={confirmDeleteMessage} className="c-pointer">
                                    <i style={{ border: "1px solid lightgrey", padding: "15px 19px", borderRadius: "100%" }} className="fa fa-trash nextarrow mx-1 text-danger" />
                                </h5> : ""}
                                {!showCall ? <span onClick={() => { startCall() }} className="border-0 bg-transparent mx-1">
                                    <img src={require('../images/videocall.svg').default} alt="videocall" />
                                </span> : ""}
                                <Link className="border-0 bg-transparent mx-1" to={"/invite_group?id=" + data.group_id} ><img src={require('../images/infoicon.svg').default} alt="info" /></Link>
                                {data.created_id == Number(user_id) ?
                                    <Link className="border-0 bg-transparent mx-1" to={`/editgroup?id=${data.group_id}`}>
                                        <img src={require('../images/edit_icon.svg').default} alt="edit" />
                                    </Link> : ""}
                            </Col>
                            <Col md={12}><hr /></Col>
                        </Row>

                        <Row>
                            <div className="chat_row">
                                <div className="chat_box" id="chatBox" onScroll={(event:any)=>{handleScrollInput(event)}}>
                                    {msgData.length
                                        ?
                                        msgData.map((msg: any, index: any) => {
                                            return (
                                                <React.Fragment key={`${msg.user_id}_${index}`}>
                                                    {
                                                        msg.user_id == Number(user_id)
                                                            ?
                                                            <div className="leftside_chat right_side_user">
                                                                <div className="user_name_p">
                                                                    <h5 className="text-right">You</h5>
                                                                    <div className="check-chk">
                                                                        {msgType(msg)}
                                                                        <Form>
                                                                            <Form.Control checked={removeMsgs.includes(Number(msg.group_msg_id)) ? true : false} onChange={(e: any) => { handleCheckbox(e, msg.group_msg_id) }} type="checkbox" className="chk" />
                                                                        </Form>
                                                                    </div>
                                                                    <div className="check-chk">
                                                                        <div className="user_img right"><img src={msg.profileImage} alt="profile" onError={(event: any) => { event.target.src = ProfileImage; }} /></div>
                                                                        <p className="msg_time">{msg.timeAgo}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="leftside_chat">
                                                                <div className="user_img"><img src={msg.profileImage} alt="profile" onError={(event: any) => { event.target.src = ProfileImage; }} /></div>
                                                                <div className="user_name_p">
                                                                    <h5>{msg.firstname} {msg.lastname}</h5>
                                                                    {msgType(msg)}
                                                                    <p className="msg_time">{msg.timeAgo}</p>
                                                                </div>
                                                            </div>
                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                        :
                                        <div className="no_chat_box">
                                            <img src={require('../images/no_msg.svg').default} alt="nomessage" />
                                        </div>
                                    }
                                </div>
                                {/* {
                                    selectedFile.length ?
                                        <div className="selected_file_section" id="selectedFile">
                                            <div className='selected_image'>
                                                <i className="fa fa-times" aria-hidden="true" onClick={() => removeFile()} />
                                                <img src={selectedImage} alt="file" />
                                            </div>
                                        </div>
                                        : ''
                                } */}
                                <div className="send_message_row">
                                    <form autoComplete='off' id="messageCompose" onSubmit={(event: any) => handleMessageSubmit(event)}>
                                        <span>
                                            <img src={require('../images/add.svg').default} alt="add" />
                                            <input style={{ cursor: "pointer" }} type="file" className="custom-form-file" onClick={(e: any) => { e.target.value = "" }} onChange={handleFile} />
                                        </span>
                                        {/* onKeyPress={(e: any) => { handleKeyPress(e) }} */}
                                        {/* <input type="text" value={sendMsg} placeholder="Enter Your Message" onChange={(e: any) => { setMsg(e.target.value) }} /> */}
                                        {/* IMPORTANT CODE 20/01/2023 */}
                                        <textarea value={sendMsg} placeholder="Enter Your Message" rows={3} cols={50} onKeyPress={(e: any) => { handleKeyPress(e) }} onChange={(e: any) => { setMsg(e.target.value) }} />
                                        <button type="submit" className="btn pr-3"><img src={require('../images/send.svg').default} alt="send" /></button>
                                    </form>
                                </div>
                            </div>
                        </Row>
                    </div>
                </Container>
            </section>

            <Modal show={modalStatus} onHide={() => toggleModal(!modalStatus)} size="md" centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton className="border-0 headerclose" />
                <Modal.Body className="text-center py-4">
                    <div className="container_fluid mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                {IMAGE_REGEX.test(`.${play.split('/').pop().split('.').pop()}`) ? <img src={play} className="modal_image" alt={`competition`} /> : <video src={play} controls={true} autoPlay={false} className="modal_video" />}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={videoPreviewModal} onHide={() => toggleVideoPreview(!videoPreviewModal)} size="md" centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton className="border-0 headerclose"/>
                <Modal.Body className="text-center py-4">
                <div className="container_fluid mt-4">
                        <div className="row">
                            <div className="col-md-12">
                            <video src={selectedVideo} controls={true} autoPlay={false} className="modal_video" />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-12 justify-content-center">
                            <button type="button" className="swal2-confirm swal2-styled" onClick={()=>sendMessage()} style={{display: 'inline-block', borderLeftColor: 'rgb(48, 133, 214)', borderRightColor: 'rgb(48, 133, 214)'}}>Send</button>
                            <button type="button" onClick={() => toggleVideoPreview(!videoPreviewModal)} className="swal2-cancel swal2-styled" style={{display: 'inline-block'}}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default GroupChat;