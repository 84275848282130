// import * as React from "react";
// import store from './store';
// import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Layout } from './components/common/Layout';
// import Web3Provider,{ Connectors } from 'web3-react';
import Home from './components/Home';
import Survey from './components/Survey';
import WebForm from './components/webform';
import HomeNew from './components/Thankyou';
import Footer from './components/common/Footer';
import Login from './components/login';
import ForgotPassword from './components/ForgotPassword';
import NewPassword from './components/NewPassword';
import SignUp from './components/signup';
import CreateProfile from './components/CreateProfile';
import EditProfile from './components/EditProfile';
import Step5 from './components/create_profile/Step5';
import Dashboard from './components/Dashboard';
import Bloodwork from './components/Bloodwork';
import Bodyvitals from './components/Bodyvitals';
import Lifestyle from './components/Lifestyle';
import Mood from './components/Mood';
import AddPrescription from './components/AddPrescription';
import Setting from './components/Setting';
import GroupChat from './components/GroupChat';
import Competition from './components/Competition';
import InviteGroup from './components/InviteGroup';
import MedicalRecord from "./components/MedicalRecord";
import JoinedGroupChat from './components/JoinedGroupChat';
import Profile from "./components/Profile";
import Group from './components/Group';
import JoinedInviteGroup from './components/JoinedInviteGroup';
import Creategroup from './components/Creategroup';
import Inviteusers from './components/Inviteusers';
import InviteSubGroup from './components/InviteSubGroup';
import Competitiondetails from './components/Competitiondetails';
import Leaderboard from './components/Leaderboard';
import Friends from './components/Friends';
import Friendrequest from './components/Friendrequest';
import Chatlist from './components/Chatlist';
import MainChat from './components/MainChat';
import Notification from './components/Notification';
import Alert from './components/Alert';
import Connect from './components/Connect';
import Bodyvitalsgraph from "./components/Bodyvitlasgraph";
import Bloodworkgraph from "./components/Bloodworkgraph";
import Moodgraph from "./components/Moodgraph";
import Lifestylegraph from "./components/Lifestylegraph";
import LiveCompetition from "./components/LiveCompetition";
import Details from "./components/Details";
import FriendChat from "./components/FriendChat";
import Editgroup from "./components/EditGroup";
import FriendsReqList from "./components/FriendsReqList";
import AddFriends from "./components/AddFriends";
import Sendrequest from "./components/SendRequest";
import ViewUser from "./components/ViewUser";
import Heatmap from "./components/Heatmap";
import HeatMapGraph from "./components/HeatMapGraph";
import AddReport from "./components/AddReport";
import ReportGraphPublic from "./components/ReportGraphPublic";
import SavedCards from "./components/SavedCards";
import AddBank from "./components/AddBank";
import AdShareVideo from './components/AdShareVideo';
import LinkedInCallBack from './components/LinkedInCallBack';
import MetricAlert from './components/MetricAlert';
import MemberShipPlan from './components/MemberShipPlan';
import AcceptReject from './components/AcceptReject';
import PageNotFound from './components/PageNotFound';
// import InputGroupWithExtras from "react-bootstrap/esm/InputGroup";
// import { ToastContainer, toast } from 'react-toastify';
// CSS
import './css/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-circular-progressbar/dist/styles.css';
import "react-datepicker/dist/react-datepicker.css";

const accesstoken = localStorage.getItem('accesstoken');

const WithLoginHeader = (props: any) => {
  return (
    <Layout type="loggedin">
      {props.children}
    </Layout>
  )
};
const WithHeader = (props: any) => {
  return (
    <Layout type="landing">
      {props.children}
    </Layout>
  )
};
const WithFormHeader = (props: any) => {
  return (
    <Layout type="form">
      {props.children}
    </Layout>
  )
};
const WithoutHeader = (props: any) => {
  return (
    <Layout type="guest">
      {props.children}
    </Layout>
  )
};

const App = () => {

  return (
    <Router>
      <Switch>
        <Route exact={true} path="/symptoms/:id" component={(props: any) => <WithFormHeader>{<Home {...props} />}</WithFormHeader>} />
        <Route exact={true} path="/survey/:id" component={(props: any) => <WithFormHeader>{<Survey {...props} />}</WithFormHeader>} />
        <Route exact={true} path="/webform/:id" component={(props: any) => <WithFormHeader>{<WebForm {...props} />}</WithFormHeader>} />
        <Route exact={true} path="/thankyou" component={() => <WithFormHeader>{<HomeNew />}</WithFormHeader>} />

        <Route exact={true} path="/login" component={() => <WithHeader>{<Login />}</WithHeader>} />
        <Route exact={true} path="/forgot_password" component={() => <WithHeader>{<ForgotPassword />}</WithHeader>} />
        <Route exact={true} path="/new_password" component={() => <WithHeader>{<NewPassword />}</WithHeader>} />
        {accesstoken ? (
          <Route exact={true} path="/create_profile" component={() => <WithLoginHeader>{<CreateProfile />}</WithLoginHeader>} />
        ) : (<Route exact={true} path="/create_profile" component={() => <WithHeader>{<CreateProfile />}</WithHeader>} />)}
        <Route exact={true} path="/signup" component={() => <WithHeader>{<SignUp />}</WithHeader>} />

        <Route exact={true} path="/edit_profile" component={() => <WithLoginHeader>{<EditProfile />}</WithLoginHeader>} />

        <Route exact={true} path="/" component={() => <WithLoginHeader>{<Dashboard />}</WithLoginHeader>} />
        <Route exact={true} path="/report" component={() => <WithLoginHeader>{<Dashboard />}</WithLoginHeader>} />
        <Route exact={true} path="/Profile" component={() => <WithLoginHeader>{<Profile />}</WithLoginHeader>} />
        <Route exact={true} path="/bodyvitals" component={() => <WithLoginHeader>{<Bodyvitals />}</WithLoginHeader>} />
        <Route exact={true} path="/bloodwork" component={() => <WithLoginHeader>{<Bloodwork />}</WithLoginHeader>} />
        <Route exact={true} path="/lifestyle" component={() => <WithLoginHeader>{<Lifestyle />}</WithLoginHeader>} />
        <Route exact={true} path="/mood" component={() => <WithLoginHeader>{<Mood />}</WithLoginHeader>} />
        <Route exact={true} path="/add_prescription" component={() => <WithLoginHeader>{<AddPrescription />}</WithLoginHeader>} />
        <Route exact={true} path="/medical_record" component={() => <WithLoginHeader>{<MedicalRecord />}</WithLoginHeader>} />
        <Route exact={true} path="/profile" component={() => <WithLoginHeader>{<Profile />}</WithLoginHeader>} />
        <Route exact={true} path="/step5" component={() => <WithHeader>{<Step5 />}</WithHeader>} />
        <Route exact={true} path="/setting" component={() => <WithLoginHeader>{<Setting />}</WithLoginHeader>} />
        <Route exact={true} path="/group" component={() => <WithLoginHeader>{<Group />}</WithLoginHeader>} />
        <Route exact={true} path="/group_chat" component={() => <WithLoginHeader>{<GroupChat />}</WithLoginHeader>} />
        <Route exact={true} path="/invite_group" component={() => <WithLoginHeader>{<InviteGroup />}</WithLoginHeader>} />
        <Route exact={true} path="/joinedgroup_chat" component={() => <WithLoginHeader>{<JoinedGroupChat />}</WithLoginHeader>} />
        <Route exact={true} path="/join_group" component={() => <WithLoginHeader>{<JoinedInviteGroup />}</WithLoginHeader>} />
        <Route exact={true} path="/creategroup" component={() => <WithLoginHeader>{<Creategroup />}</WithLoginHeader>} />
        <Route exact={true} path="/inviteusers" component={() => <WithLoginHeader>{<Inviteusers />}</WithLoginHeader>} />
        <Route exact={true} path="/invitegroup" component={() => <WithLoginHeader>{<InviteSubGroup />}</WithLoginHeader>} />
        <Route exact={true} path="/competition" component={() => <WithLoginHeader>{<Competition />}</WithLoginHeader>} />
        <Route exact={true} path="/competitiondetails" component={() => <WithLoginHeader>{<Competitiondetails />}</WithLoginHeader>} />
        <Route exact={true} path="/leaderboard" component={() => <WithLoginHeader>{<Leaderboard />}</WithLoginHeader>} />
        <Route exact={true} path="/friends" component={() => <WithLoginHeader>{<Friends />}</WithLoginHeader>} />
        <Route exact={true} path="/friendrequest" component={() => <WithLoginHeader>{<Friendrequest />}</WithLoginHeader>} />
        <Route exact={true} path="/friendrequestlist" component={() => <WithLoginHeader>{<FriendsReqList />}</WithLoginHeader>} />
        <Route exact={true} path="/addfriends" component={() => <WithLoginHeader>{<AddFriends />}</WithLoginHeader>} />
        <Route exact={true} path="/sendfriendrequest" component={() => <WithLoginHeader>{<Sendrequest />}</WithLoginHeader>} />
        <Route exact={true} path="/chatlist" component={() => <WithLoginHeader>{<Chatlist />}</WithLoginHeader>} />
        <Route exact={true} path="/main_chat" component={() => <WithLoginHeader>{<MainChat />}</WithLoginHeader>} />
        <Route exact={true} path="/notification" component={() => <WithLoginHeader>{<Notification />}</WithLoginHeader>} />
        <Route exact={true} path="/alert" component={() => <WithLoginHeader>{<Alert />}</WithLoginHeader>} />
        <Route exact={true} path="/connect" component={() => <WithLoginHeader>{<Connect />}</WithLoginHeader>} />
        <Route exact={true} path="/bodygraph" component={() => <WithLoginHeader>{<Bodyvitalsgraph />}</WithLoginHeader>} />
        <Route exact={true} path="/bloodgraph" component={() => <WithLoginHeader>{<Bloodworkgraph />}</WithLoginHeader>} />
        <Route exact={true} path="/moodgraph" component={() => <WithLoginHeader>{<Moodgraph />}</WithLoginHeader>} />
        <Route exact={true} path="/lifegraph" component={() => <WithLoginHeader>{<Lifestylegraph />}</WithLoginHeader>} />
        <Route exact={true} path="/live_competition" component={() => <WithLoginHeader>{<LiveCompetition />}</WithLoginHeader>} />
        <Route exact={true} path="/live_group" component={() => <WithLoginHeader>{<JoinedInviteGroup />}</WithLoginHeader>} />
        <Route exact={true} path="/details" component={() => <WithLoginHeader>{<Details />}</WithLoginHeader>} />
        <Route exact={true} path="/friendchat" component={() => <WithLoginHeader>{<FriendChat />}</WithLoginHeader>} />
        <Route exact={true} path="/editgroup" component={() => <WithLoginHeader>{<Editgroup />}</WithLoginHeader>} />
        <Route exact={true} path="/viewuser" component={() => <WithLoginHeader>{<ViewUser />}</WithLoginHeader>} />
        <Route exact={true} path="/report/list" component={() => <WithLoginHeader>{<Heatmap />}</WithLoginHeader>} />
        <Route exact={true} path="/report/add" component={() => <WithLoginHeader>{<AddReport />}</WithLoginHeader>} />
        <Route exact={true} path="/report/graph" component={() => <WithLoginHeader>{<HeatMapGraph />}</WithLoginHeader>} />
        <Route exact={true} path="/saved_cards" component={() => <WithLoginHeader>{<SavedCards />}</WithLoginHeader>} />
        <Route exact={true} path="/payment" component={() => <WithLoginHeader>{<SavedCards />}</WithLoginHeader>} />
        <Route exact={true} path="/report/public/graph" component={() => <ReportGraphPublic />} />
        <Route exact={true} path="/addBank" component={() => <WithLoginHeader>{<AddBank />}</WithLoginHeader>} />
        <Route exact={true} path="/group_payment" component={() => <WithLoginHeader>{<AddBank />}</WithLoginHeader>} />
        <Route exact={true} path="/adshare/video" component={() => <WithFormHeader>{<AdShareVideo />}</WithFormHeader>} />
        <Route exact={true} path="/auth/linkedin/callback" component={() => <WithFormHeader>{<LinkedInCallBack />}</WithFormHeader>} />
        <Route exact={true} path="/metricalert" component={() => <WithLoginHeader><MetricAlert /></WithLoginHeader>} />
        <Route exact={true} path="/membership/plan" component={() => <WithLoginHeader><MemberShipPlan/></WithLoginHeader>}/>
        <Route exact={true} path="/membership/current" component={() => <WithLoginHeader><MemberShipPlan/></WithLoginHeader>}/>
        <Route exact={true} path="/viewrequest" component={() => <WithLoginHeader><AcceptReject/></WithLoginHeader>}/>
        <Route path="*" component={() => <WithLoginHeader><PageNotFound/></WithLoginHeader>}/>
      </Switch>
      <Footer />
    </Router>
  );
};

export default App;