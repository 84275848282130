/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import { Line } from 'react-chartjs-2'
import { userService } from '../_services';
import { Loader } from './Loader';

const Lifestylegraph = () => {
  const [healthSummary, setHealthSummary] = useState() as any
  const [graphData, setgraphData] = useState('week') as any
  const [showloader, setShowloader] = useState({ display: "none" });
  let pressurelabels: any = [];
  let heartlabels: any = [];
  let heartdata: any = [];
  let heartColors: any = [];
  let pressured: any = [];
  let pressuredColors: any = [];
  let pressured1: any = [];
  let oxylabels: any = [];
  let oxytdata: any = [];
  let oxytColors: any = [];

  let bodylabels: any = [];
  let bodydata: any = [];
  let bodydataColors: any = [];
  let reslabels: any = [];
  let resdata: any = [];
  let resdataColors: any = [];
  let bmlabels: any = [];
  let bmdata: any = [];
  let bmdataColors: any = [];
  let Hlabels: any = [];
  let Hdata: any = [];
  let HdataColors: any = [];
  let Wlabels: any = [];
  let Wdata: any = [];
  let WdataColors: any = [];
  let Wlabels1: any = [];
  let Wdata1: any = [];
  let Wdata1Colors: any = [];
  let Wlabels2: any = [];
  let Wdata2: any = [];
  let Wdata2Colors: any = [];
  useEffect(() => {
    setShowloader({ display: "flex" })
    userService.life_style_graph().then(response => { return response.data })
      .then((result) => {
        setHealthSummary(result);
        setShowloader({ display: "none" })
      })
      .catch(error => {
        console.log(error.response)
        setShowloader({ display: "none" })
        if (error.response?.status == 401) {
          localStorage.clear()
          window.location.href = '/'
        }
      });
  }, [])

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  if (healthSummary) {
    const sleepLevel_green = healthSummary['data']['Sleep']['metric_ranges'][0]['green'].split("-")
    const sleepLevel_yellow_low = healthSummary['data']['Sleep']['metric_ranges'][0]['yellow_low'].split("-")
    const sleepLevel_yellow_high = healthSummary['data']['Sleep']['metric_ranges'][0]['yellow_high']
    // const sleepLevel_red_low = healthSummary['data']['Sleep']['metric_ranges'][0]['red_low'].replace("<", "")
    // const sleepLevel_red_high = healthSummary['data']['Sleep']['metric_ranges'][0]['red_high'].replace(">", "")

    const stressLevel_green = healthSummary['data']['Stress']['metric_ranges'][0]['green'].split("-")
    const stressLevel_yellow_high = healthSummary['data']['Stress']['metric_ranges'][0]['yellow_high'].split("-")

    const alcoholLevel_green = healthSummary['data']['Alcohol Consumption']['metric_ranges'][0]['green'].split("-")
    const alcoholLevel_yellow_high = healthSummary['data']['Alcohol Consumption']['metric_ranges'][0]['yellow_high'].split("-")

    const calorieExpended_green = healthSummary['data']['Calorie Expended']['metric_ranges'][0]['green'].split("-")
    const calorieExpended_yellow_low = healthSummary['data']['Calorie Expended']['metric_ranges'][0]['yellow_low'].split("-")
    const calorieExpended_yellow_high = healthSummary['data']['Calorie Expended']['metric_ranges'][0]['yellow_high'].split("-")

    const calorieIngested_green = healthSummary['data']['Calorie Ingested']['metric_ranges'][0]['green'].split("-")
    const calorieIngested_yellow_low = healthSummary['data']['Calorie Ingested']['metric_ranges'][0]['yellow_low'].split("-")
    const calorieIngested_yellow_high = healthSummary['data']['Calorie Ingested']['metric_ranges'][0]['yellow_high'].split("-")

    const walkRun_green = healthSummary['data']['Distance Walked/Run']['metric_ranges'][0]['green'].split("-")
    const walkRun_yellow_low = healthSummary['data']['Distance Walked/Run']['metric_ranges'][0]['yellow_low'].split("-")
    const walkRun_yellow_high = healthSummary['data']['Distance Walked/Run']['metric_ranges'][0]['yellow_high'].split("-")

    const steps_green = healthSummary['data']['Steps Taken']['metric_ranges'][0]['green'].split("-")
    const steps_yellow_low = healthSummary['data']['Steps Taken']['metric_ranges'][0]['yellow_low'].split("-")
    const steps_yellow_high = healthSummary['data']['Steps Taken']['metric_ranges'][0]['yellow_high'].split("-")

    const water_green = healthSummary['data']['Water Consumption']['metric_ranges'][0]['green'].split("-")
    const water_yellow_low = healthSummary['data']['Water Consumption']['metric_ranges'][0]['yellow_low'].split("-")
    const water_yellow_high = healthSummary['data']['Water Consumption']['metric_ranges'][0]['yellow_high'].split("-")

    const workout_green = healthSummary['data']['Workouts']['metric_ranges'][0]['green'].split("-")
    const workout_yellow_high = healthSummary['data']['Workouts']['metric_ranges'][0]['yellow_high'].split("-")

    if (graphData == "week") {
      healthSummary['data']['Sleep']['values'].map(function (gdata: any, index: any) {
        const val = gdata['measured_data']
        pressurelabels.push(gdata['day'])
        pressured.push(val)
        if (val >= sleepLevel_green[0] && val <= sleepLevel_green[1]) {
          pressuredColors.push("#00d700")
        } else if ((val >= sleepLevel_yellow_low[0] && val <= sleepLevel_yellow_low[1]) || (val > sleepLevel_green[1] && val <= sleepLevel_yellow_high)) {
          pressuredColors.push("#c7c500")
        } else {
          pressuredColors.push("#ff0000")
        }
      })

      healthSummary['data']['Stress']['values'].map(function (gdata: any, index: any) {
        const val = gdata['measured_data']
        heartlabels.push(gdata['day'])
        heartdata.push(val)
        if (val >= stressLevel_green[0] && val <= stressLevel_green[1]) {
          heartColors.push("#00d700")
        } else if (val >= stressLevel_yellow_high[0] && val <= stressLevel_yellow_high[1]) {
          heartColors.push("#c7c500")
        } else {
          heartColors.push("#ff0000")
        }
      })

      healthSummary['data']['Tobacco Usage']['values'].map(function (gdata: any, index: any) {
        const val = gdata['measured_data']
        oxylabels.push(gdata['day'])
        oxytdata.push(val)
        if (val == 0) {
          oxytColors.push("#00d700")
        } else if (val > 0 && val < 2) {
          oxytColors.push("#c7c500")
        } else {
          oxytColors.push("#ff0000")
        }
      })

      healthSummary['data']['Alcohol Consumption']['values'].map(function (gdata: any, index: any) {
        const val = gdata['measured_data']
        bodylabels.push(gdata['day'])
        bodydata.push(val)
        if (val >= alcoholLevel_green[0] && val <= alcoholLevel_green[1]) {
          bodydataColors.push("#00d700")
        } else if (val >= alcoholLevel_yellow_high[0] && val <= alcoholLevel_yellow_high[1]) {
          bodydataColors.push("#c7c500")
        } else {
          bodydataColors.push("#ff0000")
        }
      })

      healthSummary['data']['Calorie Expended']['values'].map(function (gdata: any, index: any) {
        const val = gdata['measured_data']
        reslabels.push(gdata['day'])
        resdata.push(val)
        if (val >= +calorieExpended_green[0] && val <= +calorieExpended_green[1]) {
          resdataColors.push("#00d700")
        } else if ((val >= +calorieExpended_yellow_low[0] && val <= +calorieExpended_yellow_low[1]) || (val >= +calorieExpended_yellow_high[0] && val <= +calorieExpended_yellow_high[1])) {
          resdataColors.push("#c7c500")
        } else {
          resdataColors.push("#ff0000")
        }
      })

      healthSummary['data']['Calorie Ingested']['values'].map(function (gdata: any, index: any) {
        const val = gdata['measured_data']
        bmlabels.push(gdata['day'])
        bmdata.push(val)
        if (val >= +calorieIngested_green[0] && val <= +calorieIngested_green[1]) {
          bmdataColors.push("#00d700")
        } else if ((val >= +calorieIngested_yellow_low[0] && val <= +calorieIngested_yellow_low[1]) || (val >= +calorieIngested_yellow_high[0] && val <= +calorieIngested_yellow_high[1])) {
          bmdataColors.push("#c7c500")
        } else {
          bmdataColors.push("#ff0000")
        }
      })

      healthSummary['data']['Distance Walked/Run']['values'].map(function (gdata: any, index: any) {
        const val = gdata['measured_data']
        Hlabels.push(gdata['day'])
        Hdata.push(val)
        if (val >= +walkRun_green[0] && val <= +walkRun_green[1]) {
          HdataColors.push("#00d700")
        } else if ((val >= +walkRun_yellow_low[0] && val <= +walkRun_yellow_low[1]) || (val >= +walkRun_yellow_high[0] && val <= +walkRun_yellow_high[1])) {
          HdataColors.push("#c7c500")
        } else {
          HdataColors.push("#ff0000")
        }
      })

      healthSummary['data']['Steps Taken']['values'].map(function (gdata: any, index: any) {
        const val = gdata['measured_data']
        Wlabels.push(gdata['day'])
        Wdata.push(val)
        if (val >= +steps_green[0] && val <= +steps_green[1]) {
          WdataColors.push("#00d700")
        } else if ((val >= +steps_yellow_low[0] && val <= +steps_yellow_low[1]) || (val >= +steps_yellow_high[0] && val <= +steps_yellow_high[1])) {
          WdataColors.push("#c7c500")
        } else {
          WdataColors.push("#ff0000")
        }
      })

      healthSummary['data']['Water Consumption']['values'].map(function (gdata: any, index: any) {
        const val = gdata['measured_data']
        Wlabels1.push(gdata['day'])
        Wdata1.push(val)
        if (val >= +water_green[0] && val <= +water_green[1]) {
          Wdata1Colors.push("#00d700")
        } else if ((val >= +water_yellow_low[0] && val <= +water_yellow_low[1]) || (val >= +water_yellow_high[0] && val <= +water_yellow_high[1])) {
          Wdata1Colors.push("#c7c500")
        } else {
          Wdata1Colors.push("#ff0000")
        }
      })

      healthSummary['data']['Workouts']['values'].map(function (gdata: any, index: any) {
        const val = gdata['measured_data']
        Wlabels2.push(gdata['day'])
        Wdata2.push(val)
        if (val >= +workout_green[0] && val <= +workout_green[1]) {
          Wdata2Colors.push("#00d700")
        } else if (val > +workout_yellow_high[0] && val < +workout_yellow_high[1]) {
          Wdata2Colors.push("#c7c500")
        } else {
          Wdata2Colors.push("#ff0000")
        }
      })
    } else {
      healthSummary['month']['Sleep']['values'].map(function (gdata: any, index: any) {
        const val = gdata['measured_week_data']
        pressurelabels.push("week" + gdata['week'])
        pressured.push(val)
        if (val >= sleepLevel_green[0] && val <= sleepLevel_green[1]) {
          pressuredColors.push("#00d700")
        } else if ((val >= sleepLevel_yellow_low[0] && val <= sleepLevel_yellow_low[1]) || (val > sleepLevel_green[1] && val <= sleepLevel_yellow_high)) {
          pressuredColors.push("#c7c500")
        } else {
          pressuredColors.push("#ff0000")
        }
      })

      healthSummary['month']['Stress']['values'].map(function (gdata: any, index: any) {
        const val = gdata['measured_week_data']
        heartlabels.push("week" + gdata['week'])
        heartdata.push(val)
        if (val >= stressLevel_green[0] && val <= stressLevel_green[1]) {
          heartColors.push("#00d700")
        } else if (val >= stressLevel_yellow_high[0] && val <= stressLevel_yellow_high[1]) {
          heartColors.push("#c7c500")
        } else {
          heartColors.push("#ff0000")
        }
      })

      healthSummary['month']['Tobacco Usage']['values'].map(function (gdata: any, index: any) {
        const val = gdata['measured_week_data']
        oxylabels.push("week" + gdata['week'])
        oxytdata.push(gdata['measured_week_data'])
        if (val == 0) {
          oxytColors.push("#00d700")
        } else if (val > 0 && val < 2) {
          oxytColors.push("#c7c500")
        } else {
          oxytColors.push("#ff0000")
        }
      })

      healthSummary['month']['Alcohol Consumption']['values'].map(function (gdata: any, index: any) {
        const val = gdata['measured_week_data']
        bodylabels.push("week" + gdata['week'])
        bodydata.push(val)
        if (val >= alcoholLevel_green[0] && val <= alcoholLevel_green[1]) {
          bodydataColors.push("#00d700")
        } else if (val >= alcoholLevel_yellow_high[0] && val <= alcoholLevel_yellow_high[1]) {
          bodydataColors.push("#c7c500")
        } else {
          bodydataColors.push("#ff0000")
        }
      })

      healthSummary['month']['Calorie Expended']['values'].map(function (gdata: any, index: any) {
        const val = gdata['measured_week_data']
        reslabels.push("week" + gdata['week'])
        resdata.push(val)
        if (val >= +calorieExpended_green[0] && val <= +calorieExpended_green[1]) {
          resdataColors.push("#00d700")
        } else if ((val >= +calorieExpended_yellow_low[0] && val <= +calorieExpended_yellow_low[1]) || (val >= +calorieExpended_yellow_high[0] && val <= +calorieExpended_yellow_high[1])) {
          resdataColors.push("#c7c500")
        } else {
          resdataColors.push("#ff0000")
        }
      })

      healthSummary['month']['Calorie Ingested']['values'].map(function (gdata: any, index: any) {
        const val = gdata['measured_week_data']
        bmlabels.push("week" + gdata['week'])
        bmdata.push(val)
        if (val >= +calorieIngested_green[0] && val <= +calorieIngested_green[1]) {
          bmdataColors.push("#00d700")
        } else if ((val >= +calorieIngested_yellow_low[0] && val <= +calorieIngested_yellow_low[1]) || (val >= +calorieIngested_yellow_high[0] && val <= +calorieIngested_yellow_high[1])) {
          bmdataColors.push("#c7c500")
        } else {
          bmdataColors.push("#ff0000")
        }
      })

      healthSummary['month']['Distance Walked/Run']['values'].map(function (gdata: any, index: any) {
        const val = gdata['measured_week_data']
        Hlabels.push("week" + gdata['week'])
        Hdata.push(val)
        if (val >= +walkRun_green[0] && val <= +walkRun_green[1]) {
          HdataColors.push("#00d700")
        } else if ((val >= +walkRun_yellow_low[0] && val <= +walkRun_yellow_low[1]) || (val >= +walkRun_yellow_high[0] && val <= +walkRun_yellow_high[1])) {
          HdataColors.push("#c7c500")
        } else {
          HdataColors.push("#ff0000")
        }
      })

      healthSummary['month']['Steps Taken']['values'].map(function (gdata: any, index: any) {
        const val = gdata['measured_week_data']
        Wlabels.push("week" + gdata['week'])
        Wdata.push(val)
        if (val >= +steps_green[0] && val <= +steps_green[1]) {
          WdataColors.push("#00d700")
        } else if ((val >= +steps_yellow_low[0] && val <= +steps_yellow_low[1]) || (val >= +steps_yellow_high[0] && val <= +steps_yellow_high[1])) {
          WdataColors.push("#c7c500")
        } else {
          WdataColors.push("#ff0000")
        }
      })

      healthSummary['month']['Water Consumption']['values'].map(function (gdata: any, index: any) {
        const val = gdata['measured_week_data']
        Wlabels1.push("week" + gdata['week'])
        Wdata1.push(val)
        if (val >= +water_green[0] && val <= +water_green[1]) {
          Wdata1Colors.push("#00d700")
        } else if ((val >= +water_yellow_low[0] && val <= +water_yellow_low[1]) || (val >= +water_yellow_high[0] && val <= +water_yellow_high[1])) {
          Wdata1Colors.push("#c7c500")
        } else {
          Wdata1Colors.push("#ff0000")
        }
      })

      healthSummary['month']['Workouts']['values'].map(function (gdata: any, index: any) {
        const val = gdata['measured_week_data']
        Wlabels2.push(gdata['week'])
        Wdata2.push(val)
        if (val >= +workout_green[0] && val <= +workout_green[1]) {
          Wdata2Colors.push("#00d700")
        } else if (val > +workout_yellow_high[0] && val < +workout_yellow_high[1]) {
          Wdata2Colors.push("#c7c500")
        } else {
          Wdata2Colors.push("#ff0000")
        }
      })
    }
  }

  const data = {
    labels: pressurelabels,
    datasets: [
      {
        label: 'Sleep',
        borderWidth: 1,
        backgroundColor: '#1AA0E6',
        borderColor: '#1AA0E6',
        pointRadius: 7,
        pointHoverRadius: 10,
        data: pressured,
        pointBackgroundColor: pressuredColors
      }
    ]
  }


  const dataheart = {
    labels: heartlabels,
    datasets: [
      {
        label: 'Stress',
        data: heartdata,
        backgroundColor: '#1AA0E6',
        borderColor: '#1AA0E6',
        borderWidth: 1,
        pointRadius: 7,
        pointHoverRadius: 10,
        pointBackgroundColor: heartColors
      }
    ],
  };
  const dataoxy = {
    labels: oxylabels,
    datasets: [
      {
        label: 'Tobacco Usage',
        data: oxytdata,
        backgroundColor: '#1AA0E6',
        borderColor: '#1AA0E6',
        borderWidth: 1,
        pointRadius: 7,
        pointHoverRadius: 10,
        pointBackgroundColor: oxytColors
      }
    ],
  };
  const databody = {
    labels: bodylabels,
    datasets: [
      {
        label: 'Alcohol Consumption',
        data: bodydata,
        backgroundColor: '#1AA0E6',
        borderColor: '#1AA0E6',
        borderWidth: 1,
        pointRadius: 7,
        pointHoverRadius: 10,
        pointBackgroundColor: bodydataColors
      }
    ],
  };
  const datares = {
    labels: reslabels,
    datasets: [
      {
        label: 'Calorie Expended',
        data: resdata,
        backgroundColor: '#1AA0E6',
        borderColor: '#1AA0E6',
        borderWidth: 1,
        pointRadius: 7,
        pointHoverRadius: 10,
        pointBackgroundColor: resdataColors
      }
    ],
  };
  const databm = {
    labels: bmlabels,
    datasets: [
      {
        label: 'Calorie Ingested',
        data: bmdata,
        backgroundColor: '#1AA0E6',
        borderColor: '#1AA0E6',
        borderWidth: 1,
        pointRadius: 7,
        pointHoverRadius: 10,
        pointBackgroundColor: bmdataColors
      }
    ],
  };
  const dataH = {
    labels: Hlabels,
    datasets: [
      {
        label: 'Distance Walked/Run',
        data: Hdata,
        backgroundColor: '#1AA0E6',
        borderColor: '#1AA0E6',
        pointBackgroundColor: HdataColors,
        borderWidth: 1,
        pointRadius: 7,
        pointHoverRadius: 10,
      }
    ],
  };
  const dataW = {
    labels: Wlabels,
    datasets: [
      {
        label: 'Steps Taken',
        data: Wdata,
        backgroundColor: '#1AA0E6',
        borderColor: '#1AA0E6',
        pointBackgroundColor: WdataColors,
        borderWidth: 1,
        pointRadius: 7,
        pointHoverRadius: 10,
      }
    ],
  };
  const dataW1 = {
    labels: Wlabels1,
    datasets: [
      {
        label: 'Water Consumption',
        data: Wdata1,
        backgroundColor: '#1AA0E6',
        borderColor: '#1AA0E6',
        borderWidth: 1,
        pointRadius: 7,
        pointHoverRadius: 10,
        pointBackgroundColor: Wdata1Colors
      }
    ],
  };
  const dataW2 = {
    labels: Wlabels2,
    datasets: [
      {
        label: 'Workouts',
        data: Wdata2,
        backgroundColor: '#1AA0E6',
        borderColor: '#1AA0E6',
        borderWidth: 1,
        pointRadius: 7,
        pointHoverRadius: 10,
        pointBackgroundColor: Wdata2Colors
      }
    ],
  };

  return (

    <>
      <section className="bodyvital_section py-5">
        <Loader status={showloader.display} />
        <Container>
          <div className="inner_box">
            <Row className="bodyvitals_head">
              <Col md={6}>
                <h5>
                  <a href="/">
                    <img src={require('../images/back.svg').default} alt="back" />
                  </a>
                </h5>
                <h2>Life Style Graphs</h2>
              </Col>
              <Col md={6} className="text-right">
                <h6>Date Measured</h6>
                <select onChange={(e: any) => setgraphData(e.target.value)}>
                  <option value="week" selected={graphData == 'week'}>Weekly</option>
                  <option value="month" selected={graphData == 'month'}>Monthly</option>
                </select>
              </Col>
              <Col md={12}>
                <hr />
              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Sleep</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                <Line options={options} data={data} />
                {healthSummary ? (

                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Sleep']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Sleep']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Sleep']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Sleep']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Sleep']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (<>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Sleep']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Sleep']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Sleep']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Sleep']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Sleep']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>)}
                  </div>) : (<></>)}
              </Col>


              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Stress</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                <Line options={options} data={dataheart}
                />
                {healthSummary ? (

                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Stress']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Stress']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Stress']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Stress']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Stress']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (<>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Stress']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Stress']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Stress']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Stress']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Stress']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>)}
                  </div>) : (<></>)}

              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Tobacco Usage</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                <Line options={options} data={dataoxy}
                />
                {healthSummary ? (

                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Tobacco Usage']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Tobacco Usage']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Tobacco Usage']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Tobacco Usage']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Tobacco Usage']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (<>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Tobacco Usage']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Tobacco Usage']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Tobacco Usage']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Tobacco Usage']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Tobacco Usage']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>)}
                  </div>) : (<></>)}

              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Alcohol Consumption</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                <Line options={options} data={databody}
                />
                {healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Alcohol Consumption']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Alcohol Consumption']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Alcohol Consumption']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Alcohol Consumption']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Alcohol Consumption']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (<>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Alcohol Consumption']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Alcohol Consumption']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Alcohol Consumption']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Alcohol Consumption']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Alcohol Consumption']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>)}
                  </div>) : (<></>)}

              </Col>

              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Calorie Expended</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                <Line options={options} data={datares}
                />
                {healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Calorie Expended']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Calorie Expended']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Calorie Expended']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Calorie Expended']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Calorie Expended']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (<>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Calorie Expended']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Calorie Expended']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Calorie Expended']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Calorie Expended']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Calorie Expended']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>)}
                  </div>) : (<></>)}

              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Calorie Ingested</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                <Line options={options} data={databm}
                />
                {healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Calorie Ingested']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Calorie Ingested']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Calorie Ingested']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Calorie Ingested']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Calorie Ingested']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (<>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Calorie Ingested']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Calorie Ingested']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Calorie Ingested']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Calorie Ingested']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Calorie Ingested']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>)}
                  </div>) : (<></>)}

              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Distance Walked/Run</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                <Line options={options} data={dataH}
                />
                {healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Distance Walked/Run']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Distance Walked/Run']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Distance Walked/Run']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Distance Walked/Run']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Distance Walked/Run']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Distance Walked/Run']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Distance Walked/Run']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Distance Walked/Run']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Distance Walked/Run']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Distance Walked/Run']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>
                    )}
                  </div>) : (<></>)}

              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Steps Taken</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                <Line options={options} data={dataW}
                />
                {healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Steps Taken']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Steps Taken']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Steps Taken']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Steps Taken']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Steps Taken']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Steps Taken']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Steps Taken']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Steps Taken']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Steps Taken']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Steps Taken']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>
                    )}
                  </div>) : (<></>)}

              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Water Consumption</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                <Line options={options} data={dataW1}
                />
                {healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Water Consumption']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Water Consumption']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Water Consumption']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Water Consumption']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Water Consumption']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (
                      <> <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Water Consumption']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Water Consumption']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Water Consumption']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Water Consumption']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Water Consumption']['metric_ranges'][0]['red_high']}(Max)</p></>)}
                  </div>) : (<></>)}

              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Workouts</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                <Line options={options} data={dataW2} />
                {healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['data']['Workouts']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Workouts']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Workouts']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Workouts']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Workouts']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (
                      <> <p><i className="fa fa-square gooddata" aria-hidden="true" /><span className="gooddata"> Good:</span>  {healthSummary['month']['Workouts']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true" /><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Workouts']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Workouts']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true" /><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Workouts']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Workouts']['metric_ranges'][0]['red_high']}(Max)</p></>)}
                  </div>) : (<></>)}
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  )
}

export default Lifestylegraph;