import React, { useState, useEffect, useRef } from 'react';
import { Container, Button, Row, Col, Modal, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { userService } from '../_services/user.services';
import valid from "card-validator";
import Swal from 'sweetalert2';
import { useHistory } from 'react-router';
import { Loader } from './Loader';
import { ACCEPT_NUMBER, ADD_CARD_FORM_KEYS } from '../config/constants';
import { setItems } from '../utils/localStorage/index';


// const ACCEPT_NUMBER = /[0-9]/g;
// const ACCEPT_NUMBER = /^[0-9]*$/;
// const addCardFormKeys = ["card_number", "cvc", "expiry_date", "card_holder_name"];

function SavedCards() {
    const [loader, setLoader] = useState("flex");
    const [show, setShow] = useState(false);
    const [creditCardForm, setCreditCardForm] = useState({}) as any;
    const [errorObj, setErrorObj] = useState({}) as any;
    const [savedCards, setSavedCards] = useState([]) as any;
    const [btnLoader, setBtnLoader] = useState(false);
    const [addCardFormKeysStatus, setAddCardFormKeysStatus] = useState({
        card_number: true,
        cvc: true,
        expiry_date: true,
        card_holder_name: true
    });
    const [activeCard, setActiveCard] = useState("") as any;
    const [memberShipAmount, setMemberShipAmount] = useState(0);
    const [actPrice, setActPrice] = useState(0);


    const cardRef = useRef() as any;
    const search = new URLSearchParams(window.location.search);
    let comp_id = search.get("comp_id") as any;
    let group_id = search.get("group_id") as any;
    let plan_id = search.get("plan_id") as any;
    let amount = search.get("amount") as any;
    let duration = search.get("duration") as any;
    let isAlready = search.get("isAlready") as any;
    // let memberType = search.get("memberType") as any;



    let refer_code = search.get("refer_code") as any;
    let history = useHistory();

    useEffect(() => {
        getCards();
        if (plan_id) {
            getPlanFinalDetails();
            getUserProfile('planCheck');
        }
    }, []);

    function getPlanFinalDetails() {
        userService.getPlanFinalDetails(`?plan_id=${plan_id}&period=${duration}&price=${amount}`).then((response: any) => {
            console.log('response...', response.data.data.final_price);
            if (response.data.data) {
                setMemberShipAmount(Math.ceil(response.data.data.final_price));
                if(response.data.data.actual_price){
                    setActPrice(Math.ceil(response.data.data.actual_price));
                }
            }
        }).catch((error: any) => {
            console.log('error...', error);
        })
    }

    // common function for toggle modal`
    function toggleModal(status: any) {
        setShow(status);
    }

    function handleFormSubmit(event: any) {
        event.preventDefault();
        // check form is filled or not
        let status = checkFormInputisValid();
        setBtnLoader(true);
        triggerLoader("flex");
        if (status) {
            // Calling for create Stripe card
            createStripeCard();
        } else {
            setBtnLoader(false);
            triggerLoader("none");
        }
    }


    function checkFormInputisValid() {
        let formFilled = true;
        ADD_CARD_FORM_KEYS.forEach((value: any) => {
            let status = errorObj[value] ? true : false;
            if (status) {
                // let string = `${value.replace(/_/g, " ")} is required`;
                // handleErrorObjectState(value, string);
                formFilled = !status;
            }
        })
        return formFilled;
    }

    function createStripeCard() {
        let data = {
            card_holder_name: creditCardForm.card_holder_name,
            card_number: creditCardForm.card_number,
            cvc: creditCardForm.cvc,
            exp_month: creditCardForm.expiry_date.split('/')[0],
            exp_year: creditCardForm.expiry_date.split('/')[1]
        } as any;
        userService.createStripeCard(data).then(res => {
            if (res.data.status) {
                toast.success(res.data.message);
                setAddCardFormKeysStatus((prevAddCardFormKeysStatus: any) => {
                    return {
                        ...prevAddCardFormKeysStatus,
                        card_number: true,
                        cvc: true,
                        expiry_date: true,
                        card_holder_name: true
                    }
                });
                toggleModal(false);
                getCards();
            } else {
                toast.error(res.data.message);
                triggerLoader("none");
                setBtnLoader(true);

            }
            setBtnLoader(false);
        }).catch(err => {
            setBtnLoader(false);
        })
    }

    function getCards() {
        userService.getCards().then(res => {
            console.log('getCards', res.data.data);
            if (res.data.data) {
                setSavedCards(res.data.data);
            } else {
                setSavedCards([]);
            }
            setErrorObj({});
            setCreditCardForm({});
            triggerLoader("none");
        }).catch(err => {
            console.log('getCards', err);
            triggerLoader("none");
        })
    }

    function handleFormInput(event: any) {
        let { name, value } = event.target as any;
        if (name === 'card_number') {
            if (ACCEPT_NUMBER.test(value)) {
                let cardDetail = valid.number(value) as any;
                value = value.replace(/\W/ig, "");
                if (cardDetail.card) {
                    if (cardDetail.isValid) {
                        let x1 = '';
                        value.split("").forEach((v1: any, index: any) => {
                            if ((index + 1) % 4 == 0) {
                                x1 = x1 + v1 + " "
                            } else {
                                x1 = x1 + v1;
                            }
                        })
                        handlePaymentFormState(name, x1);

                        //   Please do not remove
                        // let gaps = cardDetail.card.gaps;
                        handleErrorObjectState(name, '');
                    } else {
                        handleErrorObjectState(name, 'Please enter valid card number');
                        handlePaymentFormState(name, value);
                    }
                } else {
                    handlePaymentFormState(name, value);
                }
            } else {
                handleErrorObjectState(name, 'Please enter card number');
                handlePaymentFormState(name, value);
            }
        }
        if (name === "expiry_date") {
            // removeing slash seperator from expiry date
            let expiry_date = value.replace("/", "");
            // Check input is only number
            if (ACCEPT_NUMBER.test(expiry_date)) {
                // checking Month is valid
                let expiryDateStatus = valid.expirationDate(expiry_date);
                if (expiryDateStatus.isValid) {
                    handleErrorObjectState(name, '');
                } else {
                    handleErrorObjectState(name, 'Please enter valid card expiry');
                }
                // adding slash seperator between month and year
                expiry_date = expiry_date.length > 2 ? expiry_date.substr(0, 2) + "/" + expiry_date.substr(2, expiry_date.length - 1) : expiry_date;
                handlePaymentFormState(name, expiry_date);
            } else {
                handleErrorObjectState(name, 'Please enter valid card expiry');
            }
        }
        if (name === "cvc") {
            if (ACCEPT_NUMBER.test(value)) {
                handlePaymentFormState(name, value);
                handleErrorObjectState(name, '');
            } else {
                handleErrorObjectState(name, 'Please enter valid cvc number');
            }
        }
        if (name === "card_holder_name") {
            handlePaymentFormState(name, value);
            handleErrorObjectState(name, value ? '' : 'Please enter card holder name');
        }
    }

    // Common function to handle all state of payment form
    function handlePaymentFormState(name: any, value: any) {
        setCreditCardForm((prevCreditCardForm: any) => {
            return {
                ...prevCreditCardForm,
                [name]: value
            }
        });
    }

    function handleErrorObjectState(key: any, value: any) {
        setErrorObj((prevErrorObj: any) => {
            return {
                ...prevErrorObj,
                [key]: value
            }
        });
        setAddCardFormKeysStatus((prevAddCardFormKeysStatus: any) => {
            return {
                ...prevAddCardFormKeysStatus,
                [key]: value ? true : false
            }
        });
    }

    // trigger sweet alert for delete card, sweet alert modal is implemented here
    function triggerDeleteCard(id: any, number: any) {
        Swal.fire({
            text: `Are you sure you want to remove this card ?`,
            title: "Alert",
            showCancelButton: true,
            confirmButtonText: "Delete"
        }).then(e => {
            if (e.value) {
                userService.deleteCards(id).then(resp => {
                    toast.success(resp.data.message);
                    triggerLoader("flex");
                    getCards();
                }).catch(err => {
                    if (err.response.status == 401) {
                        localStorage.clear()
                        window.location.href = '/'
                    }
                    toast.error("An error occurerd~")
                })
            }
        })
    }

    // API of Paypal Payment
    function payment(card_id: any) {
        let payload = {
            id: card_id,
            amount: plan_id ? memberShipAmount : amount
        } as any;

        // plan_id in case of member ship plan payment
        if (plan_id) {
            payload.event_id = parseInt(plan_id);
            payload.payment_type = "S";
            if (duration) {
                payload.duration = duration;
            }
        } else {
            payload.payment_type = comp_id ? "C" : "G";
            payload.event_id = comp_id ? comp_id : group_id;
            if(comp_id){
                if (refer_code) {
                    // payload.referCode = refer_code;
                    payload.discount_code = refer_code;
                }
            }
        }
        
        userService.payment(payload).then(resp => {
            if (resp.data.status == true) {
                if (plan_id) {
                    toast.success(`Plan purchased successfully.`);
                    if (isAlready) {
                        getUserProfile('planPurchased');
                    } else {
                        history.push("/");
                    }
                } else {
                    if (group_id) {
                        var item = localStorage.getItem('paymentDetails') as any;
                        var paymentObj = JSON.parse(item);
                        paymentObj.payment_id = resp.data.data.payment_id;
                        localStorage.setItem("paymentDetails", JSON.stringify(paymentObj));
                        joinGroup(paymentObj);
                        // IMPORTANT CODE DO NOT REMOVE
                        // history.push(`/group_chat?id=${group_id}&transaction_id=${resp.data.data.payment_id}&success=1`);
                    } else {
                        joinCompetitionApi(resp.data.data.payment_id);
                    }
                }
            } else {
                toast.error(resp.data.message);
                triggerLoader("none");
            }
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
        })
    }


    // API for get user profile
    function getUserProfile(type: any) {
        let accesstoken = localStorage.getItem('accesstoken');
        userService.userdetail(accesstoken).then((response) => {
            if (type === "planCheck") {
                if (response.data.data.plan_details) {
                    if(parseInt(plan_id) === 0){
                        history.push("/");
                    }else{
                        if (response.data.data.plan_details.plan_id === parseInt(plan_id)&& duration === response.data.data.plan_details.duration) {
                            // toast.error('Same plan cannot purchased again');
                            history.push("/");
                        }
                    }
                }
            } else {
                if (response.data.data.plan_details) {
                    setItems('plan_details', response.data.data.plan_details);
                    setTimeout(() => {
                        history.push('/membership/current');
                    }, 3000)
                }
            }
        }).catch((error: any) => {
            if (error.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            triggerLoader("none");
        });
    }

    // calling join group API
    function joinGroup(paymentObj: any) {
        var payload = {
            discount_code: paymentObj.discount_code,
            payment_id: paymentObj.payment_id,
            status: "G",
            transaction_id: paymentObj.payment_id,
            group_id: paymentObj.event_id
        };

        userService.joinGroup(payload).then(resp => {
            console.log(resp);
            // when user joined group successfully then show joined group listing along with active tab
            if (resp.data.status == true) {
                toast.success('You have joined the group successfully!!');
                localStorage.removeItem("paymentDetails");
                localStorage.setItem("groupTab", "first");
                history.push("/group");
            } else {
                // in case of failure redirect user to group screen
                toast.error(resp.data.message);
                history.push('/group');
            }
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
        })
    }

    // API of Join Competition
    function joinCompetitionApi(payment_id: any) {
        let object = {
            competition_id: parseInt(comp_id)
        } as any;
        if (payment_id) {
            object.payment_id = payment_id;
        }
        if (refer_code) {
            object.referCode = refer_code;
            object.discount_code = refer_code;
        }
        userService.joinCompetition(object).then(resp => {
            if (resp.data.status) {
                localStorage.setItem("compTab", "joined");
                localStorage.setItem('discount_code', '');
                toast.success("You have successfully joined the competition! ");
                triggerLoader("none");
                history.push('/competition');
            } else {
                toast.error("Something went wrong.");
                triggerLoader("none");
            }
            localStorage.setItem('discount_code', '');
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            toast.error("Something went wrong.");
            triggerLoader("none");
        })
    }

    // trigger loader status to show or hide loader
    function triggerLoader(status: any) {
        setLoader(status);
    }

    function triggerPayment(event: any) {
        event.preventDefault();
        if (activeCard) {
            triggerLoader("flex");
            Swal.fire({
                text: `Are you sure you want to pay ?`,
                title: "Alert",
                showCancelButton: true,
                confirmButtonText: "Pay"
            }).then(e => {
                if (e.value) {
                    payment(activeCard);
                } else {
                    triggerLoader("none");
                }
            })
        } else {
            toast.error("Please select a card.");
        }
    }

    function handleRadioInput(value: any) {
        setActiveCard(value);
    }


    const back = () => {
        // in case of group_id redirect to join group
        if (group_id) {
            window.location.href = `/join_group?id=${group_id}`;
        } else {
            if (comp_id) {
                // in case of competition id redirect to competition
                window.location.href = `/details?comp_id=${comp_id}`;
            } else if (isAlready) {
                // in case of already joined membership redirect to membership plan screen and showing active plan
                window.location.href = `/membership/current`;
            } else if (plan_id) {
                // in case of plan id redirect to competition
                window.location.href = `/membership/plan`;
            }
            else {
                window.location.href = `/setting`;
            }
        }
    }

    console.log('savedCards.length',savedCards.length)

    return (
        <React.Fragment>
            <Loader status={loader} />
            <section className="bodyvital_section py-5">
                <Container>
                    <div className="inner_box">
                        <Row className="bodyvitals_head">
                            <Col md={12}><h5><span onClick={back}><img src={require('../images/back.svg').default} alt="back" /></span></h5></Col>
                            <Col md={12} className="save-card-section">
                                <h2 className="mb-1">My Cards</h2>
                                <Button type="button" className="add-new-card" onClick={() => {
                                    if (savedCards.length < 3) {
                                        toggleModal(!show)
                                    } else {
                                        toast.error('You can add maximum 3 cards ')
                                    }
                                }}><i className="fa fa-plus-circle" aria-hidden="true"></i> Add New Card</Button>
                            </Col>
                            <Col md={12}><hr /></Col>
                        </Row>
                        <div className='saved-card'>
                            {
                                savedCards.length ?
                                    <form onSubmit={(event: any) => triggerPayment(event)} autoComplete="off">
                                        {
                                            savedCards.map((value: any, index: any) => {
                                                return (
                                                    <div className='card-one' key={`${value.user_id}_${index}`}>
                                                        <div className='d-flex align-items-center' onClick={() => {
                                                            if (comp_id || group_id || plan_id) {
                                                                handleRadioInput(value.id)
                                                            }
                                                        }}>
                                                            {comp_id || group_id || plan_id ? <input type="radio" value={value.id} checked={activeCard ? parseInt(activeCard) === value.id ? true : false : false} onChange={(event: any) => handleRadioInput(event.target.value)} required /> : ''}
                                                            <i className="fa fa-credit-card-alt" aria-hidden="true" />
                                                            <h5>{`XXXX XXXX XXXX ${value.card_number}`}</h5>
                                                        </div>
                                                        <i className="fa fa-trash-o" aria-hidden="true" onClick={() => {
                                                            if(savedCards.length>1){
                                                                triggerDeleteCard(value.id, value.card_number)
                                                            }}} />
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            comp_id || group_id || plan_id ? <div className="row mt-4 justify-content-center">
                                                <button className='add-new-card btn btn-primary' type='submit'>{`Continue to pay $${plan_id ? memberShipAmount : amount} `}
                                                {plan_id?actPrice?<span className="actual-price">$ {actPrice}</span>:'':''}
                                                </button>
                                            </div> : ''
                                        }
                                    </form> :
                                    <div className="row mt-5">
                                        <div className="col-12 text-center">No Card Saved</div>
                                    </div>
                            }
                        </div>
                    </div>
                </Container>
            </section>
            <Modal show={show} onHide={() => toggleModal(!show)} size="md" aria-labelledby="contained-modal-title-vcenter" className="add-card-modal" backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton className="border-0 p-1"><Modal.Title>Add Card</Modal.Title></Modal.Header>
                <Modal.Body className="text-center pt-0">
                    <Form autoComplete="off" onSubmit={(event: any) => handleFormSubmit(event)}>
                        <div className="card-details mt-2">
                            <Form.Group className="mb-3" controlId="cardName">
                                <Form.Label>Card Holder Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter Card Holder Name" name="card_holder_name" className="input103 mb-3" value={creditCardForm.card_holder_name ? creditCardForm.card_holder_name : ''} onChange={(event: any) => handleFormInput(event)} minLength="4" maxLength="20" required={addCardFormKeysStatus.card_holder_name} />
                                {errorObj.card_holder_name ? <span className="text-danger">{errorObj.card_holder_name}</span> : ''}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="creditCard">
                                <Form.Label>Card Number</Form.Label>
                                <Form.Control type="text" placeholder="Enter Card Number" name="card_number" className="input103 mb-3" ref={cardRef} value={creditCardForm.card_number ? creditCardForm.card_number : ''} onChange={(event: any) => handleFormInput(event)} maxLength="20" required={addCardFormKeysStatus.card_number} />
                                {errorObj.card_number ? <span className="text-danger">{errorObj.card_number}</span> : ''}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="expirydate">
                                <Form.Label>Card Expiry</Form.Label>
                                <Form.Control type="text" placeholder="MM/YYYY" name="expiry_date" className="input103 mb-3" value={creditCardForm.expiry_date ? creditCardForm.expiry_date : ''} onChange={(event: any) => handleFormInput(event)} maxLength="7" required={addCardFormKeysStatus.expiry_date} />
                                {errorObj.expiry_date ? <span className="text-danger">{errorObj.expiry_date}</span> : ''}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="cvc">
                                <Form.Label>CVV Number</Form.Label>
                                <Form.Control type="password" placeholder="Enter CVV Number" name="cvc" className="input103 mb-3" value={creditCardForm.cvc ? creditCardForm.cvc : ''} onChange={(event: any) => handleFormInput(event)} maxLength="4" required={addCardFormKeysStatus.cvc} />
                                {errorObj.cvc ? <span className="text-danger">{errorObj.cvc}</span> : ''}
                            </Form.Group>
                            <Form.Group><Button type="submit" disabled={btnLoader}>{btnLoader ? <Spinner animation="border" variant="light" /> : 'Add'}</Button></Form.Group>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}
export default SavedCards;