import io from "socket.io-client";
import { config } from '../config/config';

export const socket = io(`${config.chatSocket}`, {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity
});


export const socketUrl =io(`${config.socketurl}`, {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
  }) as any;