import React, { useState, useEffect } from 'react';
import { Row, Col, Tab, Tabs, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getItems, setItems } from '../utils/localStorage/index';
import { ACCEPT_NUMBER } from './../config/constants';
import { userService } from '../_services/user.services';
import { Loader } from './Loader';

function MemberShipPlan() {

    const history = useHistory();
    const [key, setKey] = useState('free');
    const [menuTrigger, setMenuTrigger] = useState(false);
    const [feature, setFeature] = useState([]);
    const [plan, setPlan] = useState({}) as any;
    const [showloader, setShowLoader] = useState("flex");
    const [quoteForm, setQuoteForm] = useState({
        phone_no: '',
        name: '',
        employees_count: ''
    });
    const [activeDuration, setActiveDuration] = useState('');
    const [planDetail, setPlanDetail] = useState({
        plan: '',
        plan_status: '',
        duration: ''
    });
    const [quoteStatus, setQuoteStatus] = useState('');
    const [associateStatus, setAssociateStatus] = useState('');

    // Yup Schema for quote form
    const quoteFormSchema = Yup.object().shape({
        phone_no: Yup.number().typeError('Phone Number is required').required('Phone Number is required'),
        name: Yup.string().required('Business Name is required').trim(),
        employees_count: Yup.number().typeError('Number of Employee is required').required('Number of Employee is required')
    });

    // const business_status=getItems("business_status");
    // console.log('business_status',business_status);

    useEffect(() => {
        let memberKey = localStorage.getItem("memberShip_key");
        let membershipDuration = localStorage.getItem("membershipDuration");
        let plan_detail = getItems('plan_details');
        let associateDetail = localStorage.getItem('associate_status');
        window.scrollTo(0, 0);
        // if user is already subscribed to plan then getting detail from localstorage
        if (plan_detail) {
            // getPlanFinalDetails(`?plan_id=${plan_detail.plan_id}${plan_detail.duration?`&period=${plan_detail.duration}`:''}${plan_detail.amount?`&price=${plan_detail.amount}`:''}`)
            if (memberKey) {
                if (memberKey === "business") {
                    setKey(memberKey);
                    // setShowLoader("none");
                    getUserProfile();
                } else {
                    // if current selected tab is equal to selected plan than load values from plan detail other wise load values from membership duration in localstorage
                    if (memberKey === plan_detail.plan.toLowerCase()) {
                        if(plan_detail.plan.toLowerCase().includes("affiliate")){
                            setKey('affiliate');
                        }else{
                            setKey(plan_detail.plan.toLowerCase());
                        }
                        setMenuTrigger(true);
                        if (plan_detail.duration) {
                            setActiveDuration(plan_detail.duration);
                        }
                    } else {
                        if(memberKey.toLowerCase().includes("affiliate")){
                            setKey('affiliate');
                        }else{
                            setKey(memberKey);
                        }
                        setMenuTrigger(true);
                        if (membershipDuration) {
                            setActiveDuration(membershipDuration);
                        }
                    }
                }
                setPlanDetail((prevPlanDetail: any) => {
                    return {
                        ...prevPlanDetail,
                        plan:plan_detail.plan.toLowerCase().includes("affiliate")?"affiliate":plan_detail.plan.toLowerCase(),
                        // plan: plan_detail.plan.toLowerCase(),
                        plan_status: plan_detail.plan_status,
                        duration: plan_detail.duration
                    }
                });
            } else {
                getUserProfile();
            }
        } else {
            if (memberKey) {
                if (memberKey === "business") {
                    setKey(memberKey);
                    // setShowLoader("none");
                    getUserProfile();
                } else {
                    if(memberKey.toLowerCase().includes("affiliate")){
                        setKey('affiliate');
                    }else{
                        setKey(memberKey);
                    }
                    setMenuTrigger(true);
                    if (membershipDuration) {
                        setActiveDuration(membershipDuration);
                    }
                }
            } else {
                // subcriptionPlansFeatures();
                getUserProfile();
            }
        }
        if (associateDetail) {
            setAssociateStatus(associateDetail);
        } else {
            setAssociateStatus('');
        }

    }, []);

    useEffect(() => {
        if (menuTrigger) {
            subcriptionPlansFeatures();
        }
    }, [menuTrigger]);

    // get plan final detail
    function getPlanFinalDetails(params: any) {
        userService.getPlanFinalDetails(params).then((response: any) => {
            console.log('response...', response.data.data);
        }).catch((error: any) => {
            console.log('error...', error);
        })
    }

    // Calling API of Get user profile
    function getUserProfile() {
        let accesstoken = localStorage.getItem('accesstoken');
        userService.userdetail(accesstoken).then((response) => {
            if (response.data.data.business_status) {
                setQuoteStatus(response.data.data.business_status);
                setItems('business_status', response.data.data.business_status);
                if (response.data.data.business_status === "1") {
                    localStorage.setItem('memberShip_key', "");
                }
            }
            if (response.data.data.plan_details) {
                setItems('plan_details', response.data.data.plan_details);

                // in case xcellent affiliate status saving value affiliate
                if(response.data.data.plan_details.plan.toLowerCase().includes("affiliate")){
                    setKey('affiliate');
                }else{
                    setKey(response.data.data.plan_details.plan.toLowerCase());
                }

                // setKey(response.data.data.plan_details.plan.toLowerCase());
                setMenuTrigger(true);
                if (response.data.data.plan_details.duration) {
                    setActiveDuration(response.data.data.plan_details.duration);
                }
                setPlanDetail((prevPlanDetail: any) => {
                    return {
                        ...prevPlanDetail,
                        plan:response.data.data.plan_details.plan.toLowerCase().includes("affiliate")?"affiliate":response.data.data.plan_details.plan.toLowerCase(),
                        plan_status: response.data.data.plan_details.plan_status,
                        duration: response.data.data.plan_details.duration
                    }
                });
            } else {
                subcriptionPlansFeatures();
            }

            if (response.data.data.associate_status) {
                setAssociateStatus(response.data.data.associate_status);
            } else {
                setAssociateStatus('');
            }
            setShowLoader("none");
        }).catch((error: any) => {
            setShowLoader("none");
            if (error.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
        });
    }

    // get list of subscription plan feature
    function subcriptionPlansFeatures() {
        userService.subcriptionPlansFeatures(`?type=${key}`).then((response: any) => {
            if (response.data.data) {
                if (response.data.data.features) {
                    setFeature(response.data.data.features);
                } else {
                    setFeature([]);
                }
                setPlan(response.data.data.plan);
            }
            setMenuTrigger(false);
            // getUserProfile();
            setShowLoader("none");
        }).catch((error: any) => {
            setMenuTrigger(false);
            setShowLoader("none");
        })
    }

    // handle tab click 
    function handleTabClick(type: any) {
        setKey(type);
        if (type !== "business") {
            setMenuTrigger(true);
            setShowLoader("flex");
            if (type === planDetail.plan) {
                setActiveDuration(planDetail.duration);
                localStorage.setItem('membershipDuration', planDetail.duration);
            } else {
                setActiveDuration('');
                localStorage.setItem('membershipDuration', '');
            }
            localStorage.setItem('memberShip_key', type);
        } else {
            localStorage.setItem('memberShip_key', "business");
            setPlan({});
        }
    }

    // handle active duration
    function toggleDurationBox(type: any) {
        // if user already subscribed to plan then we check if he has previously checked duration     
        if (key === planDetail.plan) {
            // checking if he previously checked year now he is going to select month, then we have to avoid
            if (type === "monthly" && planDetail.duration === "yearly") {
                // toast.error('You cannot downgrade duration');
                console.log('You cannot downgrade duration');
            } else {
                setActiveDuration(type);
                localStorage.setItem('membershipDuration', type);
            }
        } else {
            // checking if he previously checked year now he is going to select month of next higher plan, then we have to avoid
            // incase of monthly plan in previous user can choose monthly and yearly, but in case of yearly plan in previous user can choose only yearly plan not monthly
            if (type === "monthly" && planDetail.duration === "yearly") {
                // toast.error('You cannot downgrade duration');
                console.log('You cannot upgrade to monthly in higher plan if you are already subscribed to yearly');
            } else {
                setActiveDuration(type);
                localStorage.setItem('membershipDuration', type);
            }
        }
    }

    // handle next page functionality
    function next() {
        if (key !== "free") {
            history.push(`/payment?plan_id=${plan.id}&amount=${activeDuration === "monthly" ? plan.cost : plan.yearly_price}${activeDuration ? `&duration=${activeDuration}` : ''}&plan_type=${key}${window.location.pathname.includes("current") ? `&isAlready=member` : ''}${window.location.pathname.includes("plan") ? `&memberType=new` : ''}`);
        } else {
            subcriptionForFree(plan.id);
        }
    }

    // API for Free subscription
    function subcriptionForFree(plan_id: any) {
        let data = {
            plan_id: plan_id
        };
        userService.subcriptionForFree(data).then((response: any) => {
            if (response.data) {
                // toast.success(response.data.message);
                history.push('/');
            }
        }).catch((error: any) => {
            console.log('error', error);
        })
    }

    // disabled lower option to avoid plan degrade returnStatusOfTab return status true or false to make Tab clickable or disabled
    function returnStatusOfTab(key: any) {
        if (key === "free") {
            // planDetail.plan === "free" ||
            if (planDetail.plan === "consumer" || planDetail.plan === "affiliate") {
                return false;
            } else {
                return true;
            }
        }
        if (key === "consumer") {
            if (planDetail.plan === "affiliate") {
                return false;
            } else {
                return true;
            }
        }
        if (key === "affiliate") {
            // if (planDetail.plan === "business") {
            //     return false;
            // } else {
            //     return true;
            // }
            return true;
        }
        if (key === "business") {
            return true
        }
    }

    // Api for buisness quote
    function sendCompanyRequest(values: any) {
        let formData = new FormData();
        // composing data for buisness quote 
        Object.keys(values).forEach((data: any) => {
            console.log(`${data}`, values[data]);
            // appending data in formdata parameter structure is already set in quoteForm status
            formData.append(`${data}`, values[data])
        })
        userService.sendCompanyRequest(formData).then((response: any) => {
            if (response.data.status) {
                toast.success(response.data.message);
                if (planDetail.plan) {
                    setShowLoader("none");
                } else {
                    // subcriptionForFree(1);
                    if (window.location.pathname === "/membership/current") {
                        history.push('/profile');
                    } else {
                        // history.push('/');
                    }
                }
                getUserProfile();
                // setShowLoader("none");
            } else {
                toast.error(response.data.message);
                setShowLoader("none");
            }
        }).catch((error: any) => {
            setShowLoader("none");
            console.log('userService.sendCompanyRequest', error);
        })
    }
    console.log('associateStatus', associateStatus);
    return (
        <React.Fragment>
            <div className='mastership-plan'>
                <Loader status={showloader} />
                <div className="container">
                    <div className="row">
                        {
                            window.location.pathname === "/membership/current" ?
                                <div className="col-md-12 mb-2 ml-2">
                                    <h5><Link to="/setting"><img src={require('../images/back.svg').default} alt="back" /></Link></h5>
                                </div> : ""
                        }
                        <div className="col-md-12">
                            <Tabs activeKey={key} onSelect={(k: any) => {
                                // avoid reload of tab
                                if (returnStatusOfTab(k)) {
                                    if (key !== k) {
                                        handleTabClick(k)
                                    }
                                }
                            }} id="fill-tab-example" className="mb-3" fill>
                                {/* important  */}
                                {/* .disable-tab class is added to disable particular tab */}
                                <Tab eventKey="free" title="FREE" disabled={planDetail.plan === "consumer" || planDetail.plan === "affiliate"}>
                                    <Row className="justify-content-center ">
                                        <Col md={8}>
                                            <div className='membership-tab-content'>
                                                <MemberShipHeader text="Consumer Free" />
                                                {feature.length ? <ul>
                                                    {
                                                        feature.map((value: any, index: any) => {
                                                            return (
                                                                <li key={`${value.features}_${index}`}>{value.features}</li>
                                                            )
                                                        })
                                                    }
                                                </ul> :
                                                    <div className="row mt-4">
                                                        <div className="col-md-12 text-center">
                                                            No Matching Records
                                                        </div>
                                                    </div>
                                                }
                                                {planDetail.plan === "free" || planDetail.plan === "consumer" || planDetail.plan === "affiliate" ? '' : <div className='purchase-btn'>
                                                    <button type='button' onClick={() => next()} disabled={planDetail.plan === "free" || planDetail.plan === "consumer" || planDetail.plan === "affiliate" ? true : false}>Continue</button>
                                                </div>}
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="consumer" title="CONSUMER" disabled={planDetail.plan === "affiliate"}>
                                    <Row className="justify-content-center">
                                        <Col md={8}>
                                            <div className='membership-tab-content'>
                                                <MemberShipHeader text="Consumer" />
                                                {feature.length ?
                                                    <FeatureSection feature={feature} />
                                                    : <div className="row mt-4">
                                                        <div className="col-md-12 text-center">
                                                            No Matching Records
                                                        </div>
                                                    </div>}

                                                <div className='monthly-yearly-btn'>
                                                    <div className={`monthly-btn ${activeDuration === "monthly" ? `active-button-section` : ``} ${planDetail.duration === "yearly" ? 'monthly-btn-disable' : 'active-btn'}`} onClick={() => toggleDurationBox('monthly')}>
                                                        {/* removing over lay functionality in case of yearly plan subscribed by user */}
                                                        {/* Monthly case
                                                        if plan is consumer and duration month then info icon is hide and tooltip is disabled 
                                                        if plan is consumer and duration year then info icon is hide and tooltip is disabled
                                                        */}
                                                        {
                                                            planDetail.plan === "consumer" && planDetail.duration === "monthly" ? '' :
                                                                planDetail.plan === "consumer" && planDetail.duration === "yearly" || planDetail.duration === "yearly" ?
                                                                    // <i className="fa fa-info-circle info-icon" aria-hidden="true" />
                                                                    ''
                                                                    : <MemberShipDetail type="monthly" plan={plan} />
                                                        }
                                                        {/* {planDetail.plan === "consumer" && planDetail.duration === "monthly" || planDetail.duration === "yearly" ? <i className="fa fa-info-circle info-icon" aria-hidden="true" /> : <MemberShipDetail type="monthly" plan={plan} />} */}
                                                        <h6><img src={require('../images/calender.png').default} alt="calender" />Monthly: <span>${plan.cost ? plan.cost : 0}/month </span></h6>
                                                    </div>
                                                    <div className={`yearly-btn ${activeDuration === "yearly" ? `active-button-section` : ``} active-btn`} onClick={() => toggleDurationBox('yearly')}>
                                                        {/* Yearly case
                                                        if plan is consumer and duration month then info icon and tooltip are enabled for year
                                                        if plan is consumer and duration year then info icon is hide and tooltip is disabled                                                  
                                                        */}
                                                        {
                                                            planDetail.plan === "consumer" && planDetail.duration === "monthly" ? <MemberShipDetail type="yearly" plan={plan} /> :
                                                                planDetail.plan === "consumer" && planDetail.duration === "yearly" ? '' :
                                                                    <MemberShipDetail type="yearly" plan={plan} />
                                                            //   <i className="fa fa-info-circle info-icon" aria-hidden="true" />
                                                        }
                                                        {/* {planDetail.duration === "yearly" ? <i className="fa fa-info-circle info-icon" aria-hidden="true" /> : <MemberShipDetail type="yearly" plan={plan} />} */}
                                                        <h6><img src={require('../images/calender.png').default} alt="calender" />Yearly: <span>${plan.yearly_price ? plan.yearly_price : 0}/year </span></h6>
                                                        {plan.yearly_discount ? <p><i>*Discount {plan.yearly_discount}%</i></p> : ''}
                                                    </div>
                                                </div>
                                                <div className='purchase-btn'>
                                                    <button type='button' onClick={() => {
                                                        if (planDetail.plan === "consumer" && activeDuration === "monthly") {
                                                            console.log('You cannot buy previous selected plan');
                                                        } else {
                                                            if (activeDuration) {
                                                                next();
                                                            } else {
                                                                toast.error('Please select Monthly/Yearly duration.')
                                                            }
                                                        }
                                                    }}
                                                        disabled={planDetail.plan === "consumer" && planDetail.duration === "yearly" || planDetail.plan === "affiliate" ? true : false}>
                                                        {window.location.pathname === "/membership/plan" ? "Purchase" : activeDuration !== planDetail.duration ? "Upgrade" : planDetail.plan === "consumer" && planDetail.duration === "yearly" ? "Active Plan" :
                                                            planDetail.plan === "consumer" && planDetail.duration === "monthly" ? "Active Plan" : 'Upgrade'}
                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="affiliate" title="AFFILIATE">
                                    <Row className="justify-content-center">
                                        <Col md={8}>
                                            <div className='membership-tab-content'>
                                                <MemberShipHeader text="Affiliate" />
                                                {feature.length ?
                                                    <FeatureSection feature={feature} />
                                                    : <div className="row mt-4">
                                                        <div className="col-md-12 text-center">
                                                            No Matching Records
                                                        </div>
                                                    </div>}

                                                <div className='affiliate-area'>
                                                    <p><img src={require("../images/badge-3.png").default} alt="badge" /><i>Affiliate (Referral used less than 1000 get 5% Commission)</i></p>
                                                    <p><img src={require("../images/badge-2.png").default} alt="badge" /><i>Gold Affiliate (Referral used 1000-5000 will get 10% Commission)</i></p>
                                                    <p><img src={require("../images/diamond_badge.png").default} alt="badge" style={{ width: "19px", height: "25px" }} /><i>Xcellent Affiliate (Referral used more than 5000 will get 15% Commission)</i></p>
                                                </div>

                                                <div className='monthly-yearly-btn'>
                                                    <div className={`monthly-btn ${activeDuration === "monthly" ? `active-button-section` : ``} ${planDetail.duration === "yearly" ? 'monthly-btn-disable' : 'active-btn'}`} onClick={() => toggleDurationBox('monthly')}>
                                                        {/* removing over lay functionality in case of yearly plan subscribed by user */}
                                                        {/* Monthly case
                                                        if plan is affilate and duration month then info icon is hide and tooltip is disabled 
                                                        if plan is affilate and duration year then info icon is hide and tooltip is disabled
                                                        */}
                                                        {
                                                            planDetail.plan === "affiliate" && planDetail.duration === "monthly" ? '' :
                                                                planDetail.plan === "affiliate" && planDetail.duration === "yearly" || planDetail.duration === "yearly" ?
                                                                    // <i className="fa fa-info-circle info-icon" aria-hidden="true" />
                                                                    ''
                                                                    : <MemberShipDetail type="monthly" plan={plan} />
                                                        }

                                                        {/* {planDetail.plan === "affiliate" && planDetail.duration === "monthly" || planDetail.duration === "yearly" ? <i className="fa fa-info-circle info-icon" aria-hidden="true" /> : <MemberShipDetail type="monthly" plan={plan} />} */}
                                                        <h6><img src={require('../images/calender.png').default} alt="calender" />Monthly: <span>${plan.cost ? plan.cost : 0}/month </span></h6>
                                                    </div>
                                                    <div className={`yearly-btn ${activeDuration === "yearly" ? `active-button-section` : ``} active-btn`} onClick={() => toggleDurationBox('yearly')}>
                                                        {/* Yearly case
                                                        if plan is affilate and duration month then info icon and tooltip are enabled for year
                                                        if plan is affilate and duration year then info icon is hide and tooltip is disabled                                                  
                                                        */}

                                                        {
                                                            planDetail.plan === "affiliate" && planDetail.duration === "monthly" ? <MemberShipDetail type="yearly" plan={plan} /> :
                                                                planDetail.plan === "affiliate" && planDetail.duration === "yearly" ? '' :
                                                                    <MemberShipDetail type="yearly" plan={plan} />
                                                            //   <i className="fa fa-info-circle info-icon" aria-hidden="true" />
                                                        }

                                                        {/* {planDetail.plan === "affiliate" &&planDetail.duration === "yearly" ? <i className="fa fa-info-circle info-icon" aria-hidden="true" /> : <MemberShipDetail type="yearly" plan={plan} />} */}

                                                        <h6><img src={require('../images/calender.png').default} alt="calender" />Yearly: <span>${plan.yearly_price ? plan.yearly_price : 0}/year </span></h6>
                                                        {plan.yearly_discount ? <p><i>*Discount {plan.yearly_discount}%</i></p> : ''}
                                                    </div>
                                                </div>
                                                <div className='purchase-btn'>
                                                    <Button type='button' onClick={() => {
                                                        if (planDetail.plan === "affiliate" && activeDuration === "monthly") {
                                                            console.log('You cannot buy previous selected plan');
                                                        } else {
                                                            if (activeDuration) {
                                                                next();
                                                            } else {
                                                                toast.error('Please select Monthly/Yearly duration.')
                                                            }
                                                        }
                                                    }}
                                                        disabled={planDetail.plan === "affiliate" && planDetail.duration === "yearly" ? true : false}>
                                                        {/* {planDetail.plan === "affiliate" && planDetail.duration === "yearly" ? "Active Plan" :
                                                            planDetail.plan === "affiliate" && planDetail.duration === "monthly" ? "Upgrade" : "Purchase"} */}
                                                        {window.location.pathname === "/membership/plan" ? "Purchase" : activeDuration !== planDetail.duration ? "Upgrade" : planDetail.plan === "affiliate" && planDetail.duration === "yearly" ? "Active Plan" :
                                                            planDetail.plan === "affiliate" && planDetail.duration === "monthly" ? "Active Plan" : 'Upgrade'}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab>
                                {
                                    associateStatus === "0" || associateStatus === "1" ? "" :
                                        // buisness section will be hide in case 1 and case 3 
                                        // case 1 is for approved and case 3 expired
                                        quoteStatus === "1" || quoteStatus === "3" ? '' :
                                            <Tab eventKey="business" title='BUSINESS LICENSE'>
                                                <Row className="justify-content-center ">
                                                    <Col md={8}>
                                                        <div className={`${quoteStatus ? quoteStatus === "0" ? `border-0` : `` : ``} business-license-contact-area`}>
                                                            <h2 className={quoteStatus ? quoteStatus === "0" ? `border-0` : `` : ``}>{quoteStatus === "0" ? "Contact Status" : "Contact for Quote"}</h2>
                                                            {quoteStatus === "0" ? <div className='business-card-border'>
                                                                <div className="centered-element"><img src={require('../images/quote_request.svg').default} alt="check" /></div>
                                                                <p className='my-4'>Your request for company license has been submitted to admin. Please wait for admin approval. </p>
                                                                <div className="centered-element mb-2"><span className='btn-progress'>In Progress</span></div>
                                                            </div> : <Formik
                                                                initialValues={quoteForm}
                                                                validationSchema={quoteFormSchema}
                                                                onSubmit={(values) => {
                                                                    setShowLoader("flex");
                                                                    sendCompanyRequest(values);
                                                                }}>
                                                                {({ errors, touched, values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                                                    <Form onSubmit={handleSubmit} autoComplete="off">
                                                                        <Form.Group className="mb-4" controlId="phone_no">
                                                                            <Form.Label>Phone Number</Form.Label>
                                                                            <Form.Control type="tel" placeholder="Enter Phone Number" name="phone_no" value={values.phone_no} onChange={(event: any) => {
                                                                                if (ACCEPT_NUMBER.test(event.target.value)) {
                                                                                    handleChange(event);
                                                                                }
                                                                            }
                                                                            } minLength="10" maxLength="13" />
                                                                            {errors.phone_no && touched.phone_no ? (<label id="uname-error" className="error" htmlFor="phone_no"> {errors.phone_no}</label>) : null}
                                                                        </Form.Group>
                                                                        <Form.Group className="mb-4" controlId="name">
                                                                            <Form.Label>Business Name</Form.Label>
                                                                            <Form.Control type="text" placeholder="Enter Business Name" name="name" value={values.name} onChange={handleChange} maxLength="50" />
                                                                            {errors.name && touched.name ? (<label id="uname-error" className="error" htmlFor="name"> {errors.name}</label>) : null}
                                                                        </Form.Group>
                                                                        <Form.Group className="mb-4" controlId="employees_count">
                                                                            <Form.Label>Number of Employees</Form.Label>
                                                                            <Form.Control type="text" placeholder="Number of Employees" name="employees_count" value={values.employees_count} onChange={(event: any) => {
                                                                                if (ACCEPT_NUMBER.test(event.target.value)) {
                                                                                    handleChange(event);
                                                                                }
                                                                            }} maxLength="7" />
                                                                            {errors.employees_count && touched.employees_count ? (<label id="uname-error" className="error" htmlFor="employees_count"> {errors.employees_count}</label>) : null}
                                                                        </Form.Group>
                                                                        {quoteStatus === "2" ? <div className="centered-element"><label className='error'>* Your request rejected by admin, please check the reason mentioned in mail and resubmit the details.</label></div> : ''}
                                                                        <Button variant="unset" type="submit" className={quoteStatus === "2" ? "mt-0" : ""}>Quote</Button>
                                                                    </Form>
                                                                )}
                                                            </Formik>}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Tab>
                                }
                                {/* {quoteStatus !== "1"?:''} */}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

// Common function component for membership headers
function MemberShipHeader(props: any) {
    return (
        <div className='membership-top-content'>
            <div className='membership-top-left-content'>
                <img src={require("../images/pointer-pitcure.png").default} alt="pointer-pitcure" />
            </div>
            <div className='membership-top-right-content'>
                <h5>{props.text}</h5>
                {props.text === "Consumer Free" ? <p>Starter</p> : ''}
            </div>
        </div>
    )
}

// common function component for feature section
function FeatureSection(props: any) {
    let { feature } = props;
    return (
        <div className='d-flex consumer-area'>
            <div className='consumer-left'>
                {feature.length ? <ul>
                    {
                        feature.map((value: any, index: any) => {
                            if (index < 11) {
                                return (
                                    <li key={`${value.features}_${index}`}>{value.features}</li>
                                )
                            } else {
                                return false
                            }
                        })
                    }
                </ul> : ''
                }
            </div>
            <div className='consumer-right'>
                {feature.length ? <ul>
                    {
                        feature.map((value: any, index: any) => {
                            if (index >= 11) {
                                return (
                                    <li key={`${value.features}_${index}`}>{value.features}</li>
                                )
                            } else {
                                return false
                            }
                        })
                    }
                </ul> : ''
                }
            </div>
        </div>
    )
}

function MemberShipDetail(props: any) {
    let { plan, type } = props;
    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip>
                    <div className="row ">
                        <div className="col-md-12 text-left">
                            <strong>Actual Price:</strong> ${type === "monthly" ? plan.cost ? plan.cost : 0 : plan.yearly_price ? plan.yearly_price : 0}
                        </div>
                        {
                            type === "monthly" ? plan.monthly_discounted_price ?
                                <MemberShipSingleSection label="Remaining Balance" value={plan.monthly_discounted_price} />
                                : '' : plan.yearly_discounted_price ?
                                <MemberShipSingleSection label="Remaining Balance" value={plan.yearly_discounted_price} />
                                : ''
                        }
                        <div className="col-md-12 text-left">
                            <strong>Amount Payable:</strong> ${type === "monthly" ? plan.monthly_payable_amount ? plan.monthly_payable_amount : 0 : plan.yearly_payable_amount ? plan.yearly_payable_amount : 0}
                        </div>
                    </div>
                </Tooltip>
            }>
            <i className="fa fa-info-circle info-icon active-btn" aria-hidden="true" />
        </OverlayTrigger>
    )
}

function MemberShipSingleSection(props: any) {
    return (
        <div className="col-md-12 text-left">
            <strong>{props.label}:</strong> ${props.value}
        </div>
    )
}
export default MemberShipPlan;