import React from 'react';
import axios from "axios";

function Interceptor() {
    
    axios.interceptors.request.use(function (config) {
        // console.log('axios.interceptors.request',config);
        return config;
    }, function (error) {
        // TODO Do something with request error
        // console.log('axios.interceptors.request error...',error);
        return Promise.reject(error);
    });

    // TODO Add a response interceptor
    const interceptor = axios.interceptors.response.use(function (response) {
        // console.log('axios.interceptors.response',response);
        return response;
    }, function (error) {
        // console.log('axios.interceptors.response error',error);

        // TODO Any status codes that falls outside the range of 2xx cause this function to trigger
        // TODO Do something with response error
        // Reject promise if usual error
        if (error.response.status !== 401) {
            return Promise.reject(error);
        }
        /* 
         * When response code is 401, try to refresh the token.
         * Eject the interceptor so it doesn't loop in case
         * token refresh causes the 401 response
         */
        axios.interceptors.response.eject(interceptor);

    });

    return <React.Fragment />;
}

export default Interceptor;