import { CONSUMER_FREE, CONSUMER, AFFILIATE } from '../../config/constants';

export function returnFeatureByPlanId(planId: any) {
    switch (planId) {
        case 1:
            return CONSUMER_FREE;
        case 2:
            return CONSUMER;
        case 3:
            return AFFILIATE;
        default:
            return false
    }
}