import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { userService } from '../_services';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { Form } from 'react-bootstrap';
import { Loader } from './Loader';
import moment from 'moment';

const Details = () => {
    const [showloader, setShowloader] = useState({ display: "none" });
    const [data, setData] = useState({}) as any;
    const [compId, setCompId] = useState("") as any;
    const [referCode, setReferCode] = useState("");
    const [referModal, setReferModal] = useState(false);
    const [improve, setImprove] = useState(false);
    const [isCouponApplied, setIsCouponApplied] = useState(false);

    let history = useHistory();
    
    useEffect(() => {
        toggleLoader("flex");
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let comp_id = params.get("comp_id") as any;
        
        localStorage.setItem("comp_id", comp_id);
        getCompetitionDetail(comp_id);
        setCompId(comp_id);
    }, []);


    function getCompetitionDetail(comp_id: any) {
        userService.competitionDetails(comp_id).then(resp => {
            setData(resp.data.data);
            if (resp.data.data.status == 'J') {
                setImprove(true);
            } else {
                setImprove(false);
            }
            toggleLoader("none");
            let refCode=localStorage.getItem('discount_code');
            if(refCode){
                setReferCode(refCode);
                setIsCouponApplied(true);
            }else{
                setIsCouponApplied(false);
            }
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            toggleLoader("none");
            setIsCouponApplied(false);
        })
    }

    function joinCompetition(e: any) {
        e.preventDefault();
        toggleLoader("flex");
        if (data.amount > 0) {
            // payment();
            let amt=isCouponApplied?data.paid_amount:data.amount;
            setIsCouponApplied(false);
            history.push(`/payment?comp_id=${data.competition_id}&amount=${amt}${referCode?`&refer_code=${referCode}`:''}`);
        } else {
            joinCompetitionApi();
        }
    }

    // API of Join Competition
    function joinCompetitionApi(){
        let object = {
            competition_id: parseInt(compId)
        } as any;
        if (referCode) {
            // object.referCode = referCode;
            object.discount_code = referCode;
        }
        userService.joinCompetition(object).then(resp => {
            if (resp.data.status) {
                toast.success("Competition joined successfully.");
                // localStorage.setItem('viaIsDetail', "true");
                toggleLoader("none");
                history.push('/competition');
                localStorage.setItem('discount_code','');
            } else {
                toast.error(resp.data.message?resp.data.message:"Something went wrong.");
                toggleLoader("none");
                // localStorage.setItem('viaIsDetail', "false");
            }
            localStorage.setItem('discount_code','');
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            toast.error("Something went wrong.");
            toggleLoader("none");
        })
    }

    function applyReferral() {
        toggleLoader("flex");
        var params = {
            type: "C",
            discount_code: referCode,
            amount: data.entry_fee,
            // competiton_type: data.entry_fee === "0" ?  "free":"paid",
            isFreeCompetition: data.entry_fee === "0" ?  "free":"paid",
            competition_id: parseInt(compId)
        };
        userService.applyDiscountCode(params).then(resp => {
            toggleLoader("none");
            if (resp.data.status === true) {
                toast.success("Referral code applied.");
                setData((prevData:any)=>{
                    return{
                        ...prevData,
                        ...resp.data.data

                    }
                })
                setReferModal(false);
                setIsCouponApplied(true);
                localStorage.setItem('discount_code',referCode);
            } else {
                toast.error(resp.data.message);
                setIsCouponApplied(false);
            }
        }).catch(err => {
            if (err.response) {
                if (err.response.status == 401) {
                    localStorage.clear();
                    window.location.href = '/';
                }
                toggleLoader("none");
                toast.error(err.response.data.message);
            }
            toggleLoader("none");
            setIsCouponApplied(false);
        })
    }

    function toggleLoader(status: any) {
        setShowloader(prevShowloader => {
            return {
                ...prevShowloader,
                display: status
            }
        });
    }

    function back() {
        let compId = localStorage.getItem("comp_id");
        let url = `/live_competition${compId ? `?comp_id=${parseInt(compId)}` : ''}`;
        localStorage.setItem('discount_code','');
        history.push(url);
    }
    console.log('data...',data); 
    return (
        <>
            <section className="profile-page py-5">
                <Loader status={showloader.display} />
                <div className="container">
                    <div className="inner_box ">
                        <Row>
                            <Col md={12} sm={12} xl={12}>
                                <h5 className='mb-2 cursor-pointer'>
                                    <span onClick={back} role="button">
                                        <img src={require('../images/back.svg').default} alt="back-btn" />
                                    </span>
                                </h5>
                            </Col>
                        </Row>
                        <Row className="bodyvitals_head align-items-center">
                            <Col md={4} sm={5}>
                                <h2>Details</h2>
                                <h5 className="live_1">{data.title}</h5>
                            </Col>
                            <Col md={8} sm={7} className="startendrow">
                                <h6 className="startenddate">Start Date: <span>{moment(data.start_date).format('MM/DD/YYYY')}</span></h6>
                                <h6 className="startenddate mt-2">End Date: <span>{moment(data.end_date).format('MM/DD/YYYY')}</span></h6>
                            </Col>
                            <Col md={12}>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="points_details text-center">
                                <h4 className="mt-3 mb-1">Total Awards: ${data.award_money}</h4>
                                <p className="mb-2">Minimum Points to Qualify: {data.min_point}</p>
                                <div className="prize_images">
                                    <p className="text-center d-inline-block p-2">
                                        <span className="prixeimg_box">
                                            <img src={require('../images/prize1.png').default} alt="prize1" />
                                        </span>
                                        <span className="d-block">${data.first_price_usd}</span>
                                    </p>
                                    <p className="text-center d-inline-block p-2">
                                        <span className="prixeimg_box">
                                            <img src={require('../images/prize2.png').default} alt="prize2" />
                                        </span>
                                        <span className="d-block">${data.second_price_usd}</span>
                                    </p>
                                    <p className="text-center d-inline-block p-2">
                                        <span className="prixeimg_box">
                                            <img src={require('../images/prize3.png').default} alt="prize3" />
                                        </span>
                                        <span className="d-block">${data.third_price_usd}</span>
                                    </p>
                                </div>
                            </Col>
                            <Col md={12} className="points_details text-center">
                                <h4>Bonus Awards</h4>
                                <p className="mb-1 mt-2">1st to 500 Points: ${data.five_hundred_bonus_usd}</p>
                                <p className="mb-1">1st to Share 5 days in a row: ${data.five_day_bonus_usd}</p>
                                <p className="mb-1">1st to Share 10 days in a row: ${data.ten_day_bonus_usd}</p>
                            </Col>
                            <Col md={12} className="points_details text-center mt-3 mb-2">
                                <h4 className="mt-3">Wellness Message Empowerment Ads</h4>
                                <p className="mb-1 mt-2 widthtext">According to the American Diabetes Association 1.4 million Americans are diagnosed with diabetes every year and diabetes is the &th leading cause of death.</p>
                            </Col>
                            <Row className="justify-content-center w-100">
                                <div className="col-md-5 bg-blue-box text-center">
                                    <h3>Being Physical Active help to <br />fight Diabetes</h3>
                                    <p>Pleadge with me workout at least 15 minutes each day this week!</p>
                                </div>
                            </Row>
                            <Col lg={12} md={12} className="text-center my-3">
                                {
                                    parseInt(data.entry_fee) ?
                                        <>
                                            <div className="mb-3">
                                                <h5>Entry Fee: ${data.entry_fee}</h5>
                                                {isCouponApplied ? <h5>Total Discount: ${data.discount_amount}</h5> : ''}
                                                {isCouponApplied ? <h5>To Pay: ${data.paid_amount}</h5> : ''}
                                            </div>
                                            {/* <a onClick={() => { setReferModal(true) }} className="invite_text mb-3">Apply Referral Code</a> */}
                                        </>
                                        : ""
                                }
                                {
                                    improve ? moment().format('YYYY-MM-DD') <= data.end_date ?'': '' :
                                    !isCouponApplied ?
                                        <button type="button" className="btn btn-link invite_text mb-3" onClick={() => { setReferModal(true) }}>Apply Referral Code</button> :
                                        <button type="button" className="btn btn-link invite_text mb-3" onClick={() => {
                                            setReferCode('');
                                            getCompetitionDetail(compId);
                                            localStorage.setItem('discount_code','');
                                        }}>Remove Applied Code</button>
                                }
                                {/* <h3 className="invite_text">Invite User</h3>
                                <div className="invite_users_links">
                                    <Link to="#/" className="mx-1">
                                        <img src={require('../images/invite_fb.svg').default} alt="invite_fb" />
                                    </Link>
                                    <Link to="#/" className="mx-1">
                                        <img src={require('../images/invite_twitter.svg').default} alt="invite_twitter" />
                                    </Link>
                                    <Link to="#/" className="mx-1">
                                        <img src={require('../images/invite_link.svg').default} alt="invite_link" />
                                    </Link>
                                    <Link to="#/" className="mx-1">
                                        <img src={require('../images/invite_youtube.svg').default} alt="invite_youtube" />
                                    </Link>
                                </div> */}
                            </Col>
                            <Col md={12} className="text-center">
                                <div className="mt-4 accptinvitation_btn">
                                    {
                                        improve ? moment().format('YYYY-MM-DD') <= data.end_date ?
                                            <Link to={"/"}>
                                                <Button onClick={() => {
                                                    localStorage.setItem('record', 'yes');
                                                    localStorage.setItem('viaIsDetail', "true");
                                                }} className="border-0 btnsumit">
                                                    Improve your wellness
                                                </Button>
                                            </Link> : '' :
                                            <Link to={"/"}><Button onClick={joinCompetition} className="border-0 btnsumit">Accept Invitation</Button></Link>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
            <Modal show={referModal} onHide={(e: any) => { setReferModal(false); setReferCode("") }}>
                <Modal.Header closeButton><h5>Apply Referral code</h5></Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(event: Event) => {
                        event.preventDefault();
                        if (referCode.trim()) {
                            applyReferral()
                        } else {
                            toast.error('Please Enter Referral Code')
                        }
                    }} autoComplete="off">
                        <Row>
                            <Col>
                                <Form.Label>Enter referral code below</Form.Label>
                                <Form.Control name="referCode" value={referCode} onChange={(e: any) => { setReferCode(e.target.value) }} placeholder="Referral Code" required />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col className="text-center">
                                <Button className="px-4" type="submit">Apply</Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Details;