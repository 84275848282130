import { useEffect, useState } from 'react'
import { Form, Container, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Chart from "react-google-charts";
import { userService } from '../_services';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from './Loader';
import { getItems, setItems } from '../utils/localStorage';
// SWAL
import Swal from 'sweetalert2'
// IMAGE
import ProfileImage from '../images/profile.png';


const InviteGroup = () => {

    let history = useHistory();
    const [show, setShow] = useState("none");
    const [data, setData] = useState([]) as any;
    const [chartData, setChartData] = useState([]) as any;
    const [groupUsers, setGroupUsers] = useState([]) as any;
    const [metric_list, setMetric] = useState([]) as any;
    const [requestStatus, setRequestStatus] = useState(false);
    let user_id = localStorage.getItem("user_id");

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let g_id = params.get("id")
        if (!g_id) {
            toast.error("Error while loading group detail.")
            history.push("/group_chat?id=" + g_id)
        }
        setShow("flex");
        // common local storage
        setItems('group_id', g_id);
        getRequestlist(`?group_id=${Number(g_id)}&page_no=0`);
        getGroupDetail(g_id);
    }, []);

    // get group detail by group id
    function getGroupDetail(g_id: any) {
        userService.groupDetail({ "group_id": Number(g_id), "metric_id": "0" }).then(resp => {
            if (!resp.data.data.metric_list) {
                history.push("/group");
            }
            setShow("none");
            setData(resp.data.data);
            setMetric(resp.data.data.metric_list);
            var tempChart = [['Day', 'Group', 'Me']] as any;

            if (resp.data.data.group_graph.length) {
                resp.data.data.group_graph.forEach((value: any) => {
                    if (parseFloat(value.group_avg) > 0 || parseFloat(value.my_data) > 0) {
                        tempChart.push([value.day, parseFloat(value.group_avg), parseFloat(value.my_data)]);
                    } else {
                        tempChart.push([value.day, 0, 0]);
                    }
                })
            }

            let status=false;
            resp.data.data.leaderBoard.forEach((element: any) => {
                if(element.member_id === Number(user_id)){
                    status=true;
                }
            });
            // in leaderboard it contain list of user who joined group if user not exist in that group then we will redirect user to group listing page
            if(!status){
                toast.error('This group is no longer available');
                history.push('/group');
            }

            // for (let index = 0; index < resp.data.data.group_graph.length; index++) {
            //     const element = resp.data.data.group_graph[index];
            //     if(parseFloat(element.group_avg)>0 || parseFloat(element.my_data)>0){
            //         tempChart.push([element.day, element.group_avg, element.my_data]);
            //     }else{
            //         tempChart.push([element.day, null, null]);
            //     }
            // }

            setGroupUsers(resp.data.data.leaderBoard);
            setChartData(tempChart);
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            setShow("none");
            toast.error('Error while loading group detail.');
        })
    }

    function updateGraph(e: any) {
        setShow("flex")
        userService.groupGraph({ "group_id": data.group_id, "metric_id": e.target.value }).then(resp => {
            setShow("none")
            // var tempChart = []
            // if (resp.data.group_graph.length) {
            //     for (let index = 0; index < resp.data.group_graph.length; index++) {
            //         const element = resp.data.group_graph[index];
            //         tempChart.push([element.day, element.group_avg, element.my_data])
            //     }
            // }

            var tempChart = [['Day', 'Group', 'Me']] as any;
            if (resp.data.group_graph.length) {
                resp.data.group_graph.forEach((value: any) => {
                    if (parseFloat(value.group_avg) > 0 || parseFloat(value.my_data) > 0) {
                        tempChart.push([value.day, parseFloat(value.group_avg), parseFloat(value.my_data)]);
                    } else {
                        tempChart.push([value.day, 0, 0]);
                    }
                })
            }
            setChartData(tempChart)
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            setShow("none")
            toast.error('Error while loading group detail.')
        })
    }

    // get request list for group admin
    function getRequestlist(params: any) {
        userService.getRequestlist(params).then((response: any) => {
            if (response.data.data.length) {
                setRequestStatus(true);
            } else {
                setRequestStatus(false);
            }
        }).catch((error: any) => {
            if (error.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
        })
    }

    // redirect to previous page
    function back() {
        let isDetail = localStorage.getItem("viaIsDetail");
        if (isDetail === "true") {
            history.push('/notification');
        } else {
            history.go(-1);
        }
    }

    function redirectToViewRequest() {
        history.push('/viewrequest');
    }

    // remove group member
    function removeGroupMember(id: any) {
        Swal.fire({
            text: `Are you sure you want to remove this member from group ?`,
            title: "Alert",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No"
        }).then(e => {
            if (e.value) {
                setShow("flex");
                deleteGroupMember(id);
            }
        })
    }

    // delete group member
    function deleteGroupMember(id: any) {
        let gId = getItems('group_id');
        userService.deleteGroupMember(`${gId}?member_id=${id}`).then((response: any) => {
            getGroupDetail(gId);
        }).catch((error: any) => {
            console.log('error', error)
            setShow("none");
        })
    }

    console.log('data',data);   

    return (
        <>
            <section className="bodyvital_section py-5">
                <Loader status={show} />
                <Container>
                    <div className="inner_box pb-0">
                        <div className={`row bodyvitals_head ${requestStatus?'justify-content-center':''}`}>
                            <div className={requestStatus ? 'col-md-3' : 'col-md-6'}>
                                <h5>
                                    <span onClick={back}>
                                        <img src={require('../images/back.svg').default} alt="back" />
                                    </span>
                                </h5>
                                <h2>{data.group_name}</h2>
                            </div>
                            {
                                data.created_id == Number(user_id) ?
                                    <div className={`${requestStatus ? 'col-md-3' : 'col-md-6'} text-right`}>
                                        <Link to={`/editgroup?id=${data.group_id}`}>
                                            <button className="border-0 bg-transparent">
                                                <img src={require('../images/edit_icon.svg').default} alt="transparent" />
                                            </button>
                                        </Link>
                                    </div> : ""
                            }
                            {
                                requestStatus ? <div className="col-md-6 user-invite-section mb-4"><span className='pr-4'>You have new request for the group</span>
                                    <button className="invite_user_button mb-0 btn btn-unset" onClick={() => redirectToViewRequest()}>View Request</button>
                                </div>
                                    : <div className="col-md-12">
                                        <hr />
                                    </div>
                            }
                        </div>
                        <div className="row">
                            <div className="col-md-7">
                                <div className="gorup_Details_head">
                                    <div className="user_image_row1">
                                        <img src={data.group_image} alt="gorup_Details_head" />
                                    </div>
                                    <h5>Created By:
                                        <span> {data.created_id == Number(user_id) ? "Me" : data.created_by}</span></h5>
                                </div>
                                <div className="Description_row mt-3">
                                    <h5>Description</h5>
                                    <p>{data.description}</p>
                                </div>
                                {data.today_data == 'N'
                                    ?
                                    <div className="text-center">
                                        <p className="text-danger">Your data for today is missing. Please enter your data to be in the competition.</p>
                                        <Button variant="unset" style={{ width: "auto" }} className="btnsumit text-white" onClick={() => {
                                            localStorage.setItem('record', 'yes');
                                            localStorage.setItem('viaInviteGroup', "true")
                                            if (window.location.search.includes("?id=")) {
                                                localStorage.setItem('inviteGroupId', window.location.search.replace("?id=", ""))
                                            }
                                            history.push('/')
                                        }}>
                                            Improve your wellness
                                        </Button>
                                    </div>
                                    :
                                    ""
                                }
                                <div className="chart_head">
                                    <h5>Avg. of the graph</h5>
                                    <Form.Group controlId="allMetric">
                                        <Form.Control as="select" onChange={updateGraph}>
                                            <option value="0">All Metric</option>
                                            {metric_list.map((d: any) => {
                                                return (
                                                    <option value={Number(d.id)} key={`${d.id}`}>{d.metrics}</option>
                                                )
                                            })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                <Chart
                                    width={'100%'}
                                    height={'350px'}
                                    chartType="Bar"
                                    loader={<div>Loading Chart</div>}
                                    data={chartData}
                                    options={{
                                        colors: ['#F6E4A9', '#1AA0E6'],
                                        responsive: true,
                                        legend: {
                                            position: 'none'
                                        }
                                    }}
                                    rootProps={{ 'data-testid': '0' }}
                                />
                                <div className="text-center my-4">
                                    <span className="legend_Row">
                                        <span className="legend_box1 mr-2"></span>Group avg.
                                    </span>
                                    <span className="legend_Row">
                                        <span className="legend_box2 mr-2"></span>Your record
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-5">
                            <div className={`gorup_Details_head group_list_row1 d-block ${groupUsers.length > 7?"group_detail_scroll":""}`} >
                                        <h5 className="d-block ml-0 mb-4">Group Members <span>({groupUsers.length})</span> </h5>
                                        {
                                            data.leaderBoard
                                                ? groupUsers.map((user: any, i: any) => {
                                                    return (
                                                        <div className={`group_list_row2 ${data.created_id === Number(user_id) ? 'justify-content-between' : ''} `} key={`${user.firstname}_${i}`}>
                                                            <div className="user_image_row1">
                                                                <img src={user.profileImage ? user.profileImage : ''} alt="group_list_row2" onError={(event: any) => { event.target.src = ProfileImage; }} />
                                                            </div>
                                                            <span>{user.firstname} {user.lastname}</span>
                                                            {
                                                                // data.created_id == Number(user_id)?''
                                                                data.created_id === user.member_id ? <span className={`${data.created_id === Number(user_id)?'':'ml-auto'} error`}>Admin</span> :
                                                                data.created_id == Number(user_id) ? <button className="accept_reject_btn" onClick={() => removeGroupMember(user.member_id)}><i className="fa fa-trash reject_btn" /></button> : ''
                                                            }
                                                        </div>
                                                    )
                                                })
                                                : ""
                                        }
                                    </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}
export default InviteGroup;