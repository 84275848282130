import { useEffect, useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { userService } from '../_services';
import { useHistory } from 'react-router';
import { Loader } from './Loader';
import { paginationObject } from '../config/config';
import Swal from 'sweetalert2';
import ProfileImage from '../images/profile.png';

const Friends = () => {
    const [show, setShow] = useState("none");
    const [data, setData] = useState([]);
    const [allFriends, setAllFriends] = useState([]);
    const [request, setRequest] = useState([]);
    const [search, setSearch] = useState("");
    const [isSearchtrigger, setIsSearchTrigger] = useState(false);
    const [pagination, setPagination] = useState({ ...paginationObject });

    let history = useHistory();

    useEffect(() => {
        localStorage.removeItem("groupTab")
        localStorage.removeItem("compTab")
        setShow("flex");
        friendList();
        friendRequestList();
    }, []);

    // get friend list of user list
    function friendList() {
        let params = `?page_no=${pagination.page_number}${search ? `&keyword=${search}` : ''}`;
        userService.friendList(params).then(resp => {
            setShow("none");
            setData(resp.data.data);
            setAllFriends(resp.data.data);
            setSearch('');
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            setShow("none")
        })
    }

    // get list of friend request list
    function friendRequestList() {
        userService.friendRequestList().then(resp => {
            setRequest(resp.data.data)
            setShow("none")
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            setShow("none")
        })
    }


    function handleFriendSearch(event: any) {
        event.preventDefault();
        triggerSearch();
    }

    function triggerSearch() {
        let array = [] as any;
        array = allFriends.filter((x: any) => {
            let fullName = x.firstname + " " + x.lastname;
            if (fullName.toLowerCase().includes(search)) {
                return x;
            } else {
                return false;
            }
        });
        setData(array);
        setShow("none");
        setIsSearchTrigger(false);

    }

    function handleSearchInput(event: any) {
        let { value } = event.target;
        if (value) {
            setIsSearchTrigger(true);
        } else {
            friendList();
        }
        setShow("flex");
        setSearch(value);
    }

    useEffect(() => {
        if (isSearchtrigger) {
            triggerSearch();
        }
    }, [isSearchtrigger]);

    // confirm unfriend user
    function confirmUnfriendUser(id: any) {
        Swal.fire({
            text: `Are you sure you want to unfriend this user?`,
            title: "Alert",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText:"No"
        }).then(e => {
            if (e.value) {
                setShow('flex');
                unfriendUser(id);
            }
        })
    }

    // API of Unfriend user
    function unfriendUser(id: any) {
        userService.unfriendUser(id).then((res: any) => {
            setShow('none');
            friendList();
        }).catch((err: any) => {

        })
    }

    function handlePagination(name: any, value: any) {
        setPagination((...prevPagination) => {
            return {
                ...prevPagination,
                [name]: value
            }
        })
    }

    console.log('pagination', pagination);

    return (
        <>
            <section className="bodyvital_section friend_sections py-5">
                <Loader status={show} />
                <Container>
                    <div className="inner_box pb-4">
                        <Row className="align-items-center">
                            <Col xl={4} lg={2}>
                                <h2 className="d-inline">My Friends</h2>
                            </Col>
                            <Col xl={8} lg={10} className="text-right">
                                <div className="d-flex justify-content-end align-items-center my-friend-head">
                                    <span onClick={() => { history.push('/addfriends') }} className="pr-3 cursor-pointer friend-head-one">
                                        <span className="newrqst1">Add new friend</span>
                                        <span className="position-relative d-inline-block ml-2">
                                            <img src={require('../images/addF.png').default} alt="addF" className="ml-1" width="22px" />
                                        </span>
                                    </span>
                                    <div className={request.length ? "cursor-pointer friend-head-one" : 'friend-head-one'} onClick={() => {
                                        if (request.length) {
                                            history.push('/friendrequestlist')
                                        }
                                    }}>
                                        <span className="newrqst1">{request.length ? 'You Have New Friend Request' : 'No new friend request'}</span>
                                        <span className="position-relative d-inline-block ml-2">
                                            <img src={require('../images/bell_1.svg').default} alt="bell_1" width="22px" />
                                            {request.length ? <span className="count">{request.length}</span> : ''}
                                        </span>
                                    </div>
                                    {/* {request.length?
                                        <div className="cursor-pointer" onClick={() => { history.push('/friendrequestlist') }}>
                                            <span className="newrqst1">You Have New Friend Request</span>
                                            <span className="position-relative d-inline-block ml-2">
                                                <img src={require('../images/bell_1.svg').default} alt="bell_1" width="22px" />
                                                <span className="count">{request.length}</span>
                                            </span>
                                        </div>
                                        :
                                        <div>
                                            <span className="newrqst1">No new friend request</span>
                                            <span className="position-relative d-inline-block ml-2">
                                                <img src={require('../images/bell_1.svg').default} alt="bell_1" width="22px" />
                                            </span>
                                        </div>
                                    } */}
                                    <Form className="friend-head-one" autoComplete="off" onSubmit={(event: any) => { handleFriendSearch(event) }}>
                                        <Form.Group className="mb-0 d-inline-block ml-2 searchfrnd_input" controlId="myFriend" style={{ minWidth: "260px" }}>
                                            <div className="search_bar_row">
                                                <Form.Control type="text" placeholder="Search here" className="chat_search_box" value={search} onChange={(event: any) => { handleSearchInput(event) }} required />
                                                <button type={search ? "reset" : "submit"} className="btn btn-link" onClick={() => {
                                                    if (search) {
                                                        setShow("flex");
                                                        friendList();
                                                    }
                                                }}><img src={require(search ? '../images/cross_23.svg' : '../images/search.svg').default} alt="search" width="18px" /></button>
                                                {/* <span><img src={require('../images/search.svg').default} alt="" width="18px" /></span> */}
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Col>
                            <Col md={12}><hr /></Col>
                        </Row>

                        <Row>
                            {data.length ? data.map((f: any, i: any) => {
                                console.log('map...', f);
                                return (
                                    <Col lg={6} md={6} sm={6} key={i}>
                                        <div className="users_box-friend mb-3 ">
                                            <div className='d-flex align-items-center c-pointer' onClick={() => {
                                                localStorage.setItem('otherFriendChat', `${f.firstname} ${f.lastname}`)
                                                history.push(`/friendchat?room=${btoa(f.room_id)}&uid=${btoa(f.id)}`)
                                            }}>
                                                <div className="invite_user_image c-pointer" >
                                                    {f.profileImage == 'https://nlpbackend.xcellentlife.com/profile/' || !f.profileImage
                                                        ?
                                                        <img src={require('../images/usericon.svg').default} />
                                                        :
                                                        <img width="100%" height="100%" src={f.profileImage} alt="profile" onError={(event: any) => { event.target.src = ProfileImage; }} />
                                                    }
                                                </div>
                                                <span>{`${f.firstname} ${f.lastname}`}</span>
                                            </div>
                                            <div className="invite_btn1">
                                                <button className='border-0 btnsumit btn btn-unset mb-0' onClick={() => confirmUnfriendUser(f.id)}>Unfriend</button>
                                            </div>
                                            {/* <div className="invite_btn1"><i className="fa fa-angle-right nextarrow" /></div> */}
                                        </div>
                                    </Col>
                                )
                            }) : <Col className="text-center mt-3"><p>No data found</p></Col>
                            }
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Friends;