import { useState, useEffect } from "react";
// import { useForm, useStep } from "react-hooks-helper";
import { Steps, Step } from "react-step-builder";
import Step1 from "./create_profile/Step1";
import Step2 from "./create_profile/Step2";
import Step3 from "./create_profile/Step3";
import Step4 from "./create_profile/Step4";
import Step5 from "./create_profile/Step5";
import { userService } from '../_services';
import { Loader } from './Loader';
import profieimage from "../images/user_avtar.svg";

const defaultData = {
  firstname: "",
  email: "",
  lastname: "",
  profileImage: "",
  profileImageshow: profieimage,
  accountNumber: "",
  routingNumber: "",
  birthDate: null,
  gender: "",
  location: "",
  height: "",
  weight: "",
  bloodGroup: "",
  paypal_email: "",
  expiryDate: "",
  cardNumber: "",
  cvv: "",
  latitude: null,
  longitude: null,
  account_holder_name: "",
  branchLocation: "",
  ssn_no: '',
  // isEmailVerified:false,
  formIsValid: false,
  editProfile: false
} as any;
const errors = {
  firstname: "",
  email: "",
  lastname: "",
  profileImage: "",
  profileImageshow: "",
  accountNumber: "",
  routingNumber: "",
};

const MultiStepForm = (props: any) => {
  const [showloader, setShow] = useState("none");
  useEffect(() => {
    let accesstoken = localStorage.getItem('accesstoken_create')
    if (accesstoken) {
      setShow("flex");
      // alert(accesstoken)
      userService.userdetail(accesstoken).then((response) => {

        const userInfo: any = response.data.data;

        if (userInfo) {

          // console.log(`${config.Imageurl}`+userInfo.profileImagePath)
          defaultData.firstname = userInfo.firstname
          defaultData.email = userInfo.email
          defaultData.lastname = userInfo.lastname
          defaultData.height = userInfo.height
          defaultData.weight = userInfo.weight
          defaultData.bloodGroup = userInfo.bloodGroup
          defaultData.cardNumber = userInfo.cardNumber
          defaultData.profileImageshow = userInfo.profileImage
          defaultData.paypal_email = userInfo.paypal_email
          defaultData.gender = userInfo.gender
          if (userInfo.location && userInfo.location != 'null') {
            defaultData.location = userInfo.location
          }

          if (userInfo.birthDate && userInfo.birthDate != 'Invalid date') {
            defaultData.birthDate = userInfo.birthDate
          }
          // else{
          //   defaultData.birthDate ="01-01-";
          // }
          defaultData.accountNumber = userInfo.accountNumber
          defaultData.routingNumber = userInfo.routingNumber
          defaultData.profileImage = userInfo.profileImage;
          defaultData.account_holder_name=userInfo.account_holder_name;
          defaultData.ssn_no=userInfo.ssn_no;          
          setShow("none");
        }
      }).catch((error: any) => {
        setShow("none");
      });
    }

  }, []);

  const Navigation = (props: any) => {
    return (
      <div>
        {props.current == 1 ? (
          <button onClick={props.next}>Next</button>
        ) : (<> <button onClick={props.prev}>Previous</button>
          <button onClick={props.next}>Next</button></>)}
      </div>
    );
  }

  const Before = (props: any) => {
    return <span />
  }

  const After = (props: any) => {
    return <></>
  }
  const prop = { defaultData, errors };
  const config: any = {
    before: Before, // a React component with special props provided automatically
    after: After, // a React component with special props provided automatically
    navigation: {
      component: Navigation, // a React component with special props provided automatically
      location: "after" // or after
    }
  }
  return (
    <>
      <Loader status={showloader} />
      <Steps >
        <Step component={Step1} {...prop} />
        <Step component={Step2} {...prop} />
        <Step component={Step3} {...prop} />
        <Step component={Step4} {...prop} />
        <Step component={Step5} {...prop} />
      </Steps>
    </>
  )
};

export default MultiStepForm;