import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Form, Container, Button, Row, Col } from 'react-bootstrap';
// import Chart from "react-google-charts";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userService } from '../_services';
import { Loader } from './Loader';
import { returnTotalPages } from '../utils/pagination';
import Pagination from './Pagination';
// IMAGE
import ProfileImage from '../images/profile.png';


const Chatlist = () => {
    const [search, setSearch] = useState("");
    const [searchData, setSearchData] = useState([]) as any;
    const [data, setData] = useState([]) as any;
    const [show, setShow] = useState("none");
    const [delModal, setDelModal] = useState(false);
    const [otherid, setOtherid] = useState(0);
    const [paginationObj, setPaginationObj] = useState({
        page: 0,
        totalPage: 0
    }) as any;
    const [isPaginationtrigger, setIsPaginationTrigger] = useState(false);
    const [isSearchTrigger, setIsSearchTrigger] = useState(false);

    useEffect(() => {
        toggleLoader("block");
        getChatList();
    }, [])


    function getChatList() {
        userService.getChatList(`?page_no=${paginationObj.page}${search ? `&keyword=${search}` : ``}`).then((resp: any) => {
            if (resp.data.data) {
                setData(resp.data.data);
                setSearchData(resp.data.data);
                let total_page = returnTotalPages(resp.data.total_records, 10);
                updatePaginationState('totalPage', total_page);
            }
            toggleLoader("none");
            setIsPaginationTrigger(false);
        }).catch(err => {
            setIsPaginationTrigger(false);
            toggleLoader("none");
            toast.error("Unknown error occured.")
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
        })
    }

    const handleSearch = (e: any) => {
        setSearch(e.target.value);

        // var s = e.target.value;
        // var temp = [] as any;
        // for (let index = 0; index < data.length; index++) {
        //     const element = data[index];
        //     let fullName=element.firstname+" "+ element.lastname;
        //     if (fullName.toLowerCase().includes(s)) {
        //         temp.push(element);
        //     }
        // }
        // setSearchData(temp);
    }

    function proceedDelChat() {
        toggleLoader("block");
        let param = {
            "other_id": Number(otherid)
        }
        userService.deleteChat(param).then(resp => {
            if (resp.data) {
                var temp = data
                for (let index = 0; index < temp.length; index++) {
                    const element = temp[index];
                    if (element.other_id == otherid) {
                        temp.splice(index, 1);
                    }
                }
                toast.success(resp.data.message);
                setDelModal(false);
                toggleLoader("none");

            } else {
                toast.error("An error occured");
                toggleLoader("none");
                setDelModal(false);
            }
        }).catch(err => {
            toast.error("An error occured");
            setDelModal(false);
            toggleLoader("none");
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
        })
    }

    function msgType(msg: string, t: any) {
        if (t == 'F') {
            if (msg.toLowerCase().endsWith("png") || msg.toLowerCase().endsWith("jpg") || msg.toLowerCase().endsWith("jpeg")) {
                return "Image";
            }
            if (msg.toLowerCase().endsWith("mov") || msg.toLowerCase().endsWith("mp4")) {
                return "Video";
            }
            if (msg.toLowerCase().endsWith("ppt") || msg.toLowerCase().endsWith("pptx") || msg.toLowerCase().endsWith("pdf") || msg.toLowerCase().endsWith("xls") || msg.toLowerCase().endsWith("xlsx") || msg.toLowerCase().endsWith("doc") || msg.toLowerCase().endsWith("docx")) {
                return "Document";
            }
        }
        else {
            return msg;
        }
    }


    function updatePaginationState(name: any, value: any) {
        setPaginationObj((prevPaginationObj: any) => {
            return {
                ...prevPaginationObj,
                [name]: value
            }
        });
    }

    function toggleLoader(status: any) {
        setShow(status);
    }

    function handlePageClick(event: any) {
        if (event.selected <= paginationObj.totalPage) {
            updatePaginationState('page', event.selected);
            toggleLoader("block");
            setIsPaginationTrigger(true);
        }
    }

    useEffect(() => {
        if (isPaginationtrigger) {
            getChatList();
        }
    }, [isPaginationtrigger])

    function handleSearchSubmit(event: any) {
        event.preventDefault();
        updatePaginationState('page', 0);
        toggleLoader("block");
        setIsPaginationTrigger(true);
        setIsSearchTrigger(true);
    }

    function clearSearch() {
        setSearch('');
        setIsSearchTrigger(false);
        updatePaginationState('page', 0);
        toggleLoader("block");
        setIsPaginationTrigger(true);
    }

    return (
        <>
            <section className="bodyvital_section chatlist_section py-5">
                <Loader status={show} />
                <Container>
                    <div className="inner_box pb-4">
                        <Row className="bodyvitals_head align-items-center">
                            <Col md={9}>
                                <h2 className="mb-0">Chat</h2>
                            </Col>
                            <Col md={3} className="text-right">
                                <Form autoComplete="off" onSubmit={(event: any) => { handleSearchSubmit(event) }}>
                                    <Form.Group className="mb-0" controlId="search">
                                        <div className="search_bar_row">
                                            <Form.Control type="text" value={search} placeholder="Search here" className="chat_search_box" onChange={handleSearch} />
                                            {
                                                isSearchTrigger ?
                                                    <button type="reset" className='btn btn-link' onClick={() => clearSearch()}>
                                                        <img src={require('../images/cross_23.svg').default} alt="search" width="18px" />
                                                    </button> :
                                                    <button type="submit" className='btn btn-link' disabled={search ? false : true}>
                                                        <img src={require('../images/search.svg').default} alt="search" width="18px" />
                                                    </button>
                                            }


                                        </div>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col md={12}><hr /></Col>
                        </Row>
                        <Row>
                            {
                                searchData.length ?
                                    searchData.map((f: any, index: any) => {
                                        return (
                                            <Col md={6} key={`${f.room_id}_${index}`}>
                                                <Link className="users_box mb-3" to={"/friendchat?room=" + btoa(f.room_id) + "&uid=" + btoa(f.other_id)} onClick={() => {
                                                    localStorage.setItem('otherFriendChat', f.firstname + " " + f.lastname)
                                                }}>
                                                    <div className="invite_user_image">
                                                        {
                                                            f.profileImage == "https://nlpbackend.xcellentlife.com/profile/" || !f.profileImage
                                                                ?
                                                                <img src={require('../images/usericon.svg').default} alt="usericon" />
                                                                :
                                                                <img src={f.profileImage} alt="user" width="100%" height="100%" onError={(event: any) => { event.target.src = ProfileImage; }} />
                                                        }

                                                    </div>
                                                    {/* <span className="ofine1"></span> */}
                                                    <div>
                                                        <span>{`${f.firstname} ${f.lastname}`}</span>
                                                        <span className="msg1">
                                                            {msgType(f.message, f.message_type)}
                                                        </span>
                                                    </div>
                                                    <div className="invite_btn1">
                                                        <i onClick={(e: any) => {
                                                            e.preventDefault()
                                                            setOtherid(f.other_id)
                                                            setDelModal(true)
                                                        }}
                                                            className="fa fa-trash nextarrow mr-3 text-danger" style={{ color: "#9EA9AE" }} />
                                                        <i className="fa fa-angle-right nextarrow" style={{ color: "#9EA9AE" }} />
                                                    </div>
                                                    {/* <div className="invite_btn1">
                                                        <span className="chat_time">2 days ago</span>
                                                    </div> */}
                                                </Link>
                                            </Col>
                                        )
                                    })
                                    :
                                    <Col md={12} lg={12} sm={12} className="text-center py-4"><p>No chat found</p></Col>
                            }


                        </Row>
                        {
                            paginationObj.totalPage > 1 ?
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <Pagination {...paginationObj} handlePageClick={handlePageClick} />
                                    </div>
                                </div>
                                : ''
                        }
                    </div>
                </Container>
            </section>
            <Modal centered show={delModal} onHide={() => { setDelModal(false) }}>
                <Modal.Header style={{ background: "#1AA0E6" }} closeButton>
                    <Modal.Title className="w-100 text-center"><h4 className="text-white">XcellentLife</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body><h5 className="py-3">Are you sure you want to delete this chat?</h5></Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col md={5} lg={5} className="d-flex">
                            <Button onClick={proceedDelChat} className="px-4 text-white mr-5" variant="unset" style={{ background: "#1AA0E6" }}>Yes</Button>
                            <Button onClick={() => { setDelModal(false) }} className="px-4 text-white" variant="secondary">No</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Chatlist;