import { Modal } from 'react-bootstrap';
import { IMAGE_REGEX } from '../config/constants';

function VideoPreviewModal(props: any) {
    let { status, selectedVideo, modalType, play } = props;
    return (
        <Modal show={status} onHide={() => props.onClose(false, '')} size="md" centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton className="border-0 headerclose" />
            <Modal.Body className="text-center py-4">
                <div className="container_fluid mt-4">
                    <div className="row">
                        <div className="col-md-12">
                            {modalType === "file_upload" ? <video src={selectedVideo} controls={true} autoPlay={false} className="modal_video" /> : ''}
                            {
                                modalType === "message_media_view" ?
                                    IMAGE_REGEX.test(`.${play.split('/').pop().split('.').pop()}`) ? <img src={play} className="modal_image" alt={`competition`} /> : <video src={play} controls={true} autoPlay={false} className="modal_video" />
                                    : ''
                            }
                        </div>
                    </div>
                    {
                        modalType === "file_upload" ?
                            <div className='row'>
                                <div className="col-md-12 justify-content-center">
                                    <button type="button" className="swal2-confirm swal2-styled" onClick={() => props.sendMessage()} style={{ display: 'inline-block', borderLeftColor: 'rgb(48, 133, 214)', borderRightColor: 'rgb(48, 133, 214)' }}>Send</button>
                                    <button type="button" onClick={() => props.onClose(false, '')} className="swal2-cancel swal2-styled" style={{ display: 'inline-block' }}>Cancel</button>
                                </div>
                            </div> : ''
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default VideoPreviewModal;