import React from 'react';
import { Modal } from 'react-bootstrap';


function RejectUserModal(props: any) {
    let { status } = props;

    return (
        <React.Fragment>
            <Modal show={status} onHide={() => props.onClose(false, '')} size="md" centered backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton className="border-0 p-4"><h5 className='text-center'>Reject Reason</h5></Modal.Header>
                <Modal.Body className="text-center pt-0 precaution-modal-main">
                    <div className="container">
                        <form onSubmit={(event:any)=>{props.handleModalSubmit(event)}}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <textarea className='form-control' name="reason" value={props.reason} onChange={(event:any)=>props.handleModalInput(event)} rows={3} cols={50} maxLength={500}/>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center">
                                    <button type="submit" className='btnsumit text-white btn btn-unset'>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}
export default RejectUserModal;