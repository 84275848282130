/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Bar, Line } from 'react-chartjs-2';
import { userService } from '../_services';
import {Loader} from './Loader';

const Bloodworkgraph = () => {

  const [healthSummary, setHealthSummary] = useState() as any
  const [graphData, setgraphData] = useState('week') as any
  const [graphColors, setGraphColors] = useState({ glucose: {} })
  let pressurelabels: any = [];
  let heartlabels: any = [];
  let heartdata: any = [];
  let heartDataColors: Array<string | number> = [];
  let pressured: any = [];
  let pressuredColors: Array<string | number> = [];
  let pressured1: any = [];
  let oxylabels: any = [];
  let oxytdata: any = [];
  let oxytdataColors: Array<string | number> = [];
  const [showloader, setShowloader] = useState({ display: "none" });

  let bodylabels: any = [];
  let bodydata: any = [];
  let bodydataColors: any = [];
  let reslabels: any = [];
  let resdata: any = [];
  let resdataColors: any = [];
  let bmlabels: any = [];
  let bmdata: any = [];
  let bmdataColors: any = [];
  let Hlabels: any = [];
  let Hdata: any = [];
  let HdataColors: any = [];
  let Wlabels: any = [];
  let Wdata: any = [];
  let WdataColors: any = [];
  let Wlabels1: any = [];
  let Wdata1: any = [];
  let Wdata1Colors: any = [];

  const chartOptions = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  }

  useEffect(() => {
    // setShow({display:"flex"})
    setShowloader({ display: "flex" })
    userService.blood_work_graph().then(response => { return response.data })
      .then((result) => {
        setHealthSummary(result);
        setShowloader({ display: "none" })
      })
      .catch(error => {
        setShowloader({ display: "none" })
        if (error.response?.status == 401) {
          localStorage.clear()
          window.location.href = '/'
        }
      });
  }, [])

  if (healthSummary) {
    const glucoseLevel_green = healthSummary['data']['Glucose Level']['metric_ranges'][0]['green'].split("-")
    const glucoseLevel_yellow_low = healthSummary['data']['Glucose Level']['metric_ranges'][0]['yellow_low'].split("-")
    const glucoseLevel_yellow_high = healthSummary['data']['Glucose Level']['metric_ranges'][0]['yellow_high'].split("-")
    const glucoseLevel_red_low = healthSummary['data']['Glucose Level']['metric_ranges'][0]['red_low'].replace("<", "")
    const glucoseLevel_red_high = healthSummary['data']['Glucose Level']['metric_ranges'][0]['red_high'].replace(">", "")

    // const cholesterolLevel_yellow_low = healthSummary['data']['Cholesterol Level']['metric_ranges'][0]['yellow_low'].split("-")
    // const cholesterolLevel_red_low = healthSummary['data']['Cholesterol Level']['metric_ranges'][0]['red_low'].replace("<", "")
    const cholesterolLevel_green = healthSummary['data']['Cholesterol Level']['metric_ranges'][0]['green'].split("-")
    const cholesterolLevel_yellow_high = healthSummary['data']['Cholesterol Level']['metric_ranges'][0]['yellow_high'].split("-")
    const cholesterolLevel_red_high = healthSummary['data']['Cholesterol Level']['metric_ranges'][0]['red_high'].replace(">", "")

    const oxytdataLevel_green = healthSummary['data']['Cholesterol Level LDL']['metric_ranges'][0]['green'].split("-")
    const oxytdataLevel_yellow_high = healthSummary['data']['Cholesterol Level LDL']['metric_ranges'][0]['yellow_high'].split("-")
    const oxytdataLevel_red_high = healthSummary['data']['Cholesterol Level LDL']['metric_ranges'][0]['red_high'].replace(">", "")

    const bodydataLevel_green = healthSummary['data']['Cholesterol Level HDL']['metric_ranges'][0]['green'].split("-")
    const bodydataLevel_yellow_low = healthSummary['data']['Cholesterol Level HDL']['metric_ranges'][0]['yellow_low'].split("-")
    const bodydataLevel_red_low = healthSummary['data']['Cholesterol Level HDL']['metric_ranges'][0]['red_low'].replace("<", "")

    const resLevel_green = healthSummary['data']['Cholesterol Level Triglycerides']['metric_ranges'][0]['green'].split("-")
    const resLevel_yellow_high = healthSummary['data']['Cholesterol Level Triglycerides']['metric_ranges'][0]['yellow_high'].split("-")
    const resLevel_red_high = healthSummary['data']['Cholesterol Level Triglycerides']['metric_ranges'][0]['red_high'].replace(">", "")

    const bmDatazLevel_green = healthSummary['data']['PSA Level']['metric_ranges'][0]['green'].split("-")
    const bmDatazLevel_yellow_low = healthSummary['data']['PSA Level']['metric_ranges'][0]['yellow_low'].split("-")
    const bmDatazLevel_red_low = healthSummary['data']['PSA Level']['metric_ranges'][0]['red_low'].replace("<", "")
    const bmDatazLevel_red_high = healthSummary['data']['PSA Level']['metric_ranges'][0]['red_high'].replace(">", "")

    const hDataLevel_green = healthSummary['data']['Testosterone Level']['metric_ranges'][0]['green'].split("-")
    const hDataLevel_yellow_low = healthSummary['data']['Testosterone Level']['metric_ranges'][0]['yellow_low'].split("-")
    const hDataLevel_yellow_high = healthSummary['data']['Testosterone Level']['metric_ranges'][0]['yellow_high'].split("-")
    const hDataLevel_red_low = healthSummary['data']['Testosterone Level']['metric_ranges'][0]['red_low'].replace("<", "")
    const hDataLevel_red_high = healthSummary['data']['Testosterone Level']['metric_ranges'][0]['red_high'].replace(">", "")

    const wDataLevel_green = healthSummary['data']['Estrogen Level']['metric_ranges'][0]['green'].split("-")
    const wDataLevel_yellow_low = healthSummary['data']['Estrogen Level']['metric_ranges'][0]['yellow_low'].split("-")
    const wDataLevel_yellow_high = healthSummary['data']['Estrogen Level']['metric_ranges'][0]['yellow_high'].split("-")
    const wDataLevel_red_low = healthSummary['data']['Estrogen Level']['metric_ranges'][0]['red_low'].replace("<", "")
    const wDataLevel_red_high = healthSummary['data']['Estrogen Level']['metric_ranges'][0]['red_high'].replace(">", "")

    const wData1Level_green = healthSummary['data']['Progesterone Level']['metric_ranges'][0]['green'].split("-")
    const wData1Level_yellow_low = healthSummary['data']['Progesterone Level']['metric_ranges'][0]['yellow_low'].split("-")
    const wData1Level_yellow_high = healthSummary['data']['Progesterone Level']['metric_ranges'][0]['yellow_high'].split("-")
    const wData1Level_red_low = healthSummary['data']['Progesterone Level']['metric_ranges'][0]['red_low'].replace("<", "")
    const wData1Level_red_high = healthSummary['data']['Progesterone Level']['metric_ranges'][0]['red_high'].replace(">", "")

    if (graphData == "week") {
      // Glucose Level
      healthSummary['data']['Glucose Level']['values'].map(function (gdata: any, index: any) {
        pressurelabels.push(gdata['day'])
        const val = gdata['measured_data']
        pressured.push(val)
        if (val > +glucoseLevel_green[0] && val < +glucoseLevel_green[1]) {
          pressuredColors.push("#00d700")
        }
        else if ((val >= +glucoseLevel_yellow_low[0] && val <= +glucoseLevel_yellow_low[1]) && (val >= glucoseLevel_yellow_high[0] && val <= glucoseLevel_yellow_high[1])) {
          pressuredColors.push("#c7c500")
        }
        else if (val < +glucoseLevel_red_low || val > +glucoseLevel_red_high) {
          pressuredColors.push("#ff0000")
        }
      })

      // Cholesterol Level
      healthSummary['data']['Cholesterol Level']['values'].map(function (gdata: any, index: any) {
        heartlabels.push(gdata['day'])
        const val = gdata['measured_data']
        heartdata.push(val)
        if (val >= +cholesterolLevel_green[0] && val < +cholesterolLevel_green[1]) {
          heartDataColors.push("#00d700")
        }
        else if (val >= +cholesterolLevel_yellow_high[0] && val < +cholesterolLevel_yellow_high[1]) {
          heartDataColors.push("#c7c500")
        }
        else {
          heartDataColors.push("#ff0000")
        }
      })

      // Cholesterol Level LDL
      healthSummary['data']['Cholesterol Level LDL']['values'].map(function (gdata: any, index: any) {
        oxylabels.push(gdata['day'])
        const val = gdata['measured_data']
        oxytdata.push(val)
        if (val >= oxytdataLevel_green[0] && val < oxytdataLevel_green[1]) {
          oxytdataColors.push("#00d700")
        }
        else if (val > +oxytdataLevel_yellow_high[0] && val <= +oxytdataLevel_yellow_high[1]) {
          oxytdataColors.push("#c7c500")
        }
        else if (val > +oxytdataLevel_red_high) {
          oxytdataColors.push("#ff0000")
        }
      })

      // Cholesterol Level HDL
      healthSummary['data']['Cholesterol Level HDL']['values'].map(function (gdata: any, index: any) {
        const measuredData = gdata['measured_data']
        bodylabels.push(gdata['day'])
        bodydata.push(measuredData)
        if (measuredData >= +bodydataLevel_green[0] && measuredData <= +bodydataLevel_green[1]) {
          bodydataColors.push("#00d700")
        }
        else if (measuredData >= +bodydataLevel_yellow_low[0] && measuredData <= +bodydataLevel_yellow_low[1]) {
          bodydataColors.push("#c7c500")
        }
        else {
          bodydataColors.push("#ff0000")
        }
      })

      healthSummary['data']['Cholesterol Level Triglycerides']['values'].map(function (gdata: any, index: any) {
        reslabels.push(gdata['day'])
        const val = gdata['measured_data']
        resdata.push(val)
        if (val >= +resLevel_green[0] && val <= +resLevel_green[1]) {
          resdataColors.push("#00d700")
        }
        else if (val >= +resLevel_yellow_high[0] && val <= +resLevel_yellow_high[1]) {
          resdataColors.push("#c7c500")
        }
        else {
          resdataColors.push("#ff0000")
        }
      })

      healthSummary['data']['PSA Level']['values'].map(function (gdata: any, index: any) {
        const measuredData = gdata['measured_data']
        bmlabels.push(gdata['day'])
        bmdata.push(measuredData)
        if (measuredData >= +bmDatazLevel_green[0] && measuredData <= +bmDatazLevel_green[1]) {
          bmdataColors.push("#00d700")
        } else if (measuredData >= +bmDatazLevel_yellow_low[0] && measuredData <= +bmDatazLevel_yellow_low[1]) {
          bmdataColors.push("#c7c500")
        } else {
          bmdataColors.push("#ff0000")
        }
      })

      healthSummary['data']['Testosterone Level']['values'].map(function (gdata: any, index: any) {
        const graphVal = gdata['measured_data']
        Hlabels.push(gdata['day'])
        Hdata.push(gdata['measured_data'])
        if (graphVal >= +hDataLevel_green[0] && graphVal <= +hDataLevel_green[0]) {
          HdataColors.push("#00d700")
        }
        else if ((graphVal >= +hDataLevel_yellow_low[0] && graphVal < +hDataLevel_yellow_low[1]) && (graphVal >= +hDataLevel_yellow_high[0] && graphVal < +hDataLevel_yellow_high[1])) {
          HdataColors.push("#c7c500")
        }
        else {
          HdataColors.push("#ff0000")
        }
      })

      healthSummary['data']['Estrogen Level']['values'].map(function (gdata: any, index: any) {
        const graphVal = gdata['measured_data']
        Wlabels.push(gdata['day'])
        Wdata.push(graphVal)
        if (graphVal >= +wDataLevel_green[0] && graphVal <= +wDataLevel_green[1]) {
          WdataColors.push("#00d700")
        } else if ((graphVal >= +wDataLevel_yellow_low[0] && graphVal <= +wDataLevel_yellow_low[1]) || (graphVal >= +wDataLevel_yellow_high[0] && graphVal <= +wDataLevel_yellow_high[1])) {
          WdataColors.push("#c7c500")
        } else {
          WdataColors.push("#ff0000")
        }
      })

      healthSummary['data']['Progesterone Level']['values'].map(function (gdata: any, index: any) {
        const graphVal = gdata['measured_data']
        Wlabels1.push(gdata['day'])
        Wdata1.push(graphVal)
        if (graphVal >= +wData1Level_green[0] && graphVal <= +wData1Level_green[1]) {
          Wdata1Colors.push("#00d700")
        } else if ((graphVal >= +wData1Level_yellow_low[0] && graphVal <= +wData1Level_yellow_low[1]) || (graphVal >= +wData1Level_yellow_high[0] && graphVal <= +wData1Level_yellow_high[1])) {
          Wdata1Colors.push("#c7c500")
        } else {
          Wdata1Colors.push("#ff0000")
        }
      })

    } else {
      // Glucose Level
      let tempData = healthSummary['month']['Glucose Level']['values']
      for (let index = 0; index < tempData.length; index++) {
        const gData = tempData[index];
        pressurelabels.push("week" + gData['week'])
        let graphVal = gData['measured_week_data'].split('/')
        pressured.push(graphVal[0])
        pressured1.push(graphVal[1])
        if (graphVal[0] > +glucoseLevel_green[0] && graphVal[0] < +glucoseLevel_green[1]) {
          pressuredColors.push("#00d700")
        }
        else if ((graphVal[0] >= +glucoseLevel_yellow_low[0] && graphVal[0] <= +glucoseLevel_yellow_low[1]) && (graphVal[0] >= glucoseLevel_yellow_high[0] && graphVal[0] <= glucoseLevel_yellow_high[1])) {
          pressuredColors.push("#c7c500")
        }
        else if (graphVal[0] < +glucoseLevel_red_low || graphVal[0] > +glucoseLevel_red_high) {
          pressuredColors.push("#ff0000")
        }
      }

      // Cholesterol Level
      healthSummary['month']['Cholesterol Level']['values'].map(function (gdata: any, index: any) {
        heartlabels.push("week" + gdata['week'])
        const val = gdata['measured_week_data']
        heartdata.push(val)
        if (val >= +cholesterolLevel_green[0] && val < +cholesterolLevel_green[1]) {
          heartDataColors.push("#00d700")
        }
        else if (val >= +cholesterolLevel_yellow_high[0] && val < +cholesterolLevel_yellow_high[1]) {
          heartDataColors.push("#c7c500")
        }
        else {
          heartDataColors.push("#ff0000")
        }
      })

      // Cholesterol Level LDL
      healthSummary['month']['Cholesterol Level LDL']['values'].map(function (gdata: any, index: any) {
        oxylabels.push("week" + gdata['week'])
        const val = gdata['measured_week_data']
        oxytdata.push(val)
        if (val >= oxytdataLevel_green[0] && val < oxytdataLevel_green[1]) {
          oxytdataColors.push("#00d700")
        }
        else if (val > +oxytdataLevel_yellow_high[0] && val <= +oxytdataLevel_yellow_high[1]) {
          oxytdataColors.push("#c7c500")
        }
        else if (val > +oxytdataLevel_red_high) {
          oxytdataColors.push("#ff0000")
        }
      })

      // Cholesterol Level HDL
      healthSummary['month']['Cholesterol Level HDL']['values'].map(function (gdata: any, index: any) {
        const measuredData = gdata['measured_week_data']
        bodylabels.push("week" + gdata['week'])
        bodydata.push(measuredData)
        if (measuredData >= +bodydataLevel_green[0] && measuredData <= +bodydataLevel_green[1]) {
          bodydataColors.push("#00d700")
        }
        else if (measuredData >= +bodydataLevel_yellow_low[0] && measuredData <= +bodydataLevel_yellow_low[1]) {
          bodydataColors.push("#c7c500")
        }
        else {
          bodydataColors.push("#ff0000")
        }
      })

      // Cholesterol Level Triglycerides
      healthSummary['month']['Cholesterol Level Triglycerides']['values'].map(function (gdata: any, index: any) {
        reslabels.push("week" + gdata['week'])
        const val = gdata['measured_week_data']
        resdata.push(val)
        if (val >= +resLevel_green[0] && val <= +resLevel_green[1]) {
          resdataColors.push("#00d700")
        }
        else if (val >= +resLevel_yellow_high[0] && val <= +resLevel_yellow_high[1]) {
          resdataColors.push("#c7c500")
        }
        else {
          resdataColors.push("#ff0000")
        }
      })

      healthSummary['month']['PSA Level']['values'].map(function (gdata: any, index: any) {
        const measuredData = gdata['measured_week_data']
        bmlabels.push("week" + gdata['week'])
        bmdata.push(measuredData)
        if (measuredData >= +bmDatazLevel_green[0] && measuredData <= +bmDatazLevel_green[1]) {
          bmdataColors.push("#00d700")
        } else if (measuredData >= +bmDatazLevel_yellow_low[0] && measuredData <= +bmDatazLevel_yellow_low[1]) {
          bmdataColors.push("#c7c500")
        } else {
          bmdataColors.push("#ff0000")
        }
      })

      healthSummary['month']['Testosterone Level']['values'].map(function (gdata: any, index: any) {
        const graphVal = gdata['measured_week_data']
        Hlabels.push("week" + gdata['week'])
        Hdata.push(graphVal)
        if (graphVal >= +hDataLevel_green[0] && graphVal <= +hDataLevel_green[0]) {
          HdataColors.push("#00d700")
        }
        else if ((graphVal >= +hDataLevel_yellow_low[0] && graphVal < +hDataLevel_yellow_low[1]) && (graphVal >= +hDataLevel_yellow_high[0] && graphVal < +hDataLevel_yellow_high[1])) {
          HdataColors.push("#c7c500")
        }
        else {
          HdataColors.push("#ff0000")
        }
      })

      healthSummary['month']['Estrogen Level']['values'].map(function (gdata: any, index: any) {
        const graphVal = gdata['measured_week_data']
        Wlabels.push("week" + gdata['week'])
        Wdata.push(graphVal)
        if (graphVal >= +wDataLevel_green[0] && graphVal <= +wDataLevel_green[1]) {
          WdataColors.push("#00d700")
        } else if ((graphVal >= +wDataLevel_yellow_low[0] && graphVal <= +wDataLevel_yellow_low[1]) || (graphVal >= +wDataLevel_yellow_high[0] && graphVal <= +wDataLevel_yellow_high[1])) {
          WdataColors.push("#c7c500")
        } else {
          WdataColors.push("#ff0000")
        }
      })

      healthSummary['month']['Progesterone Level']['values'].map(function (gdata: any, index: any) {
        const graphVal = +gdata['measured_week_data']
        Wlabels1.push("week" + gdata['week'])
        Wdata1.push(graphVal)
        if (graphVal >= +wData1Level_green[0] && graphVal <= +wData1Level_green[1]) {
          Wdata1Colors.push("#00d700")
        } else if ((graphVal >= +wData1Level_yellow_low[0] && graphVal <= +wData1Level_yellow_low[1]) || (graphVal >= +wData1Level_yellow_high[0] && graphVal <= +wData1Level_yellow_high[1])) {
          Wdata1Colors.push("#c7c500")
        } else {
          Wdata1Colors.push("#ff0000")
        }
      })
    }
  }

  const data = {
    labels: pressurelabels,
    datasets: [
      {
        label: 'Glucose Level',
        backgroundColor: '#1AA0E6',
        borderColor: '#1AA0E6',
        borderWidth: 1,
        pointRadius: 7,
        pointHoverRadius: 10,
        data: pressured,
        pointBackgroundColor: pressuredColors
      }
    ]
  }


  const dataheart = {
    labels: heartlabels,
    datasets: [
      {
        label: 'Cholesterol Level',
        data: heartdata,
        borderColor: '#1AA0E6',
        backgroundColor: '#1AA0E6',
        pointRadius: 7,
        pointHoverRadius: 10,
        borderWidth: 1,
        pointBackgroundColor: heartDataColors
      }
    ],
  };
  const dataoxy = {
    labels: oxylabels,
    datasets: [
      {
        label: 'Cholesterol Level  (LDL)',
        data: oxytdata,
        backgroundColor: '#1AA0E6',
        pointRadius: 7,
        borderColor: '#1AA0E6',
        pointHoverRadius: 10,
        borderWidth: 1,
        pointBackgroundColor: oxytdataColors
      }
    ],
  };
  const databody = {
    labels: bodylabels,
    datasets: [
      {
        label: 'Cholesterol Level (HDL)',
        data: bodydata,
        backgroundColor: '#1AA0E6',
        pointRadius: 7,
        borderColor: '#1AA0E6',
        pointHoverRadius: 10,
        borderWidth: 1,
        pointBackgroundColor: bodydataColors
      }
    ],
  };
  const datares = {
    labels: reslabels,
    datasets: [
      {
        label: 'Cholesterol Level (Triglycerides)',
        data: resdata,
        backgroundColor: '#1AA0E6',
        pointRadius: 7,
        borderColor: '#1AA0E6',
        pointHoverRadius: 10,
        borderWidth: 1,
        pointBackgroundColor: resdataColors
      }
    ],
  };
  const databm = {
    labels: bmlabels,
    datasets: [
      {
        label: 'PSA Level',
        data: bmdata,
        backgroundColor: '#1AA0E6',
        pointRadius: 7,
        borderColor: '#1AA0E6',
        pointHoverRadius: 10,
        borderWidth: 1,
        pointBackgroundColor: bmdataColors
      }
    ],
  };
  const dataH = {
    labels: Hlabels,
    datasets: [
      {
        label: 'Testosterone Level',
        data: Hdata,
        backgroundColor: '#1AA0E6',
        pointRadius: 7,
        borderColor: '#1AA0E6',
        pointHoverRadius: 10,
        borderWidth: 1,
        pointBackgroundColor: HdataColors
      }
    ],
  };
  const dataW = {
    labels: Wlabels,
    datasets: [
      {
        label: 'Estrogen Level',
        data: Wdata,
        backgroundColor: '#1AA0E6',
        pointRadius: 7,
        borderColor: '#1AA0E6',
        pointHoverRadius: 10,
        borderWidth: 1,
        pointBackgroundColor: WdataColors
      }
    ],
  };
  const dataW1 = {
    labels: Wlabels1,
    datasets: [
      {
        label: 'Progesterone Level',
        data: Wdata1,
        backgroundColor: '#1AA0E6',
        pointRadius: 7,
        borderColor: '#1AA0E6',
        pointHoverRadius: 10,
        borderWidth: 1,
        pointBackgroundColor: Wdata1Colors
      }
    ],
  };
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <>
      {console.log(Wdata1Colors)}
      <section className="bodyvital_section py-5">
        <Loader status={showloader.display}/>
        <Container>
          <div className="inner_box">
            <Row className="bodyvitals_head">
              <Col md={6}>
                <h5>
                  <a href="/">
                    <img src={require('../images/back.svg').default} alt="back" />
                  </a>
                </h5>
                <h2>Blood Work Graphs</h2>
              </Col>
              <Col md={6} className="text-right">
                <h6>Date Measured</h6>
                <select onChange={(e: any) => setgraphData(e.target.value)}>
                  <option value="week" selected={graphData == 'week'}>Weekly</option>
                  <option value="month" selected={graphData == 'month'}>Monthly</option>
                </select>
                {/* <DatePicker
                  onChange={onChange}
                  value={value}
                /> */}
              </Col>
              <Col md={12}>
                <hr />
              </Col>
              <Col md={12}>
                <hr />
              </Col>

              {/* "PSA Level","Testosterone Level","Estrogen Level","Progesterone Level"] */}
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Glucose Level</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                <Line data={data} options={chartOptions} />
                {healthSummary ? (

                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true"/><span className="gooddata"> Good:</span>  {healthSummary['data']['Glucose Level']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true"/><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Glucose Level']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Glucose Level']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true"/><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Glucose Level']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Glucose Level']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (<>
                        <p><i className="fa fa-square gooddata" aria-hidden="true"/><span className="gooddata"> Good:</span>  {healthSummary['month']['Glucose Level']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true"/><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Glucose Level']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Glucose Level']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true"/><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Glucose Level']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Glucose Level']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>)}
                  </div>) : (<></>)}
              </Col>


              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Cholesterol Level</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                <Line options={chartOptions} data={dataheart}
                />
                {healthSummary ? (

                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true"/><span className="gooddata"> Good:</span>  {healthSummary['data']['Cholesterol Level']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true"/><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Cholesterol Level']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Cholesterol Level']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true"/><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Cholesterol Level']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Cholesterol Level']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (<>
                        <p><i className="fa fa-square gooddata" aria-hidden="true"/><span className="gooddata"> Good:</span>  {healthSummary['month']['Cholesterol Level']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true"/><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Cholesterol Level']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Cholesterol Level']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true"/><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Cholesterol Level']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Cholesterol Level']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>)}
                  </div>) : (<></>)}

              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Cholesterol Level (LDL)</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                <Line options={chartOptions} data={dataoxy}
                />
                {healthSummary ? (

                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true"/><span className="gooddata"> Good:</span>  {healthSummary['data']['Cholesterol Level LDL']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true"/><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Cholesterol Level LDL']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Cholesterol Level LDL']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true"/><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Cholesterol Level LDL']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Cholesterol Level LDL']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (<>
                        <p><i className="fa fa-square gooddata" aria-hidden="true"/><span className="gooddata"> Good:</span>  {healthSummary['month']['Cholesterol Level LDL']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true"/><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Cholesterol Level LDL']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Cholesterol Level LDL']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true"/><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Cholesterol Level LDL']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Cholesterol Level LDL']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>)}
                  </div>) : (<></>)}

              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Cholesterol Level  (HDL)</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                <Line options={chartOptions} data={databody}
                />
                {healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true"/><span className="gooddata"> Good:</span>  {healthSummary['data']['Cholesterol Level HDL']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true"/><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Cholesterol Level HDL']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Cholesterol Level HDL']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true"/><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Cholesterol Level HDL']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Cholesterol Level HDL']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (<>
                        <p><i className="fa fa-square gooddata" aria-hidden="true"/><span className="gooddata"> Good:</span>  {healthSummary['month']['Cholesterol Level HDL']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true"/><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Cholesterol Level HDL']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Cholesterol Level HDL']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true"/><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Cholesterol Level HDL']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Cholesterol Level HDL']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>)}
                  </div>) : (<></>)}

              </Col>

              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Cholesterol Level (Triglycerides)</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                <Line options={chartOptions} data={datares}
                />
                {healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true"/><span className="gooddata"> Good:</span>  {healthSummary['data']['Cholesterol Level Triglycerides']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true"/><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Cholesterol Level Triglycerides']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Cholesterol Level Triglycerides']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true"/><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Cholesterol Level Triglycerides']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Cholesterol Level Triglycerides']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (<>
                        <p><i className="fa fa-square gooddata" aria-hidden="true"/><span className="gooddata"> Good:</span>  {healthSummary['month']['Cholesterol Level Triglycerides']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true"/><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Cholesterol Level Triglycerides']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Cholesterol Level Triglycerides']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true"/><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Cholesterol Level Triglycerides']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Cholesterol Level Triglycerides']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>)}
                  </div>) : (<></>)}

              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>PSA Level</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                <Line options={chartOptions} data={databm}
                />
                {healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true"/><span className="gooddata"> Good:</span>  {healthSummary['data']['PSA Level']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true"/><span className="cautiondata"> Caution:</span>  {healthSummary['data']['PSA Level']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['PSA Level']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true"/><span className="alertdata"> Alert Level:</span> {healthSummary['data']['PSA Level']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['PSA Level']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (<>
                        <p><i className="fa fa-square gooddata" aria-hidden="true"/><span className="gooddata"> Good:</span>  {healthSummary['month']['PSA Level']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true"/><span className="cautiondata"> Caution:</span>  {healthSummary['month']['PSA Level']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['PSA Level']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true"/><span className="alertdata"> Alert Level:</span> {healthSummary['month']['PSA Level']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['PSA Level']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>)}
                  </div>) : (<></>)}

              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Testosterone Level</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                <Line options={chartOptions} data={dataH}
                />
                {healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true"/><span className="gooddata"> Good:</span>  {healthSummary['data']['Testosterone Level']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true"/><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Testosterone Level']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Testosterone Level']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true"/><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Testosterone Level']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Testosterone Level']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true"/><span className="gooddata"> Good:</span>  {healthSummary['month']['Testosterone Level']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true"/><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Testosterone Level']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Testosterone Level']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true"/><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Testosterone Level']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Testosterone Level']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>
                    )}
                  </div>) : (<></>)}

              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Estrogen Level</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                <Line options={chartOptions} data={dataW}
                />
                {healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true"/><span className="gooddata"> Good:</span>  {healthSummary['data']['Estrogen Level']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true"/><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Estrogen Level']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Estrogen Level']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true"/><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Estrogen Level']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Estrogen Level']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true"/><span className="gooddata"> Good:</span>  {healthSummary['month']['Estrogen Level']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true"/><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Estrogen Level']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Estrogen Level']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true"/><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Estrogen Level']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Estrogen Level']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>
                    )}
                  </div>) : (<></>)}

              </Col>
              <Col md={12}>
                <div className='header'>
                  <h3 className='title text-center'>Progesterone Level</h3>
                  <div className='links'>
                    <a
                      className='btn btn-gh'
                      href='https://github.com/reactchartjs/react-chartjs-2/blob/master/example/src/charts/VerticalBar.js'
                    >
                    </a>
                  </div>
                </div>
                <Line options={chartOptions} data={dataW1}
                />
                {healthSummary ? (
                  <div className="colortext">
                    {graphData == "week" ? (
                      <>
                        <p><i className="fa fa-square gooddata" aria-hidden="true"/><span className="gooddata"> Good:</span>  {healthSummary['data']['Progesterone Level']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true"/><span className="cautiondata"> Caution:</span>  {healthSummary['data']['Progesterone Level']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['data']['Progesterone Level']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true"/><span className="alertdata"> Alert Level:</span> {healthSummary['data']['Progesterone Level']['metric_ranges'][0]['red_low']}(Min){healthSummary['data']['Progesterone Level']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>) : (
                      <><p><i className="fa fa-square gooddata" aria-hidden="true"/><span className="gooddata"> Good:</span>  {healthSummary['month']['Progesterone Level']['metric_ranges'][0]['green']}</p>
                        <p><i className="fa fa-square cautiondata" aria-hidden="true"/><span className="cautiondata"> Caution:</span>  {healthSummary['month']['Progesterone Level']['metric_ranges'][0]['yellow_low']}(Min) {healthSummary['month']['Progesterone Level']['metric_ranges'][0]['yellow_high']}(Max)</p>
                        <p><i className="fa fa-square alertdata" aria-hidden="true"/><span className="alertdata"> Alert Level:</span> {healthSummary['month']['Progesterone Level']['metric_ranges'][0]['red_low']}(Min){healthSummary['month']['Progesterone Level']['metric_ranges'][0]['red_high']}(Max)</p>
                      </>)}
                  </div>) : (<></>)}

              </Col>
            </Row>
          </div>
        </Container>
      </section>



    </>

  )
}

export default Bloodworkgraph
