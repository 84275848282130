import { useState, useEffect } from 'react'
import { Modal, Container, Button, Row, Col } from 'react-bootstrap'
import DatePicker from 'react-date-picker';
import Slider, { SliderTooltip } from 'rc-slider';
import { userService } from '../_services';
import moment from 'moment';
import { useHistory } from 'react-router';
import { Loader } from './Loader';

import 'rc-slider/assets/index.css';


const Mood = () => {
    let history = useHistory();
    const [value, onChange] = useState(new Date());
    const [showloader, setShowloader] = useState({ display: "none" });
    const [show, setShow] = useState(false);
    const [metricData, setMetricData] = useState([]);
    const [fieldVals, setFieldVals] = useState(new Map)
    const [params, setParams] = useState({});
    const [showErr, setShowErr] = useState(false);
    const { Handle } = Slider;

    const handleClose = () => {
        setShow(false);
        history.push("/")
    }

    useEffect(() => {
        toggleLoader("flex");
        localStorage.setItem("record", "yes");
        userService.metric_list(moment().format('YYYY-MM-DD')).then(response => {
            setMetricData(response.data.data.mood_metric)
            const map = new Map() as any;
            let object = {} as any;
            response.data.data.mood_metric.forEach((value: any) => {
                let keys = value.metrics.toLowerCase().replace(" ", "_");
                object[keys] = value.id + "_" + value.value;
                map.set(value.id, value.value);
            });
            setFieldVals(map);
            setParams(object);
            toggleLoader("none");
        }).catch(error => {
            if (error.response.status == 401) {
                localStorage.clear()
                window.location.href = '/';
            }
            toggleLoader("none");
        })
    }, [])


    // const clearfield = () => {
    //     Setanger_mood(0);
    //     Setpeacefulness_mood(0);
    //     Setharm_mood(0);
    //     Setsadness_mood(0);
    //     Setsadness_mood(0);
    //     Sethappiness_mood(0);
    //     Setdepressed_mood(0);
    //     Setanxiety_mood(0);
    //     Setcalm_mood(0);
    //     Setgood_mood(0);
    //     Setoptimistic_mood(0)
    //     Setfatigue_mood(0)
    //     Setenergetic(0)
    //     onChange(new Date())

    // }

    const submit = () => {
        var keys = Object.keys(params);
        if (keys.length == 0) {
            setShowErr(true)
            return false
        }
        toggleLoader("flex");
        let object = {
            ...params
        } as any;
        object.date_measured = moment().format('YYYY-MM-DD');
        let viaIsDetail = localStorage.getItem('viaIsDetail');
        if (viaIsDetail === "true") {
            object.competition_id = localStorage.getItem('comp_id');
        }
        userService.health_matrics(object).then(response => { return response.data }).then((result) => {
            // toast.success('Record added successfully.');
            toggleLoader("none");
            localStorage.removeItem("record");
            let viaInviteGroup = localStorage.getItem("viaInviteGroup");
            if (viaInviteGroup === "true") {
                let inviteGroupId = localStorage.getItem("inviteGroupId")
                if (inviteGroupId) {
                    localStorage.setItem("viaInviteGroup", "false");
                    history.push(`/invite_group?id=${inviteGroupId}`)
                } else {
                    history.push('/');
                }
            } else {
                history.push('/');
            }
        }).catch(error => {
            if (error.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            console.log(error.response);
            toggleLoader("none");
        });
    }


    const handle = (props: any) => {
        const { value, dragging, index, ...restProps } = props;
        return (
            <SliderTooltip
                prefixCls="rc-slider-tooltip"
                overlay={`${value} `}
                visible={dragging}
                placement="top"
                key={index}>
                <Handle value={value} {...restProps} />
            </SliderTooltip>
        );
    };

    function setSliderVal(id: any, val: any, unit: any, metric: any) {
        let f_val = new Map(fieldVals)
        f_val.set(id, val)
        setFieldVals(f_val)
        var key = metric.toLowerCase().replace(" ", "_")
        var key_value = id + "_" + val + " " + unit
        let value = { ...params, [key]: key_value }
        setParams(value)
    }

    function toggleLoader(status: string) {
        setShowloader(prevShowloader => {
            return {
                ...prevShowloader,
                display: status
            }
        });
    }

    return (
        <>
            <section className="bodyvital_section py-5">
                <Loader status={showloader.display} />
                <Container>
                    <div className="inner_box">
                        <Row className="bodyvitals_head">
                            <Col md={6}>
                                <h5>
                                    <a href="/">
                                        <img src={require('../images/back.svg').default} alt="back" />
                                    </a>
                                </h5>
                                <h2>Mood</h2>
                            </Col>
                            <Col md={6} className="text-right">
                                <h6>Date Measured</h6>
                                <div className="clndr_icon_1">
                                    <DatePicker onChange={onChange} value={value} maxDate={new Date} format="MM-dd-y"/>
                                    <span><img src={require('../images/clndr1.svg').default} alt="clndr1" width="17px" /></span>
                                </div>
                            </Col>
                            <Col md={12}><hr /></Col>
                        </Row>

                        <Row className="metrics_row mood-page-matrix">
                            <Col md={12}>
                                <h3>Health Metrics</h3>
                                <p>Rate each metric from 0 to 5 showing that you do not feel that emotion at all and 5 showing that you feel the emotion at the highest level.</p>
                            </Col>
                            {metricData ?
                                metricData.map((d: any, i: any) => {
                                    return (
                                        <Col md={6} key={`${d.id}_${i}`}>
                                            <div className="mood-range-slider">
                                                <h4>{d.metrics} ({fieldVals && fieldVals.get(d.id) ? fieldVals.get(d.id) : 0})</h4>
                                                <div className="price_slider">
                                                    <div className="sliderArea">
                                                        <Slider min={0} max={5} step={1} value={fieldVals && fieldVals.get(d.id) ? fieldVals.get(d.id) : 0} defaultValue={0} handle={handle} onChange={(e: any) => setSliderVal(d.id, e, d.ranges[0].unit, d.metrics)} />
                                                        <div className="ranges-number d-flex align-item-center mt-3">
                                                            <h5>0</h5>
                                                            <h5 style={{ marginLeft: "auto" }}>5</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                }) : ""
                            }
                            <Col md={12}>
                                <Button variant="unset" type="button" className="btnsumit" onClick={submit}>Submit</Button>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Modal show={show} onHide={handleClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="border-0 p-1">
                        <Modal.Title />
                    </Modal.Header>
                    <Modal.Body className="text-center pt-0">
                        <img src={require('../images/check1.svg').default} alt="check1" width="85px" />
                        <h5 className="mt-2 mb-3">Record added successfully!!</h5>
                    </Modal.Body>
                </Modal>

                <Modal show={showErr} onHide={() => { setShowErr(false) }} size="sm">
                    <Modal.Header closeButton className="border-0 headerclose" />
                    <Modal.Body className="text-center py-4">
                        <img src={require('../../src/images/check.svg').default} />
                        <h5 className="thnkutext mt-3 mb-1">Try Again!</h5>
                        <p className="mb-0">Please select alteast one emotion.</p>
                    </Modal.Body>
                </Modal>
            </section>
        </>
    )
}

export default Mood;