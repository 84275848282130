import React from 'react';

function AdShareVideo() {

    return (
        <React.Fragment>
            <section className="loginsec">
                <div className="right_side py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
export default AdShareVideo;