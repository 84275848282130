import { useState ,useEffect} from "react";
import { Form, Modal } from "react-bootstrap";
import { userService } from '../_services';
import { Loader } from './Loader';
import { Formik } from "formik";
import * as Yup from "yup";

const NewPassword = () => {
    const [showLoader, setShowLoader] = useState({ display: "none" });
    const [show, setShow] = useState(false);
    const [newPassWordObj, setNewPassWordObj] = useState({
        password: '',
        cpassword: ''
    });

    const newPasswordSchema = Yup.object().shape({
        password: Yup.string().required("Password field can’t be empty."),
        cpassword: Yup.string().required("Confirm Password field can’t be empty.").oneOf([Yup.ref('password')], 'Your passwords do not match.'),
    });

    useEffect(()=>{
        const accesstoken = localStorage.getItem('accesstoken');
		localStorage.removeItem('record');
		localStorage.removeItem('prescription');
		if (accesstoken) {
			window.location.href="/";
		}
    },[])

    const submit = (values: any) => {
        let mail = localStorage.getItem('email');
        let params = {
            email: mail,
            password: values.password,
        };
        userService.ChangePassword(params).then(function (response) {
            toggleLoader("none");
            toggleModal(true, 'open');
            localStorage.removeItem('email');
        }).catch(error => {
            if (error.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            toggleLoader("none");
        });
    }

    const toggleModal = (status: any, type: any) => {
        setShow(status);
        if (type === "close") {
            window.location.href = "/login";
        }
    }
    // common loader
    const toggleLoader = (status: any) => {
        setShowLoader((prevShowLoader: any) => {
            return {
                ...prevShowLoader,
                display: status
            }
        })
    }

    return (
        <section className="loginsec">
            <Loader status={showLoader.display} />
            <div className="right_side py-5">
                <div className="container">
                    <div className="row">
                        <aside className="col-xl-5 col-lg-7 col-sm-12 m-auto">
                            <div />
                            <div className="login_form mt-4">
                                <div className="text-center">
                                    <h4>New Password</h4>
                                    <p>Create your new password by entering below.</p>
                                    <img src={require('../images/line.svg').default} alt="line" />
                                </div>
                                <Formik
                                    initialValues={newPassWordObj}
                                    validationSchema={newPasswordSchema}
                                    enableReinitialize={true}
                                    onSubmit={(values) => {
                                        submit(values);
                                    }}>
                                    {({ errors, touched, values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                        <Form className="mt-4" autoComplete="off" onSubmit={handleSubmit}>
                                            <Form.Group className="mb-3" controlId="password">
                                                <Form.Control type="password" placeholder="New Password*" className="input103" value={values.password} onChange={handleChange} />
                                                {errors.password && touched.password ? (<label id="uname-error" className="error" htmlFor="password"> {errors.password}</label>) : null}
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="cpassword">
                                                <Form.Control type="password" placeholder="Confirm New Password*" className="input103" value={values.cpassword} onChange={handleChange} />
                                                {errors.cpassword && touched.cpassword ? (<label id="uname-error" className="error" htmlFor="confirmPassword"> {errors.cpassword}</label>) : null}
                                            </Form.Group>
                                            <div className="text-center mt-4">
                                                <button type="submit" className="loginbtn btn shadow text-white">Submit</button>
                                            </div>
                                            <div className="text-center my-3">
                                                <p>Back to   <a href="/login" className="signup_text">Login</a></p>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={() => toggleModal(false, 'close')} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton className="border-0 p-1">
                    <Modal.Title />
                </Modal.Header>
                <Modal.Body className="text-center pt-0">
                    <img src={require('../images/check1.svg').default} alt="check1" width="85px" />
                    <h5 className="mt-2 mb-3">Password Reset Succesfully.</h5>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default NewPassword;