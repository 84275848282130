import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import { userService } from '../_services';
import { Loader } from './Loader';

const FriendsReqList = () => {
    const [show, setShow] = useState("none");
    const [data, setData] = useState([]);

    useEffect(() => {
        localStorage.removeItem("groupTab")
        localStorage.removeItem("compTab")
        setShow("flex")
        userService.friendRequestList().then(resp => {
            setData(resp.data.data)
            setShow("none")
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            setShow("none")
        })
    }, [])

    return (
        <>
            <section className="bodyvital_section friend_sections py-5">
                <Loader status={show} />
                <Container>
                    <div className="inner_box pb-4">
                        <Row className="align-items-center">
                            <Col lg={8} md={7}>
                                <h5>
                                    <a href="/friends">
                                        <img src={require('../images/back.svg').default} alt="back" />
                                    </a>
                                </h5>
                                <h2 className="d-inline">New Friend Request</h2>
                            </Col>
                            <Col md={12}>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            {data.length ? data.map((f: any, i: any) => {
                                return (
                                    <Col lg={6} md={6} sm={6} key={`${f.id}_${i}`}>
                                        <Link className="users_box mb-3" to={`/friendrequest?uid=${btoa(f.id)}`}>
                                            <div className="invite_user_image">
                                                <img src={f.profileImage} alt="users_box" />
                                            </div>
                                            <span>{`${f.firstname} ${f.lastname}`}</span>
                                            <div className="invite_btn1">
                                                <i className="fa fa-angle-right nextarrow" />
                                            </div>
                                        </Link>
                                    </Col>
                                )
                            }):
                                <Col className="text-center mt-3">
                                    <p>No data found</p>
                                </Col>
                            }
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default FriendsReqList;