export default function Loader() {
    return <>
        <div className="loader">
            <div className="loader-inner line-scale">
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
    </>
}