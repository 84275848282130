import { authHeader } from '../_helpers';
import { config } from '../config/config'
import axios from 'axios';

const configheaders = {
    headers: authHeader()
}

export const publicGraphServices = {
    HeatMapGraph,
    TrendGraph,
    MetricComparisonGraph,
    GroupHealthGraph,
    GroupAnalysisGraph,
    publicPopulationReportData,
    publicHealthHistoryTable,
    publicGroupListingReportGraph,
    publicSurveyReportGraph,
    publicWellnessReportGraph
}

function HeatMapGraph(user_id: string, report_id: string) {
    let url = `${config.apiurl_dev}/getHeatMapData/${user_id}/${report_id}`;
    return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}

function TrendGraph(user_id: string, report_id: string) {
    let url = `${config.apiurl_dev}/getTrendReportData/${user_id}/${report_id}`;
    return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}

function MetricComparisonGraph(user_id: string, report_id: string) {
    let url = `${config.apiurl_dev}/getMetricComparisonData/${user_id}/${report_id}`;
    return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}

function GroupHealthGraph(user_id: string, report_id: string) {
    let url = `${config.apiurl_dev}/getHealthScoreGraphData/${user_id}/${report_id}`;
    return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}

function GroupAnalysisGraph(user_id: string, report_id: string) {
    let url = `${config.apiurl_dev}/getMetricAnalysisData/${user_id}/${report_id}`;
    return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}

function publicPopulationReportData(params: any) {
    let url = `${config.apiurl_dev}/public_population_report_data${params}`;
    return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}

function publicHealthHistoryTable(params: any) {
    let url = `${config.apiurl_dev}/public_health_history_table${params}`;
    return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}

function publicGroupListingReportGraph(params: any) {
    let url = `${config.apiurl_dev}/public_group_listing_report_graph${params}`;
    return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}

function publicSurveyReportGraph(params: any) {
    let url = `${config.apiurl_dev}/public_survey_report_graph${params}`;
    return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}

function publicWellnessReportGraph(params:any){
    let url = `${config.apiurl_dev}/public_wellness_report_graph${params}`;
    return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}