import React from 'react';
import {Link} from 'react-router-dom'

function PageNotFound() {
    return (
        <React.Fragment>
            <section className='bodyvital_section py-5'>
                <div className="container">
                    <div className="inner_box">
                        <div className='bodyvitals_head row'>
                            <div className="col-md-12 text-center mt-4">
                                <img src={require('../images/not_found.svg').default} alt="not found" />
                            </div>
                            <div className="col-md-12 text-center mt-4">
                                <b>404</b>
                            </div>
                            <div className="col-md-12 text-center mt-2">
                                Sorry, the page you visited does not exist.
                            </div>
                            <div className="col-md-12 text-center mt-5">
                                <Link to="/" className="btnsumit text-center add-description">Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
export default PageNotFound;