import { useEffect, useState } from 'react'
import { Form, Container, Button, Row, Col } from 'react-bootstrap'
import { userService } from '../_services';
import Select, { StylesConfig } from 'react-select'
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Loader } from './Loader';
import { ADD_EDIT_GROUP_LABEL } from '../config/constants';
import { returnTotalPages } from '../utils/pagination';

const Editgroup = () => {



    interface ColourOption {
        readonly value: string;
        readonly label: string;
        readonly color: string;
        readonly isFixed?: boolean;
        readonly isDisabled?: boolean;
    }

    const [showloader, setShowLoader] = useState({ display: "none" });
    const [groupImage, setGroupImage] = useState("") as any;
    const [groupName, setGroupName] = useState("");
    const [groupDesc, setGroupDesc] = useState("");
    const [terms, setTerms] = useState(false);
    const [imageURL, setImageURL] = useState("") as any;
    const [data, setData] = useState([]) as any;
    const [selectedUsers, setSelectedUsers] = useState([]) as any;
    const [selectedGroups, setSelectedGroups] = useState([]) as any;
    // const [invitedUser, setInviteduser] = useState([]) as any;
    // const [invitedGroup, setInvitedGroup] = useState([]) as any;
    const [groupList, setGroupList] = useState([]) as any;
    const [userList, setUserList] = useState([]) as any;
    const [paginationObj, setPaginationObj] = useState({
        page_number: 0,
        total_pages: 0
    }) as any;
    const [isDropDownBottom, setIsDropDownBottom] = useState(false) as any;
    const [searchgroup,setSearchGroup]=useState('');
    const [isSearchtrigger,setIsSearchtrigger]=useState(false) as any;

    let history = useHistory();
    let user_id = localStorage.getItem("user_id") as any;

    useEffect(() => {
        // setShow({ display: "flex" })
        toggleLoader("flex");
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let g_id = params.get("id");
        var post = {
            "group_id": g_id,
            "metric_id": ""
        }
        userService.groupDetail(post).then(resp => {
            setData(resp.data.data);

            if (resp.data.data.group_type === "P") {
                setTerms(true);
            }

            var tempUser = [] as any;
            var users = [] as any;
            var tempGroups = [] as any;
            var group = [] as any;

            // Composing data for invited user
            if (resp.data.data.invited_users) {
                resp.data.data.invited_users.forEach((data: any) => {
                    tempUser.push({ value: data.id, label: `${data.firstname} ${data.lastname}`, isFixed: true });
                    users.push(data.id);
                })
            }


            // composing data of inivited group
            if (resp.data.data.invited_group) {
                resp.data.data.invited_group.forEach((value: any) => {
                    tempGroups.push({ value: value.id, label: value.group_name, isFixed: true });
                    group.push(value.id);
                })
            }

            setSelectedUsers(tempUser);
            setSelectedGroups(tempGroups);
            // setInviteduser(users);
            // setInvitedGroup(group);

            setGroupImage(resp.data.data.group_image);
            setGroupName(resp.data.data.group_name);
            setGroupDesc(resp.data.data.description);
            toggleLoader("none");
        }).catch(err => {

            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            toast.error('Group list not loaded.')
            // setShow({ display: "none" })
            toggleLoader("none");
        });

        allGroupList();
        getUserListing();

        toggleLoader("flex");
    }, []);

    function getUserListing() {
        userService.getUserListing().then(resp => {
            var tempUsers = [] as any
            var users = resp.data.data;
            if (users.length) {
                users.forEach((value: any) => {
                    tempUsers.push({ value: value.id, label: `${value.firstname} ${value.lastname}` });
                })
            }
            setUserList(tempUsers)
            toggleLoader("none");
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            toast.error('Group list not loaded.')
            toggleLoader("none");
        })
    }


    function allGroupList() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let g_id = params.get("id");
        let query =`?group_id=${g_id}${searchgroup?`&keyword=${searchgroup}` : `&page_no=${paginationObj.page_number}`}`;

        userService.allGroupList(query).then(resp => {
            var tempGroups = [] as any;
            var joinedGroup = resp.data.data;
            if (joinedGroup.length) {
                joinedGroup.forEach((value: any) => {
                    tempGroups.push({ value: value.id, label: value.group_name });
                })
            }
            let totalPages = returnTotalPages(resp.data.total_records, 10);
            totalPages=totalPages>1?totalPages-1:totalPages;
            updatePaginationState('total_pages', totalPages);
            if(searchgroup){
                setGroupList(tempGroups);
            }else{
                if(isDropDownBottom){
                    if (groupList.length) {
                        if (tempGroups.length) {
                            let data = groupList.concat(tempGroups);
                            setGroupList(data);
                        }
                    } else {
                        setGroupList(tempGroups);
                    }
                } else {
                    setGroupList(tempGroups);
                }     
            }
            toggleLoader("none");
            setIsDropDownBottom(false);
            triggerSearch(false);
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            toast.error('Group list not loaded.')
            toggleLoader("none");
            triggerSearch(false);
        });
    }

    function submitGroup(event: any) {
        event.preventDefault();
        if (!groupName.trim()) {
            toast.error("Please enter group name.")
            return false
        }
        if (!groupDesc.trim()) {
            toast.error("Please enter group description.")
            return false
        }
        if (data.group_type == 'P') {
            if (data.group_type == 'P' && !terms) {
                toast.error("Please accept the terms.")
                return false
            }
        }

        var invited = false;
        if (selectedUsers.length || selectedGroups.length) {
            invited = true;
        }
        if (!invited && !parseInt(data.invite_all)) {
            toast.error("Please invite users or group.")
            return false
        }

        // setShow({ display: "flex" })
        toggleLoader("flex");

        let formData = new FormData();
        formData.append("group_name", groupName)
        formData.append("id", data.group_id)
        formData.append("description", groupDesc)
        formData.append("group_type", data.group_type)
        formData.append("joining_fee", data.joining_fee)
        formData.append("user_id", user_id)
        if (imageURL) {
            formData.append("group_image", imageURL)
        }
        formData.append("invite_all", data.invite_all)

        if (selectedUsers.length) {
            let userArr = [] as any;
            selectedUsers.forEach((value: any) => {
                userArr.push(value.value);
            })
            formData.append("member_id", userArr);
        } else {
            formData.append("member_id", selectedUsers);
        }

        if (selectedGroups.length) {
            let userGroup = [] as any;
            selectedGroups.forEach((value: any) => {
                userGroup.push(value.value);
            })
            formData.append("group_ids", userGroup)
        } else {
            formData.append("group_ids", selectedGroups)
        }

        // if (invitedUser.length) {
        //     formData.append("member_id", invitedUser)
        // } else {
        //     formData.append("member_id", data.invited_users)
        // }

        // debugger
        // if (invitedGroup.length) {
        //     formData.append("group_ids", invitedGroup)
        // } else {
        //     formData.append("group_ids", data.invited_group)
        // }

        createGroup(formData);
    }

    function createGroup(formData: any) {
        userService.createGroup(formData).then(resp => {
            toast.success("Group updated successfully")
            toggleLoader("none");
            history.push('/group')
        }).catch(err => {
            if (err.response) {
                if (err.response.status == 401) {
                    localStorage.clear()
                    window.location.href = '/'
                }
                toast.error(err.response.data.message)
            }
            toggleLoader("none");
        })
    }

    function readURL(input: any) {
        if (input.files && input.files[0]) {
            const image = new Image();
            image.src = URL.createObjectURL(input.files[0])
            image.onload = function () {
                if (image.width > 2500 || image.height > 2000) {
                    toast.error(`Image size is too large, Please select image with smaller size with dimension: 2500px X 2000px`);
                    return false;
                } else {
                    var get_ext = input.files[0].name.split('.');
                    var exts = ['png', 'jpg', 'jpeg', 'gif'];
                    get_ext = get_ext.reverse();
                    var a = exts.indexOf(get_ext[0].toLowerCase());
                    if (a > -1) {
                        setImageURL(input.files[0])
                        var reader = new FileReader();
                        reader.onload = function (e: any) {
                            setGroupImage(e.target.result)
                        }
                        reader.readAsDataURL(input.files[0]);
                    } else {
                        // toast.error('Please select valid image file.')
                        toast.error(`Only png, jpg, jpeg, gif formats are allowed`);
                        return false;
                    }
                }
            }
        }
    }

    function toggleLoader(status: any) {
        setShowLoader(prevShowLoader => {
            return {
                ...prevShowLoader,
                display: status
            }
        });
    }

    // integrating read only field in dropdown
    const onChange = (
        newValue: any,
        actionMeta: any
    ) => {
        switch (actionMeta.action) {
            case 'remove-value':
            case 'pop-value':
                if (actionMeta.removedValue.isFixed) {
                    return;
                }
                break;
            case 'clear':
                newValue = selectedUsers.filter((v: any) => v.isFixed);
                break;
            case "select-option":

                break;
        }
        let data = orderOptions(newValue);
        var tempValue = [] as any;
        if (actionMeta.action === "select-option") {
            newValue.forEach((value: any) => {
                tempValue.push(value.value);
            })
        }
        setSelectedUsers(data);
        // setInviteduser(tempValue);
    };

    // integrating read only field in dropdown
    const onChangeGroup = (
        newValue: any,
        actionMeta: any
    ) => {
        console.log('actionMeta',actionMeta);
        switch (actionMeta.action) {
            case 'remove-value':
            case 'pop-value':
                if(actionMeta.removedValue){
                    if (actionMeta.removedValue.isFixed) {
                        return;
                    }
                }else{
                    newValue=[];
                }
                break;
            case 'clear':
                newValue = selectedGroups.filter((v: any) => v.isFixed);
                break;
            case "select-option":

                break;
        }
        let data = orderOptions(newValue);
        var tempValue = [] as any;
        if (actionMeta.action === "select-option") {
            newValue.forEach((value: any) => {
                tempValue.push(value.value);
            })
        }

        setSelectedGroups(data);
        // setInvitedGroup(tempValue);
    };

    const orderOptions = (values: any) => {
        return values.filter((v: any) => v.isFixed).concat(values.filter((v: any) => !v.isFixed));
    };


    // Do not remove code prewritten code
    function handleMultiSelect(e: any, type: any) {
        if (type === "group") {
            var tempGroups = [] as any;
            var vals = [] as any;
            var tempValue = [] as any;
            if (e.length) {
                e.forEach((value: any) => {
                    tempGroups.push({ value: value.value, label: value.label });
                    vals.push({ value: value.value, label: value.label });
                    tempValue.push(value.value);
                })
            }

            setSelectedGroups(tempGroups);
            // setInvitedGroup(tempValue);
        }

        if (type === "user") {
            var tempUsers = [] as any;
            var vals1 = [] as any;
            var tempValue = [] as any;
            if (e.length) {
                e.forEach((value: any) => {
                    tempUsers.push({ value: value.value, label: value.label });
                    vals1.push({ value: value.value, label: value.label });
                    tempValue.push(value.value);
                })
            }
            setSelectedUsers(tempUsers);
            // setInviteduser(tempValue);
        }
    }

    function updatePaginationState(name: any, value: any) {
        setPaginationObj((prevPaginationObj: any) => {
            return {
                ...prevPaginationObj,
                [name]: value
            }
        });
    }

    useEffect(() => {
        if (isDropDownBottom) {
            allGroupList()
        }
        if(isSearchtrigger){
            allGroupList()
        }
    }, [isDropDownBottom,isSearchtrigger]);

    function triggerSearch(status:any){
        setIsSearchtrigger(status);
    }



    const fixedStyles: StylesConfig<ColourOption, true> = {
        multiValue: (base, state) => {
            return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
        },
        multiValueLabel: (base, state) => {
            return state.data.isFixed
                ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
                : base;
        },
        multiValueRemove: (base, state) => {
            return state.data.isFixed ? { ...base, display: 'none' } : base;
        },
    };


    // console.log('selectedUsers', selectedUsers);
    // console.log('selectedGroups', selectedGroups);
    console.log('groupList',groupList);


    return (
        <>
            <section className="bodyvital_section py-5">
                <Loader status={showloader.display} />
                <Container>
                    <div className="inner_box pb-4">
                        <Row className="bodyvitals_head">
                            <Col md={12}>
                                <h5 onClick={() => { history.go(-1) }}><img src={require('../images/back.svg').default} alt="back" /></h5>
                                <h2>Edit group</h2>
                            </Col>
                            <Col md={12}><hr /></Col>
                        </Row>
                        <form onSubmit={(event: any) => submitGroup(event)}>
                            <Row className="align-items-center">
                                <Col md={3} className="text-center mb-3">
                                    <div className="prilfe_image position-relative my-4">
                                        {!groupImage ?
                                            <button className="btn bg-transparent brder-0 camerabtn" style={{ left: "40%" }}>
                                                <img src={require('../images/camera.svg').default} alt="camerabtn" width="35px" />
                                            </button>
                                            : ""
                                        }
                                        <Form.Group className="mb-3" controlId="profilePicture">
                                            <Form.Control type="file" className="input103 upload-profile-pic" onChange={(e: any) => readURL(e.target)} />
                                        </Form.Group>
                                        <div className="profile_box m-auto">
                                            <img src={groupImage} alt="profile_box" width="90px" />
                                        </div>
                                    </div>
                                    {/* <div className="gorup_Details_head grup_chat_head">
                                    <div className="user_image_row1 user_image_row1_upload_image">
                                        <i className="fa fa-camera"></i>
                                        <input onChange={(e: any) => readURL(e.target)} type="file" name="fileToUpload" id="fileToUpload"  />
                                    </div>
                                </div> */}
                                </Col>
                                <Col md={6}>
                                    <div className="create_group">
                                        <Form.Group className="mb-3" controlId="groupName">
                                            <Form.Label>Group Name</Form.Label>
                                            <Form.Control value={groupName} onChange={(e: any) => { setGroupName(e.target.value) }} type="text" placeholder="Enter Group Name" required />
                                        </Form.Group>
                                    </div>
                                    <div className="create_group">
                                        <Form.Group className="mb-3" controlId="description">
                                            <Form.Label>Description</Form.Label>
                                            <textarea rows={3} value={groupDesc} onChange={(e: any) => { if (groupDesc.length > 250) { toast.error('Group description can be maximum 250 characters.'); e.preventDefault(); return; } else { setGroupDesc(e.target.value) } }} placeholder="Enter description" className="input100" required />
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                {
                                    data.invite_all == 0 ?
                                        <>
                                            <Col md={4} className="text-center mb-3">
                                                <div className="text-left">
                                                    <Form.Group className="mb-3" controlId="inviteUser">
                                                        <Form.Label>Invite Users</Form.Label>
                                                        <div className="add_create_group_input position-relative">
                                                            <Select value={selectedUsers} isMulti={true}
                                                                isClearable={selectedUsers.some((v: any) => !v.isFixed)}
                                                                isDisabled={false} styles={fixedStyles} closeMenuOnSelect={false} onChange={onChange} placeholder="Select users" options={userList} />
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                            <Col md={4} className="text-center mb-3">
                                                <div className="text-left">
                                                    <Form.Group className="mb-3" controlId="inviteGroup">
                                                        <Form.Label>Invite Group</Form.Label>
                                                        <div className="add_create_group_input position-relative">
                                                            <Select value={selectedGroups}
                                                                isMulti={true}
                                                                isDisabled={false}
                                                                styles={fixedStyles}
                                                                closeMenuOnSelect={false}
                                                                isClearable={selectedGroups.some((v: any) => !v.isFixed)}
                                                                onMenuScrollToBottom={() => {
                                                                    if (parseInt(paginationObj.page_number) + 1 <= parseInt(paginationObj.total_pages)) {
                                                                        toggleLoader("flex");
                                                                        updatePaginationState('page_number', parseInt(paginationObj.page_number) + 1);
                                                                        setIsDropDownBottom(true);
                                                                    }
                                                                }}
                                                                onChange={onChangeGroup}
                                                                isSearchable={true}
                                                                onInputChange={(event:any)=>{
                                                                    console.log('onInputChange....',event);
                                                                    setSearchGroup(event);
                                                                    triggerSearch(true);
                                                                    if(event){
                                                                    }else{
                                                                        updatePaginationState('page_number', 0);
                                                                    }
                                                                }}
                                                                placeholder="Select groups" options={groupList} />
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                        </>
                                        : ""
                                }
                                <Col md={4} className="text-center mb-3">
                                    <div className="create_group text-left">
                                        <Form.Group controlId="inviteAll">
                                            <Form.Label>Invite All</Form.Label>
                                            <Form.Control disabled={true} as="select">
                                                <option>{data.invite_all == 1 ? "Yes" : "No"}</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </div>
                                </Col>
                                <Col md={4} className="text-center mb-3">
                                    <div className="create_group text-left">
                                        <Form.Group controlId="groupType">
                                            <Form.Label>Group Type</Form.Label>
                                            <Form.Control disabled as="select">
                                                <option value={data.group_type === "P" ? "P" : "F"}>{data.group_type === "P" ? "Paid" : "Free"}</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </div>
                                </Col>

                                {data.group_type == 'P' ?
                                    <>
                                        <Col md={4} className="text-center mb-3">
                                            <div className="create_group text-left">
                                                <Form.Group className="mb-3" controlId="joiningFee">
                                                    <Form.Label>Joining Fee (In Dollars)</Form.Label>
                                                    <Form.Control disabled value={data.joining_fee} onChange={() => { return false }} type="text" placeholder="Joining fee" />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={12}><hr /></Col>
                                        <Col md={12} className="text-left mb-3">
                                            <Form.Group className="mb-3 create_group_checkbox" controlId="formBasicCheckbox">
                                                <Form.Check onClick={(e: any) => { setTerms(e.target.checked) }} type="checkbox" checked={terms} label={ADD_EDIT_GROUP_LABEL} disabled />
                                            </Form.Group>
                                        </Col>
                                    </>
                                    : ""
                                }
                                <Col md={12}>
                                    <Button variant="unset" type="submit" className="btnsumit my-2 text-white">Update Group</Button>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Editgroup;