import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { userService } from '../_services';
import { Loader } from './Loader';
import ProfileImage from '../images/profile.png';

const Sendrequest = () => {
    const [data, setData] = useState({}) as any;
    const [show, setShow] = useState("none");
    const [requestStatus, setRequestStatus] = useState(false);
    let user_id = localStorage.getItem("user_id");
    let history = useHistory();

    useEffect(() => {
        setShow("flex");
        let search = window.location.search;
        let params = new URLSearchParams(search);
        var uid = params.get("uid") as any;
        uid = atob(uid);
        var found = false;
        userService.addFriendListing({ "name": "" }).then(resp => {
            var temp = resp.data.data;
            for (let index = 0; index < temp.length; index++) {
                const element = temp[index];
                if (element.id == uid) {
                    found = true
                    setData(element);
                }
            }
            setShow("none")
            if (!found) {
                // history.go(-1)
                history.push('/notification')
            }
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            setShow("none")
            toast.error('Unknown error occured.')
            history.go(-1)
        })
    }, []);

    // submit request
    function submitRequest() {
        setShow("flex")
        // if request status is true then unfriend user API called
        if (requestStatus) {
            unFriendUser();
        } else {
            // calling send friend request
            sendFriendRequest();
        }
    }

    // send friend request API
    function sendFriendRequest() {
        var params = {
            "user_id": user_id,
            "other_id": data.id,
        }
        userService.sendFriendRequest(params).then(resp => {
            toast.success(resp.data.message);
            setShow("none")
            setRequestStatus(true);
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            if (err.response) {
                toast.error(err.response.data.message);
            } else {
                toast.error('An error occured. Please try after some time.');
            }
            setShow("none");
        })
    }

    // unfriend request API
    function unFriendUser() {
        let params = `?other_id=${data.id}`
        userService.cancelFriendRequest(params).then((res: any) => {
            // toast.success(res.data.message);
            setRequestStatus(false);
            setShow("none")
        }).catch((err: any) => {

        })
    }

    return (
        <>
            <section className="accpt_reject_section py-5">
                <Loader status={show} />
                <Container>
                    <a href="/addfriends">
                    <img src={require('../images/back.svg').default} alt="back" />
                    </a>
                    <div className="inner_box pb-4">
                        <Row className="align-items-center">
                            <Col lg={12} md={12} className="text-center m-auto">
                                <div className="frndrqst_img m-auto">
                                    <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={data.profileImage} alt="frndrqst" onError={(event: any) => { event.target.src = ProfileImage; }} />
                                </div>
                                <div className="mt-3">
                                    <h3>{data.firstname} {data.lastname}</h3>
                                    {data.location != null ? <h5><img src={require('../images/location.svg').default} alt="location" onError={(event: any) => { event.target.src = ProfileImage; }} className="mr-2" width="14px" />{data.location}</h5> : ""}
                                    {
                                        requestStatus ?
                                            <>
                                                <button className="btn accptbtn text-white" disabled={true}>Friend Request Sent</button>
                                                <button className="btn accptbtn text-white" onClick={submitRequest}>Cancel Friend Request</button>
                                            </>
                                            : <button className="btn accptbtn text-white" onClick={submitRequest}>Send Friend Request</button>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Sendrequest;