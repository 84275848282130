/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useRef, forwardRef } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useLocation, useHistory } from "react-router-dom";
import { jsonObj, reports } from "../config/config";
import { userService } from '../_services/user.services';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Loader } from './Loader';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Formik } from "formik";
import * as Yup from "yup";
import {
    LEVEL,
    ANALYSIS_DURATION,
    DURATION,
    SELECTED_GROUPS,
    METRIC_CONDITION_TREND_REPORT_DROPDOWN,
    METRIC_COMPARISON_REPORT,
    METRIC_CONDITION_HEAT_MAP_REPORT,
    ARRAY_FORMAT_FIELD,
    METRIC_COMPARISON_REPORT_TYPE,
    INDIVIDUAL_VS_GROUP_ANALYSIS,
    INDIVIDUAL_VS_GROUP_ANALYSIS_REPORT,
    SURVEY_REPORT,
    GROUP_HEALTH_REPORT,
    POPULATION_HEALTH_REPORT_SELECT_GROUPS,
    NUMBER_DECIMAL_REGEX,
    POPULATION_GRAPH_REPORT,
    SURVEY_TYPE
} from '../config/constants';
import { toastConfig } from '../config/config';
import { toast } from 'react-toastify';
import moment from 'moment';
import { setItems, getItems } from '../utils/localStorage';
import Swal from 'sweetalert2';

const animatedComponents = makeAnimated();

const AddReport = () => {

    const location = useLocation();
    const history = useHistory();
    const [values, setValues] = useState<jsonObj>({});
    const [allMetric, setAllMetric] = useState([]);
    const [groupUser, setGroupUser] = useState([]);
    const [diseaseList, setDiseaseList] = useState([]);
    const [triggerGroup, setTriggerGroup] = useState(false);
    const [coordinate, setCoordinate] = useState({
        lat: '' as any, lng: '' as any
    });
    const [triggerLoader, setTriggerLoader] = useState(true);
    const [triggerEditModule, setTriggerEditModule] = useState(false);
    const [survey, setSurvey] = useState([]);
    const [triggerLocation, setTriggerLocation] = useState(false);

    // REF of Group ID field to clear value of Group ID if user select another option of group type
    const groupIdRef = useRef() as any;
    const populationLocation = useRef() as any;
    const populationArea = useRef() as any;


    let searchParams = new URLSearchParams(location.search);
    let item = searchParams.get("item") as any;
    let reportId = searchParams.get("id") as any;
    reportId = reportId ? parseInt(reportId) : '';
    // let opType = searchParams.get('type') as any;
    let reportCondition = item ? reports[item] : 1;

    const allDropDownSource = [
        {
            key: 'primary_metric_id',
            source: allMetric
        },
        {
            key: 'metric_id',
            source: allMetric
        },
        {
            key: 'metric_type',
            source: INDIVIDUAL_VS_GROUP_ANALYSIS
        },
        {
            key: 'secondary_condition_id',
            source: diseaseList
        },
        {
            key: 'group_type',
            source: SELECTED_GROUPS
        },
        {
            key: 'condition_id',
            source: diseaseList
        },
        {
            key: 'group_id',
            source: groupUser
        },
        {
            key: 'survey_id',
            source: survey
        },
        {
            key: 'disease_id',
            source: diseaseList
        },
        {
            key: 'survey_type',
            source: SURVEY_TYPE
        }
    ];

    const frontEndDropDownSource = [
        {
            key: 'level',
            source: LEVEL
        },
        {
            key: 'report_type',
            source: METRIC_COMPARISON_REPORT_TYPE
        },
        {
            key: 'duration',
            source: parseInt(item) === 4 ? ANALYSIS_DURATION : DURATION
        }
    ];

    const trendReportSchema = Yup.object().shape({
        report_name: Yup.string().required("Report name field can't be empty.").max(50, 'Maximum 50 characters allowed').trim(),
        location: Yup.string().required("Please select location."),
        area: Yup.number().max(1000, 'Area should not be greater than 1000 miles').positive(),
        primary_metric_id: Yup.array().required("Please select a base metric.").min(1, "Please select a base metric."),
        secondary_condition_id: Yup.array().required("Please select secondary conditions.").min(1, "Please select secondary conditions."),
        level: Yup.array().required("Please select metric level").min(1, "Please select metric level"),
        start_date: Yup.string().required("Start Date is required"),
        end_date: Yup.string().required("End Date is required"),
        group_type: Yup.array(),
        group_id: Yup.array().when('group_type', {
            is: (group_type: any) => {
                let status = returnStatusOfSelectGrouptype(group_type);
                return status;
            },
            then: Yup.array().required("Please select groups").min(1, "Please select groups"),
            otherwise: Yup.array()
        })
        // .required("Select Group is required").min(1, "Select Group is required")
    });
    // Area, Duration, level and group field
    const heatMapReportSchema = Yup.object().shape({
        report_name: Yup.string().required("Report name field can't be empty.").max(50, 'Maximum 50 characters allowed').trim(),
        duration: Yup.array(),
        location: Yup.string().required("Please select location."),
        area: Yup.number().max(1000, 'Area should not be greater than 1000 miles').positive(),
        metric_id: Yup.array().required("Please select metric").min(1, "Please select metric"),
        condition_id: Yup.array().required("Please select health condition.").min(1, "Please select health condition."),
        level: Yup.array(),
        group_type: Yup.array(),
        group_id: Yup.array().when('group_type', {
            is: (group_type: any) => {
                let status = returnStatusOfSelectGrouptype(group_type);
                return status;
            },
            then: Yup.array().required("Please select groups").min(1, "Please select groups"),
            otherwise: Yup.array()
        })
        // group_id: Yup.array().required("Select Group is required").min(1, "Select Group is required")
    });

    const comparisonReportSchema = Yup.object().shape({
        report_name: Yup.string().required("Report name field can't be empty.").max(50, 'Maximum 50 characters allowed').trim(),
        metric_id: Yup.array().required("Please select two metrics.").min(2, "Please select two metrics."),
        start_date: Yup.string().required("Start Date is required"),
        end_date: Yup.string().required("End Date is required"),
        report_type: Yup.array(),
        group_type: Yup.array(),
        group_id: Yup.array().when('group_type', {
            is: (group_type: any) => {
                let status = returnStatusOfSelectGrouptype(group_type);
                return status;
            },
            then: Yup.array().required("Select Group is required").min(1, "Select Group is required"),
            otherwise: Yup.array()
        })
        // group_id: Yup.array().required("Select Group is required").min(1, "Select Group is required")
    });

    const individualGroupAnalysisSchema = Yup.object().shape({
        report_name: Yup.string().required("Report name field can't be empty.").max(50, 'Maximum 50 characters allowed').trim(),
        metric_type: Yup.array().required("Please select report category.").min(1, "Please select report category."),
        group_type: Yup.array(),
        group_id: Yup.array().when('group_type', {
            is: (group_type: any) => {
                let status = returnStatusOfSelectGrouptype(group_type);
                return status;
            },
            then: Yup.array().required("Select Group is required").min(1, "Select Group is required"),
            otherwise: Yup.array()
        })
    });

    const healthHistorySchema = Yup.object().shape({
        report_name: Yup.string().required("Report name field can't be empty.").max(50, 'Maximum 50 characters allowed').trim(),
        start_date: Yup.string().required("Start Date is required"),
        end_date: Yup.string().required("End Date is required"),
    });

    const surveyReportSchema = Yup.object().shape({
        report_name: Yup.string().required("Report name field can't be empty.").max(50, 'Maximum 50 characters allowed').trim(),
        start_date: Yup.string().required("Start Date is required"),
        end_date: Yup.string().required("End Date is required"),
        survey_id: Yup.array().required("Please select survey").min(1, "Please select survey"),
        survey_type: Yup.array().required("Please select survey type").min(1, "Please select survey type"),
    });

    const groupHealthReportSchema = Yup.object().shape({
        report_name: Yup.string().required("Report name field can't be empty.").max(50, 'Maximum 50 characters allowed').trim(),
        group_id: Yup.array()
    });
    const populationHealthReportSchema = Yup.object().shape({
        report_name: Yup.string().required("Report name field can't be empty.").max(50, 'Maximum 50 characters allowed').trim(),
        disease_id: Yup.array().required("Please select a disease.").min(1, "Please select a disease."),
        group_type: Yup.array().required("Please select group type").min(1, "Please select group type"),
        group_id: Yup.array().when('group_type', {
            is: (group_type: any) => {
                if (group_type) {
                    let status = returnStatusOfSelectGrouptype(group_type);
                    console.log('populationHealthReportSchema...', status)
                    return status;
                } else {
                    return false;
                }
            },
            then: Yup.array().required("Select Group is required").min(1, "Select Group is required"),
            otherwise: Yup.array()
        }),
        area: Yup.number().max(1000, 'Area should not be greater than 1000 miles').positive(),
        location: Yup.string().when('group_type', {
            is: (group_type: any) => {
                console.log('group_type...', group_type);
                if (group_type) {
                    if (group_type.length) {
                        if (group_type[0].label === "Location") {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },
            then: Yup.string().required("Please select location."),
            otherwise: Yup.string()
        })
    });

    const wellnessCompetitionSchema = Yup.object().shape({
        report_name: Yup.string().required("Report name field can't be empty.").max(50, 'Maximum 50 characters allowed').trim(),
        start_date: Yup.string().required("Start Date is required"),
        end_date: Yup.string().required("End Date is required"),
    });

    const trendReportSchemaObject = {
        report_name: '',
        location: '',
        area: '',
        primary_metric_id: [],
        secondary_condition_id: [],
        level: [],
        group_type: [],
        start_date: '',
        end_date: '',
        group_id: []
    };

    const heatMapReportSchemaObject = {
        duration: '',
        report_name: '',
        location: '',
        area: '',
        metric_id: [],
        condition_id: [],
        level: [],
        group_type: [],
        group_id: []
    };

    const comparisonReportSchemaObject = {
        report_name: '',
        start_date: '',
        end_date: '',
        metric_id: [],
        group_type: [],
        report_type: [],
        group_id: []
    };
    const individualGroupAnalysisSchemaObject = {
        report_name: '',
        duration: [],
        metric_type: [],
        group_type: [],
        group_id: []
    };
    const metricsHealthHistoryReport = {
        report_name: '',
        start_date: '',
        end_date: '',
    };
    const metricsSurveyReport = {
        report_name: '',
        start_date: '',
        end_date: '',
        survey_id: [],
        survey_type: []
    };
    const groupHealthReport = {
        report_name: '',
        group_id: []
    };
    const populationHealthReport = {
        report_name: '',
        group_id: [],
        disease_id: [],
        location: '',
        area: '30',
        group_type: []
    };
    const wellnessCompetitionReport = {
        report_name: '',
        start_date: '',
        end_date: '',
    };

    // handle Form submit API
    function handleFormSubmit(allData: any) {
        if(getItems("plan_details").plan_id === 1){
            Swal.fire({
                title: '',
                text: "This feature is not included in your current plan. To use this please upgrade your plan.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Go to Subscription Plan',
                cancelButtonText: 'Cancel'
            } as any).then((result: any) => {
                if (result.value) {
                    history.push('/membership/current');
                }
            })
        }else{
            setTriggerLoader(true);
            let data = {
                ...allData,
                type: item,
                latitude: coordinate.lat,
                longitude: coordinate.lng
            } as any;
            let fields = returnDropDownArrayValue(reportCondition);
            for (let x = 0; x < fields.length; x++) {
                let name = fields[x];
                let array = [] as any;
                if (ARRAY_FORMAT_FIELD.includes(name)) {
                    if (allData[name]) {
                        if (name.includes("primary")) {
                            data[name] = allData[name] ? allData[name][0].value : '';
                        } else {
                            if (allData[name].length) {
                                allData[name].forEach((value: any) => {
                                    if (value.value) {
                                        array.push(value.value);
                                    }
                                });
                            }
                            data[name] = array;
                        }
                    }
                } else {
                    data[name] = allData[name] ? allData[name].length ? allData[name][0].value : '' : '';
                }
            }
    
            if (reportId) {
                if (parseInt(data.type) === 4) {
                    updateAnalysisReport(data);
                } else {
                    updateReportForm(data);
                }
            } else {
                if (parseInt(data.type) === 4) {
                    submitAddIndividualReportForm(data);
                } else {
                    submitAddReportForm(data);
                }
            }
        }
    }

    useEffect(() => {
        getGroupUserList();
        if (parseInt(item) === 6) {
            surveyListing();
        }
    }, []);

    // get survey listing
    function surveyListing() {
        userService.surveyListing().then((res: any) => {
            let arr = [] as any;
            if (res.data.data) {
                res.data.data.forEach((val: any) => {
                    arr.push({ label: val.name, value: val.id });
                })
                setSurvey(arr);
            }
        }).catch((err: any) => {

        })
    }

    // get data of single field
    function getSingleFilterById() {
        let api;
        if (parseInt(item) === 4) {
            api = userService.singleReportView(reportId);
        } else {
            api = userService.singleFilter(reportId);
        }
        api.then((response) => {
            // get all dropdown fields in current form
            let fields = returnDropDownArrayValue(reportCondition);
            // get schema of current forms
            let schema = returnInitialValueSchema(reportCondition) as any;
            let object = {
                ...response.data.data
            } as any;
            // compose data of dynamic dropdown 
            allDropDownSource.forEach((values) => {
                let { key, source } = values;
                if (fields.includes(key)) {
                    // check if value is array then we compose object
                    if (Array.isArray(response.data.data[key])) {
                        let r2;
                        // from IOS or Andriod device id is returned in string here ID converted into array and then data is composed
                        if (key === "secondary_condition_id" || key === "condition_id" || key === "primary_metric_id" || key === "metric_id") {
                            let arr = [] as any;
                            response.data.data[key].forEach((x: any) => {
                                arr.push(parseInt(x));
                            });
                            r2 = composeDataOfMultipleId(source, key, arr);
                        } else {
                            r2 = composeDataOfMultipleId(source, key, response.data.data[key]);
                        }
                        object[key] = r2;
                    } else {
                        if (reportCondition === "Population Health Report") {
                            // Group type field is common but dropdown data is seperate that is why seperate check is implemented
                            let r1 = dataOfEditFormDropDown(POPULATION_HEALTH_REPORT_SELECT_GROUPS, key, response.data.data[key]);
                            object[key] = r1;
                            if (r1.length) {
                                if (r1[0].label === "Location") {
                                    setTriggerLocation(true);
                                }
                            }
                        } else {
                            // for plain variable
                            let r1 = dataOfEditFormDropDown(source, key, response.data.data[key]);
                            object[key] = r1;
                        }
                    }
                }
            });
            // Compose data of dropdown field whose field option are static loaded at frontend
            frontEndDropDownSource.forEach((value) => {
                let { source, key } = value;
                let data = composeDataByLabel(source, key, response.data.data[key]);
                object[key] = data;
            });
            // Trigger group id field if user select any specific options
            if (object.group_type) {
                if (object.group_type.length) {
                    object.group_type.forEach((value: any) => {
                        if (reportCondition === "Population Health Report") {
                            if (value.value === 2) {
                                setTriggerGroup(true);
                            }
                        } else {
                            if (value.value === 3) {
                                setTriggerGroup(true);
                            }
                        }
                    })
                }
            }


            // ORIGINAL CODE DO NOT REMOVE
            // for (let x = 0; x < allDropDownSource.length; x++) {
            //     let { key, source } = allDropDownSource[x];
            //     if (fields.includes(key)) {
            //         // check if value is array then we compose object
            //         if (Array.isArray(response.data.data[key])) {
            //             let r2 = composeDataOfMultipleId(source, key, response.data.data[key]);
            //             object[key] = r2;
            //         } else {
            //             // for plain variable
            //             let r1 = dataOfEditFormDropDown(source, key, response.data.data[key]);
            //             object[key] = r1;
            //         }
            //     }
            // }

            let finalObj = {} as any;
            Object.keys(schema).forEach((x: any) => {
                if (object[x]) {
                    finalObj[x] = object[x];
                } else {
                    finalObj[x] = schema[x];
                }
            });
            finalObj.id = object.id;
            finalObj.user_id = object.user_id;
            let groupIdStatus = object.group_id ? true : false;
            let areaStatus = object.area ? true : false;
            if (!areaStatus) {
                object.area = '';
            }
            if (!groupIdStatus) {
                finalObj.group_id = [];
            } else {
                // if (parseInt(item) === 7) {
                //     finalObj.group_id.push({ 'label': 'All', 'value': '' });
                // }
            }
            setValues((prevValues) => {
                return {
                    ...prevValues,
                    ...finalObj
                }
            });
            if (response.data.data["latitude"]) {
                setCoordinate((prevCoordinate) => {
                    return {
                        ...prevCoordinate,
                        lat: response.data.data["latitude"],
                        lng: response.data.data["longitude"]
                    }
                });
            }
            setTriggerLoader(false);
            setTriggerEditModule(false);
        }).catch((error) => {
            setTriggerLoader(false);
            setTriggerEditModule(false);
        })
    }

    // get listing of group user
    function getGroupUserList() {
        userService.getGroupOfUser().then((response) => {
            let data = composeDataOfDropDown(response.data.data ? response.data.data : [], 'group_name', 'id');
            if (parseInt(item) === 7) {
                data.push({ 'label': 'All', 'value': '' })
            }
            setGroupUser(data);
            getAllMetrics();
        }).catch((error) => {
            setTriggerLoader(false);
        })
    }

    // get listing of all metric
    function getAllMetrics() {
        userService.allMetric().then((response) => {
            let data = composeDataOfDropDown(response.data.data ? response.data.data : [], 'metrics', 'id');
            setAllMetric(data);
            getDisease();
        }).catch((error) => {
            setTriggerLoader(false);
        })
    }

    // get listing of all disease
    function getDisease() {
        userService.getDisease().then((response) => {
            let data = composeDataOfDropDown(response.data.data ? response.data.data : [], 'disease_name', 'id');
            setDiseaseList(data);
            if (reportId) {
                setTriggerEditModule(true);
            } else {
                setTriggerLoader(false);
                let schema = returnInitialValueSchema(reportCondition);
                setValues((prevValues) => {
                    return {
                        ...prevValues,
                        ...schema
                    }
                });
            }
        }).catch((error) => {
            setTriggerLoader(false);
        })
    }

    useEffect(() => {
        if (triggerEditModule) {
            getSingleFilterById();

        }
    }, [triggerEditModule])

    // API of add report form 
    function submitAddReportForm(data: any) {
        userService.addFilterMetric(data).then((response) => {
            toast.success('Report added successfully.', {
                ...toastConfig
            });
            let url = `/report/list?item=${item}`;
            history.push(url);
        }).catch((error) => {
            setTriggerLoader(false);
        })
    }


    // API of add individual report form
    function submitAddIndividualReportForm(data: any) {
        userService.createAnalysisReport(data).then((response) => {
            toast.success('Report added successfully.', {
                ...toastConfig
            });
            let url = `/report/list?item=${item}`;
            history.push(url);
        }).catch((error) => {
            setTriggerLoader(false);
        })
    }

    // API of update report form
    function updateReportForm(data: any) {
        userService.updateMetricFilter(data).then((response) => {
            toast.success('Report updated successfully.', {
                ...toastConfig
            });
            let url = `/report/list?item=${item}`;
            history.push(url);
        }).catch((error) => {
            setTriggerLoader(false);
        })
    }
    // API of update Individual vs Group Analysis Report
    function updateAnalysisReport(data: any) {
        userService.updateAnalysisReport(data).then((response) => {
            toast.success('Report updated successfully.', {
                ...toastConfig
            });
            let url = `/report/list?item=${item}`;
            history.push(url);
        }).catch((error) => {
            setTriggerLoader(false);
        })
    }

    // compose data of all dropdown in key text pair
    function composeDataOfDropDown(data: any, property: any, value: any) {
        let array = [] as any;
        if (data.length) {
            for (let x = 0; x < data.length; x++) {
                let object = {
                    value: '',
                    label: ''
                };
                object.value = data[x][value];
                object.label = data[x][property];
                array.push(object);
            }
        }
        return array;
    }

    const handleSelect = (address: any) => {
        geocodeByAddress(address).then(results => getLatLng(results[0])).then(latLng => {
            setCoordinate((prevCoordinate) => {
                return {
                    ...prevCoordinate,
                    lat: latLng.lat,
                    lng: latLng.lng
                }
            });
        }).catch(error => console.error('Error', error));
    };

    // handle input of multi select value on selection
    function handleMultiSelect(event: any, type: any) {
        if (event) {
            if (reportCondition === "Population Health Report") {
                if (type === "group_type") {
                    if (parseInt(event.value) === 2) {
                        setTriggerGroup(true);
                    } else {
                        setTriggerGroup(false);
                        clearValueOfSelectGroup();
                    }
                }
            } else {
                if (type === "group_type") {
                    if (parseInt(event.value) === 3) {
                        setTriggerGroup(true);
                    } else {
                        setTriggerGroup(false);
                        clearValueOfSelectGroup();
                    }
                }
            }

        } else {
            setTriggerGroup(false);
            clearValueOfSelectGroup();
        }
    }

    // return dropdown array in case of add/edit report form to compose form value
    function returnDropDownArrayValue(type: any) {
        switch (type) {
            case 'Metric/Condition vs Metric/Condition Trend Report':
                return METRIC_CONDITION_TREND_REPORT_DROPDOWN;
            case 'Metric Comparison Report':
                return METRIC_COMPARISON_REPORT;
            case 'Metrics & Conditions Heat Map Report':
                return METRIC_CONDITION_HEAT_MAP_REPORT;
            case 'Individual vs Group Analysis Report':
                return INDIVIDUAL_VS_GROUP_ANALYSIS_REPORT;
            case 'Survey Report':
                return SURVEY_REPORT;
            case "Group Health Report":
                return GROUP_HEALTH_REPORT;
            case "Population Health Report":
                return POPULATION_GRAPH_REPORT;
            default:
                return []
        }
    }

    // return data of edit form dropdown
    function dataOfEditFormDropDown(data: any, key: any, value: any) {
        let activeObj = data.filter((x: any) => {
            if (x.value === parseInt(value)) {
                return x;
            } else {
                return false;
            }
        });
        return activeObj;
    }

    // compose data of multiple selection dropdown option
    function composeDataOfMultipleId(data: any, key: any, value: any) {
        let array = [] as any;
        data.forEach((x: any) => {
            if (value.includes(x.value)) {
                array.push(x);
            }
        });
        return array;
    }

    //return validation schema on the basis of type
    function returnValidationSchema(type: any) {
        switch (type) {
            case 'Metric/Condition vs Metric/Condition Trend Report':
                return trendReportSchema;
            case 'Metric Comparison Report':
                return comparisonReportSchema;
            case 'Metrics & Conditions Heat Map Report':
                return heatMapReportSchema;
            case 'Individual vs Group Analysis Report':
                return individualGroupAnalysisSchema;
            case 'Health History Report':
                return healthHistorySchema;
            case "Survey Report":
                return surveyReportSchema;
            case "Group Health Report":
                return groupHealthReportSchema;
            case "Population Health Report":
                return populationHealthReportSchema;
            case "Wellness Competition Report":
                return wellnessCompetitionSchema
        }
    }

    // return initialValue Structure for active form
    function returnInitialValueSchema(type: any) {
        switch (type) {
            case 'Metric/Condition vs Metric/Condition Trend Report':
                return trendReportSchemaObject;
            case 'Metric Comparison Report':
                return comparisonReportSchemaObject;
            case 'Metrics & Conditions Heat Map Report':
                return heatMapReportSchemaObject;
            case 'Individual vs Group Analysis Report':
                return individualGroupAnalysisSchemaObject;
            case 'Health History Report':
                return metricsHealthHistoryReport;
            case "Survey Report":
                return metricsSurveyReport;
            case "Group Health Report":
                return groupHealthReport;
            case "Population Health Report":
                return populationHealthReport;
            case "Wellness Competition Report":
                return wellnessCompetitionReport
        }
    }

    // return custom event of select dropdown due to key value pair format is required by react-select plugin
    function composeEventObjectForSelectDropDown(data: any, type: any) {
        let event = {
            target: { value: data ? Array.isArray(data) ? data : [data] : [], name: type },
        };
        return event;
    }

    // compose data on the basis of label
    function composeDataByLabel(data: any, key: any, value: any) {
        let activeObj = data.filter((x: any) => {
            let condition = key === "report_type" ? x.value : x.label;
            if (condition === value) {
                return x;
            } else {
                return false;
            }
        });
        return activeObj;
    }

    // return status of select group type to trigger validation of Group ID field
    function returnStatusOfSelectGrouptype(data: any) {
        if (data.length) {
            if (reportCondition === "Population Health Report") {
                if (data[0].value === 2) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if (data[0].value === 3) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return false;
        }
    }

    // clear value of select group
    function clearValueOfSelectGroup() {
        if (groupIdRef.current) {
            groupIdRef.current.clearValue();
        }
        // remove location and area in case of value change
        // if (reportCondition === "Population Health Report") {
        //     if (populationLocation.current) {
        //         populationLocation.current.value = "";
        //         handleFormState("location", '');
        //     }
        //     if (populationArea.current) {
        //         populationArea.current.value = "";
        //         handleFormState("area", '');
        //     }
        // }
    }

    // Common function to handle form state
    function handleFormState(name: any, value: any) {
        setValues((prevValues) => {
            return {
                ...prevValues,
                [name]: value
            }
        });

        if (name === "location" || name === "area") {
            setCoordinate((prevCoordinate) => {
                return {
                    ...prevCoordinate,
                    lat: '',
                    lng: '',
                }
            });
        }
    }

    function back() {
        let url = localStorage.getItem('active_url');
        window.location.href = url ? url : '/';
    }

    const REQUIRED_FIELD = <span className="text-danger">*</span>

    let startDateMinMaxObject = {
        min: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        max: moment().subtract(2, 'month').format('YYYY-MM-DD')
    };

    return (
        <>
            <section className="bodyvital_section friend_sections notification_sections py-5">
                <Loader status={triggerLoader ? "block" : "none"} />
                <Container>
                    <div className="inner_box pb-4">
                        <Row>
                            <Col md={12} sm={12} xl={12}>
                                <h5 className='mb-2 cursor-pointer'>
                                    <span onClick={back} role="button">
                                        <img src={require('../images/back.svg').default} alt="back-btn" />
                                    </span>
                                </h5>
                                <h2 className="d-inline">{reportId ? 'Update' : 'Add'} Report - {reportCondition}</h2>
                            </Col>
                            <Col md={12}><hr /></Col>
                        </Row>
                        <Formik
                            initialValues={values}
                            validationSchema={returnValidationSchema(reportCondition)}
                            enableReinitialize={true}
                            onSubmit={(values) => {
                                handleFormSubmit(values);
                            }}>
                            {
                                ({ errors, touched, values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                    <form autoComplete="off" onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md={6} sm={12} xl={6}>
                                                <div className="form-group">
                                                    <label htmlFor="report_name">Report Name {REQUIRED_FIELD}</label>
                                                    <input placeholder="Enter Report Name" name="report_name" id="report_name" className="form-control" value={values.report_name ? values.report_name : ''} onChange={handleChange} maxLength={50} />
                                                    {errors.report_name && touched.report_name ? (<label id="uname-error" className="error" htmlFor="report_name"> {errors.report_name}</label>) : null}
                                                </div>
                                            </Col>
                                            {
                                                reportCondition === 'Metrics & Conditions Heat Map Report' ||
                                                    reportCondition === 'Metric Comparison Report' ?
                                                    <Col md={6} sm={12} xl={6}>
                                                        <div className="form-group">
                                                            <label htmlFor="metric_id">Select Metric{reportCondition === 'Metric Comparison Report' ? '(s)' : ''} {REQUIRED_FIELD}</label>
                                                            <SelectDropDown
                                                                id="metric_id"
                                                                name="metric_id"
                                                                options={allMetric.length ? allMetric : []}
                                                                value={values.metric_id ? values.metric_id : []}
                                                                handleMultiSelect={(selectedOption: any, type: any) => {
                                                                    let status = reportCondition === 'Metric Comparison Report' ? true : false;
                                                                    if (status) {
                                                                        if (selectedOption.length <= 2) {
                                                                            let event = composeEventObjectForSelectDropDown(selectedOption, type);
                                                                            handleChange(event);
                                                                        }
                                                                    } else {
                                                                        let event = composeEventObjectForSelectDropDown(selectedOption, type);
                                                                        handleChange(event);
                                                                    }
                                                                }}
                                                                isMulti={reportCondition === 'Metric Comparison Report' ? true : false}
                                                                closeMenuOnSelect={reportCondition === 'Metric Comparison Report' ? false : true}
                                                                placeholder={`Select Metric${reportCondition === 'Metric Comparison Report' ? '(s)' : ''}`}
                                                            // "Select Metric"
                                                            />
                                                            {errors.metric_id && touched.metric_id ? (<label id="uname-error" className="error" htmlFor="metric_id"> {errors.metric_id}</label>) : null}
                                                        </div>
                                                    </Col>
                                                    : ''
                                            }
                                            {
                                                reportCondition === "Metric/Condition vs Metric/Condition Trend Report" ?
                                                    <Col md={6} sm={12} xl={6}>
                                                        <div className="form-group">
                                                            <label htmlFor="primary_metric_id">Base Metric {REQUIRED_FIELD}</label>
                                                            <SelectDropDown
                                                                id="primary_metric_id"
                                                                name="primary_metric_id"
                                                                options={allMetric.length ? allMetric : []}
                                                                value={values.primary_metric_id ? values.primary_metric_id : []}
                                                                handleMultiSelect={(selectedOption: any, type: any) => {
                                                                    let event = composeEventObjectForSelectDropDown(selectedOption, type);
                                                                    handleChange(event);
                                                                }}
                                                                isMulti={false}
                                                                closeMenuOnSelect={true}
                                                                placeholder="Base Metric"
                                                            />
                                                            {errors.primary_metric_id && touched.primary_metric_id ? (<label id="uname-error" className="error" htmlFor="primary_metric_id"> {errors.primary_metric_id}</label>) : null}
                                                        </div>
                                                    </Col>
                                                    : ''
                                            }
                                            {
                                                reportCondition === "Metric/Condition vs Metric/Condition Trend Report" ?
                                                    <Col md={6} sm={12} xl={6}>
                                                        <div className="form-group">
                                                            <label htmlFor="secondary_condition_id">Secondary Condition(s) {REQUIRED_FIELD}</label>
                                                            <SelectDropDown
                                                                id="secondary_condition_id"
                                                                name="secondary_condition_id"
                                                                options={diseaseList.length ? diseaseList : []}
                                                                value={values.secondary_condition_id ? values.secondary_condition_id : []}
                                                                handleMultiSelect={(selectedOption: any, type: any) => {
                                                                    if (selectedOption.length <= 3) {
                                                                        let event = composeEventObjectForSelectDropDown(selectedOption, type);
                                                                        handleChange(event);
                                                                    }
                                                                }}
                                                                isMulti={true}
                                                                closeMenuOnSelect={false}
                                                                placeholder="Select Secondary Condition"
                                                            />
                                                            {errors.secondary_condition_id && touched.secondary_condition_id ? (<label id="uname-error" className="error" htmlFor="secondary_condition_id"> {errors.secondary_condition_id}</label>) : null}
                                                        </div>
                                                    </Col>
                                                    : ''
                                            }
                                            {reportCondition === 'Metrics & Conditions Heat Map Report' ?
                                                <Col md={6} sm={12} xl={6}>
                                                    <div className="form-group">
                                                        <label htmlFor="condition_id">Select Condition {REQUIRED_FIELD}</label>
                                                        <SelectDropDown
                                                            id="condition_id"
                                                            name="condition_id"
                                                            options={diseaseList.length ? diseaseList : []}
                                                            value={values.condition_id ? values.condition_id : []}
                                                            handleMultiSelect={(selectedOption: any, type: any) => {
                                                                let event = composeEventObjectForSelectDropDown(selectedOption, type);
                                                                handleChange(event);
                                                            }}
                                                            isMulti={false}
                                                            closeMenuOnSelect={true}
                                                            placeholder="Select Condition"
                                                        />
                                                        {errors.condition_id && touched.condition_id ? (<label id="uname-error" className="error" htmlFor="condition_id"> {errors.condition_id}</label>) : null}
                                                    </div>
                                                </Col>
                                                : ''}
                                            {reportCondition === 'Metrics & Conditions Heat Map Report' ||
                                                reportCondition === "Metric/Condition vs Metric/Condition Trend Report" ?
                                                <Col md={6} sm={12} xl={6}>
                                                    <div className="form-group">
                                                        <label htmlFor="selectLevel">
                                                            {
                                                                reportCondition === "Metric/Condition vs Metric/Condition Trend Report" ? "Level " : "Select Level "
                                                            }
                                                            {reportCondition === 'Metrics & Conditions Heat Map Report' ? '' : REQUIRED_FIELD}</label>
                                                        <SelectDropDown
                                                            id="selectLevel"
                                                            name="level"
                                                            options={LEVEL}
                                                            value={values.level ? values.level : []}
                                                            handleMultiSelect={(selectedOption: any, type: any) => {
                                                                let event = composeEventObjectForSelectDropDown(selectedOption, type);
                                                                handleChange(event);
                                                            }}
                                                            isMulti={false}
                                                            closeMenuOnSelect={true}
                                                            placeholder="Select Level"
                                                        />
                                                        {errors.level && touched.level ? (<label id="uname-error" className="error" htmlFor="level"> {errors.level}</label>) : null}
                                                    </div>
                                                </Col>
                                                : ''}
                                            {
                                                reportCondition === "Metric/Condition vs Metric/Condition Trend Report" ||
                                                    reportCondition === 'Metric Comparison Report' ||
                                                    reportCondition === 'Health History Report' ||
                                                    reportCondition === "Survey Report" ||
                                                    reportCondition === "Wellness Competition Report"
                                                    ?
                                                    <Col md={6} sm={12} xl={6}>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="start_date">Start Date {REQUIRED_FIELD}</label>
                                                                    <input type="date" placeholder="YYYY-MM-DD" className="form-control" id="start_date" name="start_date" min={startDateMinMaxObject.min} max={startDateMinMaxObject.max} value={values.start_date ? values.start_date : ''}
                                                                        onChange={(event: any) => {
                                                                            handleChange(event);
                                                                            let obj = { target: { name: 'end_date', value: '' } };
                                                                            handleChange(obj);
                                                                        }} />
                                                                    {errors.start_date && touched.start_date ? (<label id="uname-error" className="error" htmlFor="start_date"> {errors.start_date}</label>) : null}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="end_date">End Date {REQUIRED_FIELD}</label>
                                                                    <input type="date" placeholder="YYYY-MM-DD" className="form-control" id="end_date" name="end_date" min={moment(values.start_date).add(2, 'month').format('YYYY-MM-DD')} max={moment().format('YYYY-MM-DD')} value={values.end_date ? values.end_date : ''} onChange={handleChange} disabled={values.start_date ? false : true} />
                                                                    {errors.end_date && touched.end_date ? (<label id="uname-error" className="error" htmlFor="end_date"> {errors.end_date}</label>) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    : ''
                                            }
                                            {
                                                reportCondition === 'Metric Comparison Report' ?
                                                    <Col md={6} sm={12} xl={6}>
                                                        <div className="form-group">
                                                            <label htmlFor="report_type">Select Report Type</label>
                                                            <SelectDropDown
                                                                id="report_type"
                                                                name="report_type"
                                                                options={METRIC_COMPARISON_REPORT_TYPE}
                                                                value={values.report_type ? values.report_type : []}
                                                                handleMultiSelect={(selectedOption: any, type: any) => {
                                                                    let event = composeEventObjectForSelectDropDown(selectedOption, type);
                                                                    handleChange(event);
                                                                }}
                                                                isMulti={false}
                                                                closeMenuOnSelect={true}
                                                                placeholder="Select Report type"
                                                            />
                                                            {errors.report_type && touched.report_type ? (<label id="uname-error" className="error" htmlFor="report_type"> {errors.report_type}</label>) : null}
                                                        </div>
                                                    </Col>
                                                    : ''
                                            }
                                            {
                                                reportCondition === 'Individual vs Group Analysis Report' ?
                                                    <Col md={6} sm={12} xl={6}>
                                                        <div className="form-group">
                                                            <label htmlFor="metric_type">Report Category {REQUIRED_FIELD}</label>
                                                            <SelectDropDown
                                                                id="metric_type"
                                                                name="metric_type"
                                                                options={INDIVIDUAL_VS_GROUP_ANALYSIS}
                                                                value={values.metric_type ? values.metric_type : []}
                                                                handleMultiSelect={(selectedOption: any, type: any) => {
                                                                    let event = composeEventObjectForSelectDropDown(selectedOption, type);
                                                                    handleChange(event);
                                                                }}
                                                                isMulti={false}
                                                                closeMenuOnSelect={true}
                                                                placeholder="Select"
                                                            />
                                                            {errors.metric_type && touched.metric_type ? (<label id="uname-error" className="error" htmlFor="metric_type"> {errors.metric_type}</label>) : null}
                                                        </div>
                                                    </Col>
                                                    : ''
                                            }
                                            {reportCondition === 'Metrics & Conditions Heat Map Report' ||
                                                reportCondition === 'Individual vs Group Analysis Report' ?
                                                <Col md={6} sm={12} xl={6}>
                                                    <div className="form-group">
                                                        <label htmlFor="duration">Select Duration</label>
                                                        <SelectDropDown
                                                            id="duration"
                                                            name="duration"
                                                            options={reportCondition === 'Individual vs Group Analysis Report' ? ANALYSIS_DURATION : DURATION}
                                                            value={values.duration ? values.duration : []}
                                                            // handleMultiSelect={handleMultiSelect}
                                                            handleMultiSelect={(selectedOption: any, type: any) => {
                                                                let event = composeEventObjectForSelectDropDown(selectedOption, type);
                                                                handleChange(event);
                                                            }}
                                                            isMulti={false}
                                                            closeMenuOnSelect={true}
                                                            placeholder="Select Duration"
                                                        />
                                                        {errors.duration && touched.duration ? (<label id="uname-error" className="error" htmlFor="duration"> {errors.duration}</label>) : null}
                                                    </div>
                                                </Col>
                                                : ''}
                                            {
                                                reportCondition === "Population Health Report" ?
                                                    <Col md={6} sm={12} xl={6}>
                                                        <div className="form-group">
                                                            <label htmlFor="disease_id">Select Disease {REQUIRED_FIELD}</label>
                                                            <SelectDropDown
                                                                id="disease_id"
                                                                name="disease_id"
                                                                options={diseaseList.length ? diseaseList : []}
                                                                value={values.disease_id ? values.disease_id : []}
                                                                handleMultiSelect={(selectedOption: any, type: any) => {
                                                                    if (selectedOption.length <= 5) {
                                                                        let event = composeEventObjectForSelectDropDown(selectedOption, type);
                                                                        handleChange(event);
                                                                    }
                                                                }}
                                                                isMulti={true}
                                                                closeMenuOnSelect={false}
                                                                placeholder="Select Disease"
                                                            />
                                                            {errors.disease_id && touched.disease_id ? (<label id="uname-error" className="error" htmlFor="disease_id"> {errors.disease_id}</label>) : null}
                                                        </div>
                                                    </Col> : ''
                                            }
                                            {
                                                reportCondition === "Population Health Report" ?
                                                    <Col md={6} sm={12} xl={6}>
                                                        <div className="form-group">
                                                            <label htmlFor="group_type">Select population {REQUIRED_FIELD}</label>
                                                            <SelectDropDown
                                                                id="group_type"
                                                                name="group_type"
                                                                options={POPULATION_HEALTH_REPORT_SELECT_GROUPS}
                                                                value={values.group_type ? values.group_type : []}
                                                                handleMultiSelect={(selectedOption: any, type: any) => {
                                                                    let event = composeEventObjectForSelectDropDown(selectedOption, type);
                                                                    handleChange(event);
                                                                    handleMultiSelect(selectedOption, type);
                                                                    if (selectedOption ? selectedOption.label === "Location" : false) {
                                                                        setTriggerLocation(true);
                                                                    } else {
                                                                        setTriggerLocation(false);
                                                                    }
                                                                }}
                                                                isMulti={false}
                                                                closeMenuOnSelect={true}
                                                                placeholder="Select"
                                                            />
                                                            {errors.group_type && touched.group_type ? (<label id="uname-error" className="error" htmlFor="group_type"> {errors.group_type}</label>) : null}
                                                        </div>
                                                    </Col>
                                                    : ''
                                            }
                                            {reportCondition === 'Metrics & Conditions Heat Map Report' || reportCondition === "Metric/Condition vs Metric/Condition Trend Report" || triggerLocation ?
                                                <Col md={6} sm={12} xl={6}>
                                                    <div className="form-group">
                                                        <label htmlFor="location">Location {REQUIRED_FIELD}</label>
                                                        {/* <input type="text" className="form-control" id="location" /> */}
                                                        <PlacesAutocomplete
                                                            value={values.location ? values.location : ''}
                                                            onChange={(e: any) => {
                                                                let event = {
                                                                    target: {
                                                                        value: e,
                                                                        name: `location`,
                                                                    },
                                                                };
                                                                handleChange(event);
                                                            }}
                                                            onSelect={(e: any) => {
                                                                let event = {
                                                                    target: {
                                                                        value: e,
                                                                        name: `location`,
                                                                    },
                                                                };
                                                                handleChange(event);
                                                                handleSelect(e);
                                                            }}>
                                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                <div>
                                                                    <input
                                                                        {...getInputProps({
                                                                            placeholder: 'Search Places ...',
                                                                            className: 'location-search-input form-control',
                                                                        })}
                                                                        ref={populationLocation}
                                                                    />
                                                                    <div className="autocomplete-dropdown-container">
                                                                        {loading && <div>Loading...</div>}
                                                                        {suggestions.map(suggestion => {
                                                                            const className = suggestion.active
                                                                                ? 'suggestion-item--active'
                                                                                : 'suggestion-item';
                                                                            // inline style for demonstration purpose
                                                                            const style = suggestion.active
                                                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                                            return (
                                                                                <div
                                                                                    {...getSuggestionItemProps(suggestion, {
                                                                                        className,
                                                                                        style,
                                                                                    })}
                                                                                    key={suggestion.placeId}>
                                                                                    <span>{suggestion.description}</span>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </PlacesAutocomplete>
                                                        {errors.location && touched.location ? (<label id="uname-error" className="error" htmlFor="location"> {errors.location}</label>) : null}
                                                    </div>
                                                </Col>
                                                : ''}
                                            {reportCondition === 'Metrics & Conditions Heat Map Report' ||
                                                reportCondition === "Metric/Condition vs Metric/Condition Trend Report" || triggerLocation ?
                                                <Col md={6} sm={12} xl={6}>
                                                    <div className="form-group">
                                                        <label htmlFor="area">Area(miles)</label>
                                                        <input type="text" placeholder="Enter Area in Miles" className="form-control" id="area" name="area" value={values.area ? values.area : ''} onChange={(event) => {
                                                            let { value } = event.target;
                                                            if (NUMBER_DECIMAL_REGEX.test(value)) {
                                                                handleChange(event)
                                                            }
                                                        }}
                                                            ref={populationArea} />
                                                        {errors.area && touched.area ? (<label id="uname-error" className="error" htmlFor="area"> {errors.area}</label>) : null}
                                                    </div>
                                                </Col>
                                                : ''}
                                            {reportCondition === 'Health History Report' ||
                                                reportCondition === "Survey Report" ||
                                                reportCondition === "Group Health Report" ||
                                                reportCondition === "Population Health Report" ||
                                                reportCondition === "Wellness Competition Report"
                                                ? '' : <Col md={6} sm={12} xl={6}>
                                                    <div className="form-group">
                                                        <label htmlFor="group_type">Select Group type</label>
                                                        <SelectDropDown
                                                            id="group_type"
                                                            name="group_type"
                                                            options={SELECTED_GROUPS}
                                                            value={values.group_type ? values.group_type : []}
                                                            handleMultiSelect={(selectedOption: any, type: any) => {
                                                                let event = composeEventObjectForSelectDropDown(selectedOption, type);
                                                                handleChange(event);
                                                                handleMultiSelect(selectedOption, type);
                                                            }}
                                                            isMulti={false}
                                                            closeMenuOnSelect={true}
                                                            placeholder="Select Group type"
                                                        />
                                                        {errors.group_type && touched.group_type ? (<label id="uname-error" className="error" htmlFor="group_type"> {errors.group_type}</label>) : null}
                                                    </div>
                                                </Col>}
                                            {
                                                reportCondition === "Survey Report" ?
                                                    <Col md={6} sm={12} xl={6}>
                                                        <div className="form-group">
                                                            <label htmlFor="survey_id">Survey {REQUIRED_FIELD}</label>
                                                            <SelectDropDown
                                                                id="survey_id"
                                                                name="survey_id"
                                                                options={survey}
                                                                value={values.survey_id ? values.survey_id : []}
                                                                handleMultiSelect={(selectedOption: any, type: any) => {
                                                                    let event = composeEventObjectForSelectDropDown(selectedOption, type);
                                                                    handleChange(event);
                                                                    handleMultiSelect(selectedOption, type);
                                                                }}
                                                                isMulti={false}
                                                                closeMenuOnSelect={true}
                                                                placeholder="Select"
                                                            />
                                                            {errors.survey_id && touched.survey_id ? (<label id="uname-error" className="error" htmlFor="survey_id"> {errors.survey_id}</label>) : null}
                                                        </div>
                                                    </Col>
                                                    : ''
                                            }
                                            {
                                                reportCondition === "Survey Report" ?
                                                    <Col md={6} sm={12} xl={6}>
                                                        <div className="form-group">
                                                            <label htmlFor="survey_type">Survey type {REQUIRED_FIELD}</label>
                                                            <SelectDropDown
                                                                id="survey_type"
                                                                name="survey_type"
                                                                options={SURVEY_TYPE}
                                                                value={values.survey_type ? values.survey_type : []}
                                                                handleMultiSelect={(selectedOption: any, type: any) => {
                                                                    let event = composeEventObjectForSelectDropDown(selectedOption, type);
                                                                    handleChange(event);
                                                                    handleMultiSelect(selectedOption, type);
                                                                }}
                                                                isMulti={false}
                                                                closeMenuOnSelect={true}
                                                                placeholder="Select"
                                                            />
                                                            {errors.survey_type && touched.survey_type ? (<label id="uname-error" className="error" htmlFor="survey_type"> {errors.survey_type}</label>) : null}
                                                        </div>
                                                    </Col>
                                                    : ''
                                            }
                                            {
                                                reportCondition === "Group Health Report" ?
                                                    <Col md={6} sm={12} xl={6}>
                                                        <div className="form-group">
                                                            <label htmlFor="survey_id">Select Group</label>
                                                            <SelectDropDown
                                                                id="group_id"
                                                                name="group_id"
                                                                options={groupUser.length ? groupUser : []}
                                                                value={values.group_id ? values.group_id : []}
                                                                handleMultiSelect={(selectedOption: any, type: any) => {
                                                                    let event = composeEventObjectForSelectDropDown(selectedOption, type);
                                                                    handleChange(event);
                                                                }}
                                                                isMulti={false}
                                                                closeMenuOnSelect={true}
                                                                placeholder="All"
                                                                ref={groupIdRef}
                                                            />
                                                            {errors.group_id && touched.group_id ? (<label id="uname-error" className="error" htmlFor="group_id"> {errors.group_id}</label>) : null}
                                                        </div>
                                                    </Col>
                                                    : ''
                                            }
                                            {
                                                triggerGroup ?
                                                    <Col md={6} sm={12} xl={6}>
                                                        <div className="form-group">
                                                            <label htmlFor="group_id">Select Groups {REQUIRED_FIELD}</label>
                                                            <SelectDropDown
                                                                id="group_id"
                                                                name="group_id"
                                                                options={groupUser.length ? groupUser : []}
                                                                value={values.group_id ? values.group_id : []}
                                                                handleMultiSelect={(selectedOption: any, type: any) => {
                                                                    let event = composeEventObjectForSelectDropDown(selectedOption, type);
                                                                    handleChange(event);
                                                                }}
                                                                isMulti={reportCondition === "Population Health Report" ? false : true}
                                                                closeMenuOnSelect={reportCondition === "Population Health Report" ? false : false}
                                                                placeholder="Select Groups"
                                                                ref={groupIdRef}
                                                            />
                                                            {errors.group_id && touched.group_id ? (<label id="uname-error" className="error" htmlFor="group_id"> {errors.group_id}</label>) : null}
                                                        </div>
                                                    </Col>
                                                    : ''
                                            }
                                        </Row>
                                        <button type="submit" className="btn btn-primary" disabled={triggerLoader}>{reportId ? 'Update' : 'Submit'}</button>
                                    </form>
                                )
                            }
                        </Formik>
                    </div>
                </Container>
            </section>
        </>
    )
}

const colourStyles = {
    option: (styles:any) => ({
      ...styles,
      zIndex: 1,
      fontSize:window.innerWidth<=500?"14px":"16px"
    })
  }

// common component of react-select dropdown
const SelectDropDown = forwardRef((props: any, ref: any) => (
    <Select
        id={props.id}
        name={props.name}
        components={animatedComponents}
        closeMenuOnSelect={props.closeMenuOnSelect}
        isClearable={true}
        onChange={(e: any) => { props.handleMultiSelect(e, props.name) }}
        options={props.options}
        value={props.value}
        isMulti={props.isMulti}
        placeholder={props.placeholder}
        ref={ref ? ref : null}
        styles={colourStyles}
    />
));

export default AddReport;