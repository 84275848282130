import { useState } from "react";
import { Form } from "react-bootstrap"
import { userService, formValidation } from '../../_services';
import { Loader } from '../Loader';
const Step1 = (props: any) => {
	const [showloader, setShowLoader] = useState("none");
	const [err, setErr] = useState("") as any;
	const handlenamechange = (e: any) => {
		props.defaultData.firstname = e;
	}
	const handlenamechange1 = (e: any) => {
		props.defaultData.lastname = e;
	}
	const errors: any = {
		firstname: "",
		lastname: "",
		formIsValid: false,
	};
	const nextstep = (event:any) => {
		event.preventDefault();
		let params = {
			firstname: props.defaultData.firstname,
			lastname: props.defaultData.lastname
		};
		let validate = formValidation.step1_validation(params, errors);
		if (validate.formIsValid) {
			setShowLoader("flex");
			var formData = new FormData();
			formData.append("firstname", props.defaultData.firstname);
			formData.append("lastname", props.defaultData.lastname);
			userService.createUserProfile(formData).then(function (response: any) {
				props.next();
				localStorage.setItem('username', params.firstname + " " + params.lastname);
				setShowLoader("none");
			}).catch(function (error: any) {
				setShowLoader("none");
			});
		} else {
			setErr(validate);
		}
	}
	
	
	return (
		<section className="loginsec">
			<Loader status={showloader} />
			<div className="right_side py-5">
				<div className="container">
					<div className="row">
						<aside className="col-xl-5 col-lg-7 col-sm-12 m-auto">
							<div/>
							<div className="login_form mt-4">
								<div className="text-center">
									<p className="text-secondary">STEP 1</p>
									{props.defaultData.editProfile ? (<h4>Update Your Profile</h4>) : (<h4>Create Your Profile</h4>)}

									<p>Completing your profile will allow the Xcellent Life Application to offer
										you the greatest value by personalizing wellness content, specifying
										wellness insights that improve your health and recommending specific
										actions that protect and improve your health.</p>
									<p>Completion of your profile will also give you access to awards and
										increase your point totals in wellness competition. Let’s get started
										with your name.</p>
								</div>
								<Form className="mt-4" autoComplete="off" onSubmit={(event:any)=>nextstep(event)}>
									<Form.Group className="mb-3" controlId="firstname">
										<Form.Control type="text" placeholder="First Name*" className="input103" defaultValue={props.defaultData.firstname} onChange={(e: any) => handlenamechange(e.target.value)} />
										{err ? (<span className="errors">{err.firstname}</span>) : (<></>)}
									</Form.Group>
									<Form.Group className="mb-3" controlId="lastname">
										<Form.Control type="text" placeholder="Last Name*" className="input103" defaultValue={props.defaultData.lastname} onChange={(e: any) => handlenamechange1(e.target.value)} />
										{err ? (<span className="errors">{err.lastname}</span>) : (<></>)}
									</Form.Group>
								<div className="text-center mt-4">
									<button type="submit" className="loginbtn btn shadow text-white">Next</button>
									{/* <a href="/step2" className="loginbtn btn shadow text-white">Next</a> */}
									<p className="forgotpassword">Profile 20% Complete </p>
								</div>
								</Form>
							</div>
						</aside>
					</div>
				</div>
			</div>
		</section>

	)
}

export default Step1;