import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ToastContainer } from 'react-toastify';
import { initFacebookSdk } from './_helpers/init-facebook-sdk';
import Interceptor from './_services/interceptor';

import { getMessaging, getToken ,isSupported} from "firebase/messaging";

function createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & (0x3 | 0x8));
        return v.toString(16);
    });
}



// console.log('firebase.messaging.isSupported()', firebase.messaging.isSupported());
// if (firebase.messaging.isSupported()) {
//     const messaging = firebase.messaging();
    
//     messaging.getToken({ vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_VAPID_KEY }).then(token => {
//         // var oldToken = localStorage.getItem("firebaseToken");
//         if (localStorage.getItem("firebaseToken") !== token) {
//             localStorage.setItem("udid", createUUID());
//             localStorage.setItem("firebaseToken", token);
//         }
//     }).catch(err => {
//         console.log("Error....", err);
//     })
// }





//   function requestPermission() {
//     console.log('Requesting permission...');
//     Notification.requestPermission().then((permission) => {
//         console.log('Notification.requestPermission',permission);
//       if (permission === 'granted') {
//         console.log('Notification permission granted.');
//       }else{

//       }
//     }).catch((error:any)=>{
//         console.log("Notification.requestPermission...",error)
//     })
// }


isSupported().then((response:any)=>{
console.log('isSupported',response);

localStorage.setItem("isSupported",response);

const messaging = getMessaging();

getToken(messaging,{ vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_VAPID_KEY }).then((currentToken) => {
    if (currentToken) {
      // Send the token to your server and update the UI if necessary
      // ...
      if (localStorage.getItem("firebaseToken") !== currentToken) {
          localStorage.setItem("udid", createUUID());
          localStorage.setItem("firebaseToken", currentToken);
      }
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
      // ...
      // requestPermission();
    }

  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // requestPermission()
    // ...
  });
}).catch((error:any)=>{
  console.log('isSupported',error);
  localStorage.setItem("isSupported","false");
})




initFacebookSdk().then(response => {
    console.log('\n initFacebookSdk...', response);
});


ReactDOM.render(
    <>
    <Interceptor />
        <App />
        <ToastContainer newestOnTop />
    </>
    , document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();