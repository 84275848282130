import ReactPaginate from 'react-paginate';


function Pagination(props: any) {
    return (
        <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            onPageChange={props.handlePageClick}
            pageRangeDisplayed={5}
            forcePage={props.page}
            pageCount={props.totalPage}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            activeClassName="active"
        />
    )
}

export default Pagination;