import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Form, Container, Button, Row, Col } from 'react-bootstrap';



const Inviteusers = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <section className="bodyvital_section py-5">
                <Container>
                    <div className="inner_box pb-4">
                        <Row className="bodyvitals_head">
                            <Col md={9}>
                                <h5>
                                    <a href="/creategroup">
                                        <img src={require('../images/back.svg').default} alt="back" />
                                    </a>
                                </h5>
                                <h2>Invite Users</h2>
                            </Col>
                            <Col md={3} className="text-right">
                                <Form.Group className="mb-3" controlId="searchHere">
                                    <div className="search_bar_row">
                                        <Form.Control type="text" placeholder="Search here" />
                                        <span>
                                            <img src={require('../images/search.svg').default} alt="search" width="18px" />
                                        </span>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <hr />
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <div className="users_box mb-3">
                                    <div className="invite_user_image">
                                        <img src={require('../images/profile.png').default} alt="profile" />
                                    </div>
                                    <span>Adam Smith</span>
                                    <div className="invite_btn1">
                                        <Button variant="unset" type="submit" className="btnsumit text-white" onClick={handleShow}>
                                            Send Invite
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="users_box mb-3">
                                    <div className="invite_user_image">
                                        <img src={require('../images/profile.png').default} alt="profile" />
                                    </div>
                                    <span>Adam Smith</span>
                                    <div className="invite_btn1">
                                        <Button variant="unset" type="submit" className="btnsumit text-white d-none" onClick={handleShow}>
                                            Send Invite
                                        </Button>
                                        <Button variant="unset" type="submit" className="btnsumit invited_btn text-white" >
                                            Sent
                                            <img src={require('../images/sent1.svg').default} alt="sent1" className="ml-2" width="25px" />
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="users_box mb-3">
                                    <div className="invite_user_image">
                                        <img src={require('../images/profile.png').default} alt="profile" />
                                    </div>
                                    <span>Adam Smith</span>
                                    <div className="invite_btn1">
                                        <Button variant="unset" type="submit" className="btnsumit text-white" onClick={handleShow}>
                                            Send Invite
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="users_box mb-3">
                                    <div className="invite_user_image">
                                        <img src={require('../images/profile.png').default} alt="profile" />
                                    </div>
                                    <span>Adam Smith</span>
                                    <div className="invite_btn1">
                                        <Button variant="unset" type="submit" className="btnsumit text-white d-none" onClick={handleShow}>
                                            Send Invite
                                        </Button>
                                        <Button variant="unset" type="submit" className="btnsumit invited_btn text-white" >
                                            Sent
                                            <img src={require('../images/sent1.svg').default} alt="sent1" className="ml-2" width="25px" />
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="users_box mb-3">
                                    <div className="invite_user_image">
                                        <img src={require('../images/profile.png').default} alt="profile" />
                                    </div>
                                    <span>Adam Smith</span>
                                    <div className="invite_btn1">
                                        <Button variant="unset" type="submit" className="btnsumit text-white" onClick={handleShow}>
                                            Send Invite
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="users_box mb-3">
                                    <div className="invite_user_image">
                                        <img src={require('../images/profile.png').default} alt="profile" />
                                    </div>
                                    <span>Adam Smith</span>
                                    <div className="invite_btn1">
                                        <Button variant="unset" type="submit" className="btnsumit text-white" onClick={handleShow}>
                                            Send Invite
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <Modal show={show} onHide={handleClose} size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton className="border-0 p-1 pt-2 pr-3 pb-0"></Modal.Header>
                <Modal.Body className="text-center pt-0">
                    <img src={require('../images/check1.svg').default} alt="check1" width="75px" />
                    <h5 className="mt-3 mb-3">Successfully sent invitation.</h5>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Inviteusers;