
import Header from './Header';
// import Footer from './Footer';
// import AuthContextProvider from '../../contexts/AuthContext';
import LoginHeader from './LoginHeader';
import FormHeader from './FormHeader';
import { Redirect } from 'react-router-dom';

export const Layout = (props: any) => {
  const accesstoken = localStorage.getItem('accesstoken');

  if (props.type === 'loggedin' || props.type === 'social') {
    if (accesstoken === null || accesstoken === undefined) {
      return <Redirect to='/login' />
    }
    return (
      // <AuthContextProvider>
      <div className="main">
        <LoginHeader />
        {props.children}
        {/* <Footer/> */}
      </div>
      // </AuthContextProvider>
    )
  } else if (props.type === 'landing') {
    return (<div className="main">
      <Header />
      {props.children}
      {/* <Footer/> */}
    </div>
    )
  } else if (props.type === 'form') {
    return (<div className="main">
      <FormHeader />
      {props.children}
      {/* <Footer/> */}
    </div>
    )
  } else {
    return (
      <div className="main">
        {props.children}
      </div>
    )
  }
}