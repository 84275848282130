import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { userService } from '../_services';
import { useHistory } from 'react-router';
import { Loader } from './Loader';
import moment from 'moment';

const LiveCompetition = (props: any) => {
    const [data, setData] = useState({}) as any;
    const [compId, setCompId] = useState("") as any;
    const [showloader, setShowloader] = useState({ display: "flex" });
    let history = useHistory();

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let comp_id = params.get("comp_id");
  
        if(comp_id){
            toggleLoader("flex");
            setCompId(comp_id);
            getCompetitionDetail(comp_id);
        }
        
    }, []);


    function getCompetitionDetail(comp_id:any){
        userService.competitionDetails(comp_id).then(resp => {
            if (resp.data.data.status == 'J') {
                history.push('/competition');
            }
            setData(resp.data.data);
            toggleLoader("none");
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            toggleLoader("none");
        })
    }

    function toggleLoader(status: string) {
        setShowloader(prevShowloader => {
            return {
                ...prevShowloader,
                display: status
            }
        });
    }

    function back() {
        history.push('/competition');
    }

    return (
        <>
            <section className="profile-page py-5">
                <Loader status={showloader.display} />
                <div className="container">
                    <div className="inner_box ">
                        {data ?
                        <>
                           <Row>
                            <Col md={12} sm={12} xl={12}>
                                <h5 className='mb-2 cursor-pointer'>
                                    <span onClick={back} role="button">
                                        <img src={require('../images/back.svg').default} alt="back-btn" />
                                    </span>
                                </h5>
                            </Col>
                        </Row>
                            <Row className="bodyvitals_head align-items-center">
                                <Col md={4} sm={5}>
                                    <h2>Details</h2>
                                    <h5 className="live_1">{data.title}</h5>
                                </Col>
                                <Col md={8} sm={7} className="startendrow">
                                    <h6 className="startenddate">Start Date: <span>{moment(data.start_date).format('MM/DD/YYYY')}</span></h6>
                                    <h6 className="startenddate mt-2">End Date: <span>{moment(data.end_date).format('MM/DD/YYYY')}</span></h6>
                                </Col>
                                <Col md={12}>
                                    <hr />
                                </Col>
                                <Col cd={12} className="text-center">
                                    <h3 className="mt-3 mb-1">Instructions to the Participants</h3>
                                    <p>{data.instruction}</p>
                                    <div className="my-4 social_icons_1">
                                        <Button variant="unset" className="bg-transparent border-0 unset_pointer">
                                            <img src={require('../images/facebook1.png').default} alt="facebook1" />
                                        </Button>
                                        <Button variant="unset" className="bg-transparent border-0 unset_pointer">
                                            <img src={require('../images/linkedin.png').default} alt="linkedin" />
                                        </Button>
                                        <Button variant="unset" className="bg-transparent border-0 unset_pointer">
                                            <img src={require('../images/twitter.png').default} alt="twitter" />
                                        </Button>
                                        <Button variant="unset" className="bg-transparent border-0 unset_pointer">
                                            <img src={require('../images/instagram.png').default} alt="instagram" />
                                        </Button>
                                    </div>
                                    {
                                        compId?<div className="mt-4">
                                        <Link to={`/details?comp_id=${compId}`}>
                                            <Button variant="unset" className="border-0 btnsumit" >
                                                Okay
                                            </Button>
                                        </Link>
                                    </div>:<div className='row'>
                                        <div className='col-md-12 text-center'>No Competition is available</div>
                                    </div>
                                    }
                                </Col>
                            </Row>
                        </>
                            : ""}
                    </div>
                </div>
            </section>
        </>
    )
}

export default LiveCompetition;