import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { userService } from '../_services';
import { Loader } from './Loader';

const Friendrequest = () => {
    const [data, setData] = useState({}) as any;
    const [show, setShow] = useState("none");
    let user_id = localStorage.getItem("user_id");
    let history = useHistory();
    useEffect(() => {
        toggleLoader("flex");
        let search = window.location.search;
        let params = new URLSearchParams(search);
        var uid = params.get("uid") as any;
        uid = atob(uid);
        userService.friendRequestList().then(resp => {
            var temp = resp.data.data;
            if (temp.length) {
                for (let index = 0; index < temp.length; index++) {
                    const element = temp[index];
                    if (element.id == uid) {
                        setData(element);
                    }
                }
            } else {
                toast.error('An error occured.');
                // history.go(-1)
                history.push("/notification");
            }
            toggleLoader("none");
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            toggleLoader("none");
            toast.error('Unknown error occured.');
            history.go(-1);
        })
    }, [])

    function submitRequest(r: any) {
        toggleLoader("flex");
        var params = {
            "user_id": user_id,
            "other_id": data.id,
            "status": r
        }
        userService.acceptRejectRequest(params).then(resp => {
            if(r === "A"){
                toast.success(resp.data.message);
            }
            toggleLoader("none");
            history.push('/friendrequestlist')
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            toast.error('An error occured. Please try after some time.')
            toggleLoader("none");
        })
    }

    function toggleLoader(status: any) {
        setShow(status);
    }

    return (
        <>
            <section className="accpt_reject_section py-5">
                <Loader status={show} />
                <Container>
                    <a href="/"><img src={require('../images/back.svg').default} alt="back" /></a>
                    <div className="inner_box pb-4">
                        <Row className="align-items-center">
                            <Col lg={12} md={12} className="text-center m-auto">
                                <div className="frndrqst_img m-auto">
                                    <img src={data.profileImage} alt="m_auto" />
                                </div>
                                <div className="mt-3">
                                    <h3>{data.firstname} {data.lastname}</h3>
                                    {data.location != null ? <h5><img src={require('../images/location.svg').default} alt="location" className="mr-2" width="14px" />{data.location}</h5> : <h5>User location error.</h5>}
                                    <button className="btn accptbtn text-white" onClick={() => { submitRequest("A") }}>Accept Friend Request</button>
                                    <button className="btn Rejectbtn text-white" onClick={() => { submitRequest("R") }}>Reject Friend Request</button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Friendrequest;