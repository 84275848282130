import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const MainChat = () => {
    const [show, setShow] = useState(false);

    function toggleModal(status:any){
        setShow(status);
    }

    return (
        <>
            <section className="bodyvital_section py-5">
                <Container>
                    <div className="inner_box pb-0">
                        <Row className="bodyvitals_head">
                            <Col md={6}>
                                <h5>
                                    <a href="/chatlist">
                                        <img src={require('../images/back.svg').default} alt="back" />
                                    </a>
                                </h5>
                                <h2>Adam Smith</h2>
                            </Col>
                            <Col md={6} className="text-right">
                                <Link className="border-0 bg-transparent mx-1" to="#/">
                                    <img src={require('../images/videocall.svg').default} alt="videocall" />
                                </Link>
                            </Col>
                            <Col md={12}>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <div className="chat_row">
                                {/* <div className="no_chat_box">
                                    <img src={require('../images/no_msg.svg').default} alt="" />
                                </div> */}
                                <div className="chat_box">
                                    <div className="leftside_chat">
                                        <div className="user_img"><img src={require('../images/user.png').default} alt="user" /></div>
                                        <div className="user_name_p">
                                            <h5>Victor</h5>
                                            <p className="user_msg">Hello @all, How are you?</p>
                                            <p className="msg_time">12:10 PM</p>
                                        </div>
                                    </div>
                                    <div className="leftside_chat">
                                        <div className="user_img"><img src={require('../images/user.png').default} alt="user" /></div>
                                        <div className="user_name_p">
                                            <h5>Noel</h5>
                                            <p className="user_msg">I am fine and you?</p>
                                            <p className="msg_time">12:10 PM</p>
                                        </div>
                                    </div>
                                    <div className="leftside_chat right_side_user">
                                        <div className="user_name_p">
                                            <h5 className="text-right">You</h5>
                                            <p className="user_msg">Hello @all, How are you?</p>
                                            <p className="msg_time text-left">12:10 PM</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="send_message_row">
                                    <span>
                                        <Link to="">
                                            <img src={require('../images/add.svg').default} alt="add" />
                                        </Link>
                                    </span>
                                    <input type="text" placeholder="Enter Your Message" />
                                    <span>
                                        <Link to="">
                                            <img src={require('../images/send.svg').default} alt="send" />
                                        </Link>
                                    </span>
                                </div>
                            </div>
                        </Row>
                    </div>
                </Container>
            </section>

            <Modal show={show} onHide={()=>toggleModal(!show)} size="sm"
                aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton className="border-0 p-1">
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center pt-0">
                    <img src={require('../images/check1.svg').default} alt="check1" width="85px" />
                    <h5 className="mt-2 mb-3">Record added successfully.</h5>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MainChat;