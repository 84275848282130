export const Loader = (props: any) => {
    return (
        <div className="loader" style={{ display: props.status }}>
            <div className="loader-inner line-scale">
                <div />
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    )
}
export const ButtonLoader = () => {
    return (
        <div role="status" className="spinner-border">
            <span className="sr-only">Loading...</span>
        </div>
    )
}