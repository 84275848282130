import { useEffect, useState } from 'react';
import { Form, Container, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { userService } from '../_services';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Loader } from './Loader';

const Creategroup = () => {

    const [showloader, setShowLoader] = useState({ display: "flex" });
    const [groupImage, setGroupImage] = useState("") as any;
    const [groupList, setGroupList] = useState([]) as any;
    const [userList, setUserList] = useState([]) as any;
    const [selectedGroups, setSelectedGroups] = useState([]) as any;
    const [selectedUsers, setSelectedUsers] = useState([]) as any;
    const [terms, setTerms] = useState(false);
    const [imageURL, setImageURL] = useState("") as any;
    const [valUsers, setValUsers] = useState([]) as any;
    const [valGroups, setValGroups] = useState([]) as any;

    const [createGroupForm, setCreateGroupForm] = useState({
        group_name: '',
        description: '',
        invite_all: '0',
        group_type: 'P',
        joining_fee: ''
    }) as any;


    const customStyles = {
        control: () => ({
            minHeight: "46px",
            border: "1px solid #E5E5E5",
            borderRadius: "10px",
            display: "flex",
            fontSize:window.innerWidth<=500?"14px":"16px"
        })
    }

    let history = useHistory();
    let user_id = localStorage.getItem("user_id") as any;

    useEffect(() => {
        getGroupList();
        getUserListing();
    }, []);

    function getGroupList() {
        userService.getGroupList().then(resp => {
            var tempGroups = [] as any;
            var joinedGroup = resp.data.data.joined;
            if (joinedGroup.length) {
                joinedGroup.forEach((value: any) => {
                    tempGroups.push({ value: value.group_id, label: value.group_name });
                })
            }
            setGroupList(tempGroups);
            toggleLoader("none");
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            toast.error('Group list not loaded.')
            toggleLoader("none");
        });
    }

    function getUserListing() {
        userService.getUserListing().then(resp => {
            var tempUsers = [] as any
            var users = resp.data.data;
            if (users.length) {
                users.forEach((value: any) => {
                    tempUsers.push({ value: value.id, label: `${value.firstname} ${value.lastname}` });
                })
            }
            setUserList(tempUsers)
            toggleLoader("none");
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            toast.error('Group list not loaded.')
            toggleLoader("none");
        })
    }

    function submitGroup(event: any) {
        event.preventDefault();
        if (!groupImage) {
            toast.error("Please select Image.")
            return false
        }
        if (!createGroupForm.group_name.trim()) {
            toast.error("Please enter group name.")
            return false
        }
        if (!createGroupForm.description.trim()) {
            toast.error("Please enter group description.")
            return false
        }
        var invited = false
        if (selectedUsers.length || selectedGroups.length) {
            invited = true
        }

        if(createGroupForm.group_type === "P"){
            if (!invited && createGroupForm.group_type === "P" && !parseInt(createGroupForm.invite_all)) {
                toast.error("Please invite users or group.")
                return false
            }
            if (createGroupForm.group_type === "P" && createGroupForm.joining_fee === "") {
                toast.error("Please enter joining fee.")
                return false
            }
            if (createGroupForm.group_type === "P" && !terms) {
                toast.error("Please check the box notifying Xcellent Life that you have read and accept the terms for paid group creation.")
                return false
            }
        }else{
            if (!invited && !parseInt(createGroupForm.invite_all)){
                toast.error("Please invite users or group.")
                return false
            }
        }
        
        toggleLoader("flex");

        var formData = new FormData();
        Object.keys(createGroupForm).forEach((value: any) => {
            formData.append(value, createGroupForm[value])
        });

        formData.append("user_id", user_id);
        formData.append("member_id", selectedUsers.toString().trim());
        formData.append("group_ids", selectedGroups.toString().trim());
        formData.append("group_image", imageURL);

        createGroup(formData);
    }


    function createGroup(formData:any){
        userService.createGroup(formData).then(resp => {
            toast.success(resp.data.message);
            toggleLoader("none");
            localStorage.setItem("groupTab", "first");
            history.push('/group');
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            if (err.response) {
                toast.error(err.response.data.message);
            }
            toggleLoader("none");
        })
    }

    function handleMultiSelect(e: any, type: any) {

        if (type === "group") {
            var tempGroups = [] as any;
            var vals = [] as any;
            if (e.length) {
                e.forEach((value: any) => {
                    tempGroups.push(value.value);
                    vals.push({ value: value.value, label: value.label });
                })
            }
            setSelectedGroups(tempGroups);
            setValGroups(vals)
        }

        if (type === "user") {
            var tempUsers = [] as any;
            var vals1 = [] as any;
            if (e.length) {
                e.forEach((value: any) => {
                    tempUsers.push(value.value);
                    vals1.push({ value: value.value, label: value.label });
                })
            }
            setSelectedUsers(tempUsers);
            setValUsers(vals1);
        }
    }

    function readURL(input: any) {
        const image = new Image();
        if (input.files && input.files[0]) {
            image.src = URL.createObjectURL(input.files[0]);
            image.onload = function () {
                if (image.width > 2500 || image.height > 2000) {
                    toast.error(`Image size is too large, Please select image with smaller size with dimension: 2500px X 2000px`);
                    return false;
                } else {
                    var exts = ['png', 'jpg', 'jpeg', 'gif'];
                    let splittedFileName = input.files[0].name.split('.')
                    let fileExtension = splittedFileName[splittedFileName.length - 1];
                    fileExtension=fileExtension.toLowerCase();
                    let isFileValid = exts.includes(fileExtension);
                    if (isFileValid) {
                        setImageURL(input.files[0])
                        var reader = new FileReader();
                        reader.onload = function (e: any) {
                            setGroupImage(e.target.result);
                        }
                        reader.readAsDataURL(input.files[0]);
                    } else {
                        toast.error('Only png, jpg, jpeg, gif formats are allowed')
                        return false;
                    }
                    // OLD CODE DO NOT REMOVE
                    // var get_ext = input.files[0].name.split('.');
                    // get_ext = get_ext.reverse();
                    // var a = exts.indexOf(get_ext[0].toLowerCase());
                    // if (a > -1) {
                    //     setImageURL(input.files[0])
                    //     var reader = new FileReader();
                    //     reader.onload = function (e: any) {
                    //         setGroupImage(e.target.result);
                    //     }
                    //     reader.readAsDataURL(input.files[0]);
                    // } else {
                    //     toast.error('Please select valid image file.')
                    //     return false;
                    // }
                }
            }
        } else {
            setGroupImage('');
            setImageURL('');
        }
    }


    // for same file choose
    function onInputClick(event: any) {
        event.target.value = '';
    }

    function toggleLoader(status: any) {
        setShowLoader(prevShowLoader => {
            return {
                ...prevShowLoader,
                display: status
            }
        });
    }


    function handleFormInput(event: any) {
        let { name, value } = event.target as any;
        setCreateGroupForm((prevCreateGroupForm: any) => {
            return {
                ...prevCreateGroupForm,
                [name]: value
            }
        })
    }

    return (
        <>
            <section className="bodyvital_section py-5">
                <Loader status={showloader.display} />
                <Container>
                    <div className="inner_box pb-4">
                        <Row className="bodyvitals_head">
                            <Col md={12}>
                                <h5><Link to="/group"><img src={require('../images/back.svg').default} alt="back" /></Link></h5>
                                <h2>Create Group</h2>
                            </Col>
                            <Col md={12}><hr /></Col>
                        </Row>
                        <Form onSubmit={(event: any) => submitGroup(event)} autoComplete="off">
                            <Row className="align-items-center">
                                <Col md={4} className="text-center mb-3">
                                    <div className="prilfe_image position-relative my-4">
                                        {!groupImage ?
                                            <button className="btn bg-transparent brder-0 camerabtn" type="button" style={{ left: "40%" }}>
                                                <img src={require('../images/camera.svg').default} alt="camera" width="35px" />
                                                <Form.Group className="mb-3" controlId="profilePic">
                                                    <Form.Control type="file" className="input103 upload-profile-pic" multiple={false} onChange={(e: any) => readURL(e.target)} onClick={(e: any) => onInputClick(e)} />
                                                </Form.Group>
                                            </button>   
                                            : ""
                                        }
                                        {groupImage ? <div className={`${groupImage ? 'bg-transparent' : ''} profile_box main_profile m-auto`} >
                                            <img src={groupImage} alt="profile_box" width="90px" />
                                            <Form.Group className="mb-3" controlId="profilePic">
                                                <Form.Control type="file" className="input103 upload-profile-pic" multiple={false} onChange={(e: any) => readURL(e.target)} onClick={(e: any) => onInputClick(e)} />
                                            </Form.Group>
                                        </div> : ''}
                                    </div>
                                    {/* <div className="gorup_Details_head grup_chat_head">
                                    <div className="user_image_row1 user_image_row1_upload_image">
                                        <i className="fa fa-camera"></i>
                                        <input onChange={(e: any) => readURL(e.target)} type="file" name="fileToUpload" id="fileToUpload"  />
                                    </div>
                                </div> */}
                                </Col>
                                <Col md={8}>
                                    <div className="create_group">
                                        <Form.Group className="mb-3" controlId="groupName">
                                            <Form.Label>Group Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Group Name" name="group_name" value={createGroupForm.group_name ? createGroupForm.group_name : ''} onChange={(event: any) => handleFormInput(event)} maxLength={30} />
                                        </Form.Group>
                                    </div>
                                    <div className="create_group">
                                        <Form.Group className="mb-3" controlId="description">
                                            <Form.Label>Description</Form.Label>
                                            <textarea placeholder="Enter description" className="input100" rows={3} name="description" value={createGroupForm.description ? createGroupForm.description : ''} onChange={(event: any) => handleFormInput(event)} maxLength={500} />
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                {parseInt(createGroupForm.invite_all) === 0
                                    ?
                                    <>
                                        <Col md={4} className="text-center mb-3">
                                            <div className="text-left">
                                                <Form.Group className="mb-3" controlId="inviteUser">
                                                    <Form.Label>Invite Users</Form.Label>
                                                    <div className="add_create_group_input position-relative">
                                                        <Select styles={customStyles} closeMenuOnSelect={false} isClearable={true} onChange={(e: any) => { handleMultiSelect(e, 'user') }} options={userList} value={valUsers} isMulti={true} placeholder="Select users" />
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={4} className="text-center mb-3">
                                            <div className="text-left">
                                                <Form.Group className="mb-3" controlId="inviteGroup">
                                                    <Form.Label>Invite Group</Form.Label>
                                                    <div className="add_create_group_input position-relative">
                                                        <Select styles={customStyles} closeMenuOnSelect={false} isClearable={true} onChange={(e: any) => { handleMultiSelect(e, 'group') }} options={groupList} value={valGroups} isMulti={true} placeholder="Select groups" />
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </Col>

                                    </>
                                    : ""
                                }
                                <Col md={4} className="text-center mb-3">
                                    <div className="create_group text-left">
                                        <Form.Group controlId="inviteAll">
                                            <Form.Label>Invite all individual</Form.Label>
                                            <Form.Control as="select" name="invite_all" value={createGroupForm.invite_all ? parseInt(createGroupForm.invite_all) : ''} onChange={(event: any) => handleFormInput(event)} >
                                                {/* <option value={1}>Invite All</option> */}
                                                <option value={1}>Yes</option>
                                                <option value={0}>No</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </div>
                                </Col>
                                <Col md={4} className="text-center mb-3">
                                    <div className="create_group text-left">
                                        <Form.Group controlId="groupType">
                                            <Form.Label>Group Type</Form.Label>
                                            <Form.Control as="select" name="group_type" value={createGroupForm.group_type ? createGroupForm.group_type : ''} onChange={(event: any) => handleFormInput(event)}>
                                                <option value="P">Paid</option>
                                                <option value="F">Free</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </div>
                                </Col>

                                {createGroupForm.group_type === 'P' ?
                                    <>
                                        <Col md={4} className="text-center mb-3">
                                            <div className="create_group text-left">
                                                <Form.Group className="mb-3" controlId="joining_fee">
                                                    <Form.Label>Joining Fee (In Dollars)</Form.Label>
                                                    <Form.Control type="text" placeholder="Joining fee" name="joining_fee" value={createGroupForm.joining_fee ? createGroupForm.joining_fee : ''} onChange={(event: any) => { if (!isNaN(event.target.value)) { handleFormInput(event) } }} maxLength={3} />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={12}><hr /></Col>
                                        <Col md={12} className="text-left mb-3">
                                            <Form.Group className="mb-3 create_group_checkbox" controlId="formBasicCheckbox">
                                                <Form.Check onClick={(e: any) => { setTerms(e.target.checked) }} type="checkbox" label="All Group fees will be paid out to Xcellent Life initially. At the end of the month, Xcellent Life will pay out 80% of the group fees to the owner of this group while Xcellent Life will retain 20%. All retained fees go towards improving health and wellness as well as research and development. Please check the box notifying Xcellent Life that you have read and accept the terms for paid group creation." />
                                            </Form.Group>
                                        </Col>
                                    </>
                                    : ""}
                                <Col md={12} className="text-center">
                                    <Button variant="unset" type="submit" className="btnsumit my-2 text-white">Create Group</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Creategroup;