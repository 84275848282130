import React,{ useEffect, useState } from 'react';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { userService } from '../_services';
import { Loader } from './Loader';
import ProfileImage from '../images/profile.png';


const AddFriends = () => {
    const [show, setShow] = useState("none");
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [toShow, setToShow] = useState(20);
    const [showData, setShowData] = useState([]);
    const [loadBtn, setLoadBtn] = useState(true);
    
    useEffect(() => {
        localStorage.removeItem("groupTab");
        localStorage.removeItem("compTab");
        if (!search.length) {
            setShow("flex");
        }
        userService.addFriendListing({ "name": search }).then(resp => {
            var temp = resp.data.data;
            if (temp) {
                for (let index = 0; index < temp.length; index++) {
                    const element = temp[index];
                    if (index < toShow) {
                        element.show = true;
                    } else {
                        element.show = false;
                    }
                }
                setData(resp.data.data);
                setShowData(temp);
            }else{
                // setData([]);
                // setShowData([]);
            }
            setShow("none");
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            setShow("none")
        })
    }, [search])

    function handleShow(event: any) {
        var tempData = data;
        var tShow = toShow;
        for (let index = toShow; index < tempData.length; index++) {
            const element = tempData[index] as any;
            if (index < tShow + 20) {
                element.show = true;
                console.log(index, tempData.length - 1);
                if (index == tempData.length - 1) {
                    setLoadBtn(false);
                }
            } else {
                element.show = false;
            }

        }
        setToShow(tShow + 20);
        setShowData(tempData);
    }

    return (
        <>
            <section className="bodyvital_section friend_sections py-5">
                <Loader status={show} />
                <Container>
                    <div className="inner_box pb-4">
                        <Row className="align-items-center">
                            <Col lg={8} md={7}>
                                <h2 className="d-inline">Search Friends</h2>
                                <Form.Group className="mb-0 d-inline-block ml-2 searchfrnd_input" controlId="searchFriend" style={{ minWidth: "260px" }}>
                                    <div className="search_bar_row">
                                        <Form.Control onChange={(e: any) => { setSearch(e.target.value) }} type="text" placeholder="Type here to search" />
                                        <span><img src={require('../images/search.svg').default} alt="search" width="18px" /></span>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <hr />
                            </Col>
                        </Row>

                        <Row>
                            {showData ?
                                showData.map((f: any, i: any) => {
                                    return (
                                        <React.Fragment key={`${f.id}_${i}`}>
                                            {
                                                f.show
                                                    ?
                                                    <Col lg={6} md={6} sm={6}>
                                                        <Link className="users_box mb-3" to={`/sendfriendrequest?uid=${btoa(f.id)}`}>
                                                            <div className="invite_user_image">
                                                                {f.profileImage == 'https://nlpbackend.xcellentlife.com/profile/'
                                                                    ?
                                                                    <img src={require('../images/usericon.svg').default} alt="usericon"  onError={(event: any) => { event.target.src = ProfileImage; }}/>
                                                                    :
                                                                    <img src={f.profileImage} alt="profile" onError={(event: any) => { event.target.src = ProfileImage; }} style={{ width: "100%", height: "100%", objectFit: "cover" }}/>
                                                                }
                                                            </div>
                                                            <span>{f.firstname} {f.lastname}</span>
                                                            <div className="invite_btn1"><i className="fa fa-angle-right nextarrow" /></div>
                                                        </Link>
                                                    </Col>: ""
                                            }
                                        </React.Fragment>
                                    )
                                }):
                                <Col className="text-center mt-3"><p>No data found</p></Col>
                            }

                            {/* Prev Next */}

                            {
                                data.length > 10 && loadBtn ?
                                    <>
                                        <Col md={12} lg={12} className="text-center">
                                            <Button className="page-btn px-3" variant="unset" onClick={(e: any) => { handleShow(e) }}>Load more</Button>
                                        </Col>
                                    </>
                                    : ""
                            }
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default AddFriends;