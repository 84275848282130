import { useState, useEffect } from 'react';
import { Form } from "react-bootstrap";
import { userService } from '../_services';
import { Modal } from 'react-bootstrap';
import { Loader } from './Loader';
import { useHistory } from 'react-router-dom';
import { Formik } from "formik";
import * as Yup from "yup";
import { SIGN_UP_QUESTION } from '../config/constants';

const SignUp = () => {
	const [showLoader, setShowLoader] = useState({ display: "none" });
	const [message, setMessage] = useState('') as any;
	const [showErr, setShowErr] = useState(false);
	const [signUpFormObj, setSignUpFormObj] = useState({
		email: '',
		promocode: '',
		securityQuestion: '',
		securityAnswer: '',
		password: '',
		cpassword: ''
	});
	const loginSchema = Yup.object().shape({
		email: Yup.string().email().required("E-mail field can’t be empty."),
		password: Yup.string().required("Password field can’t be empty."),
		cpassword: Yup.string().required("Confirm Password field can’t be empty.").oneOf([Yup.ref('password')], 'Your passwords do not match.'),
		promocode: Yup.string(),
		securityQuestion: Yup.string().required("Security Question field can’t be empty."),
		securityAnswer: Yup.string().required("Security Answer field can’t be empty."),
	});
	const history = useHistory();
	const handleCloseE = () => setShowErr(false);
	// let questions = ["What is the name of the person you first kissed?", "What is the name of teacher who gave you first failing grade?", "What is the name of place where your marriage reception was held?", "What is name of first school you attended?", "Where you were when you first heard about 9/11?", "What is your oldest cousin's first and last name?"];

	useEffect(() => {
		let token = localStorage.getItem("accesstoken");
		let search = window.location.search;
		let params = new URLSearchParams(search);
		let referral_code = params.get("referralCode") as any;
		if (token) {
			if(referral_code){
				// check if compitition id exist in then redirect to competition detail page 
				let compId = referral_code.split('_');
				if (compId.length > 1) {
					localStorage.setItem('comp_id', compId[compId.length - 1]);
					history.push(`/competitiondetails?comp_id=${compId[compId.length - 1]}`);
				}
			}else{
				window.location.href="/";
			}
		} else {
			if (referral_code) {
				setSignUpFormObj((prevSignUpFormObj: any) => {
					return {
						...prevSignUpFormObj,
						promocode: referral_code
					}
				});
				localStorage.setItem('referral_code', referral_code);
			}
		}
	}, []);



	const register = (values: any) => {
		toggleLoader("flex");
		let token = localStorage.getItem("firebaseToken") as any;
		let registerParams = {
			email: values.email,
			promocode: values.promocode,
			securityQuestion: values.securityQuestion,
			securityAnswer: values.securityAnswer,
			password: values.password,
			deviceId: "",
			deviceType: "w",
			unique_device_id: ""
		};

		if (token) {
			registerParams.deviceId = token;
			registerParams.unique_device_id = localStorage.getItem("udid") as any;
		}

		userService.register(registerParams).then(function (response: any) {
			localStorage.setItem('accesstoken', response.data.data.access_token);
			localStorage.setItem('user_id', response.data.data.user.id);
			localStorage.setItem('referCode', response.data.data.user.referral_code);
			window.location.href = "/create_profile";
			toggleLoader("none");
		}).catch(function (error: any) {
			toggleLoader("none");
			if (error.response.status == 422) {
				setShowErr(true);
				if(error.response.data.errors){
					setMessage(error.response.data.errors.email);
				}else{
					setMessage(error.response.data.message);
				}
			} 
			if (error.response.status == 505) {
				setShowErr(true);
				setMessage(error.response.data.data.message);
			} 

		});
	}

	// common loader function
	function toggleLoader(status: any) {
		setShowLoader(prevShowLoader => {
			return {
				...prevShowLoader,
				display: status
			}
		});
	}

	return (
		<section className="loginsec">
			<Loader status={showLoader.display} />
			<div className="right_side py-5">
				<div className="container">
					<div className="row">
						<aside className=" col-lg-7 col-sm-12 m-auto">
							<div />
							<div className="login_form mt-4">
								<div className="text-center">
									<h4>Sign Up</h4>
									<p>{`Let's get started with us.`}</p>
									<img src={require('../images/line.svg').default} alt="line" />
								</div>
								<Formik
									initialValues={signUpFormObj}
									validationSchema={loginSchema}
									enableReinitialize={true}
									onSubmit={(values) => {
										register(values);
									}}>
									{
										({ errors, touched, values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
											<Form className="mt-4" autoComplete="off" onSubmit={handleSubmit}>
												<Form.Group className="mb-3" controlId="email">
													<Form.Control type="email" placeholder="Email*" className="input103" value={values.email ? values.email : ''} onChange={handleChange} />
													{errors.email && touched.email ? (<label id="uname-error" className="error" htmlFor="email"> {errors.email}</label>) : null}
												</Form.Group>
												<Form.Group className="mb-3" controlId="password">
													<Form.Control type="password" placeholder="Password*" className="input103" value={values.password ? values.password : ''} onChange={handleChange} />
													{errors.password && touched.password ? (<label id="uname-error" className="error" htmlFor="password"> {errors.password}</label>) : null}
												</Form.Group>
												<Form.Group className="mb-3" controlId="cpassword">
													<Form.Control type="password" placeholder="Confirm Password*" className="input103" value={values.cpassword ? values.cpassword : ''} onChange={handleChange} />
													{errors.cpassword && touched.cpassword ? (<label id="uname-error" className="error" htmlFor="confirmPassword"> {errors.cpassword}</label>) : null}
												</Form.Group>
												<h5 className="mt-4">Security Question</h5>
												<p className="text-left mb-3">(Used for security when re-setting your password).</p>
												<Form.Group className="mb-3" controlId="securityQuestion">
													<Form.Control as="select" className="input103 mb-3" value={values.securityQuestion ? values.securityQuestion : ''} onChange={handleChange}>
														<option>Choose one</option>
														{
															SIGN_UP_QUESTION.map(function (ques: any, index: any) {
																return (<option value={ques} key={`${ques}_${index}`}>{ques}</option>)
															})
														}
													</Form.Control>
													{errors.securityQuestion && touched.securityQuestion ? (<label id="uname-error" className="error" htmlFor="securityQuestion"> {errors.securityQuestion}</label>) : null}
												</Form.Group>
												<Form.Group className="mb-3" controlId="securityAnswer">
													<Form.Control type="text" placeholder="Answer*" className="input103" value={values.securityAnswer ? values.securityAnswer : ''} onChange={handleChange} />
													{errors.securityAnswer && touched.securityAnswer ? (<label id="uname-error" className="error" htmlFor="securityAnswer"> {errors.securityAnswer}</label>) : null}
												</Form.Group>
												<Form.Group className="mb-3" controlId="promocode">
													<Form.Control type="text" placeholder="Referral Code" className="input103" value={values.promocode ? values.promocode : ''} onChange={handleChange} />
													{errors.promocode && touched.promocode ? (<label id="uname-error" className="error" htmlFor="promocode"> {errors.promocode}</label>) : null}
												</Form.Group>
												<div className="text-center mt-4">
													<button type="submit" className="loginbtn btn shadow text-white" >Register</button>
												</div>
												<div className="text-center mt-3 mb-4">
													<p>Already have an account ?   <a href="/login" className="signup_text">Login</a></p>
												</div>
											</Form>
										)
									}
								</Formik>
							</div>
						</aside>
					</div>
				</div>
			</div>
			<Modal show={showErr} onHide={handleCloseE} size="sm">
				<Modal.Header closeButton className="border-0 headerclose">
				</Modal.Header>
				<Modal.Body className="text-center py-4">
					<img src={require('../../src/images/check.svg').default} alt="check" />
					<h5 className="thnkutext mt-3 mb-1">Try Again!</h5>
					<p className="mb-0">{message?message:'Something went wrong Please try again later.'}</p>
				</Modal.Body>
			</Modal>
		</section>
	)
}

export default SignUp;